import { trigger, transition, style, animate } from '@angular/animations';
import { SCREEN_SIZE } from '../../../../shared/services/enums/screen-size.enum';
import { DeviceDetectorService } from '../../../../shared/services/device-detector.service';
import {
  Component,
  Input,
  Output,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-single-review',
  templateUrl: './single_review.component.html',
  styleUrls: ['./single_review.component.scss'],
  animations: [
    trigger('image-in-out', [
      transition(':enter', [
        style({
          transform: 'translateY(100%)',
          opacity: 0,
        }),
        animate('0.3s ease'),
      ]),
      transition(':leave', [
        animate(
          '0.3s ease',
          style({
            transform: 'translateY(100%)',
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class SingleReviewComponent implements OnInit {
  @Input() review: any;
  @Input() currentLoc: any;

  @Output() imageModalEmitter = new EventEmitter();


  displayAllReview = false;

  numberOfCharacters = 100; // Number of characters to display for each review. Depends on height of picture. Default is 75.
  size: SCREEN_SIZE;

  @ViewChild('ImageContainer', { read: ElementRef, static: false })
  imageContainer: ElementRef;

    
    constructor(private resizeSvc: DeviceDetectorService) {
      this.size = resizeSvc.getCurrentSize();

      resizeSvc.onResize$.subscribe((x) => {
        this.size = x;
      });
    }

  ngOnInit(): void { }






}
