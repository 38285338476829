import { SearchPageComponent } from './search_page/search_page.component';
import { SearchResultsComponent } from './results_page/search_results.component';
import { RouterModule } from '@angular/router';
import { SearchResortDetailMainComponent } from './search_resort_detail/search_resort_detail_main/search_resort_detail_main.component';

const routes = [
  {
    //path: 'search-resorts/:town/:id',
    path: 'search-ski-resorts/:town/:id',
    component: SearchResortDetailMainComponent,
  },
  {
    //path: 'search-resorts/results',
    path: 'search-ski-resorts/results',
    component: SearchResultsComponent,
  },
  {
    //path: 'search-resorts',
    path: 'search-ski-resorts',
    component: SearchPageComponent,
  },
];

export const SearchRoutes = RouterModule.forChild(routes);
