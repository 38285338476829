import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-details-weather',
  templateUrl: './details_weather.component.html',
  styleUrls: ['./details_weather.component.scss'],
})
export class DetailsWeatherComponent implements OnInit {

  @Input() wambrella: any;

  constructor(private analytics: AngularFireAnalytics) {}

  ngOnInit(): void {
  }

  goToLink(url: string):void {
    window.open(url, "_blank");
  }

  logEvent() {
    this.analytics.logEvent('RAT_user_clicked_partner_link', {partnerName: 'wambrella' });
  }
}
