
export class GetUserRatingByLocation {
  static readonly type = 'GetUserRatingByLocation';
  constructor(public locationId: number, public userId: string) {}
}

export class SaveRating {
  static readonly type = 'SaveRating';
  constructor(public locationId: number, public subCategoryId: number, public ratingNumber: number,
              public userId: string, public questionName: string, public questionCategory: string) {}
}

export class SaveRatingTest {
  static readonly type = 'SaveRatingTest';
  constructor(public locationId: number, public userId: string, public dataContainer: any) {}
}

export class SaveSkiGroup {
  static readonly type = 'SaveSkiGroup';
  constructor(public locationId: number, public skiGroupId: number, public userId: string, public skiGroupTitle: string, public riderType: string) {} 
}


export class ResetRatingState {
  static readonly type = 'ResetRatingState';
}

export class SaveWrittenReview {
  static readonly type = 'SaveWrittenReview';
  constructor(public locationId: number, public review: string, public uid: string, public selectedPhoto?, public filePath?) {}
}

export class GetAllReviewsFromLocation {
  static readonly type = 'GetAllReviewsFromLocation';
  constructor(public locationId: number) {}
}


