<div class="details-container w-100">
  <div class="text-center everything-you-need">
    Everything you need for your next ski trip&mdash;all in one place!
  </div>
  <div *ngIf="!mobile" class="row w-100 d-flex justify-content-center">
    <div *ngFor="let feature of features" class="col-md-4 col-sm-6">
      <div class="d-flex h-100 flex-column align-items-center white-box">
        <div class="icon">
          <img
            [src]="'assets/images/icons/' + feature.image"
            alt="{{ feature.title }}"
          />
        </div>
        <h4 class="feature-title w-100 text-center">{{ feature.title }}</h4>
        <p class="feature-text mb-0">
          {{ feature.text }}
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="mobile">
    <div class="home-carousel" [@slideAnimation]="index">
      <ng-template ngFor [ngForOf]="features" let-feature let-i="index">
        <div
          (panleft)="incrementIndex()"
          (panright)="decrementIndex()"
          *ngIf="i == index"
          class="carousel-col"
        >
          <div class="d-flex h-100 flex-column align-items-center white-box">
            <div class="icon">
              <img
                [src]="'assets/images/icons/' + feature.image"
                alt="{{ feature.title }}"
              />
            </div>
            <h4 class="feature-title w-100 text-center">{{ feature.title }}</h4>
            <p class="feature-text mb-0">
              {{ feature.text }}
            </p>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="d-flex mt-3 justify-content-center">
      <div class="index-tracker d-flex justify-content-between">
        <ng-template ngFor [ngForOf]="features" let-i="index">
          <div
            class="box"
            [ngClass]="{
              'bg-ratoong': i == index,
              'bg-grey': i != index
            }"
          ></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
