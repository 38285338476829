import { ScrollDirective } from './../ui/events/scroll.directive';
import { SignupEmailComponent } from '../ui/modals/login_popup/signup_email/signup_email.component';
import { LoginEmailComponent } from '../ui/modals/login_popup/login_email/login_email.component';
import { LoginSignupEmailComponent } from '../ui/modals/login_popup/login_signup_email/login_signup_email.component';
import { LoginPopupComponent } from '../ui/modals/login_popup/login_popup.component';
import { ProfanityFilterPipe } from './../pipes/profanity-filter.pipe';
import { BypassSanitizePipe } from './../pipes/bypass-sanitize.pipe';
import { TitleSectionComponent } from './../ui/title-section/title-section.component';
import { DropDownComponent } from './../ui/drop_down/drop_down.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SearchBarComponent } from '../ui/search_bar/search_bar.component';
import { PisteModalComponent } from '../ui/modals/piste_modal/piste_modal.component';
import { PasswordModalComponent } from '../ui/modals/password_modal/password_modal.component';
import { PasswordForgotModalComponent } from '../ui/modals/password_forgot_modal/password_forgot_modal.component';
import { LocationAdderComponent } from '../ui/modals/location_adder/location_adder.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UrlNamePipe } from '../pipes';
import { LoginSignupStartComponent } from '../ui/modals/login_popup/login_signup_start/login_signup_start.component';
import { LanguageSwitchComponent } from '../ui/language_switch/language_switch.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (http:HttpClient) => { return new TranslateHttpLoader(http, './assets/locales/','.json');},
          deps: [HttpClient]
        }
      }
    )
  ],
  declarations: [
    LanguageSwitchComponent,
    SearchBarComponent,
    LocationAdderComponent,
    PisteModalComponent,
    PasswordForgotModalComponent,
    PasswordModalComponent,
    LoginPopupComponent,
    LoginSignupStartComponent,
    LoginSignupEmailComponent,
    LoginEmailComponent,
    SignupEmailComponent,
    TitleSectionComponent,
    DropDownComponent,
    UrlNamePipe,
    BypassSanitizePipe,
    ProfanityFilterPipe,
    ScrollDirective,
  ],
  exports: [
    LanguageSwitchComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SearchBarComponent,
    LocationAdderComponent,
    PisteModalComponent,
    PasswordForgotModalComponent,
    PasswordModalComponent,
    LoginPopupComponent,
    TitleSectionComponent,
    DropDownComponent,
    BypassSanitizePipe,
    ProfanityFilterPipe,
    ScrollDirective,
  ],
  entryComponents: [],
})
export class SharedModule {}
