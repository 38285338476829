import { trigger, transition, style, animate, group } from '@angular/animations';
import { newSearchParameters } from '../../../shared/states/auth/entities/preference_entities/new_params';
import { SubCategory } from '../../../shared/states/preference/entities/subCategory';
import { PreferenceState } from '../../../shared/states/preference/preference.state';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DeepLocation } from '../../../shared/states/location/entities/deepLocations';
import { Observable, Subscription } from 'rxjs';
import { ModalService } from '../../../shared/services';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AuthState } from '../../../shared/states/auth/auth.state';
import { AuUser } from '../../../shared/states/auth/entities/user_interface';

@Component({
  selector: 'app-details-ratings',
  templateUrl: './details_ratings.component.html',
  styleUrls: ['./details_ratings.component.scss'],
  animations: [
    trigger('expandSection', [
      transition(':enter', [
        style({
          height: 0,
          opacity: 0,
          visibility: 'hidden'
        }),
        animate('0.4s ease'),
      ]),
      transition(':leave', [group([
        animate('0.4s ease', style({
            opacity: 0
        })),
        animate('0.4s 0.2s ease', style({
            height : 0
        })),
        animate('0.4s ease', style({
            visibility: 'hidden'
        }))
    ]
    )]),
    ]),
  ],
})
export class DetailsRatingsComponent implements OnInit, OnDestroy {
  @Input() currentLoc: DeepLocation;
  subscription: Subscription;
  newParams = newSearchParameters;
  thePiste = false;
  thePark = false;
  theLift = false;
  theTown = false;
  theSustainability = false;
  theArea = false;
  overall = 0;
  totalRatings = 0;

  clicked = false;

  redirectToRate = false;


  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;

  @Select(PreferenceState.listOfSubRatings) questions: Observable<
    SubCategory[]
  >;
  listOfQuestions: SubCategory[];
  thePisteGroup = [];
  theParkGroup = [];
  theLiftsGroup = [];
  theTownGroup = [];
  theSustainabilityGroup = [];
  theAreaGroup = [];

  asteriskQuestions = [6, 32];

  modalSubscription: Subscription;

  constructor(
    private router: Router,
    private store: Store,
    private modalService: ModalService,
    private analytics: AngularFireAnalytics
  ) {
    this.modalSubscription = modalService.onChange$.subscribe(() => {
      if (!this.modalService.get('Login-Popup')) {
        if (!this.currentU) {
          this.redirectToRate = false;
        }
        this.checkChanges();
      }
    });
  }

  // Populate the arrays for each group that hold the individual questions
  populateGroupArrays(): void {
    // Loop through each question in our database
    this.listOfQuestions.forEach((question) => {
      // For each question create an object with a 'title', 'score' and 'isNew' field
      let ratingObject = {};
      ratingObject['title'] = question.questionText;
      ratingObject['isNew'] = this.newParams.includes(question.id);
      ratingObject['isAsterisk'] = this.asteriskQuestions.includes(question.id);
      let score = 0; // initialise 'score' to 0
      this.totalRatings = 0;
      // Loop through the location scores (if any)
      this.currentLoc.locationScores.forEach((rating) => {
        // Sum the total number of ratings
        this.totalRatings = this.totalRatings + rating.overallCount;
        // If this question has a rating, set it to 'score'
        if (Number(rating.questionID) === question.id) {
          score = Number(rating.overallScore);
        }
      });
      ratingObject['score'] = score; // Add the score to the object
      // Add a grey filter to the ratings with 0 score
      if (ratingObject['score'] === 0){
        ratingObject['filter'] = 'filter';
      }
      // Amd add the object to the corresponding array based on the category id
      if (question.categoryId === 1) {
        this.thePisteGroup.push(ratingObject);
      } else if (question.categoryId === 2) {
        this.theParkGroup.push(ratingObject);
      } else if (question.categoryId === 4) {
        this.theLiftsGroup.push(ratingObject);
      } else if (question.categoryId === 5) {
        this.theTownGroup.push(ratingObject);
      } else if (question.categoryId === 6) {
        this.theSustainabilityGroup.push(ratingObject);
      } else if (question.categoryId === 7) {
        this.theAreaGroup.push(ratingObject);
      }
    });
  }

  ngOnInit(): void {
    // Get the current user
    this.subscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
    });
    // Get the list of questions
    this.questions.subscribe((data) => {
      this.listOfQuestions = data;
      // Once we have the list of questions, to call 'populateGroupArrays'
      if (this.listOfQuestions) {
        this.populateGroupArrays();
      }
    });
    this.overall = parseFloat(this.currentLoc.generalScore);
  }

  calculateAverage(group: any[]): any {
    let totalCount = 0;
    let sum = 0;
    for (let i = 0; i < group.length; i++) {
      if (group[i]['score'] !== 0) {
        sum += group[i]['score'];
        totalCount++;
      }
    }
    if (totalCount !== 0) {
      const num = sum / totalCount;
      return Math.round((num + Number.EPSILON) * 100) / 100;
    } else if (totalCount == 0){
      return 0;
    }
  }

  checkChanges(content?: any): any {
    // The modal onChange$ will also be triggered when user logs in from the navbar (and redirect the user to rating the resort even if they didn't want to)
    // To get round this, redirectToRate is assigned to true when user is clicking on rating, so the function knows whether user is logging in to rate or just logging in in general.
    if (this.currentU && this.redirectToRate) {
      this.analytics.logEvent('went-to-ratings-in-info', {
        item: this.currentLoc.name,
      });

      this.router.navigate([
        '/rate-ski-resort/' + this.currentLoc.cityName + '/' + this.currentLoc.id,
      ]);
    } else if (content) {
      // this.modalService.onChange(false);
      this.modalService.open('Login-Popup', content, 'lg');
      this.modalService.onChange(false);
    }
  }

  goRate(content: any): any {
    this.redirectToRate = true;
    this.checkChanges(content);
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
    this.modalSubscription.unsubscribe();
  }

  changeState(number): any {
    if (number == 1) {
      this.thePiste = !this.thePiste;
    } else if (number == 2) {
      this.thePark = !this.thePark;
    } else if (number == 3) {
      this.theLift = !this.theLift;
    } else if (number == 4) {
      this.theTown = !this.theTown;
    } else if (number == 5) {
      this.theSustainability = !this.theSustainability;
    } else if (number == 6) {
      this.theArea = !this.theArea;
    }
  }

  logEvent() {
    this.analytics.logEvent('RAT_user_clicked_partner_link', {partnerName: 'checkyeti' });
  }
}
