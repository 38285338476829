import { FirestorageService } from '../../../shared/services/firestorage.service';
import { Ambassador } from '../../../shared/states/ambassadors/entities/ambassador';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-ambassadors-item',
  templateUrl: './home_ambassadors_item.component.html',
  styleUrls: ['./home_ambassadors_item.component.scss'],
})
export class HomeAmbassadorsItemComponent implements OnInit {
  // Parent component detects if mobile and passes information to this component
  @Input() mobile: boolean;
  // Individual ambassadors are passed to this component from home-ambassadors.component
  @Input() ambassador: Ambassador;
  // Hold details of the image to render
  imageLink: string;
  flagLink: string;

  loaded = false;

  displayAllDescription: boolean = false;
 
  constructor(private storage: FirestorageService) {}

  ngOnInit(): any {
    this.setImageLink(this.ambassador);
  }

  private setImageLink(data: Ambassador): any {
    let imageToLoad: string;
    let flagToLoad: string;
    // For some annoying reason, firebase storage cannot find the file 'sm-Josefine-Lützau.png' even though it is in our storage!
    // I think it has to do with the 'ü' character. 
    // Temporary fix - as will soon be moved to about page soon anyway - is to load the image for 'sm-Josefine-Lutzau.png'
    if(data.image == 'Josefine-Lützau.png'){
      imageToLoad = 'sm-Josefine-Lutzau.png'
    }else{
      imageToLoad = 'sm-' + data.image;
    }

    flagToLoad = data.country + '.jpg'

    this.storage
      .getImageUrl('/ambassadors/', imageToLoad)
      .then((url: string) => {
        this.imageLink = url;
      });

    this.storage
      .getImageUrl('/flags/', flagToLoad)
      .then((url: string) => {
        this.flagLink = url;
      })
  }

  imageLoaded() {
    this.loaded = true;
  }
}
