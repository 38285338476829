<div class="modal-container d-flex flex-column align-items-center">
  <span><i (click)="closeModal('Login-Popup', true)" class="close fas fa-times"></i></span>
  <div class="d-flex flex-column align-items-center">
    <img class="logo mb-2" src="assets/images/logo.png" alt="Ratoong" />
    <h1 class="title">log in / sign up</h1>
    <p class="text text-center mb-1">{{subtitleOne}}</p>
    <p class="text text-center mb-3">{{subtitleTwo}}</p>
  </div>
  <div class="center d-flex flex-column align-items-center">
    <!-- <img class="logo mb-2" src="assets/images/logo.png" alt="Ratoong logo" />
    <h1 class="title">log in / sign up</h1>
    <p class="text mb-1">{{subtitleOne}}</p>
    <p class="text mb-3">{{subtitleTwo}}</p> -->
    <!-- ****** DIFFERENT COMPONENTS LOAD DEPENDING ON SITUATION ****** -->
    <!-- Load this component when user first loads login popup. Displays options for logn (google, facebook, email) -->
    <app-login-signup-start class="w-100" *ngIf="!loginWithEmail" (closeModal)="closeModal('Login-Popup', true)"
      (continueWithEmail)="setEnterEmailScreen()"></app-login-signup-start>
    <!-- Load this component when the user clicks 'login with email' but we don't yet know if the user has an account -->
    <app-login-signup-email class="w-100" (goBack)="setStartScreen()" (proceedToLogin)="setLoginScreen($event)"
      (proceedToSignup)="setSignupScreen($event)" *ngIf="loginWithEmail && !(hasAnAccount || doesntHaveAccount)">
    </app-login-signup-email>
    <!-- Load this login component when the user enters their email and it is registered with an account -->
    <app-login-email class="w-100" (goBack)="setStartScreen()" (closeModal)="closeModal('Login-Popup', true)"
      [userEmail]="userEmail" *ngIf="loginWithEmail && userEmail && hasAnAccount"></app-login-email>
    <!-- Load this signup component when the user enters their email and it is not yet registered with an account -->
    <app-signup-email class="w-100" (goBack)="setStartScreen()" (closeModal)="closeModal('Login-Popup', true)"
      [userEmail]="userEmail" *ngIf="loginWithEmail && userEmail && doesntHaveAccount" [dontRedirect]="dontRedirect">
    </app-signup-email>
    <div class="d-flex align-items-center justify-content-center skip mt-4" (click)="setStartScreen()"
      *ngIf="loginWithEmail">
      <img class="pr-2 anchor-tag align-self-center" src="assets/images/search/anchor_down.svg" alt="Login options" />
      <span class="anchor-text">All login options </span>
    </div>
  </div>
  <div class="terms-text text-center mt-auto">
    By proceeding, you agree to Ratoong's
    <a (click)="closeModal('Login-Popup', false)" [routerLink]="['/terms']" class="terms-link px-1">Privacy Policy.</a>
  </div>
</div>
