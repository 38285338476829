<!-- Title section -->
<div class="row">
  <div class="col-sm-8">
    <app-title-section title="Weather Conditions"
      subtitleOne="See how our partner Wambrella predict the comfort level for each day. The higher the comfort level the better they predict the day will be."
      imagePath="assets/images/info/weather_icon.svg">
      <h4 class="subtitle">
        Read more about how they calculate it here at <a (click)="logEvent()" class="wambrella-link"
          href="https://www.wambrella.com/en/Information/WhatsThat#" target="_blank"
          title="Go to the Wambrella website">Wambrella.</a>
      </h4>
      <div class="forecast-text mt-3 mb-4">
        Nearest forecast:
        <span [ngClass]="{
          green: wambrella.distance < 10,
          yellow: wambrella.distance >= 10 && wambrella.distance < 20,
          red: wambrella.distance >= 20
        }">{{ wambrella.distance | number: "1.0-0" }} km</span>
        away
      </div>
    </app-title-section>
  </div>
  <div class="wambrella-icon">
    <a (click)="logEvent()" href="https://www.wambrella.com/" target="_blank">
      <img class="img-fluid" src="assets/images/info/wambrella.svg" alt="Weather condition" />
    </a>
  </div>
</div>
<iframe
  class="iframe"
  [src]="'https://www.wambrella.com/en/Resorts/Chart/' + wambrella.eventProviderId + '?activity=' + wambrella.eventId + '&descr=false&tabs=false' | bypassSanitize"
  title="Wambrella Weather Forecast"></iframe>
