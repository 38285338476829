
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import {AdminsUsers} from './entities/AdminUser';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../../../../shared/states/auth/auth.service';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthService {
  database: any = null;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private auth: AuthService,
    private analytics: AngularFireAnalytics) {
    this.database = firebase.database();
  }

  async createNewUser(referralID: string, email: string, password: string): Promise<any> {
    const userExists = await this.checkEmailExists(email);
    const ref = await this.checkIfRefExists(referralID);
    if (!ref){
      throw new Error('No such referral');
    }
    if (userExists.length !== 0) {
      // User exists
      await this.afAuth.signInWithEmailAndPassword(email, password);
      this.analytics.logEvent('RAT_partner_has_signed_up', {type: 'Pre Existing User' });
      return await this.updatePartnerData(email, ref.partnerID, ref.resortID, ref.townIds, ref.roleID, ref.roleName );
    }else{
     await this.auth.createNewUser(email, password);
     this.analytics.logEvent('RAT_partner_has_signed_up', {type: 'New User' });
     return await this.updatePartnerData(email, ref.partnerID, ref.resortID, ref.townIds, ref.roleID, ref.roleName );
    }
  }

  async checkEmailExists(email: string): Promise<any> {
    return await firebase.auth().fetchSignInMethodsForEmail(email);
  }

  async checkIfRefExists(referralID: string): Promise<any> {
    let referalObj;
    return await firebase
      .database()
      .ref('/SignUpReferals')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((child) => {
          const url = String(child.val().url);
          if (url && url.includes(referralID)){
            const roleID = (child.val().roleID == null) ? 22 : child.val().roleID;
            const roleName = (child.val().roleName == null) ? 'Partner Marketing' : child.val().roleName;
            referalObj = {url, partnerID: child.val().partnerID,  resortID: child.val().resortID,
              townIds: child.val().selectedTownIds, roleID, roleName};
          }
        });
        return referalObj;
      });
  }

  async loginWithEmail(email: string, password: string): Promise<AdminsUsers> {
    await this.afAuth.signInWithEmailAndPassword(email, password).catch(error => {
      throw new Error(error.message);
    });
    const user = await this.afAuth.currentUser;
    const snapshot = await firebase.database().ref('/NewUsers/' + user.uid).once('value');
    if (snapshot.val() != null && snapshot.val().partner != null) {
      return await this.getPartnerInfo(user.uid, snapshot.val().partner, snapshot.val().email);
    }
    return undefined;
  }

  private async getPartnerInfo(uid: string, partnerID: string, email: string): Promise<any>{
    const snapshot = await firebase.database().ref('/Partners/' + partnerID ).once('value');
    if (snapshot.val() != null && snapshot.val().employees != null) {

      const listOfData = (snapshot.val().employees === null) ? null : snapshot.val().employees;
      // @ts-ignore
      let dataArray = {api: null , secret : null, resort: null,  roleID: null, towns: null, roleName: null, isDisabled: true};

      if (listOfData) {
      await Object.keys(listOfData).map((personNamedIndex) => {
          if (uid === personNamedIndex){
            const dataObject = listOfData[personNamedIndex];

            const secret = (dataObject.secret == null) ? null : dataObject.secret;
            const api = (dataObject.api == null) ? null : dataObject.api;
            const roleID = (dataObject.roleID == null) ? null : dataObject.roleID;
            const roleName = (dataObject.roleName == null) ? null : dataObject.roleName;
            const towns = (dataObject.towns == null) ? null : dataObject.towns;
            const isDisabled = (dataObject.isDisabled == null) ? true : dataObject.isDisabled;
            dataArray = {api , secret, resort: snapshot.val().resort, roleID, roleName, towns, isDisabled };
          }

        });
      }

      if (dataArray.isDisabled === true || dataArray.resort == null || dataArray.towns == null){
        return undefined;
      }

      if (dataArray.roleID == null || dataArray.roleName == null ){
        return await this.updatePartnerData(
          email,
          partnerID,
          dataArray.resort,
          dataArray.towns,
          22,
          'Partner Marketing');
      }

      return await this.generateUser(
        uid,
        email,
        dataArray.resort,
        dataArray.towns,
        dataArray.roleID,
        dataArray.roleName,
        partnerID,
        dataArray.api,
        dataArray.secret);
    }
    return undefined;
  }
  private async generateUser(uid, email, resortID, townIds, roleID, roleName, partnerID, apiKey?, apiSecret?): Promise<AdminsUsers>{
    return {uid, email, apiKey, apiSecret, resortID, townIds, roleID, roleName, partnerID} as AdminsUsers;
  }

  async logout(): Promise<any> {
    await this.afAuth.signOut();
  }

  public async changePassword(currentPassword, newPassword): Promise<any> {
    const user = firebase.auth().currentUser;
    const credential = await firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      currentPassword
    );

    await user.reauthenticateWithCredential(credential).catch((error) => {
      throw new Error(error.message);
    });

    await firebase.auth().currentUser.updatePassword(newPassword).catch(error => {
        throw new Error(error.message);
      });
  }

  public async generateAPIKeys(User: AdminsUsers): Promise<any>{
    const generateAPIKey = await firebase.functions().httpsCallable('generateAPIKey');
    return generateAPIKey({ User });
  }

  public async updatePartnerData(email: string, partnerID: string, resort: string, towns: string, roleID: number, roleName: string): Promise<any>{
    const currentUser = firebase.auth().currentUser.uid;
    const updatePartnerData = await firebase.functions().httpsCallable('updatePartnerData');
    return updatePartnerData({ currentUser , partnerID, email , resort , towns , roleID, roleName });
  }

}
