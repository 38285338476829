<div class="row">
  <div class="col-12 d-flex justify-content-center">
    <div class="w-100 ratoong-container">
      <div class="details-container">
        <div class="row">
          <div class="col-sm-8">
            <!-- Title section -->
            <div class="mb-5">
              <app-title-section title="Rate a ski resort"
                subtitleOne="Rate a resort to help others in the Ratoong community find the best resorts and to plan their next skiing holiday."
                imagePath="/assets/images/icons/rate_icon.svg"
              >
              <h4 class="subtitle">The more you rate, the more <span><img class="heart" src="assets/images/src/heart.svg" alt="Heart of snow lovers" /></span> you share.</h4>
              <h4 *ngIf="!currentU" class="subtitle mb-3 bold">You need to be logged in to rate so we secure high quality ratings. Become a valued member today.</h4>
              <!-- Search bar -->
              <div class="mt-5 mb-5">
                <p class="text">Enter the name of the resort you want to rate here.</p>
                <app-search-bar></app-search-bar>
              </div>
            </app-title-section>
            </div>
          </div>
          <div class="col-sm-4 d-flex justify-content-center align-items-center">
            <img class="thumbs" src="assets/images/icons/thumbs_icon.svg" alt="Rate a resort!" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
