<!-- Title section -->
<div class="row mb-4">
  <div class="col-md-8">
    <app-title-section title="About {{ currentLoc.cityName }}, {{ currentLoc.name }}"
      subtitleOne="All you need to know about {{ currentLoc.cityName }}, {{
        currentLoc.name
      }} such as the season length, the opening hours, the types of lifts at the resort, as well as a link to the resort's website." imagePath="assets/images/info/info_icon.svg"></app-title-section>
  </div>
</div>

<div class="d-flex justify-content-center">
  <div class="content-center d-flex flex-column align-items-center w-100">
    <div [ngClass]="{
        'justify-content-center':
          (currentLoc.openingDates && !currentLoc.openingHours) ||
          (!currentLoc.openingDates && currentLoc.openingHours) ||
          numberOfElements === 3
      }" class="row w-100 dates-and-hours mb-4 margin-top-bottom">
      <!-- Season time information -->
      <section *ngIf="currentLoc.openingDates" [ngClass]="{
          'col-md-6': numberOfElements < 3,
          'col-md-5': numberOfElements === 3
        }">
        <div class="opening-dates">
          <div class="row">
            <div class="col-4 px-0">
              <div class="d-flex flex-column align-items-center">
                <h4 class="section-text mb-2">Season Start</h4>
                <div [disableTooltip]="seasonStart != 'varies'" ngbTooltip="Depends on snow"
                  tooltipClass="search-tooltip"
                  class="calendar text-center d-flex justify-content-center align-items-center">
                  <div class="calendar-stickers">
                    <div class="calendar-sticker"></div>
                    <div class="calendar-sticker"></div>
                  </div>
                  <h4 class="calendar-text">{{ seasonStart }}</h4>
                </div>
              </div>
            </div>
            <div class="col-4 px-0">
              <div class="squiggly-line pt-2">
                <img class="w-100" src="assets/images/info/squiggly_line.svg" alt="Ski resort season start - season end" />
              </div>
            </div>
            <div class="col-4"></div>
          </div>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4"></div>
            <div class="col-4 px-0">
              <div class="neg-margin-top d-flex flex-column align-items-center">
                <h4 class="section-text mb-2">Season End</h4>
                <div
                  [disableTooltip]="seasonEnd != 'varies'"
                  ngbTooltip="Depends on snow"
                  tooltipClass="search-tooltip"
                  class="calendar text-center d-flex justify-content-center align-items-center pt-2">
                  <div class="calendar-stickers">
                    <div class="calendar-sticker"></div>
                    <div class="calendar-sticker"></div>
                  </div>
                  <h4 class="calendar-text">{{ seasonEnd }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Opening hour information -->
      <section
        *ngIf="currentLoc.openingHours"
          class="d-flex justify-content-end"
          [ngClass]="{
          'col-md-6': numberOfElements < 3,
          'col-md-4': numberOfElements === 3
          }">
        <div class="opening-dates neg-margin-top-sm">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="mb-2">
              <img class="hours-icon" src="assets/images/info/opening_hours.svg" alt="Ski resort opening hours" />
            </div>
            <h4 class="section-text mb-3 text-center">Opening Hours</h4>
            <h2 class="bold-text text-center">
              {{ currentLoc.openingHours.openingTime }} -
              {{ currentLoc.openingHours.closingTime }}
            </h2>
          </div>
        </div>
      </section>
      <section *ngIf="currentLoc.website" class="d-flex flex-column align-items-center" [ngClass]="{
          'col-md-6': numberOfElements < 3,
          'col-md-3': numberOfElements === 3
        }">
        <a href="{{ currentLoc.website }}" target="_blank" title="Visit the website for {{ currentLoc.cityName }}">
          <img class="website-icon" src="/assets/images/info/link_to_resort.svg" alt="{{ currentLoc.cityName }}" />
          <div class="section-text website-text text-center">
            <u>Link to Resort </u><sup><i class="fas fa-external-link-alt"></i></sup>
          </div>
        </a>
      </section>
    </div>
    <!-- Lift information -->
    <section *ngIf="liftsArray.length != 0" class="margin-top-bottom" [ngClass]="{
        'w-100':
          (currentLoc.openingDates && currentLoc.openingHours) ||
          (!currentLoc.openingDates && !currentLoc.openingHours)
      }">
      <div class="row">
        <div class="col-md-2 d-flex flex-column align-items-center">
          <img class="lift-type mb-2" src="assets/images/info/lifts/lift_types.svg" alt="Lift types" />
          <h4 class="section-text text-center">
            Types of Lifts
            <span [ngbTooltip]="infoToolTip" tooltipClass="lifts-tooltip">
              <img class="info pointer" src="assets/images/info/info_icon_01.svg" alt="Lifts" />
            </span>
          </h4>
        </div>
        <div class="col-md-10">
          <div class="row lift-row px-3">
            <div *ngFor="let lift of liftsArray" class="col-3 col-md-auto text-center pb-3">
              <div class="d-flex flex-column align-items-center justify-content-between pt-3 pb-2 lift">
                <img class="lift-icon pb-2" src="{{ 'assets/images/info/lifts/' + lift.image }}" alt="Lift" />

                <h2 class="lift-numbers text-center">
                  {{ lift.count }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="plan-section">
      <div>
        <img class="price-icon pb-2" src="assets/images/info/price_icon.svg" alt="Book a trip icon" />
      </div>
      <div class="plan-text">
        Find prices for {{ currentLoc.cityName }}, {{ currentLoc.name }} by
        visiting our partners when you go to plan a trip.
      </div>
      <div>
        <!-- BUTTON -->
        <div title="Go to plan-a-trip page" routerLink="/plan"
          class="border-ratoong text-center button-secondary pointer">
          <h5 class="color-ratoong button-text">Plan a trip</h5>
        </div>
      </div>
    </section>
  </div>
</div>

<ng-template #infoToolTip>
  <table class="table table-striped m-0">
    <tbody>
      <tr class="d-flex align-items-center" *ngFor="let lift of liftsArray">
        <td class="border-0 d-flex justify-content-center">
          <img class="tooltip-icon pl-1" src="{{ 'assets/images/info/lifts/' + lift.image }}" alt="{{ lift.name }}" />
        </td>
        <td class="w-100 tooltip-text border-0">{{ lift.name }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>
