export const environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyDfjohl54Q_17G5aAMpQ3rVyZAgFmVeqxg',
    appId: '1:973327933646:web:7dddecf6d1b2bf0e71b09f',
    authDomain: 'ratoong-333eb.firebaseapp.com',
    databaseURL: 'https://ratoong-333eb.firebaseio.com',
    measurementId: 'G-8G2BMNY51M',
    messagingSenderId: '973327933646',
    projectId: 'ratoong-333eb',
    storageBucket: 'ratoong-333eb.appspot.com',
  },
  production: true,
  storageBucket: 'ratoong-333eb.appspot.com',
  serviceAccount: 'ratoong.json',
  googleMapsAPI: 'AIzaSyCFUphGSDo5f4CjkXTiK4E9DjLw-fxm0Hk',
  adminAccounts: [
    'hWvFOI9XLiPUPdhzFF4clpsiCXA3',
    'hiiGW8M8DSehvft19ubeaDucYyV2',
  ],
  domain: 'https://ratoong.com/',
};