<script src="profile.component.ts"></script>
<div class="row">
  <div class="loader" *ngIf="!currentU"></div>
  <div class="col-md-10 offset-md-1" *ngIf="currentU">
    <div class="details-container">
      <form
        class="userView"
        [formGroup]="userData"
        (ngSubmit)="updateUser()"
        novalidate
      >
        <h1 class="text-uppercase">WELCOME TO YOUR PROFILE</h1>
        <p class="parag">Tell us a bit about yourself.</p>
        <div class="big-flex">
          <div class="display-flex slight-margin-right width-less-half">
            <div class="form-group">
              <label>HOW MANY WEEKS PER YEAR DO YOU RIDE?</label>
              <select
                [(ngModel)]="ridingSelect"
                class="form-control profile-select"
                formControlName="RidingDurations"
              >
                <option value="rare">1-3</option>
                <option value="common">4-7</option>
                <option value="frequent">+7</option>
              </select>
            </div>
            <div class="form-group">
              <label>YOUR RIDING LEVEL</label>
              <select
                [(ngModel)]="skillSelect"
                class="form-control profile-select"
                formControlName="ConsiderAsRider"
              >
                <option value="none">BEGINNER</option>
                <option value="little">INTERMEDIATE</option>
                <option value="some">ADVANCED</option>
                <option value="expert">EXPERT</option>
              </select>
            </div>
            <div class="form-group">
              <label>YOUR SKI HOLIDAY FOCUS</label>
              <select
                [(ngModel)]="holidaySelect"
                class="form-control profile-select"
                formControlName="SkiHolidays"
              >
                <option value="school">SKI SCHOOL</option>
                <option value="offpiste">OFFPISTE</option>
                <option value="party">PARTY</option>
                <option value="relax">RELAXATION</option>
                <option value="spa">SPA</option>
                <option value="shopping">SHOPPING</option>
                <option value="default">OTHER</option>
              </select>
            </div>
            <div class="image-flex mobile">
              <div class="selectable-image-container largly-margin-right">
                <label class="ratoong asSnowboarder mx-auto select-image">
                  <input
                    type="checkbox"
                    class="sr-only"
                    formControlName="snowboarder"
                    [value]="true"
                  />
                  <img
                    class="skier-icon"
                    [src]="
                      getActivityImage(
                        'snowboarder',
                        userData.controls.snowboarder.value
                      )
                    "
                    alt="Snowboarder"
                  />
                </label>
              </div>
              <div class="selectable-image-container">
                <label class="ratoong asSkier mx-auto select-image">
                  <input
                    type="checkbox"
                    class="sr-only"
                    formControlName="skier"
                    [value]="true"
                  />
                  <img
                    class="skier-icon"
                    [src]="
                      getActivityImage('skier', userData.controls.skier.value)
                    "
                    alt="Skier"
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="display-flex width-half">
            <label class="your-profile">YOUR PROFILE</label>
            <div class="image-flex max-width mobile">
              <div class="form-group slight-margin-right max-width">
                <input
                  type="text"
                  class="form-control profile-select"
                  formControlName="firstname"
                  placeholder="FIRST NAME"
                />
              </div>
              <div class="form-group max-width">
                <input
                  type="text"
                  class="form-control profile-select"
                  formControlName="surname"
                  placeholder="LAST NAME"
                />
              </div>
            </div>
            <div>
              <div class="form-group">
                <input
                  type="email"
                  readonly
                  class="form-control profile-select"
                  formControlName="email"
                  placeholder="EMAIL"
                />
              </div>
              <div
                class="form-group"
                *ngIf="currentU.source == 'Email' || currentU.source == 'web'"
              >
                <a
                  (click)="open('Reset-Pass-Modal', resetPassword)"
                  class="btn btn-info text-white radius"
                  >Change Password</a
                >
              </div>
            </div>
            <div class="row">
              <div class="image-flex max-width">
                <div class="form-group slight-margin-right max-width">
                  <select
                    [(ngModel)]="genderSelect"
                    class="form-control profile-select"
                    formControlName="gender"
                  >
                    <option value="default" disabled>GENDER</option>
                    <option value="male">MALE</option>
                    <option value="female">FEMALE</option>
                    <option value="donttell">DON'T WANNA TELL</option>
                  </select>
                </div>
                <div class="form-group max-width">
                  <select
                    [(ngModel)]="countrySelect"
                    class="form-control profile-select"
                    formControlName="country"
                  >
                    <option value="default" disabled>COUNTRY</option>
                    <option
                      *ngFor="let country of listOfCountries | async"
                      [ngValue]="country.name"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Birthday</label>
              <div *ngIf="message?.birth">
                <small class="text-danger"
                  >are you sure that date is correct?</small
                >
              </div>
              <div class="input-group">
                <form class="form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        #dp
                        class="form-control profile-select"
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        [(ngModel)]="model"
                        [minDate]="{ year: 1910, month: 1, day: 1 }"
                        [maxDate]="{
                          year: CurrentDate.getFullYear(),
                          month: CurrentDate.getMonth() + 1,
                          day: CurrentDate.getDate()
                        }"
                        ngbDatepicker
                        #d="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-check"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
              <div class="form-group extra-field-width">
                <label class="your-profile">Ski instructor</label>
                <select
                  [(ngModel)]="instructorSelect"
                  class="form-control profile-select"
                  formControlName="instructor"
                >
                  <option value="default" disabled>SKI INSTRUCTOR</option>
                  <option value="snowmind">SNOWMINDS</option>
                  <option value="other">OTHER</option>
                </select>
              </div>
          </div>
        </div>
        <div class="form-group">
          <p *ngIf="profileChange">
            <ngb-alert type="success" [dismissible]="false">
              Profile changed successfully!
            </ngb-alert>
          </p>
          <p *ngIf="isError">
            <ngb-alert type="danger" [dismissible]="false">
              <strong>Error</strong> Unable to change profile!
            </ngb-alert>
          </p>
          <p *ngIf="dateBad">
            <ngb-alert type="danger" [dismissible]="false">
              <strong>Error</strong> Date is incorrect!
            </ngb-alert>
          </p>
          <div class="submit-button">
            <button
              type="submit"
              class="btn btn-info text-white confirm-button"
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #resetPassword>
  <app-password-modal></app-password-modal>
</ng-template>
