import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Select} from '@ngxs/store';
import {AdminsUsers} from '../entities/AdminUser';
import {AdminAuthState} from '../admin-auth.state';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {
  @Select(AdminAuthState.getAdminAuth) currentUser: Observable<AdminsUsers>;
  currentU: AdminsUsers;
  constructor( private router: Router) {
    this.currentUser.subscribe(
      (data) => {
        this.currentU = data;
      });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): true|UrlTree {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): true|UrlTree {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): true|UrlTree {
    const adminCheck = Number(this.currentU.roleID);
    if (!isNaN(adminCheck) && adminCheck < 20) {
      return true;
    } else if (!isNaN(adminCheck) && adminCheck < 99) {
      if (url.includes('/partners/overview')){
        return true;
      }
    }
    return this.router.parseUrl('/partners');
  }
}
