import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable, Subject } from 'rxjs';
import {
  Actions,
  ofActionCompleted,
  ofActionErrored,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';
import { LocationState } from '../../../shared/states/location/location.state';
import { DeepLocation } from '../../../shared/states/location/entities/deepLocations';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FirestorageService } from '../../../shared/services';
import { GetSpecificLocation } from '../../../shared/states/location/location.action';
import { takeUntil } from 'rxjs/operators';
import { SCREEN_SIZE } from '../../../shared/services/enums/screen-size.enum';
import { DeviceDetectorService } from '../../../shared/services/device-detector.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-search-resort-detail-main',
  templateUrl: './search_resort_detail_main.component.html',
  styleUrls: ['./search_resort_detail_main.component.scss'],
})
export class SearchResortDetailMainComponent
  implements OnInit, AfterViewInit, OnDestroy {
  private modalRef: BsModalRef;

  $imageLink: string;
  public resortId: number;

  private ngUnsubscribe = new Subject();

  public isLoaded = false;

  @Select(LocationState.currentLocation) currentLocation: Observable<
    DeepLocation
  >;
  currentLoc: DeepLocation;

  mobile = false;
  size: SCREEN_SIZE;
  title = '';

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private storage: FirestorageService,
    private store: Store,
    private actions$: Actions,
    private titleService: Title,
    private resizeSvc: DeviceDetectorService
  ) {
    this.size = resizeSvc.getCurrentSize();
    this.mobile = this.size < 3;

    resizeSvc.onResize$.subscribe((x) => {
      this.size = x;
      this.mobile = this.size < 3;
    });

    this.actions$
      .pipe(
        ofActionSuccessful(GetSpecificLocation),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.setImageLink(this.currentLoc);
        this.isLoaded = true;
      });

    this.actions$
      .pipe(ofActionErrored(GetSpecificLocation), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.router.navigate(['/404']);
      });

    this.route.params.subscribe((params) => {
      this.resortId = Number(params.id);
      if (isNaN(this.resortId)) {
        this.router.navigate(['/404']);
      }
      this.store.dispatch(new GetSpecificLocation(params.id));
    });

    this.currentLocation.subscribe((data) => {
      this.currentLoc = data;
      //console.log(this.currentLoc);
      data ? this.setTitle(`${data.cityName}, ${data.name} ski resort - Information on skiing in ${data.cityName}`) : this.setTitle('Search ski resort')
    });
  }

  
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  

  ngOnInit(): any {}

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): any {
    window.scrollTo(0, 0);
  }

  private setImageLink(data: DeepLocation): any {
    this.storage
      .getImageUrl('piste-map', data.pisteMap)
      .then((url: string) => (this.$imageLink = url));
  }
}
