<div class="d-flex px-2 justify-content-start title-section">
  <div class="title-icon mr-4">
    <img src="{{ imagePath }}" alt="{{ title }}" />
  </div>
  <div class="d-flex flex-column">
    <h1 class="title">{{ title }}</h1>
    <h4 *ngIf="subtitleOne" class="subtitle">
      {{ subtitleOne }}
    </h4>
    <h4 *ngIf="subtitleTwo" class="subtitle">
      {{ subtitleTwo }}
    </h4>
    <ng-content></ng-content>
  </div>
</div>
