import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { AuthState } from '../../shared/states/auth/auth.state';
import { SubCategory } from '../../shared/states/preference/entities/subCategory'; //
import { DeepLocation } from '../../shared/states/location/entities/deepLocations'; //
import { LocationState } from '../../shared/states/location/location.state';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { PreferenceState } from '../../shared/states/preference/preference.state';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rate-recommendation',
  templateUrl: './rate_recommendation.component.html',
  styleUrls: ['./rate_recommendation.component.scss'],
})
export class RateRecommendation implements OnInit {
  //Get current user details
  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;
  //Get current question
  @Select(PreferenceState.listOfSubRatings) questions: Observable<
    SubCategory[]
  >;
  listOfQuestions: SubCategory[];
  subscription: Subscription;

  //Current location
  @Input() currentLocation: DeepLocation; //

  //Current question tracker
  @Input() questionNumber: number;
  @Output() changeQuestionNumber = new EventEmitter();

  // Emits the users chosen reccomendation rate to the parent (rate-estimate-container)
  @Output() valueEmitter = new EventEmitter();

  //Reviews container
  @Input() reviewsContainer;

  // Holds the rating for the current question. By default, this is 0
  currentRate: number = 0;

  // Current rate value holder variable to reset value if the user decided to do it
  tmpValue: number;

  // Currently, I will use static initialization of resort recommendation question,
  // id #33 and index 26 (check SubCategories DB)
  questionIndex: number = 26;

  constructor(
    private analytics: AngularFireAnalytics,
    private translateService: TranslateService
    ) {}

  ngOnInit(): any {
    this.questions.subscribe((data) => {
      this.listOfQuestions = data;
    });
    this.subscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
    });
    this.checkPreviousReview();
  }

  currentQuestionNumber(): any {
    if (this.questionNumber > 1) {
      return this.questionNumber - 1;
    } else {
      return this.questionNumber === 1;
    }
  }

  currentSlide(direction, hasSkipped): any {
    direction ? this.questionNumber++ : this.questionNumber--;
    this.changeQuestionNumber.emit(this.questionNumber);
    //keep new page on top
    window.scrollTo(0, 0);
  }

  skipToEnd(): void {
    this.questionNumber++;
    this.changeQuestionNumber.emit(this.questionNumber);
  }

  goBackwards(): any {
    this.questionNumber = 1;
    this.changeQuestionNumber.emit(this.questionNumber);
    //keep new page on top
    window.scrollTo(0, 0);
  }

  //Get review values and push inside reviewsContainer, also user has option to change value or makes it zero
  triggerSaveRate(): any {
    if (this.tmpValue === this.currentRate) {
      this.currentRate = 0;
    }
    this.tmpValue = this.currentRate;
    this.updateValue();
  }

  //Function post NPS value push inside reviewsContainer
  updateValue(): any {
    this.valueEmitter.emit({
      questionId: this.listOfQuestions[26].id,
      rating: this.currentRate,
      questionName: 'NPS',
      categoryId: 9,
    });
  }

  // Get previous NPS question review if it exist or define it like 0
  checkPreviousReview(): any {
    let currentScore = this.reviewsContainer.reviews.find(
      (element) => element.questionId === 33
    );

    if (currentScore) {
      this.currentRate = currentScore.rating;
      this.tmpValue = currentScore.rating;
    }

    if (this.reviewsContainer.previousReviews) {
      let previousScore = this.reviewsContainer.previousReviews.find(
        (element) => element.questionId === 33
      );
      if (!currentScore) {
        previousScore
          ? (this.currentRate = previousScore.rating)
          : (this.currentRate = 0);
      }
      if (!currentScore && previousScore) {
        this.tmpValue = previousScore.rating;
      }
    }
  }
}
