<div class="load-container" *ngIf="!loaded">
  <div class="loader"></div>
</div>
<div
  [ngClass]="{ invisible: !loaded }"
  class="h-100 d-flex ambassador-item flex-column align-items-center"
>
  <div class="ambassador-visible">
    <div class="position-relative">
      <img
        class="ambassador-img"
        *ngIf="imageLink"
        [src]="imageLink"
        [alt]="ambassador.name"
        (load)="imageLoaded()"
      />
      <img
        class="ambassador-flag"
        *ngIf="flagLink"
        [src]="flagLink"
        [alt]="ambassador.country"
        (load)="imageLoaded()"
      />
    </div>
    <div class="ambassador-name text-center">{{ ambassador.name }}</div>
    <div class="ambassador-social-links mb-4 text-center">
      <a target="_blank" [href]="ambassador.social" class="ambassador-instagram"
        ><i class="fab fa-instagram"></i
      ></a>
    </div>
  </div>
  <!-- If not mobile, we do not want to truncate the text. if it is mobile we want to truncate -->
  <div *ngIf="!mobile" class="ambassador-description">
    <div class="ambassador-name-visible">{{ ambassador.name }}</div>
    <div class="mb-1">{{ ambassador.description }}</div>
    <div class="ambassador-social-links mb-4 text-center">
      <a target="_blank" [href]="ambassador.social" class="ambassador-instagram"
        ><i class="fab fa-instagram"></i
      ></a>
    </div>
  </div>
  <div *ngIf="mobile" class="ambassador-description">
    <!-- If the description is less than/equal to 74 characters we can just display it all -->
    <div *ngIf="ambassador.description.length <= 74">
      <div class="ambassador-name-visible">{{ ambassador.name }}</div>
      <div>{{ ambassador.description }}</div>
    </div>
    <!-- If the description is greater 74, we display a truncated description with an option to 'Read more' -->
    <div *ngIf="ambassador.description.length > 74 && !displayAllDescription">
      <div class="ambassador-name-visible">{{ ambassador.name }}</div>
      <div>{{ (ambassador.description | slice: 0:74) + "..." }}</div>
      <span
        (click)="displayAllDescription = true"
        class="color-ratoong pointer read-more"
        >Read more</span
      >
    </div>
    <!-- If the use has chosen to read more, we display all of the description with an option to 'Show less' -->
    <div *ngIf="ambassador.description.length > 74 && displayAllDescription">
      {{ ambassador.description }}
      <span
        (click)="displayAllDescription = false"
        class="color-ratoong pointer read-more"
        >Show less</span
      >
    </div>
    <div class="ambassador-social-links mt-2 text-center">
      <a target="_blank" [href]="ambassador.social" class="ambassador-instagram"
        ><i class="fab fa-instagram"></i
      ></a>
    </div>
  </div>
</div>
