import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { ModalService } from '../../shared/services';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-join-community',
  templateUrl: './home_join_community.component.html',
  styleUrls: [
    './home_join_community.component.scss',
    '../home_plan_trip/home_plan_trip.component.scss',
  ],
})
export class HomeJoinCommunityComponent implements OnInit {
  // Currently in firebase, the only way to count numbers in a collection is reference the collection and peform a count.
  // This would obviously be very intensive for users to do each time they attended the site (downloading all ratings, performing a count).
  // Therefore for the time being I have gotten the values and hard coded them here.
  // In the future we should think about implementing a could function that keeps track of the number of users and ratings and increments the count
  // each time a user or rating is created
  ratings = 12367;
  // users = 12735;  Eg thinks this number is too large - thinks around 1250
  users = 1250;
  reviews = 2060;
  locations = 1553;

  // Number of milliseconds for duration of number animation
  step = 31;
  duration = 3000 * this.step;

  @Input() currentU: AuUser;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.ratings = this.roundDown(this.ratings);
    // this.users = this.roundDown(this.users);  Eg thinks 12735 is incorrect and the actual number is 1250
    this.locations = this.roundDown(this.locations);
  }

  // Currently we round it down by 500
  roundDown(value) {
    return Math.floor(value / 500) * 500;
  }

  openLoginModal(id, content): any {
    this.modalService.open(id, content, 'lg');
  }

  triggerCounters() {
    this.countLocations();
    this.countUsers();
    this.countRatings();
  }

  // I slow down the intervals as it approaches the end to create an easing effect
  // There is probably a much nicer way of doing this but didn't have time!
  // Slowing effect only on 'ratings' as this is by far the largest number
  countRatings() {
    let end = this.ratings;
    let current = 0;
    let interval = Math.abs(Math.floor(this.duration / end));

    let timer = setInterval(() => {
      current += this.step;
      this.ratings = current;
      if (current >= end - 50) {
        clearInterval(timer);
      }
    }, interval);
    interval = 25;
    let timer2 = setInterval(() => {
      current += 1;
      this.ratings = current;
      if (current >= end - 5) {
        clearInterval(timer2);
      }
    }, interval);
    interval = 100;
    let timer3 = setInterval(() => {
      current += 1;
      this.ratings = current;
      if (current == end) {
        this.ratings = end;
        clearInterval(timer3);
      }
    }, interval);
  }

  countUsers() {
    let end = this.users;
    let current = 0;
    let interval = Math.abs(Math.floor(this.duration / end));
    interval = interval / 1.5;

    let timer = setInterval(() => {
      current += this.step;
      this.users = current;
      if (current >= end) {
        this.users = end;
        clearInterval(timer);
      }
    }, interval);
  }

  countLocations() {
    let end = this.locations;
    let current = 0;
    let interval = Math.abs(Math.floor(this.duration / end));
    interval = interval / 1.5;

    let timer = setInterval(() => {
      current += this.step;
      this.locations = current;
      if (current >= end) {
        this.locations = end;
        clearInterval(timer);
      }
    }, interval);
  }
}
