<div class="favourite-bucket row g-0">
  <div [ngClass]="{'col-sm-4': resultsPage, 'col-4': !resultsPage}" (click)="clickedFavouriteBucketlist('visited')"
    class="px-0 d-flex flex-column justify-content-center align-items-center pointer">
    <div *ngIf="!onVisited()" class="circle" [ngClass]="{'circle-margin': resultsPage}"></div>
    <div *ngIf="onVisited()">
      <div [@favouriteBucket] class="circle circle-filled" [ngClass]="{'circle-margin': resultsPage}"></div>
    </div>
    <p>Visited</p>
  </div>
  <div [ngClass]="{'col-sm-4 col-6': resultsPage, 'col-4': !resultsPage}"
    (click)="clickedFavouriteBucketlist('favourites')" class="px-0 text-center pointer">
    <span *ngIf="!onFavourites()">
      <img class="heart-bucket" src="/assets/images/info/add_to_favourite.svg" alt="Favorite resorts" />
    </span>

    <span *ngIf="onFavourites()">
      <img [@favouriteBucket] class="heart-bucket" src="/assets/images/info/favourite_fulfilled.svg"
        alt="Favorite resorts" />
    </span>
    <p>Favorite</p>
  </div>
  <div [ngClass]="{'col-sm-4 col-6': resultsPage, 'col-4': !resultsPage}"
    (click)="clickedFavouriteBucketlist('bucketlist')" class="px-0 text-center pointer">
    <span *ngIf="!onBucketlist()">
      <img class="heart-bucket" src="/assets/images/info/add_to_bucketlist.svg" alt="Bucketlist" />
    </span>
    <span *ngIf="onBucketlist()">
      <img [@favouriteBucket] class="heart-bucket" src="/assets/images/info/bucketlist_fulfilled.svg"
        alt="Bucketlist" />
    </span>
    <p>Bucket list</p>
  </div>
</div>

<ng-template #LoginPopupFavourites>
  <app-login-popup subtitleOne="{{ subtitleOne }}" subtitleTwo="{{ subtitleTwo }}" [dontRedirect]="true">
  </app-login-popup>
</ng-template>

<!-- Preload images so there is no delay when the user clicks 'favourite' or 'bucketlist'-->
<img style="display: none" src="/assets/images/info/favourite_fulfilled.svg" alt="Favourite" />
<img style="display: none" src="/assets/images/info/add_to_favourite.svg" alt="Add to favorite" />
<img style="display: none" src="/assets/images/info/bucketlist_fulfilled.svg" alt="Bucketlist" />
<img style="display: none" src="/assets/images/info/add_to_bucketlist.svg" alt="Add to bucketlist" />
