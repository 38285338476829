<div>
  <div class="p-3 text-section">
    <div class="text">
      Selecting "Reset ALL selections" will clear all your saved parameters.
    </div>
    <div class="text">Would you like to continue?</div>
  </div>
  <div class="button-section m-0 p-0 row">
    <div class="col-6 py-2 border-up button-text" (click)="closeModal()">
      Cancel
    </div>
    <div class="col-6 py-2 border-up-left button-text" (click)="emitReset()">
      Reset all
    </div>
  </div>
</div>
