<div id='ppHeader'> Privacy Policy</div>
<div id='ppBody'>
    <div class='ppConsistencies'>
        <div class='col-2'>
            <div class="quick-links text-center">Information Collection</div>
        </div>
        <div class='col-2'>
            <div class="quick-links text-center">Information Usage</div>
        </div>
        <div class='col-2'>
            <div class="quick-links text-center">Information Protection</div>
        </div>
        <div class='col-2'>
            <div class="quick-links text-center">Cookie Usage</div>
        </div>
        <div class='col-2'>
            <div class="quick-links text-center">3rd Party Disclosure</div>
        </div>
        <div class='col-2'>
            <div class="quick-links text-center">3rd Party Links</div>
        </div>
        <div class='col-2'></div>
        <div class='col-2'></div>
        <div class='col-2'></div>
    </div>
    <div style='clear:both;height:10px;'></div>
    <div class='ppConsistencies'>
        <div class='col-2'>
            <div class="col-12 quick-links2 gen-text-center">Google AdSense</div>
        </div>
        <div class='col-2'>
            <div class="quick-links2 gen-text-center">CAN-SPAM</div>
        </div>
        <div class='col-2'>
            <div class="quick-links2 gen-text-center">Our Contact Information<br></div>
        </div>
    </div>
    <div style='clear:both;height:10px;'></div>
    <div class='innerText'>This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be
        used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise
        handle your Personally Identifiable Information in accordance with our website.<br></div><span id='infoCo'></span><br>
    <div class='grayText'><strong>What personal information do we collect from the people that visit our blog, website or app?</strong></div><br />
    <div class='innerText'>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address or other details to help you with your experience.</div><br>
    <div class='grayText'><strong>When do we collect information?</strong></div><br />
    <div class='innerText'>We collect information from you when you register on our site or enter information on our site.</div><br> <span id='infoUs'></span><br>
    <div class='grayText'><strong>How do we use your information? </strong></div><br />
    <div class='innerText'> We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:<br><br></div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> To ask for ratings and reviews of services or products</div><span id='infoPro'></span><br>
    <div class='grayText'><strong>How do we protect your information?</strong></div><br />
    <div class='innerText'>We do not use vulnerability scanning and/or scanning to PCI standards.</div>
    <div class='innerText'>We only provide articles and information. We never ask for credit card numbers.</div>
    <div class='innerText'>We use regular Malware Scanning.<br><br></div>
    <div class='innerText'>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit
        information you supply is encrypted via Secure Socket Layer (SSL) technology. </div><br>
    <div class='innerText'>We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.</div><br>
    <div class='innerText'>All transactions are processed through a gateway provider and are not stored or processed on our servers.</div><span id='coUs'></span><br>
    <div class='grayText'><strong>Do we use 'cookies'?</strong></div><br />
    <div class='innerText'>Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember
        certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide
        you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</div>
    <div class='innerText'><br><strong>We use cookies to:</strong></div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Understand and save user's preferences for future visits.</div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Keep track of advertisements.</div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this
        information on our behalf.</div>
    <div class='innerText'><br>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn
        the correct way to modify your cookies.<br></div>
    <div class='innerText'><br><strong>If users disable cookies in their browser:</strong></div><br>
    <div class='innerText'>If you turn cookies off, Some of the features that make your site experience more efficient may not function properly.Some of the features that make your site experience more efficient and may not function properly.</div><br><span id='trDi'></span><br>
    <div class='grayText'><strong>Third-party disclosure</strong></div><br />
    <div class='innerText'><strong>Do we disclose the information we collect to Third-Parties?</strong></div>
    <div class='innerText'>We sell,trade, or otherwise transfer to outside parties your name, any form or online contact identifier email, name of chat account etc., screen name or user names Personally Identifiable Information.</div><br><span id='trLi'></span><br>
    <div class='grayText'><strong>Third-party links</strong></div><br />
    <div class='innerText'>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and
        activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</div><span id='gooAd'></span><br>
    <div class='blueText'><strong>Google</strong></div><br />
    <div class='innerText'>Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en <br><br></div>
    <div class='innerText'>We use Google AdSense Advertising on our website.</div>
    <div class='innerText'><br>Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of
        the DART cookie by visiting the Google Ad and Content Network privacy policy.<br></div>
    <div class='innerText'><br><strong>We have implemented the following:</strong></div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Remarketing with Google AdSense</div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Google Display Network Impression Reporting</div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Demographics and Interests Reporting</div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> DoubleClick Platform Integration<br></div><br>
    <div class='innerText'>We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions
        with ad impressions and other ad service functions as they relate to our website. </div>
    <div class='innerText'><br><strong>Opting out:</strong><br>Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics
        Opt Out Browser add on.</div>
</div>
