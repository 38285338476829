<div [@adjustHeight]="
    !alreadyExists && !tooManySaved
      ? 'default'
      : alreadyExists && !tooManySaved
      ? 'alreadyExists'
      : 'tooMany'
  " class="bg-white container">
  <!-- <div class="bg-white container"> -->
  <div class="fade-in" *ngIf="!alreadyExists && !tooManySaved">
    <p>
      Save your search preferences for future use here. This will save to your
      profile.
      <span [ngbTooltip]="infoToolTip" tooltipClass="lifts-tooltip">
        <img class="info pointer" src="assets/images/info/info_icon_01.svg" alt="Save your search preferences" />
      </span>
    </p>
    <p class="bold mt-3 color-ratoong">Name Search</p>
    <form [formGroup]="saveSearch" (ngSubmit)="onSubmit()" action="">
      <input type="text" id="name" class="form-input form-control rounded-0" placeholder="Search 1"
        formControlName="name" />
      <div class="form-group mb-2 mt-3 d-flex justify-content-center">
        <input type="submit" class="
            w-100
            color-ratoong
            border-ratoong
            bg-white
            rounded-0
            save-button
          " value="SAVE SEARCH" />
      </div>
    </form>
  </div>
  <div class="fade-in" *ngIf="alreadyExists && !tooManySaved">
    <p>
      You already have a search saved with name
      <b>{{ saveSearch.value.name }}</b>. Would you like to overwrite?
    </p>
    <div class="form-group mb-2 mt-3 row">
      <div class="col-6">
        <input (click)="existingPreferenceId = undefined" (click)="alreadyExists = false" type="button"
          class="w-100 text-white border-0 bg-ratoong rounded-0 save-button" value="NO" />
      </div>
      <div class="col-6">
        <!-- If the user wants to overwrite, we save and set 'alreadyExists' back to false -->
        <input (click)="onSubmit()" type="submit" class="
            w-100
            color-ratoong
            border-ratoong
            bg-white
            rounded-0
            save-button
          " value="YES" />
      </div>
    </div>
  </div>
  <div class="fade-in" *ngIf="tooManySaved && !alreadyExists">
    <p>
      You have reached the limit of saved searches <b>(6)</b>. Please choose one
      to overwrite.
    </p>
    <ul>
      <li class="ellipses" (click)="existingPreferenceId = savedPreference.key" (click)="onSubmit()"
        *ngFor="let savedPreference of currentU.searchPreferences | keyvalue">
        {{ savedPreference.value.name }}
      </li>
    </ul>
    <div class="form-group mb-2 mt-3 d-flex justify-content-center">
      <input (click)="tooManySaved = false" type="button"
        class="w-100 text-white border-0 bg-ratoong rounded-0 save-button" value="CANCEL" />
    </div>
  </div>

  <ng-template #LoginPopup>
    <app-login-popup subtitleOne="Want to keep track of your searches?"
      subtitleTwo="Log in / Sign up to save your searches to your profile!" [dontRedirect]="true">
    </app-login-popup>
  </ng-template>
</div>

<ng-template #infoToolTip>
  <div class="p-3">
    <p>
      <b>NOTE: </b>This will only save your preferences, not the resorts
      displayed on this page. To save the resorts, you can add them to your
      favourites or bucketlist.
    </p>
  </div>
</ng-template>
