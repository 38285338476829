<div class="container">
  <app-section-header title="Additional search criteria"></app-section-header>
  <div class="mb-4">
    <h5 class="text-uppercase slider-text mb-0">
      Do you want resorts at higher altitudes?
    </h5>
    <h6 class="slider-subtext mb-4">Choose your minimum altitude.</h6>
    <div class="slider-value">{{ preferences.minAltitude }} m</div>
    <input
      #alt
      (input)="setValue(alt.value, 'minAltitude')"
      class="blue-slider"
      type="range"
      min="{{ parameters.minAltitude.min }}"
      max="{{ parameters.minAltitude.max }}"
      step="50"
      value="{{ preferences.minAltitude }}"
    />
  </div>
</div>
