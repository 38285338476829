import { ModalService } from '../../../../services/modal-service.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-reset-popup',
  templateUrl: './confirm_reset_popup.component.html',
  styleUrls: ['./confirm_reset_popup.component.scss'],
})
export class ConfirmResetPopupComponent implements OnInit {
  @Output() valueEmitter = new EventEmitter();

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  closeModal() {
    this.modalService.close('reset-all-popup');
  }

  emitReset() {
    this.valueEmitter.emit();
    this.modalService.close('reset-all-popup');
  }
}
