import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { ModalService } from '../../services';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language_switch.component.html',
  styleUrls: ['./language_switch.component.scss'],
})
export class LanguageSwitchComponent {
  // Supported language and current language
  supportedLanguages = {
    "en": {
      name: "english",
      flag: "https://firebasestorage.googleapis.com/v0/b/ratoong-dev.appspot.com/o/flags%2Funited-kingdom.jpg?alt=media&token=64a2aafc-cf0b-4a97-89dd-77840a495689",
      alt: "uk-flag"
    },
    "fr": {
      name: "français",
      flag: "https://firebasestorage.googleapis.com/v0/b/ratoong-dev.appspot.com/o/flags%2Ffrance.jpg?alt=media&token=c2221e6c-44cd-48bd-8902-dd99d74d7f18",
      alt: "fr-flag"
    },
    'de': {
      name: 'deutsch',
      flag: "https://firebasestorage.googleapis.com/v0/b/ratoong-dev.appspot.com/o/flags%2Fgermany.jpg?alt=media&token=bcbde1f4-50b0-42a9-9d8d-fda62fa52d2d",
      alt: "de-flag"
    },
    'it': {
      name: 'italiano',
      flag: "https://firebasestorage.googleapis.com/v0/b/ratoong-dev.appspot.com/o/flags%2Fitalia.jpg?alt=media&token=16d5f793-539d-4b52-95ee-5c2ad1b3f7ca",
      alt: "it-flag"
    },
    'es': {
      name: 'español',
      flag: "https://firebasestorage.googleapis.com/v0/b/ratoong-dev.appspot.com/o/flags%2Fspain.jpg?alt=media&token=fc72c1ff-03df-4630-97b3-8d5337476782",
      alt: "es-flag"
    }
  };

  supportedLanguageList = Object.keys(this.supportedLanguages)
  currentLanguage: string;
  languageFlag: string;

  toggleLanguage: boolean = true;

  constructor(
    private translateService: TranslateService,
  ) {
    // Languages to translation service and default language setted as english
    this.translateService.addLangs(Object.keys(this.supportedLanguages));
    this.translateService.setDefaultLang('en');

    // Get browser language, if supported run it as default
    const browserlang = this.translateService.getBrowserLang();

    if (Object.keys(this.supportedLanguages).includes(browserlang)) {
      this.translateService.use(browserlang);
      this.translateService.setDefaultLang(browserlang);
    }

    this.currentLanguage = this.translateService.getDefaultLang();
  }

    // Language change function
    useLang(lang: string) {
      this.translateService.use(lang);
      this.translateService.setDefaultLang(lang);
      this.toggleLanguage = !this.toggleLanguage;
      this.languageFlag = this.supportedLanguages[lang].flag;
      this.currentLanguage = lang;
    }

    languageButton() {
      this.toggleLanguage = false;
    }
    languageOffButton() {
      this.toggleLanguage = true;
    }
}
