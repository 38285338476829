import { GetUserRatingByLocation } from './../../shared/states/ratings/rating.action';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { SaveWrittenReview } from '../../shared/states/ratings/rating.action';
import { RatingState } from '../../shared/states/ratings/rating.state';
import { first, takeUntil } from 'rxjs/operators';
import { RatingService } from '../../shared/states/ratings/rating.service';
import { Select, Store, Actions, ofActionCompleted } from '@ngxs/store';
import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { Observable, Subscription, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../../shared/services';
import { AuthState } from '../../shared/states/auth/auth.state';
import { SubCategory } from '../../shared/states/preference/entities/subCategory';
import { PreferenceState } from '../../shared/states/preference/preference.state';
import { Review } from '../../shared/states/ratings/entities/review';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rate-overview',
  templateUrl: './rate_overview.component.html',
  styleUrls: ['./rate_overview.component.scss'],
})
export class RateOverviewComponent implements OnInit, OnDestroy {
  @Select(PreferenceState.listOfSubRatings) questions: Observable<
    SubCategory[]
  >;
  listOfQuestions: SubCategory[];
  modalSubscription: Subscription;
  unsubscribe: Subject<any> = new Subject<any>();

  desc = '';
  @Output() changeQuestionNumber = new EventEmitter();
  @Input() questionNumber: number;
  @Input() locationNumber: number;
  @Input() reviewText = '';
  @Input() reviewImage = '';
  @Input() currentLocation: any;
  @Output() reviewTextChange = new EventEmitter();
  validation = { text: this.reviewText, file: '' };

  // Triggers parent component (rate-estimate-container) to save the rating
  @Output() saveRatingsTest = new EventEmitter();
  @Output() saveGroup = new EventEmitter();

  //Reviews container
  @Input() reviewsContainer;

  //Emits the users chosen questions reviews data to the parent (rate-estimate-container)
  @Output() valueEmitter = new EventEmitter();

  // Set scores start conditions
  overall = 0;
  totalRatings = 0;

  // Set amount of questions groups (there is 6 groups currently)
  groupsRange: number = 0;
  index: number = 0;

  // Set links to images
  imgUrl = '/assets/images/info/ratings/';
  questionImgLink;

  // If rider type is skier we will hide questions related with snowboarding
  // defined as array in case other questions should be hidden in future
  snowboardQuestions = [23];
  kidsQuestions = [22];

  // Add empty object. Further, we will add grouped questions inside this object
  questionGroupsOrderedList = {};

  // Text review part
  reviewForm: FormGroup;
  selectedFile: File;
  fileUrl: string;

  // Check is user logged and get user data
  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;
  locationId;

  previousReviewText: string;

  imageLoading = true;
  isLoading = false;
  isClosed = false;


  userSubscription: Subscription;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private ratingService: RatingService,
    private store: Store,
    private router: Router,
    private modalService: ModalService,
    private analytics: AngularFireAnalytics,
    private actions$: Actions,
    private translateService: TranslateService
  ) {
    this.userSubscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
    });

    this.actions$
      .pipe(
        ofActionCompleted(GetUserRatingByLocation),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        // LOGIC FOR COMBINING OLD AND NEW REVIEWS
        if (this.reviewsContainer.previousReviews) {
          this.ratingsGroupConstructor();
          this.questionGroupsConstructor();
          this.calculateAverage();
        }
      });
  }

  // Add questionGroups object, here we will hold all reviews questions
  questionGroups = {
    1: {
      title: 'thePiste',
      groupName: 'SKI PISTE',
      questionsList: [],
      imgLink: 'ski_piste.svg',
      order: 2,
    },
    2: {
      title: 'thePark',
      groupName: 'SNOW PARK',
      questionsList: [],
      imgLink: 'snowpark.svg',
      order: 3,
    },
    4: {
      title: 'theLifts',
      groupName: 'LIFTS',
      questionsList: [],
      imgLink: 'lifts.svg',
      order: 4,
    },
    5: {
      title: 'theTown',
      groupName: 'TOWN',
      questionsList: [],
      imgLink: 'town.svg',
      order: 5,
    },
    6: {
      title: 'theSustainability',
      groupName: 'SUSTAINABILITY',
      questionsList: [],
      imgLink: 'sustainability.svg',
      order: 6,
    },
    7: {
      title: 'theArea',
      groupName: 'THE AREA',
      questionsList: [],
      imgLink: 'the_area.svg',
      order: 1,
    },
  };

  ratingSubscription: any;

  ngOnInit(): void {
    this.questions.subscribe((data) => {
      this.listOfQuestions = data;
    });

    if (this.reviewsContainer.riderType == 'skier') {
      this.listOfQuestions = this.listOfQuestions.filter(
        (question) => !this.snowboardQuestions.includes(question.id)
      );
    }
    if (
      this.reviewsContainer.skiGroup == 1 ||
      this.reviewsContainer.skiGroup == 2
    ) {
      this.listOfQuestions = this.listOfQuestions.filter(
        (question) => !this.kidsQuestions.includes(question.id)
      );
    }

    if (this.listOfQuestions) {
      this.ratingsGroupConstructor();
      this.questionGroupsConstructor();
      this.calculateAverage();
    }

    this.groupsRange = Object.keys(this.questionGroupsOrderedList).length;

    // So we can keep track of whether a user has changed anything
    this.previousReviewText = this.reviewText;
    this.initForm();

    if (!this.selectedFile) {
      this.fileUrl = '';
    } else {
      this.fileUrl = this.selectedFile.name;
    }
  }

  private initForm(): any {
    this.reviewForm = this.formBuilder.group({
      review: this.reviewText,
      fileInput: '',
    });
  }

  //--------------------------//
  // Get review values and push inside reviewsContainer
  triggerSaveRate(id, rate, groupID, question, categoryId): any {
    this.updateValue(id, rate, groupID, question, categoryId);
  }

  updateValue(id, rate, groupID, question, categoryId): any {

    let previous;
    let current = this.reviewsContainer.reviews.find(
      (question) => question.questionId == id
    );
    if (this.reviewsContainer.previousReviews) {
      previous = this.reviewsContainer.previousReviews.find(
        (question) => question.questionId == id
      );
    }
    let updatedRate;
    if (previous) {
      previous.rating == rate ? (updatedRate = 0) : (updatedRate = rate);
    }
    if (current) {
      current.tmpValue == rate ? (updatedRate = 0) : (updatedRate = rate);
    }

    this.valueEmitter.emit({
      questionId: id,
      rating: this.getUpdatedRate(updatedRate, rate),
      questionName: question,
      categoryId: categoryId,
      tmpValue: this.getUpdatedRate(updatedRate, rate),
    });

    this.questionGroupsOrderedList[groupID].questionsList.find(
      (element) => element.questionId == id
    ).score = this.getUpdatedRate(updatedRate, rate);

    this.calculateAverage();
  }

  getUpdatedRate(updatedRate, rate): any {
    if (updatedRate === 0) {
      return 0;
    } else {
      return rate;
    }
  }

  getRate(current, previous) {
    let rate;
    if (current !== 0) {
      rate = current || previous || 0;
    } else {
      rate = current;
    }
    return rate;
  }

  //-------------------------//

  questionGroupsConstructor(): any {
    Object.keys(this.questionGroups).forEach((key) => {
      this.questionGroupsOrderedList[this.questionGroups[key].order] = {};
      this.questionGroupsOrderedList[this.questionGroups[key].order].groupName =
        this.questionGroups[key].groupName;
      this.questionGroupsOrderedList[
        this.questionGroups[key].order
      ].questionsList = this.questionGroups[key].questionsList;
      this.questionGroupsOrderedList[this.questionGroups[key].order].imgLink =
        this.questionGroups[key].imgLink;
      this.questionGroupsOrderedList[
        this.questionGroups[key].order
      ].averageScore = '';
    });

    //Remove off-piste questions and add it the end of array of quesiton groupId = 2
    let offPistQuestions = this.questionGroupsOrderedList[
      '2'
    ].questionsList.slice(0, 2);
    this.questionGroupsOrderedList['2'].questionsList.splice(0, 2);
    this.questionGroupsOrderedList['2'].questionsList =
      this.questionGroupsOrderedList['2'].questionsList.concat(
        offPistQuestions
      );
  }

  ratingsGroupConstructor(): void {
    this.listOfQuestions.forEach((item) => {
      if (item.categoryId !== 0 && item.categoryId !== 9) {
        let oldScore;
        if (this.reviewsContainer.previousReviews) {
          oldScore = this.reviewsContainer.previousReviews.find(
            (element) => element.questionId === item.id
          );
        }
        let oldRating = oldScore ? oldScore.rating : 0;

        let currentScore = this.reviewsContainer.reviews.find(
          (element) => element.questionId === item.id
        );
        let newRating = currentScore ? currentScore.rating : false;

        let finalRating = this.getRate(newRating, oldRating);

        let updated = this.questionGroups[item.categoryId].questionsList.find(
          (e) => e.questionId == item.id
        );
        if (updated) {
          updated.score = finalRating;
        } else {
          this.questionGroups[item.categoryId].questionsList.push({
            question: item.questionText,
            min: item.min || '',
            max: item.max || '',
            questionId: item.id,
            score: finalRating,
            categoryId: item.categoryId,
          });
        }
      }
    });
  }

  counter() {
    return new Array(this.groupsRange);
  }

  changeState(value) {
    let headyId = 'dropdownHead' + value;
    let bodyId = 'dropdownBody' + value;
    let targetHead = document.getElementById(headyId);
    let targetBody = document.getElementById(bodyId);
    targetHead.classList.toggle('img-hor-vert');
    targetBody.classList.toggle('d-none');
  }

  calculateAverage(): any {
    let totalSum = 0;
    let totalCount = 0;

    Object.values(this.questionGroupsOrderedList).forEach((element) => {
      let sum = 0;
      let count = 0;
      element['questionsList'].forEach((question) => {
        if (question['score']) {
          sum += question['score'];
          count++;
        }
      });
      sum
        ? (element['averageScore'] =
            Math.round((sum / count + Number.EPSILON) * 100) / 100)
        : (element['averageScore'] = 0);
      //sum ? element['averageScore'] =  Math.round((sum/count) * 2 ) /2  : element['averageScore'] = 0;
      totalSum += sum;
      totalCount += count;
    });
    totalSum
      ? (this.overall =
          Math.round((totalSum / totalCount + Number.EPSILON) * 100) / 100)
      : (this.overall = 0);
  }

  onFileChange(event): any {
    this.reviewImage = null;
    this.selectedFile = event.target.files[0];
    this.changeFile(this.selectedFile).then((base64: string): any => {
      this.fileUrl = base64;
    });
  }

  changeFile(selectedFile: File): any {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  //Save all new ratings
  saveNewRatings(): any {
    this.saveRatingsTest.emit(this.reviewsContainer.reviews);
  }

  saveNewGroup(): any {
    this.saveGroup.emit(this.reviewsContainer.skiGroup);
  }

  onSubmit(): any {
    this.goToProfile();
  }

  goToProfile(): any {
    if (this.currentU) {
      this.saveNewRatings();
      this.saveNewGroup();
      // We do not want to post if the written review doesn't change and there is no file input
      if (
        this.reviewForm.value.review == this.previousReviewText &&
        !this.reviewForm.value.fileInput
      ) {
        this.router.navigate(['/profile']);
        return;
      }
      this.isLoading = true;
      this.store
        .dispatch(
          new SaveWrittenReview(
            this.locationNumber,
            this.reviewForm.value.review,
            this.currentU.uid,
            this.selectedFile,
            this.reviewImage
          )
        )
        .pipe(first())
        .subscribe(
          (data) => {
            this.reviewText = this.reviewForm.value.review;
            this.reviewImage = data.rate.review.imageURL;
            this.reviewTextChange.emit(this.reviewText);
            this.isLoading = false;
            this.router.navigate(['/profile']);
          },
          (error) => {
            this.isLoading = false;
          }
        );
      this.router.navigate(['/profile']);
    }
  }

  openPopUp(id, content, overviewPopUp): any {
    if (!this.currentU) {
      this.modalService.open(id, content, 'lg');
      this.modalService.onChange(false);
      this.modalService.onChange$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          if (this.currentU) {
            this.store.dispatch(
              new GetUserRatingByLocation(
                this.currentLocation.id,
                this.currentU.uid
              )
            );
            this.modalSubscription.unsubscribe();
          }
          this.modalSubscription.unsubscribe();
        });
    } else {
      this.modalService.open('review-popUp', overviewPopUp, 'md');
    }
  }

  goBackwards(): any {
    this.questionNumber = 3;
    this.changeQuestionNumber.emit(this.questionNumber);
    //keep new page on top
    window.scrollTo(0, 0);
  }

  currentSlide(direction, hasSkipped): any {
    direction ? this.questionNumber++ : this.questionNumber--;
    this.changeQuestionNumber.emit(this.questionNumber);
  }

  ngOnDestroy(): void {
    //
    this.unsubscribe.next();
    this.unsubscribe.complete();
    //
    //this.userSubscription.unsubscribe();
    //
  }
}
