import { Component, OnInit } from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Component({
  selector: 'app-home-plan-trip',
  templateUrl: './home_plan_trip.component.html',
  styleUrls: ['./home_plan_trip.component.scss']
})
export class HomePlanTripComponent implements OnInit {

  constructor(
    private analytics: AngularFireAnalytics) { }

  ngOnInit(): void {
  }

  recordEvent(): any{
    this.analytics.logEvent('RAT_user_home_page_button_click', {button: 'Plan a trip' });
  }
}
