<div class="modal-container py-5">
  <span class="close" (click)="closeModal('Forgot-Pass-Modal')"><i class="fas fa-times"></i></span>
  <div *ngIf="!submitOk" class="center d-flex flex-column align-items-center">
    <h2 class="title mb-2">Forgot password?</h2>
    <p class="subtitle mb-2 text-center">
      Please re-enter your email address and a password reset link will be sent.
    </p>
    <form class="w-100" [formGroup]="recoveryForm" (ngSubmit)="resetPassword()">
      <div class="form-group">
        <div class="error-container">
          <div class="error-box p-1 pl-2" *ngIf="submitted && (f.email.errors || wrongEmail)">
            <div *ngIf="f.email.errors; else checkEmailValidity">
              <!-- Setup in this way to ensure only one error message shows, with highest priority first -->
              <div *ngIf="f.email.errors.required" class="error-text">
                Email is required.
              </div>
              <div *ngIf="f.email.errors.email || f.email.errors.pattern" class="error-text">
                Please enter a valid email address.
              </div>
            </div>
            <ng-template #checkEmailValidity>
              <div *ngIf="wrongEmail" class="error-text">
                This email does not match what you wrote previously.
              </div>
            </ng-template>
          </div>
        </div>
        <label for="email" class="form-label mb-0">Email</label>
        <input id="email" type="email" formControlName="email" class="form-control mb-4 form-input rounded-0"
          placeholder="user@email.com" [ngClass]="{
            'error-input': submitted && (f.email.errors || wrongEmail)
          }" />
      </div>
      <div class="form-group">
        <input type="submit" class="w-100 text-white rounded-0 next-button" value="RESET YOUR PASSWORD"
          [disabled]="!recoveryForm.valid" [ngClass]="{
            'bg-grey': !recoveryForm.valid
          }" />
      </div>
    </form>
  </div>

  <div *ngIf="submitOk">
    <div class="d-flex flex-column align-items-center">
      <img class="snowflake my-5" src="assets/images/src/sf_filled.png" alt="Email sent" />
      <h2 class="title text-center">Email has been sent</h2>
      <p class="subtitle">
        A link to reset your password has been sent to your email.
      </p>
    </div>
  </div>
</div>
