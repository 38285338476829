import { Component, Input, OnInit } from '@angular/core';
import { SendResetPassword } from '../../../states/auth/auth.action';
import { first } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ModalService } from '../../../services';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-password-forgot-modal',
  templateUrl: './password_forgot_modal.component.html',
  styleUrls: ['./password_forgot_modal.component.scss'],
})
export class PasswordForgotModalComponent implements OnInit {
  wrongEmail = false;
  submitted = false;
  submitOk: boolean = false;
  @Input() userEmail: string;

  recoveryForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.recoveryForm = this.fb.group({
      email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(200),
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$'),
        ])
      ),
    });
    // Initialise the form is not submitted
    this.submitOk = false;
    this.onInputChange();
  }

  get f(): any {
    return this.recoveryForm.controls;
  }

  resetPassword(): any {
    this.submitted = true;
    this.wrongEmail = false;
    this.submitOk = false;

    if (this.recoveryForm.invalid) {
      return;
    }
    if (this.userEmail != this.recoveryForm.value.email) {
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(this.recoveryForm.value.email).toLocaleLowerCase())) {
      return (this.wrongEmail = true);
    }
    this.store
      .dispatch(new SendResetPassword(this.recoveryForm.value.email))
      .pipe(first())
      .subscribe(
        (data) => {
          this.submitOk = true;
        },
        (error) => {
          return (this.wrongEmail = true);
        }
      );
  }

  closeModal(id): any {
    this.modalService.close(id);
  }

  onInputChange() {
    this.recoveryForm.valueChanges.subscribe((val) => {
      this.wrongEmail = false;
    });
  }
}
