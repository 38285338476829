<div class="details-container w-100">
  <div class="row d-flex justify-content-between">
    <!-- LEFT COLUMN -->
    <div class="col-md-7 col-xl-6">
      <div class="d-flex pr-2 justify-content-start title-section">
        <div class="icon">
          <img src="assets/images/icons/community_icon.svg" alt="Welcome to the Ratoong family!" />
        </div>
        <div class="d-flex flex-column">
          <!-- TITLE -->
          <h1 class="title">A growing ski community</h1>
          <!-- To trigger the counter animation after the text has entered the screen -->
          <h4 class="subtitle" (appScroll)="triggerCounters()">
            Become a Ratoong member today to join riders worldwide in finding,
            planning, booking, rating and reviewing your ski trips!
          </h4>
          <!-- BUTTON -->
          <button
            *ngIf="!currentU"
            (click)="openLoginModal('Login-Popup', LoginPopup)"
            class="bg-red d-flex align-items-center justify-content-center mt-4"
          >
            <h1 class="text-white button-text">Login/sign up</h1>
          </button>
        </div>
      </div>
    </div>
    <!-- RIGHT COLUMN -->
    <div class="col-md-5">
      <!-- IMAGE OF CHALET -->
      <img
        class="chalet-image"
        src="assets/images/home/community.jpg"
        alt="Chalet image"
      />
    </div>
  </div>
  <div class="d-flex justify-content-between numbers" #joinCommunity>
    <div
      class="
        number-box
        ratoong-shadow
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <h5 class="number-actual">{{ ratings }}+</h5>
      <p class="number-text mb-0">ratings</p>
    </div>
    <div
      class="
        number-box
        ratoong-shadow
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <h5 class="number-actual">{{ users }}+</h5>
      <p class="number-text mb-0">User profiles</p>
    </div>
    <div
      class="
        number-box
        ratoong-shadow
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <h5 class="number-actual">{{ locations }}+</h5>
      <p class="number-text mb-0">Ski destinations</p>
    </div>
  </div>
</div>

<ng-template #LoginPopup>
  <app-login-popup
    subtitleOne="Log in to rate resorts and save your searches."
    subtitleTwo="Rate more and share the ❤️"
    [dontRedirect]="false"
  >
  </app-login-popup>
</ng-template>
