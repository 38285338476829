import { AngularFireAnalytics } from '@angular/fire/analytics';
import { trigger, transition, style, animate } from '@angular/animations';
import { DeepLocation } from '../../../shared/states/location/entities/deepLocations';
import { LocationFilter } from '../../../shared/states/location/helper/location.filter';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-airports',
  templateUrl: './details_airports.component.html',
  styleUrls: ['./details_airports.component.scss'],
  animations: [
    trigger('fadein', [
      transition(':enter', [
        style({
          transform: 'translateX(20%)',
          opacity: 0,
        }),
        animate('0.4s ease'),
      ]),
    ]),
  ],
})
export class DetailsAirportsComponent implements OnInit {
  @Input() currentLoc: DeepLocation;

  locationPermission: boolean;
  isLoading = false;

  distanceFromLocation: number;
  buttonText = 'CLICK TO CALCULATE DISTANCE';
  dots = '';

  intervalId: any;

  currentLongitude: number;
  currentLatitude: number;
  currentLocation: any;
  destinationLocation: any;
  noDirections = false;

  drivingDistance: number;
  drivingDuration: number;

  constructor(private locationFilter: LocationFilter, private analytics: AngularFireAnalytics) {}

  ngOnInit(): void {
    this.destinationLocation = {
      lat: this.currentLoc.latitude,
      lng: this.currentLoc.longitude,
    };
  }

  calculateDistance(): void {
    // Stores coordinates inside longitude and latitude variables
    this.getLocation();
  }

  // Get users current location (if they grant permission)
  getLocation(): void {
    if (navigator.geolocation) {
      this.isLoading = true;
      this.startCalculating();
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.locationPermission = true;
          this.currentLongitude = position.coords.longitude;
          this.currentLatitude = position.coords.latitude;
          this.currentLocation = {
            lat: this.currentLatitude,
            lng: this.currentLongitude,
          };
          this.distanceFromLocation = this.locationFilter.distanceInKm(
            this.currentLatitude,
            this.currentLongitude,
            this.currentLoc.latitude,
            this.currentLoc.longitude
          );
          this.isLoading = false;
          clearInterval(this.intervalId);
        },
        (error) => {
          if (error) {
            console.log(error);
            this.locationPermission = false;
            this.buttonText = 'ENABLE LOCATION PERMISSION';
            this.analytics.logEvent('RAT_user_calculated_distance', {outcome: 'Failed' });
            this.isLoading = false;
            clearInterval(this.intervalId);
          }
        }
        ,
        {
          timeout: 10000,
        }
      );
    } else {
      console.log('Permission not granted');
    }
  }

  // Animation that shows dots when calculating distance
  startCalculating(): void {
    this.buttonText = 'CALCULATING';
    let dots = '\u00a0\u00a0\u00a0'; // 3 empty space characters (so that the width remains pretty consistent and the text doesn't shift)
    let dot = '.';
    this.dots = dots;
    this.intervalId = setInterval(() => {
      dots = dot.concat(dots); // Add a '.' to the start of the characters
      if (dots.includes('....')) {
        // when we have more than 3 dots we start again with empty space characters
        dots = '\u00a0\u00a0\u00a0';
      }
      this.dots = dots.substring(0, 3); // trim the string down to just 3 characters
    }, 500);
  }

  public onResponse(response: any) {
    if (response.status === 'OK') {
      this.noDirections = false;
      this.drivingDistance = response.routes[0].legs[0].distance.text;
      this.drivingDuration = response.routes[0].legs[0].duration.text;
      this.analytics.logEvent('RAT_user_calculated_distance', {outcome: 'Driving and direct routes calculated' });
    } else {
      this.analytics.logEvent('RAT_user_calculated_distance', {outcome: 'Only direct route calculated' });
      this.noDirections = true;
    }
  }

  logEvent() {
    this.analytics.logEvent('RAT_user_clicked_partner_link', {partnerName: 'POW' });
  }
}
