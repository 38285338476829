import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms_and_conditions.component.html',
  styleUrls: ['./terms_and_conditions.component.sass'],
})
export class TermsAndConditionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): any {}
}
