import {
  trigger,
  transition,
  group,
  query,
  style,
  animate,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

const features = [
  {
    title: 'Unique search options',
    text: 'Do you know where to go next time? Choose among more than 25 parameters to find new and exciting ski resorts that you never knew existed!',
    image: 'look_up.svg',
  },
  {
    title: 'Plan your next ski trip',
    text: 'Book everything you need for your next ski holiday, all from ski rentals, to ski insurance, and ski school etc. etc. all in one place!',
    image: 'plan_trip.svg',
  },
  {
    title: 'Share your experience',
    text: 'Rate ski destinations to help others discover the best resorts for their next ski holiday. We call it "Rated by riders for riders"!',
    image: 'rate_icon.svg',
  },
];

@Component({
  selector: 'app-home-summary-section',
  templateUrl: './home_summary_section.component.html',
  styleUrls: ['./home_summary_section.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(
        ':increment',
        group([
          query(':enter', [
            style({
              transform: 'translateX(100%)',
            }),
            animate('0.5s ease-out'),
          ]),
          query(':leave', [
            animate(
              '0.5s ease-out',
              style({
                transform: 'translateX(-100%)',
              })
            ),
          ]),
        ])
      ),
      transition(
        ':decrement',
        group([
          query(':enter', [
            style({
              transform: 'translateX(-100%)',
            }),
            animate('0.5s ease-out'),
          ]),
          query(':leave', [
            animate(
              '0.5s ease-out',
              style({
                transform: 'translateX(100%)',
              })
            ),
          ]),
        ])
      ),
    ]),
  ],
})
export class HomeSummarySectionComponent implements OnInit {
  // Parent component detects if mobile and passes information to this component
  @Input() mobile: boolean;

  features: any[] = features;

  // For mobile - we need to loop through showing one feature at a time
  index: number;

  constructor() {}

  ngOnInit(): void {
    // Initialise index to start at first feature
    this.index = 0;
  }

  // Only increment index if it is not at the final item in the array
  incrementIndex(): void {
    if (this.index < features.length - 1) {
      this.index++;
    }
  }

  // Only decrement index if it is not at the first item in the array
  decrementIndex(): void {
    if (this.index != 0) {
      this.index--;
    }
  }
}
