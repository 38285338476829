<!-- Check all child components have loaded -->
<div *ngIf="isLoading || countryLoading || travelLoading || preferenceLoading" class="load-screen">
  <div class="loader"></div>
</div>
<div class="row">
  <div class="col-12 d-flex justify-content-center">
    <div class="ratoong-container">
      <div class="details-container">
        <div class="mb-4">
          <app-title-section title="Find your next ski resort to visit"
            subtitleOne="Personalise your search by choosing what is important to you."
            subtitleTwo="We help you find ski resorts that match your choices."
            imagePath="assets/images/search/mountain_icon.svg">
            <span class="pointer p-1 pr-2 my-3 border-ratoong d-flex text-uppercase anchor-tag align-self-start"
              (click)="scrollTo('searchByName')">
              <img class="mr-2 align-self-center" src="assets/images/search/anchor_down.svg" alt="Search resorts by name on Ratoong" />
              Search resorts by name
            </span>
          </app-title-section>
        </div>
        <div class="row justify-content-between">
          <div class="col-lg-4 px-3 mb-5">
            <app-my-preferences [preferences]="preferences" [parameters]="parameters" (valueEmitter)="runFilter()"
              (loadEmitter)="setPreferenceLoading($event)"></app-my-preferences>
            <app-additional-criteria [preferences]="preferences" [parameters]="parameters" (valueEmitter)="runFilter()">
            </app-additional-criteria>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-5 px-3 mb-5">
            <!-- ----SELECT COUNTRY QUESTION---- -->
            <app-select-country class="mb-2" [preferences]="preferences" (valueEmitter)="runFilter()"
              (loadingEmitter)="setCountryLoading($event)"></app-select-country>
            <!-- Travelling PREFERENCES SECTION -->
            <div class="row w-100 justify-content-start ml-0 pl-0">
              <app-travelling-preferences class="col-lg-11 col-xl-10 mb-2 pl-0 ml-0" [preferences]="preferences"
                [parameters]="parameters" (valueEmitter)="runFilter()" (loadingEmitter)="setTravelLoading($event)"
                (latLongEmitter)="setLatitudeLongitude($event)"></app-travelling-preferences>
            </div>
          </div>
          <!-- ------ CIRCLE DISPLAYING MATCHING RESULTS ------ -->
          <div class="col-lg-2 px-0 d-flex justify-content-center">
            <div class="circle-container">
              <div class="pointer count-circle d-flex align-items-center justify-content-center" [ngClass]="{
                  'large-text': includedLocations.length >= 1000
                }" [ngStyle]="{
                  width: circleWidth(includedLocations.length) + '%',
                  height: circleWidth(includedLocations.length) + '%'
                }" (click)="saveAndProceed()">
                <p class="text-uppercase text-center mb-0">
                  {{ includedLocations.length }} <br />matches
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- ------- SUBMIT BUTTON -------- -->
        <div class="d-flex flex-column align-items-center">
          <p *ngIf="infoBad">
            <ngb-alert type="danger" class="error rounded-0" [dismissible]="false">
              There are no matching resorts! Please review your search criteria
              or Reset ALL selections to start over.
            </ngb-alert>
          </p>
          <div class="d-flex w-100 flex-column align-items-center mb-3">
            <div class="btn btn-info text-white confirm-button text-uppercase" (click)="saveAndProceed()">
              View resorts
            </div>
            <div *ngIf="showReset" (click)="resetAllPopup()" class="reset mt-5 pointer">
              Reset ALL selections
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <app-search_name id="searchByName"></app-search_name>
      </div>
    </div>
  </div>
</div>

<ng-template #ResetAll>
  <app-confirm-reset-popup (valueEmitter)="resetAll()"></app-confirm-reset-popup>
</ng-template>

<ng-template #LoginPopup>
  <app-login-popup subtitleOne="Want to keep track of your searches?"
    subtitleTwo="Log in / Sign up to save your searches to your profile!" [dontRedirect]="true">
  </app-login-popup>
</ng-template>
