<div class="modal-body">
  <div class="piste-map">
  <div class="overlay"></div>
  <!-- command panel -->
  <div class="piste-map-modal">
    <div class="piste-map-command-panel">
      <span class="piste-scale-plus  far fa-plus-square "(click)="scalePiste(true)"></span>
      <span class="piste-scale-minus far fa-minus-square " (click)="scalePiste(false)"></span>
      <span
        class="piste-close fa fa-window-close"
        aria-label="Close"
        (click)="closeModal('Piste-Popup')"
        aria-hidden="true"></span>
    </div>
    <!-- command panel -->
    <div class="body-piste-image">
      <div class="wrapper-image" [ngStyle]="{'transform': 'scale(' + config.scale + ')'}">
        <img *ngIf="pisteMap" [src]="pisteMap" alt="Piste map" >
      </div>
    </div>
  </div>
  </div>
</div>
