import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from '../../../services';

@Component({
  selector: 'app-piste-modal',
  templateUrl: './piste_modal.component.html',
  styleUrls: ['./piste_modal.component.scss']
})
export class PisteModalComponent implements OnInit {

  public config = {
    ignoreBackdropClick: true,
    scale: 1
  };

  @Input() pisteMap: string;
  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  closeModal(id): any {
    this.modalService.close(id);
  }
  scalePiste(bool: boolean): any{
    if (bool){
      if ( this.config.scale > 2.8 ){ return false; }
      this.config.scale += 0.2;
    }else {
      if ( this.config.scale < 1.2 ){ return false; }
      this.config.scale -= 0.2;
    }
  }
}
