import { SharedModule } from './../shared/modules/shared.module';
import { environment } from './../../environments/environment';
import { HomeModule } from './../home/home.module';
import { ProfanityFilterPipe } from './../shared/pipes/profanity-filter.pipe';
import { ConfirmResetPopupComponent } from './../shared/ui/modals/preferences/confirm_reset_popup/confirm_reset_popup.component';
import { NewParametersComponent } from './../shared/ui/modals/preferences/new_parameters/new_parameters.component';
import { AdditionalCriteriaComponent } from './search_page/additional_criteria/additional_criteria.component';
import { SearchResultsComponent } from './results_page/search_results.component';
import { SectionHeaderComponent } from './search_page/section_header/section_header.component';
import { MyPreferencesComponent } from './search_page/my_preferences/my_preferences.component';
import { TravellingPreferencesComponent } from './search_page/travelling_preferences/travelling_preferences.component';
import { SelectCountryComponent } from './search_page/select_country/select_country.component';
import { PreferenceSliderPopupComponent } from './../shared/ui/modals/preferences/preference_slider_popup/preference_slider_popup.component';
import { PreferenceSelectPopupComponent } from '../shared/ui/modals/preferences/preference_select_popup/preference_select_popup.component';
import { SearchPageComponent } from './search_page/search_page.component';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { SearchNameComponent } from './search_name/search_name.component';

import { SearchRoutes } from './search.routes';
import { ResortListComponent } from './results_page/resort_list/resort_list.component';

import { ChartsModule } from 'ng2-charts';
import { SearchResortDetailMainComponent } from './search_resort_detail/search_resort_detail_main/search_resort_detail_main.component';
import { DetailsContactComponent } from './search_resort_detail/details_contact/details_contact.component';
import { DetailsSkiPisteComponent } from './search_resort_detail/details_ski_piste/details_ski_piste.component';
import { DetailsAirportsComponent } from './search_resort_detail/details_airports/details_airports.component';

import { DetailsRatingsComponent } from './search_resort_detail/details_ratings/details_ratings.component';
import { DetailsOpeningLiftsComponent } from './search_resort_detail/details_opening_lifts/details_opening_lifts.component';
import { SearchResortTitleComponent } from './search_resort_detail/search_resort_title/search_resort_title.component';
import { DetailsWeatherComponent } from './search_resort_detail/details_weather/details_weather.component';
import { DetailsReviewsComponent } from './search_resort_detail/details_reviews/details_reviews.component';
import { SingleReviewComponent } from './search_resort_detail/details_reviews/single_review/single_review.component';

// For google maps
import { AgmCoreModule } from '@agm/core'; // @agm/core
import { AgmDirectionModule } from 'agm-direction';
import { ResortListItemComponent } from './results_page/resort_list_item/resort_list_item.component';
import { FavouriteBucketlistComponent } from './search_resort_detail/search_resort_title/favourite_bucketlist/favourite_bucketlist.component';
import { SaveResultsComponent } from './results_page/save_results/save_results.component'; // agm-direction

@NgModule({
  imports: [
    SearchRoutes,
    HttpClientModule,
    NgbModule,
    CommonModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    SharedModule,
    ChartsModule,
    HomeModule,
    AgmCoreModule.forRoot({
      // @agm/core
      apiKey: environment.googleMapsAPI,
    }),
    AgmDirectionModule, // agm-direction
  ],
  exports: [SearchNameComponent, ResortListComponent],
  declarations: [
    SearchNameComponent,
    SearchResortDetailMainComponent,
    DetailsSkiPisteComponent,
    DetailsContactComponent,
    DetailsAirportsComponent,
    DetailsRatingsComponent,
    DetailsReviewsComponent,
    ResortListComponent,
    DetailsOpeningLiftsComponent,
    SearchResortTitleComponent,
    DetailsWeatherComponent,
    SingleReviewComponent,
    SearchPageComponent,
    PreferenceSelectPopupComponent,
    PreferenceSliderPopupComponent,
    SelectCountryComponent,
    TravellingPreferencesComponent,
    MyPreferencesComponent,
    SectionHeaderComponent,
    SearchResultsComponent,
    AdditionalCriteriaComponent,
    NewParametersComponent,
    ConfirmResetPopupComponent,
    ResortListItemComponent,
    FavouriteBucketlistComponent,
    SaveResultsComponent,
  ],
  providers: [ProfanityFilterPipe],
})
export class SearchModule {}
