<div class="parallax">
  <div class="container-fluid why-use-ratoong">
    <div class="row" id="weLoveYou">
      <div class="offset-lg-2 offset-md-2 col-12 col-md-8 col-lg-8">
        <div class="card bg-bor-none">
          <div class="card-body">
            <div class="parag">
              <div class="section-head">
                <img class="icon-section" src="/assets/images/src/about_we_love_you.svg" alt="We Love You" />
                <h1 class="headers text-uppercase display-4">
                  WE LOVE YOU
                </h1>
              </div>
              <p class="rtng mt-4 font-weight-bold">
                <em>“</em> RATOONG IS MADE BY RIDERS FOR RIDERS. WHEN WE SAY WE LOVE YOU WE MEAN IT <em>„</em>
              </p>
              <p>
                Why? We built Ratoong based on our love of skiers and snowboarders - riders.
                Why? We want all riders to have the best experiences at ski resorts.
                This is also the reason we collaborate with ski resorts so they can keep improving their ski area.
              </p>
              <p>
                We want to make searching for your next ski resort easier, faster and more efficient than finding a good
                hotel or restaurant. We want to make the rating of ski resorts easier as well, you can easily share your
                experiences using pictograms and snowflakes to rate 20 different parameters such as the lift system, the
                scenery, the off-piste, etc.
              </p>
              <p>
                Future versions will include many more features. Most are based on requests from our beloved users –
                you!
                Others are developed by us and tested on users. Therefore don’t forget to sign up and follow us on one
                of
                our many Social Media platforms.
              </p>
            </div>
            <div class="social">
              <a target="_blank" href="https://www.facebook.com/ratoongIVS/">
                <i class="fab fa-facebook-square"></i>
              </a>
              <a target="_blank" href="https://www.instagram.com/ratoong_/">
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" id="whyUseRatoong">
      <div class="offset-lg-2 offset-md-2 col-12 col-md-8 col-lg-8">
        <div class="card bg-bor-none">
          <div class="card-body">
            <div class="parag">
              <div class="section-head">
                <img class="icon-section" src="/assets/images/src/about_why_ratoong.svg" alt="Why use ratoong" />
                <h1 class="headers text-uppercase display-4">
                  WHY USE RATOONG?
                </h1>
              </div>
              <p class="mt-4">
                We are an independent company; not using commercials or favoring particular resorts, nor are we
                influenced
                by any economic interests - all we have is sponsors - world-known ski brands - offering equipment to our
                crew.
              </p>
              <p>
                We believe in giving and getting!
              </p>
              <p>
                It’s fine if you use Ratoong just to search for your next ski holiday or next ski area to visit,
                but we hope you will also rate the ski resorts you visit, telling us and your fellow skiers about
                the experience and conditions, etc. We only ask you to rate 5 randomly chosen parameters.
                It takes less than 15 secs.!
              </p>
              <p>
                You can of course rate all 20 parameters if you would like, for all ski resorts you have been to,
                and give more back to our community.
              </p>
              <p>
                Ratings are simple; all you have to do is mark the number of snowflakes from 1 to 5 for the different
                parameters.
                5 is always the best or most.
              </p>
              <p>
                By rating you help others – and they help you back when they rate.
                That is what we like and we hope you like it too.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid history" id="history">
    <div class="row">
      <div class="offset-lg-2 offset-md-2 col-12 col-md-8 col-lg-8">
        <div class="card bg-bor-none">
          <div class="card-body">
            <div class="section-head history-header">
              <img class="icon-section" src="/assets/images/src/about_history.svg" alt="History" />
              <h1 class="headers text-uppercase display-4">
                HISTORY
              </h1>
            </div>
            <p class="mt-4">
              Ratoong was started in 2017 by Eg Nicolajsen, who is a passionate skier and ski instructor,
              in the flat country of Denmark. Ratoong has its headquarters in a small fishing village north of
              Copenhagen with a view of <a class="text-links" href="Https://www.Copenhill.Dk/"
                target="_blank">COPENHILL</a>.
            </p>
            <p>
              Eg wanted to build a platform that could inspire you to go to other ski areas than the ones where most
              people go. In many ways, we live in a time where people want to have unique experiences, but when it
              comes to skiing and snowboarding, 80% of people go to 20% of the biggest resorts that exist in the world.
              We want to change that to make people visiting resorts matching their preferences and not just because
              they are big!
            </p>
            <p>
              Secondly, Eg wanted to build a platform that could give users a objective take on a ski resort,
              supplementing those you get from a friend or review sites. Eg wanted to build a platform that delivers
              “wisdom of the crowd” objectivity, by letting millions of users rate.
            </p>
            <p>
              Since then, we have built a unique team working from all around the globe and with support from The Danish
              Innovation
              Fund - <a class="text-links" href="https://innovationsfonden.dk/da/programmer/innobooster"
                target="_blank">INNOBOOSTER</a>!
            </p>
            <p>
              We Hope You Share Our Passion As Well!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" id="team">
    <div class="row">
      <div class="offset-lg-2 offset-md-2 col-12 col-md-8 col-lg-8">
        <div class="card bg-bor-none" >
          <div class="card-body">
            <div class="section-head">
              <img class="icon-section" src="/assets/images/src/about_team.svg" alt="Team" />
              <h1 class="headers text-uppercase display-4">TEAM</h1>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-3 col-12 mb-5" *ngFor="let member of members">
                <div class="card border-0">
                  <img class="card-img-top rounded-circle mx-auto d-block" src="{{member.img}}" [alt]="member.name" />
                  <h5 class="card-title text-center">
                    {{member.name}}
                  </h5>
                  <p class="card-text">
                    {{member.text}}
                  </p>
                  <a href="{{member.ldin}}" target="_blank">
                    <i class="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="contact" class="container-fluid contact" >
    <div class="row">
      <div class="offset-lg-2 offset-md-2 col-12 col-md-8 col-lg-8">
        <div class="card bg-bor-none">
          <div class="row">
            <div class="col-12">
              <div class="card-body">
                <div class="section-head">
                  <img class="icon-section" src="/assets/images/src/about_want_your_ideas.svg" alt="We value your ideas!" />
                  <h1 class=" headers text-uppercase display-4">We want your Ideas!</h1>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 mt-4">
                    <p class="contact-text">
                      Ratoong is always looking for young talent to join our divers international team.
                      Send us a message via Facebook Massenger or Email us your CV and lets make a positive change together.
                    </p>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 mt-4 align-self-center">
                    <div class="row">
                      <!-- <div class="col-6 col-md-6 text-center">
                        <i class="fab fa-facebook-messenger" (click)="showFbMessenger()">
                          <p class="contact-title contact-block text-uppercase text-center">TEXT US</p>
                        </i>
                      </div> -->
                      <div class="col-6 col-md-6 text-center">
                        <a href="mailto:lovesnow@ratoong.com" class="">
                          <div class="wrap-circle-email">
                            <i class="fas fa-envelope"></i>
                          </div>
                          <p class="contact-title text-uppercase text-center">EMAIL US</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
