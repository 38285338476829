export class UpdateSubPreference {
  static readonly type = '[Auth] UpdateSubPreference';
  constructor(
    public uid: string,
    public nameCtrl: string,
    public mark: number
  ) {}
}

export class GetSubCategories {
  static readonly type = '[Auth] GetSubCategories';
}
export class CheckRedirect{
  static readonly type = 'CheckRedirect';
  constructor(
    public redirectID: string,
  ) {}
}

export class WipeRedirect {
  static readonly type = 'WipeRedirect';
}

export class GetSkiGroups {
  static readonly type = '[Auth] GetSkiGroups';
}
