<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" action="" class="w-100">
  <h1 class="welcome">Welcome back!</h1>
  <div class="form-group w-100 mb-5">
    <div class="error-container">
      <div class="error-box p-1 pl-2" *ngIf="submitted && errorMessage">
        <div>
          Sorry, we do not recognise that password. Please try again or visit
          <span
            (click)="open('Forgot-Pass-Modal', forgot)"
            class="color-ratoong"
            >forgot password</span
          >
          to reset.
        </div>
      </div>
    </div>
    <label for="password" class="form-label">Password</label>
    <div class="input-container mb-1">
      <input
        #password
        autofocus
        type="{{ fieldTextType }}"
        id="password"
        placeholder="••••••••••••"
        class="form-input form-control rounded-0"
        formControlName="password"
        [ngClass]="{
          'error-input': (submitted && f.password.errors) || errorMessage
        }"
      />
      <span
        class="eye"
        [ngClass]="{
          'grey-icon': !password.value
        }"
        ><i
          (click)="changeInputType('text')"
          *ngIf="fieldTextType == 'password'"
          class="fas fa-eye-slash"
        ></i
        ><i
          (click)="changeInputType('password')"
          *ngIf="fieldTextType == 'text'"
          class="fas fa-eye"
        ></i
      ></span>
    </div>
    <a class="extra-options" (click)="open('Forgot-Pass-Modal', forgot)"
      >Forgot password?</a
    >
  </div>

  <div class="form-group mb-2">
    <input
      [ngClass]="{
        grey: !password.value || password.value.length < 6
      }"
      [disabled]="password.value.length < 6"
      type="submit"
      class="w-100 text-white rounded-0 next-button"
      value="LOGIN"
    />
  </div>
</form>
<div class="position-relative w-100 mb-3">
  <div
    *ngIf="isLoading"
    [ngClass]="{
      visible: isLoading
    }"
    class="load-container"
  >
    <div class="loader"></div>
  </div>
</div>

<ng-template #forgot>
  <app-password-forgot-modal
    [userEmail]="userEmail"
  ></app-password-forgot-modal>
</ng-template>
