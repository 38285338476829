
import {Action,  State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {DeleteUnusedUsers} from './dev-console.action';
import {DevConsoleService} from './dev-console.service';

export class DevConsoleStateModel {

}

@State<DevConsoleStateModel>({
  name: 'DevConsole',
  defaults: {
  }
})
@Injectable()
export class DevConsoleState {

  constructor(private devConsoleService: DevConsoleService) {}

  @Action(DeleteUnusedUsers)
  deleteUnusedUsers({getState, setState}: StateContext<DevConsoleStateModel>, {uid}: DeleteUnusedUsers): any {
    return this.devConsoleService.deleteUnusedUsers(uid);
  }

}
