import { ModalService } from '../../../../services/modal-service.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-preference-slider-popup',
  templateUrl: './preference_slider_popup.component.html',
  styleUrls: ['./preference_slider_popup.component.scss'],
})
export class PreferenceSliderPopupComponent implements OnInit {
  @Input() question: any;
  // The list of users rangeQuestion preferences
  @Input() userPreferences: any[];
  // We need to emit the value that the user selects, back to the parent (search component)
  @Output() valueEmitter = new EventEmitter();

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  setValue(parameter, value) {
    let question = this.getQuestion(parameter.questionID);
    // If the question is in the preferences array already we can set the value directly
    if (question) {
      question.value = value;
    } else {
      // Else we add the question and set the value
      this.userPreferences.push({
        questionID: parameter.questionID,
        name: parameter.name,
        value: value,
      });
    }
    this.valueEmitter.emit();
  }

  disableQuestion(parameter: any) {
    let question = this.getQuestion(parameter.questionID);
    // If the question is in the preferences array already we set it directly
    if (question) {
      this.removeItem(parameter.questionID);
    }
    this.valueEmitter.emit();
  }

  closeModal() {
    this.modalService.close('Preference-Popup');
  }

  // Gets the value from the question in the user preferences, using the questionID
  getValue(questionID: number) {
    // By default, the value is set to '1'
    let value = '1';
    const question = this.getQuestion(questionID);
    if (question) {
      value = question.value;
    }
    return value;
  }

  // Returns the question in the user preferences (if it exists) based on the parameter id, else returns null
  getQuestion(questionID: number) {
    for (let i = 0; i < this.userPreferences.length; i++) {
      if (this.userPreferences[i].questionID === questionID) {
        return this.userPreferences[i];
      }
    }
    return null;
  }

  // Removes item from userPreferences array by finding the index of the item and then removing it
  removeItem(questionID) {
    let index = -1;
    for (let i = 0; i < this.userPreferences.length; i++) {
      if (this.userPreferences[i].questionID == questionID) {
        index = i;
      }
    }
    if (index > -1) {
      this.userPreferences.splice(index, 1);
    }
  }
}
