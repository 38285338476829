export class GetFilteredResortData {
  constructor(public arrayOfLocations: [], public country: string, public age: string, public gender: string,
              public fromDate: string, public toDate: string) {}
  static readonly type = 'GetFilteredResortData';
}

export class GetAllLocations {
  constructor(public id: number, public listOfTowns: string) {}
  static readonly type = 'GetAllLocations';
}
