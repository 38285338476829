<div class="container footer bg-dark">
  <div class="row pt-5 d-flex justify-content-between">
    <div class="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 pb-4">
      <div class="row">
        <div class="col-2 pr-1 pl-0">
          <img class="logo" src="/assets/images/logo_w.png" alt="Ratoong" />
        </div>
        <div class="col-10 px-2 h-100 d-flex align-items-center logo">
          <h4 class="mb-0 mt-1">Ratoong</h4>
        </div>
        <div class="col-lg-2 col-0 px-0"></div>
        <div class="col-lg-10 col-12 px-2 pt-1 ratoong-info">
          <p class="logo-text">
            Find, plan and book your next ski trip! Rated by riders for riders.
          </p>
          <div class="d-flex">
            <a
              href="https://www.instagram.com/ratoong_/"
              target="new"
              title="instagram"
              class="white grey-hover mr-3"
              ><i class="fab fa-instagram"></i
            ></a>
            <a
              href="https://www.facebook.com/ratoongIVS/"
              target="new"
              title="facebook"
              class="white grey-hover mr-3"
              ><i class="fab fa-facebook-square"></i
            ></a>
            <a
              href="https://twitter.com/ratoong/"
              target="new"
              title="twitter"
              class="white grey-hover mr-3"
              ><i class="fab fa-twitter-square"></i
            ></a>
            <a
              href="https://www.linkedin.com/company/17969583"
              target="new"
              title="linkedin"
              class="white grey-hover"
              ><i class="fab fa-linkedin"></i
            ></a>
          </div>
          <p class="logo-text">#SMILEINTHESNOW</p>
        </div>
      </div>
    </div>
    <div class="col-xl-6 pb-4 col-md-7 col-sm-6 pt-1">
      <div class="row">
        <div class="col-4 link-column">
          <div class="d-flex flex-column">
            <h5>Ratoong</h5>
            <a
              class="link-text grey-hover pointer"
              [routerLink]="['about']"
              title="About page"
              >About us</a
            >
            <a
              class="link-text grey-hover pointer"
              (click)="goPlaces('about', 'whyUseRatoong')"
              title="What is our goal"
              >Why Ratoong
            </a>
            <a
              class="link-text grey-hover pointer"
              (click)="goPlaces('about', 'history')"
              title="View our history"
              >History</a
            >
            <a
              class="link-text grey-hover pointer"
              (click)="goPlaces('about', 'team')"
              title="View the team"
              >Team</a
            >
          </div>
        </div>
        <div class="col-4 link-column">
          <div class="d-flex flex-column">
            <h5>Explore</h5>
            <a
              class="link-text grey-hover pointer"
              href="https://blog.ratoong.com/"
              title="Go to blog site"
              >Blog</a
            >
            <a
              class="link-text grey-hover pointer"
              (click)="goPlaces('ambassador','')"
              >Ambassadors</a
            >
          </div>
        </div>
        <div class="col-4 link-column">
          <div class="d-flex flex-column">
            <h5>Get in touch</h5>
            <a
              class="link-text grey-hover pointer"
              (click)="goPlaces('about', 'contact')"
              title="View contacts"
              >Contact us</a
            >
            <a class="link-text grey-hover pointer" title="Log in as partner"
               (click)="goPlaces('partners', '')"
               title="View contacts"
              >Partners</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-0"></div>
  </div>
  <div class="row px-0 d-flex justify-content-between">
    <div
      class="col-12 py-3 px-0 d-flex align-items-center justify-content-between"
    >
      <a
        class="extra-text white grey-hover pointer"
        [routerLink]="['/terms']"
        title="View Privacy Policy"
        >PRIVACY POLICY</a
      >
      <p class="extra-text mb-0">BORN IN COPENHAGEN, DENMARK</p>
    </div>
  </div>
</div>
<!-- </footer> -->

<!-- <fb messenger> -->
<!-- <script></script>
<div>
  <div
    id="facebookMessenger"
    [ngClass]="showFbMessenger ? 'msg-show' : 'msg-hide'"
  >
    <a
      class="facebookMsgClose"
      href="javascript:void(0);"
      (click)="showFbMessenger = !showFbMessenger"
    >
      <img
        src="assets/images/src/2136486-128.png"
        style="border-width: 0px"
        alt="Messenger Icon"
      />Close messenger
    </a>
    <div
      class="fb-page"
      data-href="https://www.facebook.com/ratoongIVS/?notif_t=page_admin&notif_id=1502782478576922"
      data-tabs="messages"
      data-small-header="false"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true"
      fb-xfbml-state="rendered"
      fb-iframe-plugin-query="adapt_container_width=true&app_id=&container_width=0&hide_cover=false&href=https://facebook.com/ratoongIVS&locale=en_EN&sdk=joey&show_facepile=true&small_header=false&tabs=messages"
    >
      <span style="vertical-align: bottom; width: 340px; height: 500px">
        <iframe
          name="f372ae846d6ac7"
          frameborder="0"
          allowtransparency="true"
          allowfullscreen="true"
          scrolling="no"
          title="fb:page Facebook Social Plugin"
          src="https://www.facebook.com/v2.5/plugins/page.php?adapt_container_width=true&app_id=&channel=https://staticxx.facebook.com/connect/xd_arbiter%2Fr%2FXBwzv5Yrm_1.js%3Fversion%3D42%23cb%3Dfe9f70a61ed754%26relation%3Dparent.parent&container_width=0&hide_cover=false&href=https://facebook.com/ratoongIVS&locale=en_EN&sdk=joey&show_facepile=true&small_header=false&tabs=messages"
        ></iframe>
      </span>
    </div>
  </div>
</div> -->
<!-- </fb messenger> -->
