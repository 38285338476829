import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlName'
})
export class UrlNamePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, args?: any): any {
    let formatedURL = this.transformFacebook(value);
    if (formatedURL.includes('/search-ski-resorts/') || formatedURL.includes('/rate-ski-resort/')){
      const splitArray = formatedURL.split('/');
      splitArray.pop();
      formatedURL = splitArray.join('/');
    }
    return  formatedURL;
  }

  private transformFacebook(url: string): any{
    const splitArray = url.split('?');
    return  splitArray[0];
  }
}
