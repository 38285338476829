<div class="modal-body container">
  <div [ngClass]="{
      invisible: isLoading
    }">
    <div class="d-flex justify-space-between">
      <h1 class="text-uppercase title mb-3">{{ title }}</h1>
      <span><i (click)="closeModal()" class="close fas fa-times"></i></span>
    </div>
    <div class="sub-title text-center">
      Mark those that are the most important to you.
    </div>
    <!-- Disclaimer for sustainability parameters -->
    <p *ngIf="title == 'Sustainability'" class="w-100 text-center mb-0 disclaimer mt-2">
      These criteria are based on the opinions and observations of your fellow
      snow riders - they are not results of a science-based assessment.
    </p>
    <!-- Options -->
    <div class="d-flex justify-content-center">
      <div [ngClass]="{ 'w-650': options.length > 4 }" class="row my-4 justify-content-center images">
        <div
          disableTooltip="{{ option.questionID }}"
          tooltipClass="really-custom"
          ngbTooltip="This is a new search option. As we get more ratings for this you will get more results that match."
          class="col-auto d-flex justify-content-center position-relative mb-3"
          [ngClass]="{
            'col-lg-4': options.length > 4,
            'col-lg-3': options.length == 4
          }"
          *ngFor="let option of options">
          <div class="position-relative">
            <img
              [ngClass]="{ selected: questionSelected(option) }"
              (click)="selectOption(option)"
              class="image"
              src="{{ 'assets/images/search/' + option.image }}"
              alt="{{ option.name }}"
              (load)="imageLoaded()" />
            <img
              *ngIf="questionSelected(option)"
              class="tick"
              src="assets/images/search/selected_checkmark.svg"
              alt="{{ option.name }} selected" />
            <!-- Display N on all new parameters -->
            <div *ngIf="newParams.includes(option.questionID)" class="symbol">
              <div class="N d-flex justify-content-center align-items-center text-white">
                N
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex w-100 flex-column align-items-center mb-3">
      <p *ngIf="containsNewParameters" class="mb-3 mt-0 new-params">
        <span class="bg-ratoong N-2 mr-2 text-white"> N </span>
        New search parameters that may not have as much data.
      </p>
      <div class="btn btn-info text-white confirm-button my-3 text-uppercase" (click)="closeModal()">
        Save
      </div>
      <div *ngIf="showReset" (click)="resetAll()" class="reset my-3 pointer">
        Reset ALL selections
      </div>
    </div>
  </div>
  <div class="load-screen" [ngClass]="{
      invisible: !isLoading
    }">
    <div class="loader"></div>
  </div>
</div>
