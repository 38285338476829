<div class="modal-body container">
  <div class="d-flex justify-space-between">
    <h1 class="text-uppercase title mb-3">{{ question.name }}</h1>
    <span><i (click)="closeModal()" class="close fas fa-times"></i></span>
  </div>
  <div class="sub-title text-center mb-3">
    Choose the <span class="text-lowercase">{{ question.name }}</span> you would
    like.
  </div>
  <div class="d-flex justify-content-center">
    <div class="row range-container my-5 w-100 justify-content-center">
      <input
        #slider
        (input)="setValue(question, slider.value)"
        class="w-100 my-3"
        type="range"
        min="1"
        max="5"
        value="{{ getValue(question.questionID) }}"
        [ngClass]="{
          grey: !getQuestion(question.questionID)
        }"
      />
      <div class="w-100 d-flex justify-content-between">
        <span
          class="text-uppercase slider-sub-text"
          [ngClass]="{
            'low-value': slider.value < 5 && getQuestion(question.questionID)
          }"
          >{{ question.range.lowest }}</span
        >
        <span
          class="text-uppercase slider-sub-text"
          [ngClass]="{
            'high-value': slider.value > 5 && getQuestion(question.questionID)
          }"
          >{{ question.range.highest }}</span
        >
      </div>
    </div>
  </div>
  <div class="d-flex w-100 flex-column align-items-center mb-3">
    <div
      class="btn btn-info text-white confirm-button my-3 text-uppercase"
      (click)="closeModal()"
    >
      Save
    </div>
    <div
      *ngIf="getQuestion(question.questionID)"
      (click)="disableQuestion(question)"
      class="reset my-3 pointer"
    >
      Reset
    </div>
  </div>
</div>
