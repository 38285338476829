<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
  <a (click)="recordEvent('Home icon')" class="navbar-brand d-flex align-items-center" [routerLink]="['']"
    (click)="isCollapsed = true">
    <img src="/assets/images/logo_w.png" alt="Ratoong" />
    <span class="nav-item ml-2">RATOONG</span>
  </a>
  <button class="navbar-toggler ml-auto custom-toggler" type="button" data-toggle="collapse"
    (click)="isCollapsed = !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngClass]="{ hideMenu: isCollapsed, showMenu: !isCollapsed }" id="navbarsTop">
    <ul class="navbar-nav w-100 d-flex nav-center">
      <li class="nav-item">
        <a [routerLink]="['search-ski-resorts']" (click)="recordEvent('Search')" [routerLinkActive]="['active']"
          (click)="isCollapsed = true" class="nav-link padding-nav-link">Search</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['rate-ski-resort']" (click)="recordEvent('Rate')" [routerLinkActive]="['active']"
          (click)="isCollapsed = true" class="nav-link padding-nav-link">Rate</a>
      </li>
      <!-- item for desktop -->
      <li class="nav-item">
        <a (click)="recordEvent('Plan')" [routerLink]="['plan']" [routerLinkActive]="['active']"
          (click)="isCollapsed = true" class="nav-link padding-nav-link">Plan a trip
        </a>
      </li>
      <li class="nav-item">
        <a (click)="recordEvent('Blog')" href="https://blog.ratoong.com/" class="nav-link padding-nav-link">Blog</a>
      </li>
      <li class="nav-item" *ngIf="currentU" (click)="isCollapsed = true">
        <a [routerLink]="['/profile']" (click)="recordEvent('Profile')" [routerLinkActive]="['active']"
          class="nav-link padding-nav-link">
          Profile
        </a>
      </li>
      <li class="group navbar">
        <div class="nav-item account-bnt" *ngIf="!currentU" (click)="isCollapsed = true">
          <a class="login-btn" (click)="open('Login-Popup', LoginPopup)">Log in / Sign up</a>
        </div>
        <div class="nav-item account-bnt" (click)="signOut(); isCollapsed = true" *ngIf="currentU">
          <a class="nav-link padding-nav-link logout">Log out</a>
        </div>
      </li>
    </ul>
  </div>
  <ng-template #LoginPopup>
    <app-login-popup subtitleOne="Log in to rate resorts and save your searches."
      subtitleTwo="Rate more and share the ❤️" [dontRedirect]="false">
    </app-login-popup>
  </ng-template>
  <div *ngIf="mobile && !showMobileMenu" class="overlay-menu" (click)="openMobileMenu()"></div>

  <!-- CURRENT THE TWO 'PROMPTS' WE HAVE (RATE AND SEARCH) ARE VERY SIMILAR (SAME ICON, SAME LOGIN FUNCTIONALITY)
  IF FUTURE ONES ALSO FOLLOW THE SAME PATTERN, IT IS PROBABLY WORTH US JUST PUTTING THE CODE INSIDE THE 'PromptComponent'
  I WANTED TO KEEP 'PromptComponent' MINIMAL FOR NOW SO IT IS FLEXIBLE AND CAN FACTOR IN ANY DESIGNS -->
  <!-- PROMPT ON RATE PAGE -->
  <app-prompt [@prompt] *ngIf="isRateMain() && !currentU && !userClosedRatePrompt" class="prompt"
    (userClosedPrompt)="userClosedRatePrompt = true">
    <div class="row">
      <img class="col-2 prompt-icon shake" src="/assets/images/icons/login_prompt_icon.svg" alt="Login" />
      <div class="col-10">
        <p class="prompt-text">
          You will need to <b>Log in/Sign up</b> to validate your ratings.
        </p>
        <div class="d-flex">
          <p class="prompt-text mb-0 mr-4 pt-1">For faster login, use:</p>
          <a class="mr-4 pointer" (click)="loginGoogle()">
            <img class="social-icon" src="assets/images/icons/google_icon.png" alt="Login with Google" />
          </a>
          <a class="mr-4 pointer" (click)="loginFb()">
            <img class="social-icon" src="assets/images/icons/facebook_icon.png" alt="Login with Facebook" />
          </a>
        </div>
      </div>
    </div>
  </app-prompt>
  <!-- PROMPT ON SEARCH PAGE AND RESULT PAGE -->
  <app-prompt [@prompt] *ngIf="isSearchPage() && !currentU && !userClosedSearchPrompt" class="prompt"
    (userClosedPrompt)="userClosedSearchPrompt = true">
    <div class="row">
      <img class="col-2 prompt-icon shake" src="/assets/images/icons/login_prompt_icon.svg" alt="Login" />
      <div class="col-10">
        <p class="prompt-text">
          <b>Log in/Sign up</b> to save the search preferences you selected. You
          can access your saved searches in your profile.
        </p>
        <div class="d-flex">
          <p class="prompt-text mb-0 mr-4 pt-1">For faster login, use:</p>
          <a class="mr-4 pointer" (click)="loginGoogle()">
            <img class="social-icon" src="assets/images/icons/google_icon.png" alt="Login with Google" />
          </a>
          <a class="mr-4 pointer" (click)="loginFb()">
            <img class="social-icon" src="assets/images/icons/facebook_icon.png" alt="Login with Facebook" />
          </a>
        </div>
      </div>
    </div>
  </app-prompt>
</nav>
