import { takeUntil } from 'rxjs/operators';
import { LoginPopupComponent } from '../../../ui/modals/login_popup/login_popup.component';
import { ModalService } from './../../../services/modal-service.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Select } from '@ngxs/store';
import { AuUser } from '../entities/user_interface';
import { AuthState } from '../auth.state';

@Injectable({
  providedIn: 'root',
})
export class GuardService implements CanActivate {
  modalUnsubscribe$: Subject<any> = new Subject<any>();

  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;
  constructor(private router: Router, private modalService: ModalService) {
    this.currentUser.subscribe((data) => {
      this.currentU = data;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): true | UrlTree {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): true | UrlTree {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): true | UrlTree {
    if (this.currentU) {
      return true;
    } else {
      // Set the redirect link so we can redirect user after signin with redirect
      localStorage.setItem('ratoongRedirect', url);
      this.modalService.open('Login-Popup', LoginPopupComponent, 'lg');
      this.modalService.onChange(false);
      this.modalService.onChange$
        .pipe(takeUntil(this.modalUnsubscribe$))
        .subscribe(() => {
          // Check when the login-popup is closed
          if (!this.modalService.get('Login-Popup')) {
            localStorage.removeItem('ratoongRedirect');
            // If the user is now logged in we can move forwards
            if (this.currentU && url) {
              // Unsibscribe and then navigate to the url
              this.modalUnsubscribe$.next();
              this.modalUnsubscribe$.complete();
              return this.router.navigate([url]);
            } else {
              // Else we just unsubscribe and default to the homepage
              this.modalUnsubscribe$.next();
              this.modalUnsubscribe$.complete();
            }
          }
        });
    }
    return this.router.parseUrl('');
  }
}
