import { RouterModule } from '@angular/router';
import { HomeAmbassadorsItemComponent } from './home_ambassadors/home_ambassadors_item/home_ambassadors_item.component';
import { HomeSummarySectionComponent } from './home_summary_section/home_summary_section.component';
import { HomeJoinCommunityComponent } from './home_join_community/home_join_community.component';
import { HomePlanTripComponent } from './home_plan_trip/home_plan_trip.component';
import { HomeRateShareComponent } from './home_rate_share/home_rate_share.component';
import { HomeAmbassadorsComponent } from './home_ambassadors/home_ambassadors.component';
import { HomeComponent } from './home.component';
import { SharedModule } from './../shared/modules/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgModule } from '@angular/core';

@NgModule({
  imports: [NgbModule, SharedModule, RouterModule],
  declarations: [
    HomeComponent,
    HomeAmbassadorsComponent,
    HomeRateShareComponent,
    HomePlanTripComponent,
    HomeJoinCommunityComponent,
    HomeSummarySectionComponent,
    HomeAmbassadorsItemComponent,
  ],
  exports: [HomePlanTripComponent, HomeAmbassadorsComponent, HomeAmbassadorsItemComponent,],
})
export class HomeModule {}
