import { SkiGroup } from './entities/skiGroup';
import { Injectable } from '@angular/core';

import * as firebase from 'firebase';
import { SubCategory } from './entities/subCategory';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  constructor(
    private analytics: AngularFireAnalytics) {
    this.database = firebase.database();
  }
  database: any = null;

  updateSubPreference(uid, nameCtrl, mark): any {
    const path = `NewUsers/${uid}/Preferences/`;
    const updates = {};
    updates['/' + nameCtrl] = mark;
    this.database
      .object(path)
      .update(updates)
      .catch((error) => console.log(error));
  }

  async getSubRatings(): Promise<any> {
    const child = [];
    await firebase
      .database()
      .ref('/SubCategories')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((obj) => {
          if (obj.val().active) {
            const group = obj.val().group;
            const name = obj.val().CategoryName;
            const categorySlug = obj.val().CategorySlug;
            const imagePath = obj.val().ImagePath;
            const questionText = obj.val().QuestionText;
            const riderType = obj.val().riderType; 
            const id = obj.val().SubCategoryId;
            const categoryId = obj.val().CategoryGroupId;
            const min = obj.val().Min;
            const max = obj.val().Max;
            child.push({id, group, name, categorySlug, imagePath, questionText, min, max, categoryId, riderType} as SubCategory);
          }
        });
      });
    return child;
  }

  async getSkiGroups(): Promise<any> {
    const child = [];
    await firebase
      .database()
      .ref('/SkiGroups')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((obj) => {
          const groupId = obj.val().groupId;
          const title = obj.val().title;
          const imageName = obj.val().imageName;
          const riderType = obj.val().riderType
          child.push({groupId, title, imageName, riderType} as SkiGroup);
        });
      });
    return child;
  }

  async checkRedirect(redirectID: string): Promise<any> {
    let referalObj = null;
    let count = 1;
    await firebase
      .database()
      .ref('/RedirectLinks')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((child) => {
          const url = String(child.val().url);
          if (url && url.includes(redirectID)){

            if (child.val().hasOwnProperty('clicks')){
              count = Number(child.val().clicks) + 1;
            }
            const value = child.val().value;

            const redirect = (child.val().customURL == null) ? '' : child.val().customURL;
            const type = child.val().type;
            const name = child.val().name;

            this.analytics.logEvent('RAT_incoming_traffic', { traffic: value});
            // referalObj = redirect;
            referalObj = {redirect, type, name};
            this.firebaseUpdate('/RedirectLinks/' + child.key + '/clicks' , count);

            const postData = {
              date: new Date()
            };

            // Get a key for a new Post.
            const newPostKey = firebase.database().ref().child('/RedirectLinksData/' + child.key).push().key;

            this.firebaseUpdate( '/RedirectLinksData/' + child.key + '/' + newPostKey , postData);
          }
        });
      });
    return referalObj;
  }

  private async firebaseUpdate(path: any, updates: any): Promise<any> {
    const object = {};
    // @ts-ignore
    object[path] = updates;

    await firebase.database()
      .ref()
      .update(object)
      .catch((error: any) => {
        throw new Error(error.message);
      });
    return Promise.resolve(undefined);
  }
}
