<div class="d-flex flex-column align-items-center svg-transformer{{
    questionNumber
  }}">
  <div class="image-container d-flex justify-content-between align-items-center">
    <img
      src="/assets/images/info/ratings/{{ questionGroupsOrderedList[currentQuestionNumber()].imgLink }}"
      class="img-fluid-title title-icon"
      alt="{{ questionGroupsOrderedList[currentQuestionNumber()].groupName }}" />
  </div>
  <div class="text-dark custom-title" >
    <!-- Translated text with an example question group: THE AREA -->
    {{ 'rate.' + questionGroupsOrderedList[currentQuestionNumber()].groupName | translate | uppercase }}
  </div>
  <p class="custom-text">
    <!-- Translated text: Rate the parameters you care about. It's okay to skip the ones that you don't apply to your experience. -->
    {{ "rate.note-to-user" | translate}}
  </p>
  <div class="
      d-flex
      justify-content-between
      align-items-center
      position-relative
      w-100
      px-2
    ">
    <div class="prev" (click)="currentSlide(false, false)">
      <img src="assets/images/src/arrow_bold.png" class="carousel-arrow-prev" alt="Previous ski resort rating parameters" />
    </div>
    <ul class="list-unstyled content-wrapper">
      <li *ngFor="
          let question of questionGroupsOrderedList[questionNumber - 2]
            .questionsList
        " class="list-element">
        <div class="question-img-holder">
          <img src="/assets/images{{ question.imagePath }}" class="img-fluid question-icon"
            alt="{{ question.question }}" />
        </div>
        <span class="question-title">
          <!-- Translated text with an example question: OFF-PISTE POSSIBILITIES -->
          {{ 'rate.question' + question.id | translate | uppercase }}
        </span>
        <!-- snow-flakes area -->
        <div class="d-flex flex-column flakes-holder">
          <div class="inline-block">
            <div>
              <ng-template #t let-fill="fill" class="no-outline">
                <img class="rate-flakes" [class.full]="fill === 100" src="assets/images/src/sf_{{ fill }}.png" alt="Ski resort rating" />
              </ng-template>

              <ngb-rating [(rate)]="question.rate" [(ngModel)]="question.rate" [starTemplate]="t" [max]="5" (click)="
                  triggerSaveRate(
                    question.id,
                    question.rate,
                    questionNumber - 2,
                    question.question,
                    question.categoryId
                  )
                "></ngb-rating>
            </div>
          </div>
          <div class="star-width d-flex justify-content-between px-2">
            <small class="low-high low">
              <!-- Translated text with above example question min value: Few -->
              {{ 'rate.question' + question.id + "-min"   | translate }}
            </small>
            <small class="low-high high">
               <!-- Translated text with above example question min value: Many -->
              {{ 'rate.question' + question.id + "-max"   | translate }}
            </small>
          </div>
        </div>

        <!-- snow-flakes area -->
      </li>
    </ul>
    <div class="next" (click)="currentSlide(true, true)">
      <img src="assets/images/src/arrow_bold.png" class="carousel-arrow-next" alt="Next ski resort rating parameters" />
    </div>
  </div>
  <div class="d-flex justify-content-center w-100">
    <!-- Skip param -->
    <button
      class="border-ratoong text-center text-white next-button pt-1 w-25"
      (click)="currentSlide(true, true)"
    >
      <!-- Translated text: Next -->
      {{ 'rate.next-button' | translate }}
    </button>
  </div>
  <!-- skip to end -->
  <div class="d-flex justify-content-center skip color-grey mt-4">
    <span class="pointer text-uppercase" (click)="skipToEnd()"
      >
      <!-- Translated text: SKIP TO WRITE A REVIEW -->
      {{ 'rate.skip-button' | translate }}
      <img
        class="anchor-tag grey-filter align-self-center"
        src="assets/images/search/anchor_down_gray.svg"
        alt="Skip to write a review"
      />
    </span>
  </div>
</div>
