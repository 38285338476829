<div class="container mb-4">
  <app-section-header
    title="Where would you like to ski?"
    subtitle="Choose countries for your next ski trip"
  ></app-section-header>
  <!-- Display all the regions -->
  <div class="p-0 mb-2" *ngFor="let region of regions">
    <div class="d-flex align-items-start flex-column mb-3">
      <span
        class="btn region rounded-0 text-uppercase py-0 px-3"
        (click)="regionChosen(region)"
        [ngClass]="{ 'region-selected': region == selectedRegion }"
      >
        {{ region }}
        <!-- Tick to appear top right of region when countries selected in that region -->
        <img
          *ngIf="!regionEmpty(region) && previouslySelected.includes(region)"
          class="region-tick"
          src="../assets/images/search/selected_checkmark.svg"
          alt="{{ region }}"
        />
      </span>
      <!-- Clear regions or select all -->
      <div class="d-flex">
        <h6
          (click)="fillRegion(region)"
          *ngIf="region == selectedRegion"
          class="clear-fill fill align-self-end mr-2"
          tooltipClass="really-custom"
        >
          Select all region
        </h6>
        <h6
          (click)="clearRegion(region)"
          *ngIf="region == selectedRegion && !regionEmpty(region)"
          class="clear-fill clear align-self-end"
        >
          Clear region
        </h6>
      </div>
    </div>
    <div [@countriesEnterExit] *ngIf="region == selectedRegion" class="row">
      <!-- Display countries relating to the chosen region -->
      <!-- Apply different column size to each country depending on width of screen -->
      <div
        *ngFor="let country of regionCountries"
        class="col-6 col-md-4 col-lg-6 col-xl-4 mx-0 pr-0 my-2 d-flex align-items-center"
      >
        <label class="checkbox-container"
          >{{ country }}
          <input
            type="checkbox"
            (change)="countryClicked(country)"
            [checked]="preferences.countries.includes(country)"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</div>
