import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Resort} from '../admin-page/entities/resort';

import {Injectable} from '@angular/core';
import {GetAllLocations, GetFilteredResortData} from './summary.action';
import {StatisticsFilter} from './helpers/statistics';
import {SummaryService} from './summary.service';

export class SummaryStateModel {
  summaryLocationList: Resort;
  statisticsObject: object;

}

@State<SummaryStateModel>({
  name: 'Summary',
  defaults: {
    summaryLocationList: undefined,
    statisticsObject: undefined
  }
})

@Injectable()
export class SummaryState {

  constructor(private summaryService: SummaryService,
              private statisticsFilter: StatisticsFilter) {
  }

  @Selector()
  static getStatistics(state: SummaryStateModel): any {
    return state.statisticsObject;
  }

  @Selector()
  static summaryLocationList(state: SummaryStateModel): any {
    return state.summaryLocationList;
  }

  @Action(GetFilteredResortData)
  getFilteredResortData(ctx: StateContext<SummaryStateModel>, {
    arrayOfLocations,
    country,
    age,
    gender,
    fromDate,
    toDate
  }: GetFilteredResortData): any {
    return this.summaryService.getFilteredResortData(arrayOfLocations, country, age, gender, fromDate, toDate).then((result) => {
        ctx.patchState({
          statisticsObject: this.statisticsFilter.calculateData(result)
        });
      }
    );
  }

  @Action(GetAllLocations)
  getAllLocations(ctx: StateContext<SummaryStateModel>, {id, listOfTowns}: GetAllLocations): any {
    return this.summaryService.getAllLocations(id, listOfTowns).then((result) => {
        ctx.patchState({
          summaryLocationList: result
        });
      }
    );
  }
}
