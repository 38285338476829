<!-- <label for="RatoongRate"></label> -->
<div class="display-search">
  <input
    id="typeahead-focus"
    type="text"
    class="form-control searchbar"
    [(ngModel)]="model"
    [ngbTypeahead]="search"
    [inputFormatter]="formatter"
    [resultFormatter]="formatter"
    (selectItem)="selectedItem($event, openLogin)"
    [editable]="false"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    (keyup.enter)="selectedItem(currentItem, openLogin)"
    #instance="ngbTypeahead"
    placeholder="TYPE A RESORT"
  />
  <div class="input-group-append bg-ratoong">
    <span (click)="selectedItem(currentItem, openLogin)" class="btn searchBarDes text-white">
      <i *ngIf="searchPage" class="fas fa-search"></i>
      <p *ngIf="!searchPage" class="search-text mb-0 px-2">Go Rate</p>
    </span>
  </div>
</div>

<ng-template #openLogin>
  <app-login-popup 
    subtitleOne="Share what you think about the resorts you have visited to help others know where to go!"
    subtitleTwo="Log in / Sign up to rate a resort!" 
    [dontRedirect]="true"
    >
  </app-login-popup>
</ng-template>
