import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { RatingState } from '../../shared/states/ratings/rating.state';
import { SkiGroup } from '../../shared/states/preference/entities/skiGroup';
import { Observable, Subscription } from 'rxjs';
import { PreferenceState } from '../../shared/states/preference/preference.state';
import { Select } from '@ngxs/store';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import {Review} from "../../shared/states/ratings/entities/review";
import { DefaultLangChangeEvent, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-rate-estimate-group',
  templateUrl: './rate_estimate_group.component.html',
  styleUrls: ['./rate_estimate_group.component.scss'],
})
export class RateEstimateGroupComponent implements OnInit, OnDestroy {
  // User details
  @Input() currentU: AuUser;
  // Emits the users chosen skigroup to the parent (rate-estimate-container)
  @Output() valueEmitter = new EventEmitter();

  // Emits the users chosen skier of snowborder to the parent (rate-estimate-container)
  @Output() setRiderType = new EventEmitter();

  @Input() questionNumber: number;
  @Output() changeQuestionNumber = new EventEmitter();
  @Input() reviewsContainer;



  // To hold the different types of ski groups
  skiGroups: SkiGroup[] = [];
  // Variables that track if content has loaded
  previousAnswerLoading = true;
  imagesLoading = true;
  skiGroupsLoading = true;
  // keeps track of the number of images loaded so far
  imageCount: number;

  // Holds the users previous answer (if they have selected one)
  skiGroup: number;
  riderType: string;

  // Subscriptions
  previousAnswerSubscription: Subscription;
  skiGroupSubscription: Subscription;
  @Select(PreferenceState.listOfSkiGroups) listOfSkiGroups: Observable<
    SkiGroup[]
  >;
  // What was the users previous answer to this question
  @Select(RatingState.previousReview) previousSkiGroup: Observable<Review>;

  currentLanguage: string;

  constructor(
    private translateService: TranslateService,
  ) {
    // Gets the list of all ski groups
    this.skiGroupSubscription = this.listOfSkiGroups.subscribe((data) => {
      this.skiGroups = data;
      if (this.skiGroups.length !== 0) {
        this.skiGroupsLoading = false;
        this.currentLanguage = this.translateService.getDefaultLang();
      }
    });
    this.currentLanguage = this.translateService.getDefaultLang();
  }


  ngOnInit(): void {
    // At the start, no images will be loaded
    this.imageCount = 0;
    // Gets the users previous answer from the database
    this.previousAnswerSubscription = this.previousSkiGroup.subscribe(
      (data) => {
        // By default the state is null. If not null it means that a call to the database has been completed.
        if (data !== null) {
          if (data){
            this.skiGroup = data.skiGroup;
            this.riderType = data.riderType;
          }
          this.previousAnswerLoading = false;
        }
        this.previousAnswerLoading = false;
      }
    );
    // THe loading should finish when all images are loaded. However, set a 5 second timer as a fall back just incase they never load
    setTimeout(() => {
      this.imagesLoading = false;
    }, 10);

    // Setter for currentLanguage on each language change
    this.translateService.onDefaultLangChange.subscribe((event: DefaultLangChangeEvent) => {
      this.currentLanguage = this.translateService.getDefaultLang()
    })
  }

  ngOnDestroy(): void {
    this.skiGroupSubscription.unsubscribe();
    this.previousAnswerSubscription.unsubscribe();
  }

  selectOption(groupId, title): any {
    this.skiGroup = groupId;

    // Briefly display the selected option as a tick before moving the question on
    setTimeout(() => {
      this.valueEmitter.emit({
        groupId,
        title,
      });
      this.setRiderType.emit(this.riderType)
      this.questionNumber++;
      this.changeQuestionNumber.emit(this.questionNumber);
    }, 250);
    window.scrollTo(0, 0);
  }

  // Imported setRiderType as setRider function
  setRider(type) {
    if (this.riderType == type) {
      this.riderType = '';
    } else if (!this.riderType) {
      this.riderType = type;
    } else if (this.riderType == 'both') {
      type == 'skier' ? this.riderType = 'snowboarder' : this.riderType = 'skier'
    } else {
      this.riderType = "both"
    }
    this.setRiderType.emit(this.riderType)
  }

  // Called each time an image has loaded. When all images have loaded then we set the loader to false.
  imageLoaded(): any {

    this.imageCount++;
    // i.e. if all images have loaded
    if (this.imageCount === this.skiGroups.length) {
      // we can set the loader to false
      this.imagesLoading = false;
    }
  }

  currentSlide(direction, hasSkipped): any {
    // if (hasSkipped) {
    //   this.analytics.logEvent('RAT_skipped_rating', {questionName: this.currentRatingQuestion.name ,
    //   questionCategory: this.currentRatingQuestion.categoryId });
    // }
    direction ? this.questionNumber++ : this.questionNumber--;
    this.changeQuestionNumber.emit(this.questionNumber);
    //keep new page on top
    window.scrollTo(0, 0);
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

}
