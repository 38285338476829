<header>
  <app-navbar *ngIf="showDefaultHeaders"></app-navbar>
</header>

<main *ngIf="!isLoading" role="main" class="container-fluid pb-0 bg-image-default">
  <router-outlet></router-outlet>
</main>


<main *ngIf="isLoading" role="main" class="container-fluid pb-0 bg-image-default">
  <div class="row">
    <div class="loader" *ngIf="isLoading"></div>
  </div>
</main>



<footer class="footer bg-dark">
  <app-footer *ngIf="showDefaultHeaders"></app-footer>
</footer>

<app-size-detector></app-size-detector>
