import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { LocationState } from '../../shared/states/location/location.state';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { PreferenceState } from '../../shared/states/preference/preference.state';
import { SubCategory } from '../../shared/states/preference/entities/subCategory';
import { Country } from '../../shared/states/location/entities/countries';
import { FilterLocationList } from '../../shared/states/location/location.action';
import { takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from '../../shared/services/device-detector.service';
import { SCREEN_SIZE } from '../../shared/services/enums/screen-size.enum';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-search_name',
  templateUrl: './search_name.component.html',
  styleUrls: ['./search_name.component.scss'],
})
export class SearchNameComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  category = 'Default';
  Default: boolean;

  @Select(PreferenceState.listOfSubRatings) categories: Observable<
    SubCategory[]
  >;

  public isLoading = false;
  public listLoaded = false;
  mobile = false;
  size: SCREEN_SIZE;
  @Select(LocationState.filteredCountries) countryList: Observable<Country[]>;
  @Select(LocationState.locationCount) filteredLocationCount: Observable<
    number
  >;
  countOfLocations: number;
  private ngUnsubscribe = new Subject();

  constructor(
    // private route: ActivatedRoute,
    // private sharedEventService: SharedEventService,
    // private router: Router,
    private spinner: NgxSpinnerService,
    private store: Store,
    private analytics: AngularFireAnalytics,
    private actions$: Actions,
    private resizeSvc: DeviceDetectorService
  ) {
    this.actions$
      .pipe(
        ofActionCompleted(FilterLocationList),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.spinner.hide();
      });

    this.size = resizeSvc.getCurrentSize();
    this.mobile = this.size < 3;

    resizeSvc.onResize$.subscribe((x) => {
      this.size = x;
      this.mobile = this.size < 3;
    });
  }

  ngOnInit(): any {
    this.spinner.show();
    this.store.dispatch(new FilterLocationList({ query: '' }));
    this.filteredLocationCount.subscribe((data) => {
      this.countOfLocations = data;
    });
    this.isLoading = false;
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  onChangeSearch(): any {
    this.spinner.show();
    // @ts-ignore
    this.store.dispatch(new FilterLocationList({ Rating: this.category.id }));
    this.analytics.logEvent('category-search');
    this.listLoaded = true;
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
