import { newSearchParameters } from '../../../../states/auth/entities/preference_entities/new_params';
import { ModalService } from '../../../../services/modal-service.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-preference-select-popup',
  templateUrl: './preference_select_popup.component.html',
  styleUrls: ['./preference_select_popup.component.scss'],
})
export class PreferenceSelectPopupComponent implements OnInit {
  // The options within this 'group'
  @Input() options: any[];
  // The list of users multiplechoice preferences
  @Input() userPreferences: any[];
  @Input() title: string;

  @Output() valueEmitter = new EventEmitter();

  isLoading: boolean = true;
  showReset: boolean;

  containsNewParameters: boolean = false;

  constructor(private modalService: ModalService) {}
  // Id of the new parameters. Can remove this when we have more data on them.
  newParams: number[] = newSearchParameters;

  count: number = 0;

  ngOnInit(): void {
    this.count = 0;
    this.anyOptionsSelected();
    this.containsNewParameters = this.modalContainsNewParameters();
  }

  imageLoaded() {
    this.count++;
    if (this.count == this.options.length) {
      this.isLoading = false;
    }
  }

  selectOption(option: any): void {
    // If already in the array
    if (this.questionSelected(option)) {
      // remove it
      this.removeItem(option.questionID);
    } else {
      // Else, add it
      this.userPreferences.push({
        questionID: option.questionID,
        name: option.name,
      });
    }
    this.anyOptionsSelected();
    this.valueEmitter.emit();
  }

  // Returns true if the user has selected this option already
  questionSelected(option: any): boolean {
    return this.userPreferences.some((question) => {
      return question.questionID === option.questionID;
    });
  }

  resetAll(): void {
    // remove all options from userPreferences
    this.options.forEach((option) => {
      this.removeItem(option.questionID);
    });
    this.showReset = false;
    this.valueEmitter.emit();
  }

  closeModal(): void {
    this.modalService.close('Preference-Popup');
  }

  // Sets showReset button to true if any options have been selected
  anyOptionsSelected(): void {
    for (let i = 0; i < this.options.length; i++) {
      if (this.questionSelected(this.options[i])) {
        this.showReset = true;
        return;
      }
    }
    this.showReset = false;
  }

  // Returns true if there are new parameters in the modal. Else, returns false
  modalContainsNewParameters(): boolean {
    for (let i = 0; i < this.options.length; i++) {
      if (this.newParams.includes(this.options[i].questionID)) {
        return true;
      }
    }
    return false;
  }

  // Removes item from userPreferences array by finding the index of the item and then removing it
  removeItem(questionID) {
    let index = -1;
    for (let i = 0; i < this.userPreferences.length; i++) {
      if (this.userPreferences[i].questionID == questionID) {
        index = i;
      }
    }
    if (index > -1) {
      this.userPreferences.splice(index, 1);
    }
  }
}
