<form [formGroup]="signupForm" (ngSubmit)="onSubmit()" action="" class="w-100">
  <div class="form-group w-100 mb-2">
    <div class="error-container">
      <div
        class="error-box p-1 pl-2"
        *ngIf="submitted && (f.password.errors || f.password2.errors)"
      >
        <!-- Setup in this way to ensure only one error message shows, with highest priority first -->
        <div class="error-text" *ngIf="checkRequired()">
          Password is required.
        </div>
        <div class="error-text" *ngIf="checkMinLength()">
          Password must be at least 6 characters.
        </div>
        <div class="error-text" *ngIf="checkMatch()">
          The passwords do not match. Please try again.
        </div>
      </div>
    </div>
    <label for="password" class="form-label mb-0">Create Password</label>
    <div class="input-container mb-1">
      <input
        #password
        autofocus
        type="{{ fieldTextType }}"
        id="password"
        placeholder="••••••••••••"
        class="form-input form-control rounded-0"
        formControlName="password"
        [ngClass]="{
          'error-input':
            (submitted && (f.password.errors || f.password2.errors)) ||
            errorMessage
        }"
      />
      <span
        class="eye"
        [ngClass]="{
          'grey-icon': !password.value
        }"
        ><i
          (click)="changeInputType('text')"
          *ngIf="fieldTextType == 'password'"
          class="fas fa-eye-slash"
        ></i
        ><i
          (click)="changeInputType('password')"
          *ngIf="fieldTextType == 'text'"
          class="fas fa-eye"
        ></i
      ></span>
    </div>
    <div
      class="password-instruction d-flex justify-content-between align-items-center mb-2"
    >
      <div>Must be at least 6 characters</div>
      <div class="d-flex align-items-center">
        <div>{{ passwordStrength }}</div>
        <div
          class="strength-rectangle grey"
          [ngClass]="{
            'red-bg': passwordStrength == 'Weak',
            'yellow-bg': passwordStrength == 'Average',
            'green-bg': passwordStrength == 'Strong'
          }"
        ></div>
        <div
          class="strength-rectangle grey"
          [ngClass]="{
            'yellow-bg': passwordStrength == 'Average',
            'green-bg': passwordStrength == 'Strong'
          }"
        ></div>
        <div
          class="strength-rectangle grey"
          [ngClass]="{
            'green-bg': passwordStrength == 'Strong'
          }"
        ></div>
      </div>
    </div>
  </div>
  <div class="form-group w-100 mb-4">
    <label for="password2" class="form-label mb-0">Re-enter Password</label>
    <div class="input-container">
      <input
        #password2
        type="{{ fieldTextType }}"
        id="password2"
        placeholder="••••••••••••"
        class="form-input form-control rounded-0"
        formControlName="password2"
        [ngClass]="{
          'error-input':
            (submitted && (f.password.errors || f.password2.errors)) ||
            errorMessage
        }"
      />
      <span
        class="eye"
        [ngClass]="{
          'grey-icon': !password2.value
        }"
        ><i
          (click)="changeInputType('text')"
          *ngIf="fieldTextType == 'password'"
          class="fas fa-eye-slash"
        ></i
        ><i
          (click)="changeInputType('password')"
          *ngIf="fieldTextType == 'text'"
          class="fas fa-eye"
        ></i
      ></span>
    </div>
  </div>
  <div class="form-group mb-1">
    <input
      [ngClass]="{
        grey: !password.value || password.value.length < 6
      }"
      [disabled]="password.value.length < 6"
      type="submit"
      class="w-100 text-white rounded-0 next-button"
      value="SIGN UP"
    />
    <p *ngIf="errorMessage" class="error-message mb-0">
      Something went wrong with the server. Please try again in a bit.
    </p>
  </div>
</form>
<div class="position-relative w-100 mb-1">
  <div
    *ngIf="isLoading"
    [ngClass]="{
      visible: isLoading
    }"
    class="load-container"
  >
    <div class="loader"></div>
  </div>
</div>

<ng-template #forgot>
  <app-password-forgot-modal></app-password-forgot-modal>
</ng-template>
