import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';


import { SharedModule } from '../shared/modules/shared.module';
import { RatingModule } from 'ngx-bootstrap/rating';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ProfileComponent } from './profile/profile.component';
import { SocialmediaComponent } from './socialmedia/socialmedia.component';
import {NgbAlertModule, NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    HttpClientModule,
    RatingModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    SharedModule,
    NgbDatepickerModule,
    FontAwesomeModule,
    NgbAlertModule
  ],
  providers: [],
  exports: [
    SocialmediaComponent,
  ],
  declarations: [
    ProfileComponent,
    SocialmediaComponent,
  ]
})
export class AccountModule { }
