<div class="row center d-flex flex-column align-items-center w-100 m-0">
  <div class="image-container mb-2">
    <img src="/assets/images/icons/rate_icon.svg" alt="{{ title }}" />
  </div>
  <h1 class="title my-4">{{ title }}</h1>
  <div class="margin-bottom-mobile">
    <ng-content></ng-content>
    <p class="text">
      <!-- Translated text: Share what you think about the ski resorts you have visited by rating out unique criteria - all tailored to the ski experience. -->
      {{ 'redirect.rate.content-part1' | translate }}
    </p>
    <p class="text">
      <!-- Translated text: After, explore Ratoong and get inspired for your next ski trip! -->
      {{ 'redirect.rate.content-part2' | translate }}
    </p>
  </div>
  <p class="text margin-bottom-mobile bold mt-3 mb-4">
    <!-- Translated text: We will ask you to log in to validate your rating. -->
    {{ 'redirect.rate.ask-login-text' | translate }}
  </p>
  <button
    class="btn text-white rate-button bg-red rounded-0 mb-3 position-relative"
    (click)="navigate()"
  >
    {{ buttonText }}
  </button>
</div>
