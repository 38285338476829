<div class="w-100 mt-4">
  <a class="button-social text-center" (click)="loginGoogle()">
    <img class="social-icon" src="assets/images/icons/google_icon.png" alt="Login with Google" />
    Continue with Google</a>
  <a class="button-social text-center mb-4" (click)="loginFb()">
    <img class="social-icon" src="assets/images/icons/facebook_icon.png" alt="Login with Facebook" />
    Continue with Facebook</a>
  <div class="w-100 d-flex align-items-center justify-space-between mb-4">
    <div class="line"></div>
    <p class="continue-text px-2">Or, continue with</p>
    <div class="line"></div>
  </div>
  <a class="button-social text-center mt-0 mb-5" (click)="continueWithEmail.emit()"><i
      class="far fa-envelope social-icon"></i> Continue with Email</a>
</div>
