import { UserSearchPreference } from '../../../shared/states/auth/entities/preference_entities/user_search_preference';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-additional-criteria',
  templateUrl: './additional_criteria.component.html',
  styleUrls: ['./additional_criteria.component.scss'],
})
export class AdditionalCriteriaComponent implements OnInit {
  @Input() preferences: UserSearchPreference;
  // The questions to display
  @Input() parameters: any;

  @Output() valueEmitter = new EventEmitter();

  constructor() {}

  setValue(value: string, type: string) {
    if (type == 'minAltitude') this.preferences.minAltitude = value;
    this.valueEmitter.emit();
  }

  ngOnInit(): void {}
}
