
export class GetResorts {
  static readonly type = 'GetResorts';
}

export class GetEmailList {
  static readonly type = 'GetEmailList';
}

export class GetLinks {
  static readonly type = 'GetLinks';
}

export class GetRedirects {
  static readonly type = 'GetRedirects';
}

export class GetPartnerRedirects {

  constructor(public partnerID: string) {}
  static readonly type = 'GetPartnerRedirects';
}

export class GetPartners {
  static readonly type = 'GetPartners';
}

export class GenerateLink {
  constructor(public partnerID: string,
              public resortID: string,
              public resortName: string,
              public selectedTownIds: string,
              public selectedTownNames: string,
              public roleID: number,
              public roleName: string) {}
  static readonly type = 'GenerateLink';
}

export class RedirectLinkGeneration{
  constructor(public customURL: string,
              public value: string,
              public purpose: string,
              public title: string,
              public partnerID: string) {}
  static readonly type = 'RedirectLinkGeneration';
}

export class CreatePartner {
  static readonly type = 'CreatePartner';
  constructor(public managerID: string, public title: string, public resort: string, public towns: string) {}
}

