import { ModalService } from '../../../../services/modal-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-parameters',
  templateUrl: './new_parameters.component.html',
  styleUrls: ['./new_parameters.component.scss'],
})
export class NewParametersComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  closeModal() {
    this.modalService.close('new-params-popup');
  }
}
