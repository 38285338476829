import { Injectable } from '@angular/core';

import * as firebase from 'firebase';
import { Country } from './entities/countries';
import { Location } from './entities/location';
import { DeepLocation } from './entities/deepLocations';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  database: any = null;
  allQuestions = [];
  constructor(
    private analytics: AngularFireAnalytics
  ) {
    this.database = firebase.database();
  }

  getAllCountries(): any {
    return firebase
      .database()
      .ref('/Countries')
      .once('value')
      .then((snapshot) => {
        return snapshot.val().map((a) => {
          const countId = a.CountryId;
          const name = a.Name;
          const region = a.Region;
          return { countryId: countId, name, region } as Country;
        });
      });
  }

  /**
   * For some reason snapshot.val.map works for countries only. But not for locations. I switched up to foreach
   * My teory is that the value either is too large or it contains subvalues which impact it.
   */
  getAllLocations(questionList): any {
    return firebase
      .database()
      .ref('/NewLocations')
      .once('value')
      .then((snapshot) => {
        const list = [];
        snapshot.forEach((child) => {
          if(child.val().inactive) return; // If resort is inactive, we want skip it
          const name = child.val().Name;
          const countryName = child.val().CountryName;
          const cityName = child.val().CityName;
          const locationScores = this.getRelevantScores(child, questionList);
          const generalScore =
            locationScores === []
              ? '0'
              : this.calculateGeneralRating(locationScores);
          // ---Added these for the purpose of new-search---
          const minAltitude = this.getMinAltitude(child.val().ElevationInfo);
          const minAirportDistance = this.getNearestAirport(
            child.val().Airports
          );
          const longitude = child.val().Longitude;
          const latitude = child.val().Latitude;
          const location: Location = {
            id: Number(child.key),
            scoreToDisplay: generalScore,
            name,
            countryName,
            cityName,
            generalScore,
            locationScores,
            // Added for the purpose of new-search
            minAirportDistance,
            minAltitude, 
            latitude,
            longitude,
          };
          list.push(location);
        });
        return list;
      });
  }

  getMinAltitude(altitude): any {
    if (altitude === undefined || altitude === false) {
      return 0;
    } else {
      return altitude.min;
    }
  }


  // Is this function necessary? I think most aiports are stored in the database with the shortest distance first
  getNearestAirport(airports: any): any {
    if (airports == null || airports === false) {
      // if resort doesnt have specified airports set the distance to 0 by default
      return 0;
    }
    // Use a reduce function to get the airport with the smallest distance
    const minDistance = airports.reduce(
      (minValue: number, airport: any) =>
        airport.distance < minValue ? airport.distance : minValue,
      airports[0].distance
    );
    return minDistance;
  }

  /**
   * This method takes in the locations ID and returns only a single location.
   * This would be used for showing details about a location
   */
  getLocation(uid, questionList): any {
    let locationScores = [];
    return firebase
      .database()
      .ref('/NewLocations/' + uid)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val() != null) {
          // If resort is inactive, we want to send the user to 404 page
          if(snapshot.val().inactive) throw new Error('Invalid location');
          // User has inserted a reivew
          const name = snapshot.val().Name;
          const pisteMap = snapshot.val().PisteMap;
          const countryName = snapshot.val().CountryName;
          const cityName = snapshot.val().CityName;
          const website = snapshot.val().Website;
          locationScores = this.getRelevantScores(snapshot, questionList);
          const generalScore =
            locationScores === []
              ? '0'
              : this.calculateGeneralRating(locationScores);
          const airports = this.addDataToArray(snapshot, 'Airports');
          const pistes = this.addDataToArray(snapshot, 'Pistes');
          const min = snapshot.val().ElevationInfo.min;
          const max = snapshot.val().ElevationInfo.max;
          // --------Added for extra resort information--------
          const region = snapshot.val().Region;
          const openingDates = snapshot.val().OpeningDates;
          const openingHours = snapshot.val().OpeningHours;
          const lifts = snapshot.val().Lifts;
          const longitude = snapshot.val().Longitude;
          const latitude = snapshot.val().Latitude;
          const wambrella = snapshot.val().Wambrella;
          // --------------------------------------------------
          return {
            id: Number(snapshot.key),
            scoreToDisplay: generalScore,
            name,
            pisteMap,
            countryName,
            cityName,
            generalScore,
            locationScores,
            website,
            airports,
            pistes,
            ElevationInfo: { max, min },
            region,
            openingDates,
            openingHours,
            lifts,
            longitude,
            latitude,
            wambrella
          } as DeepLocation;
        } else {
          throw new Error('Invalid location');
        }
      });
  }
  private calculateGeneralRating(ratings): any {
    let total = 0;
    let count = 0;
    ratings.forEach((child) => {
      total = total + parseFloat(child.overallScore);
      count = count + 1;
    });

    return (Math.round((total / count) * 100) / 100).toFixed(2);
  }
  private getRelevantScores(snapshot, questionList): any {
    const localScores = [];
    if (
      snapshot.val().LocationScores == null ||
      snapshot.val().LocationScores === []
    ) {
      return localScores;
    }
    // console.log(snapshot.val().LocationScores);
    Object.keys(snapshot.val().LocationScores).map((index) => {
      const data = snapshot.val().LocationScores[index];
      const found = questionList.find((x) => x.id === Number(index));
      if (found) {
        if (found.group === false) {
          if (data.masterOverallCount > 1) {
            localScores.push({
              questionID: Number(index),
              overallScore: data.masterOverallScore,
              overallCount: data.masterOverallCount
            });
          }
        } else {
          const totalRatings =
            Number(data.anonTotalCount) + Number(data.totalCount);
          if (totalRatings > 1) {
            localScores.push({
              questionID: Number(index),
              overallScore: data.overallScore,
              overallCount: data.totalCount
            });
          }
        }
      }
    });
    if (localScores.length < 2) {
      return [];
    }
    return localScores;
  }
  private addDataToArray(snapshot, type): any {
    let listOfData;
    const dataArray = [];
    if (type === 'Airports') {
      listOfData =
        snapshot.val().Airports == null ? null : snapshot.val().Airports;
    }
    if (type === 'Pistes') {
      listOfData =
        snapshot.val().ThePiste == null ? null : snapshot.val().ThePiste;
    }
    if (listOfData != null) {
      Object.keys(listOfData).map((personNamedIndex) => {
        const dataObject = listOfData[personNamedIndex];
        if (type === 'Airports') {
          dataArray.push({
            city: dataObject.city,
            country: dataObject.overallScore,
            distance: dataObject.distance,
            name: dataObject.name,
          });
        }
        if (type === 'Pistes') {
          dataArray.push({ km: dataObject.km, title: dataObject.title });
        }
      });
    }
    return dataArray;
  }

  addNewLocation(userUid, date, guid, city, website, location): any {
    return firebase
      .database()
      .ref('/location-suggestion/')
      .push({
        uid: userUid,
        timestamp: date,
        guid,
        city,
        Website: website,
        name: location,
        approve: false,
      });
  }
}
