<div id="notfound">
  <div class="notfound-bg"></div>
  <div class="notfound">
    <div class="notfound-404">
      <h1>404</h1>
    </div>
    <h2>Oops! Page Not Found</h2>
    <div class="notfound-social">
      <a href="https://www.linkedin.com/company/17969583" target="new" title="linkedin" class="ratoong-linkedin-icon"><i class="fab fa-linkedin"></i></a>
      <a href="https://twitter.com/ratoong/" target="new" title="twitter" class="ratoong-twitter-icon"><i class="fab fa-twitter-square"></i></a>
      <a href="https://www.facebook.com/ratoongIVS/" target="new" title="facebook" class="ratoong-facebook-icon"><i class="fab fa-facebook-square"></i></a>
      <a href="https://www.instagram.com/ratoong_/" target="new" title="instagram" class="ratoong-instagram-icon"><i class="fab fa-instagram"></i></a>
      <a href="https://ratoong.wordpress.com/" target="new" title="blog" class="ratoong-wordpress-icon"><i class="fab fa-wordpress-simple"></i></a>
    </div>
    <a [routerLink]="['']">Back To Homepage</a>
  </div>
</div>
