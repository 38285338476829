import { DeepLocation } from '../../../shared/states/location/entities/deepLocations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-opening-lifts',
  templateUrl: './details_opening_lifts.component.html',
  styleUrls: ['./details_opening_lifts.component.scss'],
})
export class DetailsOpeningLiftsComponent implements OnInit {
  @Input() currentLoc: DeepLocation;

  // Processed season start and end dates
  seasonStart: string;
  seasonEnd: string;

  // Array that holds the processed lifts and counts
  liftsArray = [];

  // Refers to the number of elements that will be displayed on the first row, between 1-3 (opening dates, opening hours, resort website).
  numberOfElements: number;

  constructor() {}

  ngOnInit(): void {
    this.numberOfElements = 0;

    if (this.currentLoc.openingDates) {
      this.numberOfElements++;
      this.processDates();
    }
    if (this.currentLoc.lifts) {
      this.processLifts();
    }

    if (this.currentLoc.openingHours) this.numberOfElements++;
    if (this.currentLoc.website) this.numberOfElements++;
  }

  processDates(): void {
    if (
      this.currentLoc.openingDates.seasonStart.toLowerCase().trim() == 'depending on snow conditions'
    ) {
      this.seasonStart = 'varies';
    } else {
      this.seasonStart = this.formatString(
        this.currentLoc.openingDates.seasonStart
      );
    }
    if (
      this.currentLoc.openingDates.seasonEnd.toLowerCase().trim() == 'depending on snow conditions'
    ) {
      this.seasonEnd = 'varies';
    } else {
      this.seasonEnd = this.formatString(
        this.currentLoc.openingDates.seasonEnd
      );
    }
  }

  // Formats dates like so: 'early november' to 'early nov'
  formatString(date: string): string {
    // Splits the date into 2 words
    const stringArray = date.split(' ');
    const stringLength = 3;
    // The month is the second word in the string. We want to make it only 3 characters long.
    const shortenedMonth = stringArray[1].substring(0, stringLength);
    // Rejoin the shortened string with the first part
    return stringArray[0] + ' ' + shortenedMonth;
  }

  processLifts(): void {
    const lifts = this.currentLoc.lifts;
    if (lifts.funicular != 0) {
      this.liftsArray.push({
        name: 'Funicular',
        image: 'funicular.svg',
        count: lifts.funicular,
      });
    }
    if (lifts.gondola != 0) {
      this.liftsArray.push({
        name: 'Gondolas',
        image: 'gondola.svg',
        count: lifts.gondola,
      });
    }
    if (lifts.cabinLift != 0) {
      this.liftsArray.push({
        name: 'Cabin Lifts',
        image: 'cabin_lift.svg',
        count: lifts.cabinLift,
      });
    }
    if (lifts.chairLift != 0) {
      this.liftsArray.push({
        name: 'Chair Lifts',
        image: 'chair_lift.svg',
        count: lifts.chairLift,
      });
    }
    if (lifts.combinationLift != 0) {
      this.liftsArray.push({
        name: 'Combination Lifts',
        image: 'combination_lift.svg',
        count: lifts.combinationLift,
      });
    }
    if (lifts.peopleMover != 0) {
      this.liftsArray.push({
        name: 'People Movers',
        image: 'people_mover.svg',
        count: lifts.peopleMover,
      });
    }
    if (lifts.tBarAndOthers != 0) {
      this.liftsArray.push({
        name: 'T-Bars and Others',
        image: 't_bar.svg',
        count: lifts.tBarAndOthers,
      });
    }
  }
}
