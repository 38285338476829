import { ActivatedRoute } from '@angular/router';
import { AuthState } from '../shared/states/auth/auth.state';
import { Select } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { AuUser } from '../shared/states/auth/entities/user_interface';
import { DeviceDetectorService } from '../shared/services/device-detector.service';
import { SCREEN_SIZE } from '../shared/services/enums/screen-size.enum';
import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  mobile: boolean;
  size: SCREEN_SIZE;

  // Check if user is logged in or not
  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;

  currentU: AuUser;
  userSubscription: Subscription;

  // Temporary - Ambassadors will be moved to about page and this can then be removed
  private fragment: string;

  constructor(
    private resizeSvc: DeviceDetectorService,
    private route: ActivatedRoute,
    private analytics: AngularFireAnalytics,
    private titleService: Title
  ) {
    this.size = resizeSvc.getCurrentSize();
    this.mobile = this.size === 0;

    resizeSvc.onResize$.subscribe((x) => {
      this.size = x;
      this.mobile = this.size === 0;
    });

    // Get user data (if they are logged in)
    this.userSubscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
    });

    // this is just temporary, can be removed when ambassadors is moved to about page
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
  }

  
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): any {
    this.setTitle('Find Best Ski Resorts and Rate Ski Destinations - Ratoong') 
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  // this is just temporary, can be removed when ambassadors is moved to about page
  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) {
      // If not able to move to anchor - backup is to simply load the page as normal
      return;
    }
  }

  recordEvent(): any{
    this.analytics.logEvent('RAT_user_home_page_button_click', {button: 'Find a resort' });
  }
}
