import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import {Store} from '@ngxs/store';
import {WipeRedirect} from '../../shared/states/preference/preference.action';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rate-landing-page',
  templateUrl: './rate_landing_page.component.html',
  styleUrls: ['./rate_landing_page.component.scss'],
})
export class RateLandingPageComponent implements OnInit {
  @Input() title: string;
  @Input() buttonText: string;
  @Input() buttonUrl: string;
  @Input() partner: string;

  constructor(private store: Store,
              private router: Router,
              private translateService: TranslateService
              ) {}

  ngOnInit(): void {}

  navigate(): void {
    this.store.dispatch(new WipeRedirect());
    this.router.navigate([this.buttonUrl]);
  }
}
