import { LocationState } from './../shared/states/location/location.state';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import {
  CheckRedirect,
  WipeRedirect,
} from '../shared/states/preference/preference.action';
import { Observable, Subscription } from 'rxjs';
import { PreferenceState } from '../shared/states/preference/preference.state';
import { Location } from '../shared/states/location/entities/location';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-redirect-page',
  templateUrl: './redirect_page.component.html',
  styleUrls: ['./redirect_page.component.scss'],
})
export class RedirectPageComponent implements OnInit {
  getURL;
  public referralID: string;
  isLoading = false;
  company: string = 'Snowminds';
  private redirectSubscribe = new Subscription();

  name: string;
  type: string;
  redirect: string;

  @Select(LocationState.locationList) locations: Observable<Location[]>;

  @Select(PreferenceState.getURL) redirectURL: Observable<any>;

  currentLanguage: string;

  toggleLanguage: boolean = true;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {

    this.route.params.subscribe((params) => {
      this.referralID = String(params.referralID);
      if (!this.referralID) {
        this.router.navigate(['/']);
      }
    });

    this.locations.subscribe((data) => {
      if (data && this.redirect && this.type == 'skiResort') {
        const resortId = this.redirect.split('/').pop();
        let resort = data.find((location) => {
          return location.id == Number(resortId);
        });
        this.name = resort.cityName + ', ' + resort.name;
      }
    });

    this.redirectSubscribe = this.redirectURL.subscribe((data) => {
      // Have to specifically check redirect is not null/undefined (since it can still be an empty string which is falsey)
      if (data && data.redirect !== null && data.redirect !== undefined) {
        if (!data.type || data.type == 'normal' || data.redirect == '') {
          this.store.dispatch(new WipeRedirect());
          this.router.navigate(['/' + data.redirect]);
        } else {
          this.isLoading = false;
          if (data.type == 'partner') {
            this.name = data.name;
          }
          this.type = data.type;
          this.redirect = data.redirect;
        }
      } else if (data === null) {
        // If data is null (important: not undefined), it means the service was called but there was no data
        this.store.dispatch(new WipeRedirect());
        this.router.navigate(['/']);
      }
    });
  }


  ngOnInit(): void {
    this.isLoading = true;
    this.store
      .dispatch(new CheckRedirect(this.referralID))
      .pipe(first())
      .subscribe(
        (data) => {},
        (error) => {
          this.isLoading = false;
          this.router.navigate(['/']);
        }
      );
  }

  ngOnDestroy(): any {
    this.redirectSubscribe.unsubscribe();
  }
}
