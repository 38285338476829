import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { ModalService } from '../../shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rate-estimate-end',
  templateUrl: './rate_estimate_end.component.html',
  styleUrls: ['./rate_estimate_end.component.scss'],
})
export class RateEstimateEndComponent implements OnInit {
  @Input() location: any;
  //@Input() finalAverage: any;
  @Input() overall: any;
  @Input() reviewText: any;
  @Input() reviewImage: any;
  @Input() currentLocation: any;
  @Output() changeQuestionNumber = new EventEmitter();

  finalAverage: number;

  currentDate: any;
  constructor(
    private modalService: ModalService,
    private translateService: TranslateService
    ) {}

  ngOnInit(): any {
    this.getCurrentDate();
  }

  facebookShare(): boolean {
    const title = this.zeroOrNaN(this.overall)
      ? 'I just rated ' +
        this.currentLocation.cityName +
        ', ' +
        this.currentLocation.countryName +
        ' on RATOONG!'
      : 'I just rated ' +
        this.currentLocation.cityName +
        ', ' +
        this.currentLocation.countryName +
        ' with a score of ' +
        this.overall.toFixed(2) +
        '!';

    const subtitle =
      'Have you been to ' +
      this.currentLocation.cityName +
      ', ' +
      this.currentLocation.countryName +
      '? What do you think?';

    //If user has written review , we display with the title/subtitle when they share. Else we just display the title/subtitle
    const description = this.reviewText
      ? title + '\n\n"' + this.reviewText + '"\n\n' + subtitle
      : title + '\n\n' + subtitle;

    // @ts-ignore
    FB.ui(
      {
        method: 'share',
        href: window.location.href,
        quote: description
      },
      function (response) {
        console.log(response);
      }
    );
    return false;
  }

  //Checks if the final score is NaN or 0 (i.e. the user didn't rate anything)
  zeroOrNaN(value): boolean {
    return isNaN(value) || value === 0;
  }

  closeModal(id: string, change: boolean): any {
    this.modalService.close(id);
    this.modalService.onChange(change);
  }

  backToReview(): void {
    // Send user back to the review question
    this.changeQuestionNumber.emit(10);
  }

  getCurrentDate(): any {
    const today = new Date();
    this.currentDate = today.toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    });
  }
}
