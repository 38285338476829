import { RateEstimateContainerComponent } from './rate_estimate_container/rate_estimate_container.component';
import { RouterModule } from '@angular/router';

import { GuardService } from '../shared/states/auth/_guard/guard.service';

const routes = [
  {
    path: 'rate-ski-resort/:town/:locationId',
    //canActivate: [GuardService],
    // component: RateEstimateResortComponent,
    component: RateEstimateContainerComponent,
  },
];

export const RateRoutes = RouterModule.forChild(routes);
