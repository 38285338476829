<!-- Main section -->
<div class="row">
  <div class="loader" *ngIf="isLoading || !name"></div>
  <div *ngIf="!isLoading && name" class="col-12 d-flex justify-content-center">
    <div class="w-100 ratoong-container">
      <div class="details-container d-flex justify-content-center">
        <app-language-switch></app-language-switch>
        <!-- Translated button text: GET STARTED -->
        <app-rate-landing-page
          *ngIf="type == 'partner'"
          title="WELCOME - SHARE YOUR EXPERIENCE!"
          buttonText="{{ 'redirect.get-started-button' | translate }}"
          buttonUrl="{{ '/' + redirect }}"
          [partner]="name"
        >
          <p class="text">
            Ratoong is an official partner of
            <b class="text-uppercase">{{ name }}</b
            >. You are, as part of <b class="text-uppercase">{{ name }}</b
            >, a highly valued user on Ratoong.
          </p>
        </app-rate-landing-page>
        <!-- Translated button text: GET STARTED -->
        <app-rate-landing-page
          *ngIf="type == 'skiResort'"
          title="RATE {{ name }}!"
          buttonText="{{ 'redirect.get-started-button' | translate }}"
          buttonUrl="{{ '/' + redirect }}"
        >
          <p class="text" [innerHTML]= "'redirect.content' | translate: {name: name | uppercase }">
            <!-- Translated text: Thanks for choosing to rate partner_name at Ratoong! We value your opinion on your experience at partner_name. -->
          </p>
        </app-rate-landing-page>
      </div>
    </div>
  </div>
</div>
