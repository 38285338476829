import { ResortListComponent } from './resort_list/resort_list.component';
import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
} from '@angular/animations';
import { SCREEN_SIZE } from './../../shared/services/enums/screen-size.enum';
import { DeviceDetectorService } from './../../shared/services/device-detector.service';
import { UserSearchPreference } from './../../shared/states/auth/entities/preference_entities/user_search_preference';
import { Observable, Subscription } from 'rxjs';
import { AuthState } from './../../shared/states/auth/auth.state';
import { Select } from '@ngxs/store';
import { ModalService } from './../../shared/services/modal-service.service';
import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-search-results',
  templateUrl: './search_results.component.html',
  styleUrls: ['./search_results.component.scss'],
  animations: [
    trigger('searchSaved', [
      transition(':enter', [
        style({
          paddingLeft: '150px',
          transform: 'scale(0.7);',
          transformOrigin: '60% 0%',
        }),
        animate(
          '500ms cubic-bezier(0.35, 0, 0.25, 1)',
          keyframes([
            style({ transform: 'scale(0)' }),
            style({ transform: 'scale(1.5)' }),
            style({ transform: 'scale(1)' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  isLoading = false;
  saveResultsPopup = false;
  preferenceSubscription: Subscription;
  // Changes to true after the user has saved their search
  searchSaved = false;

  mobile = false;
  size: SCREEN_SIZE;

  @Select(AuthState.getCurrentPreferences)
  currentPreferencesState: Observable<UserSearchPreference>;
  currentPreferences: UserSearchPreference;

  @ViewChild(ResortListComponent) resortList: ResortListComponent;

  // Function that is triggered every time the user clicks on the screen. 
  // Will cause the 'matching parameters prompt' to close
  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    this.resortList.setCurrentPrompt(0);  
  }

  constructor(
    private modalService: ModalService,
    private resizeSvc: DeviceDetectorService
  ) {
    this.preferenceSubscription = this.currentPreferencesState.subscribe(
      (data) => {
        this.currentPreferences = data;
      }
    );

    this.size = resizeSvc.getCurrentSize();
    this.mobile = this.size < 3;

    resizeSvc.onResize$.subscribe((x) => {
      this.size = x;
      this.mobile = this.size < 3;
    });
  }

  setLoading(value: boolean): any {
    this.isLoading = value;
  }

  ngOnInit(): void {}

  openModal(id, content): any {
    this.modalService.open(id, content);
  }

  closeModal(id: string, change: boolean): any {
    this.modalService.close(id);
    this.modalService.onChange(change);
  }

  ngOnDestroy(): void {
    this.preferenceSubscription.unsubscribe();
  }
}
