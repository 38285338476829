<div class="row">
  <!-- Main section -->
  <div class="col-12 d-flex justify-content-center">
    <div class="w-100 ratoong-container">
      <div class="d-flex flex-column">
        <!-- Landing view -->
        <div class="landing-view">
          <div class="title-text" data-aos="zoom-out" data-aos-duration="1500">
            <h1 class="home-text landing-title mb-0">
              A community <span class="home-text title-mobile">for all snow lovers</span>
            </h1>
            <h2 class="home-text landing-subtitle">GET INSPIRED, CHOOSE, GO!</h2>
            <h3 class="home-text landing-subtitle">
               For riders by riders
            </h3>
          </div>
          <div
            data-aos="zoom-out"
            data-aos-duration="1500"
            class="white-container"
          >
            <div class="row">
              <div class="col-md-7 px-0">
                <h1 class="home-text search-title mb-0">
                  Discover your next ski destination
                </h1>
                <h3 class="search-subtitle mb-0">
                  The only search function based on ratings from fellow riders.
                </h3>
              </div>
              <a (click)="recordEvent()"
                title="Go to search page"
                [routerLink]="['/search-ski-resorts']"
                class="col-md-5 bg-red d-flex align-items-center justify-content-center w-100"
              >
                <h1 class="text-white button-text">Find a resort</h1>
              </a>
            </div>
          </div>
        </div>
        <!-- End of landing view -->
        <!-- Unfortunately, applying 'disable: mobile' in AOS config doesn't seem to work in angular. -->
        <!-- To work around, I have created 2 versions of content using *ngIf. -->
        <!-- ------DESKTOP------ -->
        <ng-container *ngIf="!mobile">
          <app-home-summary-section
            [mobile]="mobile"
            class="w-100"
          ></app-home-summary-section>
          <app-home-plan-trip
            data-aos="fade-up"
            data-aos-duration="700"
            class="w-100"
          ></app-home-plan-trip>
          <app-home-rate-share
            data-aos="fade-up"
            data-aos-duration="700"
            [currentU]="currentU"
            [mobile]="mobile"
            class="w-100"
          ></app-home-rate-share>
          <app-home-join-community
            data-aos="fade-up"
            data-aos-duration="700"
            [currentU]="currentU"
            class="w-100"
          ></app-home-join-community>
          <app-home-ambassadors
            id="ambassadors"
            data-aos="fade-up"
            data-aos-duration="700"
            [mobile]="mobile"
            class="w-100"
          ></app-home-ambassadors>
        </ng-container>
        <!-- ------MOBILE------ -->
        <ng-container *ngIf="mobile">
          <app-home-summary-section
            [mobile]="mobile"
            class="w-100 mt-5"
          ></app-home-summary-section>
          <app-home-plan-trip class="w-100"></app-home-plan-trip>
          <app-home-rate-share
            [currentU]="currentU"
            [mobile]="mobile"
            class="w-100"
          ></app-home-rate-share>
          <app-home-join-community
            [currentU]="currentU"
            class="w-100"
          ></app-home-join-community>
          <app-home-ambassadors
            id="ambassadors"
            [mobile]="mobile"
            class="w-100"
          ></app-home-ambassadors>
        </ng-container>
      </div>
    </div>
  </div>
</div>
