<!-- Keep this div invisible whilst the content is loading  -->
<div class="d-flex flex-column align-items-center">
  <div class="question-title">
    <span *ngIf="currentU" class="title">
      <!-- Translated text with an example user & city & location: MERVE, HOW LIKELY ARE YOU TO RECOMMEND KÜHTAI, HOCHOETZ TO A FRIEND? -->
      {{ 'rate.recommendation.question-with-user' | translate: {firstname: currentU.firstname, cityName: currentLocation.cityName, locationName: currentLocation.name} }}
    </span>
    <span *ngIf="!currentU" class="title">
      <!-- Translated text with an example city & location: HOW LIKELY ARE YOU TO RECOMMEND KÜHTAI, HOCHOETZ TO A FRIEND? -->
      {{ 'rate.recommendation.question-without-user' | translate: { cityName: currentLocation.cityName, locationName: currentLocation.name } }}
    </span>
  </div>
  <div class="content-wrapper-NPS">
    <!-- left arrow -->
    <div class="prev" (click)="currentSlide(false, false)">
      <img src="assets/images/src/arrow_bold.png" alt="Previous ski resort rating parameters" class="carousel-arrow-prev" />
    </div>
    <!-- left arrow -->

    <!-- rate -->
    <div class="d-flex">
      <div class="text-center">
        <div class="inline-block">
          <!-- Snowflakes -->
          <ng-template #t let-fill="fill" class="no-outline">
            <img
            class="rate-flakes-NPS"
            [class.full]="fill === 100"
            src="assets/images/src/sf_{{ fill }}.png"
            alt="Ski resort rating"
            />
          </ng-template>
          <ngb-rating [(rate)]="currentRate" [(ngModel)]="currentRate" [starTemplate]="t" [max]="10"
            (click)="triggerSaveRate()"></ngb-rating>
          <!-- high/low text -->
          <div class="star-width d-flex justify-content-between">
            <small class="low-high low">
              <!-- Translated text: Low -->
              {{"rate.recommendation.question-min" | translate}}
            </small>
            <small class="low-high high">
              <!-- Translated text: High -->
              {{"rate.recommendation.question-max" | translate }}
            </small>
          </div>
        </div>
      </div>
    </div>

    <!-- start right arrow -->
    <div class="next" (click)="currentSlide(true, true)">
      <img src="assets/images/src/arrow_bold.png" alt="Next ski resort rating parameters" class="carousel-arrow-next" />
    </div>
    <!-- end right arrow -->
  </div>

  <!-- If the data is not yet loaded from the state or the images haven't yet loaded +ADD IT LATER-->

  <div class="d-flex justify-content-center w-100">
    <!-- Skip param
    <button class="border-ratoong text-center text-white next-button pt-1 w-25 mr-2" (click)="testClick()">TRY</button> -->
    <button
      class="border-ratoong text-center text-white next-button pt-1 w-25 text-uppercase"
      (click)="currentSlide(true, true)"
    >
      <!-- Translated text: NEXT -->
      {{ "rate.next-button" | translate }}
    </button>
  </div>
  <!-- skip to end -->
  <div class="back-button-holder skip">
    <span class="pointer text-uppercase" (click)="goBackwards()">
      <img
        class="anchor-tag-back grey-filter align-self-center"
        src="assets/images/search/anchor_down_gray.svg"
        alt="Back" />Back
    </span>
  </div>
</div>
