import {Action, Selector, State, StateContext} from '@ngxs/store';

import {Injectable} from '@angular/core';
import {GetStatistics} from './admin-statistics.action';
import {AdminStatisticsService} from './admin-statistics.service';

export class AdminStatisticsStateModel {
  statisticsObject: object;

}

@State<AdminStatisticsStateModel>({
  name: 'AdminStatistics',
  defaults: {
    statisticsObject: {users: 0 , registeredUsers: 0, userProfiles: 0, skiers: 0, snowboarders: 0}
  }
})

@Injectable()
export class AdminStatisticsState {

  constructor(private statisticsService: AdminStatisticsService) {
  }

  @Selector()
  static getWebStats(state: AdminStatisticsStateModel): any {
    return state.statisticsObject;
  }

  @Action(GetStatistics)
  getFilteredResortData(ctx: StateContext<AdminStatisticsStateModel>): any {
    return this.statisticsService.getAllStatistics().then((result) => {
        ctx.patchState({
          statisticsObject: result
        });
      }
    );
  }
}
