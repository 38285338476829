import { LocationFilter } from './../../../shared/states/location/helper/location.filter';
import { UserSearchPreference } from './../../../shared/states/auth/entities/preference_entities/user_search_preference';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AuUser } from './../../../shared/states/auth/entities/user_interface';
import { Observable, Subscription } from 'rxjs';
import { AuthState } from './../../../shared/states/auth/auth.state';
import { Select } from '@ngxs/store';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-resort-list-item',
  templateUrl: './resort_list_item.component.html',
  styleUrls: ['./resort_list_item.component.scss'],
})
export class ResortListItemComponent implements OnInit, OnDestroy {
  @Input() currentLocation: any;
  @Input() isFirst: boolean;
  @Input() currentPreferences: UserSearchPreference;
  @Input() currentPrompt: number;

  @Output() promptOpened = new EventEmitter();

  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;

  subscription: Subscription;

  showHelperPrompt = true;
  showMatches = false;

  matchingParameters = [];
  nonMatchingParameters = [];

  constructor(
    private analytics: AngularFireAnalytics,
    private locationFilter: LocationFilter
  ) {}

  ngOnInit(): void {
    this.subscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  notANumber(value: number): any {
    return isNaN(value) || value == null;
  }

  isNumber(value: any): any {
    if (isNaN(value)) {
      return false;
    } else {
      return true;
    }
  }

  navigateToResortPage(): void {
    this.analytics.logEvent('RAT_user_clicked_single_resort', {
      town: this.currentLocation.cityName,
    });

    window.open(
      '/search-ski-resorts/' +
        this.currentLocation.cityName +
        '/' +
        this.currentLocation.id,
      '_blank'
    );
  }

  // A function that calculates all matching and non matching params and displays (or closes) the prompt
  showMatchingParameters(): void {
    this.showHelperPrompt = false; // close the 'helper' prompt
    // Show prompt if the data exists (preferences and scores) and if the current prompt is not already showing
    if (
      this.currentLocation.locationScores &&
      this.currentPreferences &&
      this.currentPrompt !== this.currentLocation.id
    ) {
      // Calculate all matching and non-matching parameters
      let result = this.locationFilter.getMatchingParameters(
        this.currentLocation.locationScores,
        this.currentPreferences.multipleChoice,
        this.currentPreferences.rangeQuestions
      );
      // And store them in arrays
      this.matchingParameters = result.matchingParameters;
      this.nonMatchingParameters = result.nonMatchingParameters;
      // And show the matches/non-matches
      this.showMatches = true;

      // Putting a very slight delay on opening the prompt ensures that the 'close' prompt function
      // inside search_results (this.resortList.setCurrentPrompt(0)) has already been triggered and won't close it after opening
      setTimeout(() => {
        this.promptOpened.emit(this.currentLocation.id);
      }, 10);
    }
    // } else {
    //   // Else we close the prompt by setting the prompt id to 0
    //   this.promptOpened.emit(0);
    // }
  }
}
