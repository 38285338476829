import { AuthService } from '../../../../states/auth/auth.service';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-login-signup-email',
  templateUrl: './login_signup_email.component.html',
  styleUrls: ['./login_signup_email.component.scss'],
})
export class LoginSignupEmailComponent implements OnInit, OnDestroy {
  @Output() goBack = new EventEmitter();
  @Output() proceedToLogin = new EventEmitter();
  @Output() proceedToSignup = new EventEmitter();

  fieldTextType: boolean;
  emailDetails: FormGroup;
  errorMessage: string;
  isLoading = false;

  private ngUnsubscribe = new Subject();

  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.emailDetails = this.formBuilder.group({
      email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(200),
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$'),
        ])
      ),
    });
  }

  async onSubmit(): Promise<any> {
    this.isLoading = true;
    this.submitted = true;
    // If invalid email or not entered, to display an error message
    if (this.emailDetails.invalid) {
      this.isLoading = false;
      return;
    }
    // Initialise a string array which will hold information on whether the supplied email is already taken
    let userExists: string[] = [];
    try {
      // Check if email exists in database
      userExists = await this.authService.checkEmailExists(
        this.emailDetails.value.email
      );
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
      return;
    }

    // i.e. if email/user already exists
    if (userExists.length !== 0) {
      // We direct them to login with the provided email
      this.proceedToLogin.emit(this.emailDetails.value.email);
    } else {
      // Else we direct them to signup with the provided email
      this.proceedToSignup.emit(this.emailDetails.value.email);
    }
  }

  // convenience getter for easy access to form fields
  get f(): any {
    return this.emailDetails.controls;
  }

  toggleFieldTextType(): any {
    this.fieldTextType = !this.fieldTextType;
  }
}
