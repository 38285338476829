import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
} from '@angular/animations';
import {
  LoginWithGoogle,
  LoginWithFaceBook,
} from './../../shared/states/auth/auth.action';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '../../shared/states/auth/auth.state';
import { Observable } from 'rxjs';
import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { Logout } from '../../shared/states/auth/auth.action';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../shared/services';
import { SCREEN_SIZE } from '../../shared/services/enums/screen-size.enum';
import { DeviceDetectorService } from '../../shared/services/device-detector.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
  animations: [
    trigger('prompt', [
      transition(':enter', [
        style({ transform: 'scale(0.7)', transformOrigin: '80% 0%' }),
        animate(
          '2.5s cubic-bezier(.85,.42,.94,.56)',
          keyframes([
            style({ transform: 'scale(0)' }),
            style({ transform: 'scale(0)' }),
            style({ transform: 'scale(0)' }),
            style({ transform: 'scale(1.05)' }),
            style({ transform: 'scale(1)' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnInit {
  showMobileMenu = true;
  mobile = false;
  device: string;
  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;
  isCollapsed = true;
  size: SCREEN_SIZE;

  userClosedRatePrompt = false;
  userClosedSearchPrompt = false;

  ngOnInit(): any {}

  constructor(
    private router: Router,
    private store: Store,
    private popupService: NgbModal,
    private modalService: ModalService,
    private resizeSvc: DeviceDetectorService,
    private analytics: AngularFireAnalytics
  ) {
    this.currentUser.subscribe((data) => {
      this.currentU = data;
    });

    this.size = resizeSvc.getCurrentSize();
    this.mobile = this.size < 3;

    resizeSvc.onResize$.subscribe((x) => {
      this.size = x;
      this.mobile = this.size < 3;
    });
  }

  goPlaces(anchor): any {
    this.router.navigate(['about'], { fragment: anchor });
  }
  openMobileMenu(): any {
    this.recordEvent('Mobile menu');
    if (this.mobile) {
      this.showMobileMenu = !this.showMobileMenu;
    }
  }

  signOut(): any {
    this.recordEvent('Sign out');
    this.store.dispatch(new Logout());
    this.router.navigate(['/']);
  }

  open(id, content): any {
    this.recordEvent('Log in / Sign up');
    this.modalService.open(id, content, 'lg');
    
  }

  recordEvent(value: string): void {
    this.analytics.logEvent('RAT_user_clicked_navbar', { link: value });
  }

  isRateMain(): boolean {
    return this.router.url === '/rate-ski-resort';
  }

  isSearchPage(): boolean {
    return this.router.url.includes('search-ski-resorts');
  }

  loginFb(): void {
    this.store.dispatch(new LoginWithFaceBook());
  }

  loginGoogle(): void {
    this.store.dispatch(new LoginWithGoogle());
  }
}
