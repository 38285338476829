import { SetUserState } from './shared/states/auth/auth.action';
import { AuthService } from './shared/states/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  GetAllCountries,
  GetAllLocations,
} from './shared/states/location/location.action';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import {
  GetSubCategories,
  GetSkiGroups,
} from './shared/states/preference/preference.action';

import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subject, Subscription } from 'rxjs';
import { Router, Event } from '@angular/router';
import {
  NavigationStart,
  NavigationError,
  NavigationEnd,
} from '@angular/router';
// fbq is used by Facebook Pixel
declare let fbq;
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { takeUntil } from 'rxjs/operators';
import AOS from 'aos';
import { UrlNamePipe } from './shared/pipes';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  // Site title tag
  title = 'ratoong';

  // Required references for cookie subscription
  // See https://www.npmjs.com/package/ngx-cookieconsent
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;

  private ngUnsubscribe = new Subject();

  public showDefaultHeaders = true;

  isLoading = false;

  constructor(
    private store: Store,
    private router: Router,
    private ccService: NgcCookieConsentService,
    private analytics: AngularFireAnalytics,
    private actions$: Actions,
    private pipe: UrlNamePipe,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private titleService: Title
  ) {
    this.actions$
      .pipe(ofActionSuccessful(GetSubCategories), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.store.dispatch(new GetAllLocations());
      });
    // Store all countries and all subcategories into the state so that it can be accessed all over the application
    this.store.dispatch(new GetAllCountries());
    this.store.dispatch(new GetSubCategories());
    this.store.dispatch(new GetSkiGroups());

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const generalisedUrl = this.pipe.transform(this.router.url);
        this.showDefaultHeaders = !generalisedUrl.includes('/partners');
        this.analytics.logEvent('RAT_page_view', {
          pageName: event.url,
          pageNameGeneral: generalisedUrl,
        });

        // fbq('track', 'PageView');
        // Hide loading indicator
      }
    });

    // This handles cases when user returns to the site following signInWithRedirect()
    if (localStorage.getItem('signInWithRedirect')) {
      // SET LOADING SCREEN
      this.isLoading = true;
      localStorage.removeItem('signInWithRedirect');
      this.afAuth.getRedirectResult().then((result) => {
        if (result.credential) {
          authService
            .checkIfUserExists(result)
            .then((data) => {
              store.dispatch(new SetUserState(data)).subscribe(
                () => {
                  const redirect = localStorage.getItem('ratoongRedirect');
                  // REMOVE LOADING SCREEN
                  this.isLoading = false;
                  if (redirect) {
                    localStorage.removeItem('ratoongRedirect');
                    router.navigate([redirect]);
                  }
                },
                (error) => {
                  console.log(error);
                  this.isLoading = false;
                }
              );
            })
            .catch((error) => {
              throw new Error(error.message);
            });
        } else {
          // If user not logged in we can set loader to false
          this.isLoading = false;
          localStorage.removeItem('ratoongRedirect');
        }
      });
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): any {
    // ----- Cookie consent subscriptions -----
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {}
    );
    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        this.ccService.close(false);
      }
    );
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {}
    );
    // ------ End of Cookie consent subscriptions ------
    // push GTM data layer for every visited page
    // AOS animations
    AOS.init();
  }

  ngOnDestroy(): any {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
