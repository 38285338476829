import { Component, OnInit } from '@angular/core';
import {AngularFireAnalytics} from "@angular/fire/analytics";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.sass'],
})
export class PlanComponent implements OnInit {

  public showSkyScanner = false;
  public showSkiSet = false;
  public showSkiLifts = false;
  map = new Map();

  skyScannerEventSet = false;
  // Initialise widgets
  ngOnInit(): any {
    this.loadScript(
      'https://widgets.skyscanner.net/widget-server/js/loader.js'
    );
    var skParams = {
      "partner"		: "ratoong",
      "lang"			: "en",
      "type"			: "full",
      "custom_show_header"		: 0,
    };
    (function(i,s,o,g,r,a,m){i["SkisetWidgetObject"]=r;a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);
    })(window,document,"script","//prtsk.skiset.com/js/partnerIntegration.js",skParams);
    this.setTitle('Plan your next trip')
  }

  constructor(
    private titleService: Title,
    private analytics: AngularFireAnalytics
    ) {
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


  public loadScript(url: string): any {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  /*
  public loadSkiliftScript() {
    const script = document.createElement('script');
    const protocol = (("https:" == document.location.protocol) ? "https://" : "http://");
    const widget_id = "20FF6168-3938-2E4E-D30E-1E4FFA423DF8";
    const template = "responsive";
    script.type = "text/javascript";
    script.src = protocol+"cdn.ski-lifts.com/widget/loader/dist/widget.load.v1.skilifts.js?widget_id="+widget_id+"&t="+template+"&base_url="+protocol+"cdn.ski-lifts.com/widget/app";
    script.async = true;
 
    // var protocol = (("https:" == document.location.protocol) ? "https://" : "http://"); 
    // var widget_id = "20FF6168-3938-2E4E-D30E-1E4FFA423DF8"; 
    // var template = "responsive"; 
    document.write(decodeURI("%3Cscript src='"+protocol+"cdn.ski-lifts.com/widget/loader/dist/widget.load.v1.skilifts.js?widget_id="+widget_id+"&t="+template+"&base_url="+protocol+"cdn.ski-lifts.com/widget/app' async %3E%3C/script%3E")); 

    let skiLiftElement = document.getElementById("skiLiftElement");
    
    skiLiftElement.appendChild(script);
  }
  */


  ngAfterViewInit() {
    //this.loadSkiliftScript()
  }

  public recordEvent(value): any{
    if (!this.map.has(value)){
      this.map.set(value, 1);
      this.analytics.logEvent('RAT_user_clicked_plan_link', {partnerName: value });
    }
  }

  openSkyScanner(): void{
    this.showSkyScanner = !this.showSkyScanner
    // if(!this.skyScannerEventSet){
    //    // Attach event
    //   const button = document.querySelector(".ReferralButton__buttonContainer___3X6Dk")
    //   if(!button) return; // If the content hasn't loaded yet then we want to return so as to avoid errors
    //   button.addEventListener("click", function(event){
    //     const fromTo = document.querySelectorAll(".bpk-input");
    //     const from = fromTo[0].value;
    //     const to = fromTo[1].value;

    //     const departReturn = document.querySelectorAll(".PopupDatePicker__datePickerInput___dXDjX");
    //     const depart = departReturn[0].value;
    //     const comeHome = departReturn[1].value;

    //     const adults = document.querySelector("#adults-nudger").value;
    //     const children = document.querySelector("#children-nudger").value;
    //     const cabinClass = document.querySelector("#cabin-class-selector").value;
    //     this.analytics.logEvent('RAT_user_submitted_sky_scanner');

    //   }); 
    //   this.skyScannerEventSet = true; 
    // } 
  }

}
