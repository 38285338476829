import { UserSearchPreference } from '../auth/entities/preference_entities/user_search_preference';
export class GetAllCountries {
  static readonly type = '[Auth] GetAllCountries';
}

export class FilterCountriesByRegion {
  static readonly type = 'FilterCountriesByRegion';
  constructor(public region: string) {}
}

export class GetAllLocations {
  static readonly type = 'GetAllLocations';
}

export class FilterLocationList {
  static readonly type = 'FilterLocationList';
  constructor(public params: object) {}
}

export class GetSpecificLocation {
  static readonly type = 'GetSpecificLocation';
  constructor(public uid: string) {}
}

export class AddNewLocation {
  static readonly type = 'AddNewLocation';
  constructor(
    public userUid: string,
    public date: any,
    public guid: any,
    public city: string,
    public website: any,
    public location: any
  ) {}
}

export class NewFilterLocationList {
  static readonly type = 'NewFilterLocationList';
  constructor(
    public searchPreferences: UserSearchPreference,
    public latitude: number,
    public longitude: number
  ) {}
}

// Sets the search results when user has specified their
export class SetSearchResults {
  static readonly type = 'setSearchResults';
  // locations here also includes additional information, such as longitude and therefore has to be type any
  constructor(public locations: any[]) {}
}
