
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RatingService } from './rating.service';
import {
  GetUserRatingByLocation,
  SaveRating,
  SaveRatingTest,
  SaveSkiGroup,
  ResetRatingState, SaveWrittenReview, GetAllReviewsFromLocation,
} from './rating.action';
import { Injectable } from '@angular/core';
import {Review} from './entities/review';


export class RatingStateModel {

  totalReviews: Review[];
  review: Review;
}

@State<RatingStateModel>({
  name: 'rate',
  defaults: {

    totalReviews: [],
    review: null
  },
})
@Injectable()
export class RatingState {
  constructor(private ratingService: RatingService) {}

  @Selector()
  static previousReview(state: RatingStateModel): any {
    return state.review;
  }

  @Selector()
  static getAllReviews(state: RatingStateModel): any {
    return state.totalReviews;
  }

  @Action(GetUserRatingByLocation)
  GetUserRatingByLocation(
    { getState, setState }: StateContext<RatingStateModel>,
    { locationId, userId }: GetUserRatingByLocation
  ): any {
    return this.ratingService
      .getUserRatingByLocation(locationId, userId)
      .then((data) => {
        const state = getState();
        setState({
          ...state,
          review: data
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(SaveRating)
  SaveRating(
    { getState, setState }: StateContext<RatingStateModel>,
    { locationId, subCategoryId, ratingNumber, userId, questionName, questionCategory }: SaveRating
  ): any {
    return this.ratingService
      .saveRating(locationId, subCategoryId, ratingNumber, userId, questionName, questionCategory)
      .catch((error) => {
        throw new Error(error.message);
      });
  }


  @Action(SaveRatingTest)
  SaveRatingTest(
    { getState, setState }: StateContext<RatingStateModel>,
    { locationId, userId, dataContainer }: SaveRatingTest
  ): any {
    return this.ratingService
      .saveRatingTest(locationId, userId, dataContainer)
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(SaveSkiGroup)
  SaveSkiGroup(
    { getState, setState }: StateContext<RatingStateModel>,
    { locationId, skiGroupId, userId, skiGroupTitle, riderType}: SaveSkiGroup 
  ): any {
    return this.ratingService
    .saveSkiGroup(locationId, skiGroupId, userId, skiGroupTitle, riderType) 
    .then((data) => {
      // console.log('from saveSkiGroup state', data)
      // console.log('from saveSkiGroup state', riderType)
        const state = getState();
        setState({
          ...state,
          review: data,
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(ResetRatingState)
  ResetState(
    { setState }: StateContext<RatingStateModel>,
    {}: ResetRatingState
  ): any {
    setState({
      review: null,
      totalReviews: []
    });
  }

  @Action(SaveWrittenReview)
  saveWrittenReview( { getState, setState }: StateContext<RatingStateModel>,
                     { locationId, uid, review, selectedPhoto, filePath }: SaveWrittenReview): any {
    return this.ratingService
      .saveWrittenReview(locationId, review, uid, selectedPhoto, filePath)
      .then((data) => {
        const state = getState();
        setState({
          ...state,
          review: data,
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(GetAllReviewsFromLocation)
  getAllReviewsFromLocation(
    { getState, setState }: StateContext<RatingStateModel>,
    { locationId }: GetAllReviewsFromLocation
  ): any {
    return this.ratingService
      .getAllReviewsFromLocation(locationId).then((data) => {
        const state = getState();
        setState({
          ...state,
          totalReviews: data,
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }
}
