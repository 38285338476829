
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import {Resort} from './entities/resort';
import {Town} from './entities/town';
import {AdminAuthService} from '../admin-auth/admin-auth.service';
import {Link} from './entities/link';
import {Redirect} from './entities/redirect';
import {Partner} from "./entities/partner";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  database: any = null;

  constructor(private authService: AdminAuthService) {
    this.database = firebase.database();
  }

  async getResorts(): Promise<any> {
    const list = [];
    return await firebase
      .database()
      .ref('/ResortCommon')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((child) => {
          const name = child.val().Name;
          const commonId = child.val().CommonId;
          list.push({commonId, name} as Resort);
        });
        return list;
      });
  }

  async getAllTowns(): Promise<Town[]>  {
    const list = [];
    return await firebase
      .database()
      .ref('/NewLocations')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((child) => {
          const name = child.val().CityName;
          const id = child.val().LocationId;
          const commonId = child.val().CommonId;
          list.push({name, id, commonId} as Town);
        });
        return list;
      });
  }

  async getAllLocations(): Promise<Resort[]>  {
    const allresortList = await this.getResorts();
    const allTownList = await this.getAllTowns();
    let townList = [];
    for (let i = 0; i < allresortList.length; i++) {
      for (let x = 0; x < allTownList.length; x++) {
        if (String(allresortList[i].commonId) === String(allTownList[x].commonId)) {
          townList.push(allTownList[x]);
        }
      }
      allresortList[i].townList = townList;
      townList = [];
    }
    return allresortList;
  }

  async getEmail(): Promise<any>  {
    const list = [];
    return await firebase
      .database()
      .ref('/NewUsers')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((child) => {
          const email = child.val().email;
          if (email && email.includes('@')){
            list.push(email);
          }
        });
        return list;
      });
  }

  async getLinks(): Promise<any>  {
    const list = [];
    return await firebase
      .database()
      .ref('/SignUpReferals')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((child) => {
          const url = child.val().url;
          const resortName = child.val().resortName;
          const resortTowns = child.val().selectedTownNames;
          const role = child.val().roleName;
          list.push({url, resortName, resortTowns, role} as Link);
        });
        return list;
      });
  }


  async GenerateLink(partnerID,
                     resortID: string,
                     resortName: string,
                     selectedTownIds: string,
                     selectedTownNames: string,
                     roleID: number,
                     roleName: string): Promise<any> {
    const uid = firebase.auth().currentUser.uid;
    const generateSignUpLink = await firebase.functions().httpsCallable('generateSignUpLink');

    return await generateSignUpLink({uid, partnerID, resortID , resortName , selectedTownIds , selectedTownNames , roleID, roleName });
  }

  async RedirectLinkGeneration(customURL: string, value: string, purpose: string, title: string, partnerID: string): Promise<any> {
    const uid = firebase.auth().currentUser.uid;
    const generateCampaignLink = await firebase.functions().httpsCallable('generateCampaignLink');
    return await generateCampaignLink({uid, customURL , value, purpose, title, partnerID });
  }

  async getRedirects(): Promise<any> {
      const list = [];
      return await firebase
        .database()
        .ref('/RedirectLinks')
        .once('value')
        .then((snapshot) => {
          snapshot.forEach((child) => {
            const url = child.val().url;
            const redirect = child.val().customURL;
            const value = child.val().value;
            list.push({url, redirect , value} as Redirect);
          });
          return list;
        });
  }

  async getPartners(): Promise<any> {
    const list = [];
    return await firebase
      .database()
      .ref('/Partners')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((child) => {
          const title = child.val().title;
          const resort = child.val().resort;
          const towns = child.val().towns;
          const name = child.key;
          list.push({title, resort, towns, name} as Partner);
        });
        return list;
      });
  }

  async CreatePartner(managerID: string, title: string, resort: string, towns: string): Promise<any> {
    const uid = firebase.auth().currentUser.uid;
    const createPartner = await firebase.functions().httpsCallable('createPartner');
    return await createPartner({uid, managerID, towns, resort, title});
  }

  async GetPartnerRedirects(partnerID: string): Promise<any>  {
    const list = [];
    const partnerRedirects = [];

    console.log(partnerID);
    const snapshot = await firebase.database().ref('/Partners/' + partnerID + '/links' ).once('value');
    if (snapshot.val() != null) {
      const listOfData = (snapshot.val().employees === null) ? null : snapshot.val().employees;

      await snapshot.forEach((child) => {
        list.push(child.key);
      });
       for (let i = 0; i < list.length; i++) {
       const redirectSanp = await firebase.database().ref('/RedirectLinks/' + list[i]).once('value');

        if (redirectSanp.val() != null ) {
          let clicks = 0;
          if (redirectSanp.val().hasOwnProperty('clicks')){
            clicks = Number(redirectSanp.val().clicks);
          }
          const url = redirectSanp.val().url;
          const redirect = redirectSanp.val().customURL;
          const value = redirectSanp.val().title;
          partnerRedirects.push({url, redirect , value, clicks} as Redirect);
        }
      }
    }
    return partnerRedirects;
  }
}
