<div class="row">
  <section class="col-sm-6">
    <div class="d-flex px-2 justify-content-start title-section">
      <div class="title-icon mr-4">
        <img src="assets/images/info/resort_icon.svg" alt="{{ currentLoc.name }}" />
      </div>
      <div class="d-flex flex-column">
        <h1 class="location">{{ currentLoc.cityName }}</h1>
        <h1 class="title">{{ currentLoc.name }}</h1>
        <h4 class="subtitle">
          Find out if {{ currentLoc.cityName }} is the best ski resort in
          {{ currentLoc.countryName }} for you by checking out other skiers and
          snowboarders' ratings. You can also find out about
          {{ currentLoc.name }} ski pistes: the difficulty level, opening hours,
          length and types of lifts. Finally, don't forget to look at
          {{ currentLoc.cityName }} weather conditions during your travel time!
        </h4>
      </div>
    </div>
  </section>

  <section class="col-sm-6 d-flex justify-content-center align-items-center">
    <div class="button-container px-3 d-flex flex-column align-items-center">
      <h4 class="create-profile mb-3">Create a profile to:</h4>
      <a title="Go to plan a trip page" routerLink="/plan" class="red-button">
        PLAN A TRIP
      </a>
      <div class="favourite-container">
        <app-favourite-bucketlist
          class="w-100"
          [currentLoc]="currentLoc"
          [currentU]="currentU"
        ></app-favourite-bucketlist>
      </div>
    </div>
  </section>
</div>
