import { FormGroup } from '@angular/forms';

// A custom validator for checking that passwords match when user enters their passwords
export function PasswordsMatch(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    // If errors we want to return
    // if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
    //   return;
    // }
    // We set an error if the passwords do not match
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ passwordsMatch: true });
    } else {
      matchingControl.setErrors(null);   
    }
  };
}
