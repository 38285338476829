import { SkiGroup } from './entities/skiGroup';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PreferenceService } from './preference.service';
import {
  GetSubCategories,
  UpdateSubPreference,
  GetSkiGroups, CheckRedirect, WipeRedirect,
} from './preference.action';
import { Injectable } from '@angular/core';
import { SubCategory } from './entities/subCategory';


export class PrefereceStateModel {
  listOfSubRatings: SubCategory[];
  skiGroups: SkiGroup[];
  redirectInfo: string;
}

@State<PrefereceStateModel>({
  name: 'pref',
  defaults: {
    listOfSubRatings: [],
    skiGroups: [],
    redirectInfo: undefined
  },
})
@Injectable()
export class PreferenceState {
  constructor(private prefService: PreferenceService) {}

  @Selector()
  static listOfSubRatings(state: PrefereceStateModel): any {
    return state.listOfSubRatings;
  }

  @Selector()
  static listOfSkiGroups(state: PrefereceStateModel): any {
    return state.skiGroups;
  }

  @Selector()
  static getURL(state: PrefereceStateModel): any {
    return state.redirectInfo;
  }

  @Action(UpdateSubPreference)
  updateSubPreference(
    { getState, setState }: StateContext<PrefereceStateModel>,
    { uid, nameCtrl, mark }: UpdateSubPreference
  ): any {
    return this.prefService.updateSubPreference(uid, nameCtrl, mark);
  }

  @Action(GetSubCategories)
  getSubCategories({
    getState,
    setState,
  }: StateContext<PrefereceStateModel>): any {
    this.prefService.getSubRatings().then((result) => {
      const state = getState();
      setState({
        ...state,
        listOfSubRatings: result,
      });
    });
  }

  @Action(GetSkiGroups)
  getSkiGroups({ getState, setState }: StateContext<PrefereceStateModel>): any {
    this.prefService.getSkiGroups().then((result) => {
      const state = getState();
      setState({
        ...state,
        skiGroups: result,
      });
    });
  }


  @Action(CheckRedirect)
  checkRedirect({getState, setState}: StateContext<PrefereceStateModel>,
                {
                  redirectID
                }: CheckRedirect): any {
    this.prefService.checkRedirect(redirectID).then((result) => {
      const state = getState();
      setState({
        ...state,
        redirectInfo: result,
      });
    });
  }

  @Action(WipeRedirect)
  wipeRedirect({getState, setState}: StateContext<PrefereceStateModel>): any {
      const state = getState();
      setState({
        ...state,
        redirectInfo: undefined,
      });
  }
}
