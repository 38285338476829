import { Select } from '@ngxs/store';
import { AuthState } from './../../shared/states/auth/auth.state';
import { AuUser } from './../../shared/states/auth/entities/user_interface';
import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser'; 

@Component({
  selector: 'app-rate-main',
  templateUrl: './rate_main.component.html',
  styleUrls: ['./rate_main.component.scss'],
})
export class RateMainComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  currentU: AuUser

  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;


  // Purely an HTML based component
  constructor(
    private titleService: Title
  ) {}

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): any {
    this.subscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
    })
    this.setTitle('Rate Your Favorite Ski Resort')
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
