import { RatingState } from '../../shared/states/ratings/rating.state';
import {
  SaveSkiGroup,
  ResetRatingState,
  SaveWrittenReview,
} from '../../shared/states/ratings/rating.action';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  Actions,
  ofActionCompleted,
  ofActionErrored,
  Select,
  Store,
} from '@ngxs/store';
import {
  GetUserRatingByLocation,
  SaveRating,
  SaveRatingTest,
} from '../../shared/states/ratings/rating.action';
import { AuthState } from '../../shared/states/auth/auth.state';
import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { PreferenceState } from '../../shared/states/preference/preference.state';
import { SubCategory } from '../../shared/states/preference/entities/subCategory';
import { LocationState } from '../../shared/states/location/location.state';
import { map, takeUntil } from 'rxjs/operators';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Review } from '../../shared/states/ratings/entities/review';
import { ReviewsContainer } from '../../shared/states/ratings/entities/reviewsContainer';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rate-estimate-container',
  templateUrl: './rate_estimate_container.component.html',
  styleUrls: ['./rate_estimate_container.component.scss'],
})
export class RateEstimateContainerComponent implements OnInit, OnDestroy {
  public isLoading = false;

  // shows current global position of the user. This is used to stop the user when he reaches the end of all know ratings.
  public currentQuestionLimit = 9;

  public currentLocation;

  // Keep track of subscriptions - so they can be unsubscribed at the end.
  private userSubscription: Subscription;
  private routeSubscription: Subscription;
  private ratingSubscription: Subscription;



  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  @Select(PreferenceState.listOfSubRatings) questions: Observable<
    SubCategory[]
  >;

  @Select(LocationState.locationList) locations: Observable<Location[]>;
  @Select(RatingState.previousReview) previousRatings: Observable<Review>;
  map = new Map();
  currentU: AuUser;
  listOfTotalQuestions: SubCategory[];
  listOfQuestions: SubCategory[];
  private ngUnsubscribe = new Subject();

  // Total number of questions (including ski-group, rate questions and the review question)
  numberOfQuestions: number;

  // Current question number
  questionNumber: number;

  // Type of rider holder
  riderType: string;

  // Create user's reviews holder (object where we will collect all data before push it to db)
  reviewsContainer = <ReviewsContainer>{};


  // Holds a map of all the previous questions:ratings that the user has done at this location in the past
  questionRatingMap = new Map();

  // Holds the value for the average score that the user has rated at this resort.
  // Not only includes from this review (8 questions) but also other ratings within the year.
  finalAverage = 0;

  //Variables for text and image reviews
  reviewImage: string;
  reviewText: string;


  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private store: Store,
    private actions$: Actions,
    private analytics: AngularFireAnalytics,
    private translateService: TranslateService
  ) {


    this.actions$
    .pipe(
      ofActionCompleted(ResetRatingState),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(() => {
      // After resetting- we can carry out another service call with the most recent location being reviewed
      if(this.currentU){
        this.store.dispatch(
          new GetUserRatingByLocation(
            this.currentLocation.id,
            this.currentU.uid
          )
        );
      }
    });
    this.actions$
        .pipe(
          ofActionErrored(GetUserRatingByLocation),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(() => {
          this.router.navigate(['/404']);
        });
  }

  ngOnInit(): void {

    this.isLoading = true;
    // Initialize container where we will hold all data that come from user and previous reviews from DB
    this.reviewsContainer.reviews = [];
    // Get the user object
    this.userSubscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
    });
    // Get the route parameters, containing the location data
    this.routeSubscription = this.route.params.subscribe((params) => {
      const locationID = Number(params.locationId);
      // i.e. if location isn't valid/doesn't exist
      if (isNaN(locationID)) {
        this.router.navigate(['/404']);
      }
      // Get the location using the id and assign to this.currentLocation
      this.getLocation(Number(params.locationId)).subscribe((data) => {
        // this.getLocation(100).subscribe((data) => {
        this.currentLocation = data;
      });
    });

    this.store.dispatch(new ResetRatingState());
    this.questions.subscribe((data) => {
      this.listOfTotalQuestions = data;
    });

    // ***************************************************
    // Start at the first question
    this.questionNumber = 1;
    // Total number of questions/groups of questions at the moment = 9
    this.numberOfQuestions = 9;

    this.ratingSubscription = this.previousRatings.subscribe((data) => {
      //this.reviewsContainer.currentLocation = this.currentLocation.id
      if (data) {
        if (data.previousRatings) {
          this.reviewsContainer.previousReviews = data.previousRatings;
          data.previousRatings.map((dataset) => {
            this.questionRatingMap.set(dataset.questionId, dataset.rating);
          });
        } else {
          this.reviewsContainer.previousReviews = []
        }
        if (data.imageURL) {
          this.reviewImage = data.imageURL;
        }
        if (data.reviewText) {
          this.reviewText = data.reviewText;
        }
        if (data.riderType) {
          this.riderType = data.riderType;
        }
      } else {
        this.reviewsContainer.previousReviews = '';
      }
    });
  }

  // Set up type of rider and add it inside reviewsContainer
  setRiderType(type: string): any {
    this.riderType = type;
    this.reviewsContainer.riderType = type;
    //type ? this.reviewsContainer.riderType = type : ''
  }

  // Add ski group id inside reviewsContainer
  addGroup(skiGroupId): any {
    this.reviewsContainer.skiGroup = skiGroupId.groupId;
  }

  // Add review question id and rating inside reviewsContainer
  addExperience(reviewData): any {
    let index = this.reviewsContainer.reviews.findIndex((element) => {
      return element.questionId == reviewData.questionId;
    });
    index == -1
      ? this.reviewsContainer.reviews.push(reviewData)
      : (this.reviewsContainer.reviews[index] = reviewData);
  }

  getLocation(id: number): Observable<Location> {
    const location = this.locations.pipe(
      // @ts-ignore
      map((txs) => txs.find((txn) => txn.id === id))
    );

    location.subscribe((data) => {
      if (!data) {
        this.router.navigate(['/404']);
      }
    });

    return location;
  }

  // ******* BELOW HERE ARE EVENTS TRIGGERED BY CHILD COMPONENTS *******

  // Triggered by rate_overview. Saves the rating in the database
  saveRate(dataContainer): any {
    let userID = '';
    if (this.currentU) {
      userID = this.currentU.uid;
    }
    this.store.dispatch(
      new SaveRatingTest(this.currentLocation.id, userID, dataContainer)
    );
  }

  // Loading event. Specifies if content is loading or not
  setLoading(value): any {
    this.isLoading = value;
  }

  private recordEvent(): any {
    if (this.questionNumber > 1 && this.questionNumber < 10) {
      // Record on question number 1 , 5 , 10
      if (!this.map.has(this.questionNumber)) {
        switch (this.questionNumber) {
          case 2: {
            this.analytics.logEvent('RAT_rating_process', {
              stage: 'start',
              numberOfQuestions: this.questionNumber,
            });
            break;
          }
          case 5: {
            this.analytics.logEvent('RAT_rating_process', {
              stage: 'middle',
              numberOfQuestions: this.questionNumber,
            });
            break;
          }
          case 9: {
            this.analytics.logEvent('RAT_rating_process', {
              stage: 'end',
              numberOfQuestions: this.questionNumber,
            });
            break;
          }
        }
        this.map.set(this.questionNumber, 1);
      }
    }
  }

  // When the user moves on a question this is triggered
  changeQuestionCount(value): any {
    this.recordEvent();
    this.questionNumber = value;
  }

  // Saves the ski group value and rider type in the database
  saveGroup(skiGroupId): any {
    let userID = '';
    if (this.currentU) {
      userID = this.currentU.uid;
    }
    if (skiGroupId.groupId !== undefined) {
      this.store.dispatch(
        new SaveSkiGroup(
          this.currentLocation.id,
          skiGroupId.groupId,
          userID,
          skiGroupId.title,
          this.riderType
        )
      );
    }
     else {
      this.store.dispatch(
        new SaveSkiGroup(
          this.currentLocation.id,
          skiGroupId,
          userID,
          '',
          this.riderType
        )
      );
    }
  }

  //Save written review
  saveWrittenReview(writtenReviewId): any {
    let userId = '';
    if (this.currentU) {
      userId = this.currentU.uid;
    }
    if (writtenReviewId !== undefined) {
      this.store.dispatch(
        new SaveWrittenReview(this.currentLocation.id, writtenReviewId, userId)
      );
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userSubscription.unsubscribe();
    this.ratingSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }
}
