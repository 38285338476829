<div class="row">
  <div class="loader" *ngIf="!isLoaded"></div>
  <div class="col-12 d-flex justify-content-center" *ngIf="isLoaded">
    <div class="ratoong-container">
      <div class="details-container">
        <app-search-resort-title [currentLoc]="currentLoc"></app-search-resort-title>
      </div>
      <div class="details-container" *ngIf="currentLoc">
        <app-details-ratings [currentLoc]="currentLoc"></app-details-ratings>
      </div>
      <app-details-reviews [currentLoc]="currentLoc" [mobile]="mobile"></app-details-reviews>
      <div class="details-container" *ngIf="currentLoc.pistes && currentLoc.pistes.length != 0 ">
        <app-details-ski-piste [currentLoc]="currentLoc" [pisteMap]="$imageLink" ></app-details-ski-piste>
      </div>
      <div class="details-container" *ngIf="currentLoc.openingDates || currentLoc.openingHours || currentLoc.lifts ">
        <app-details-opening-lifts [currentLoc]="currentLoc"></app-details-opening-lifts>
      </div>
      <div class="details-container" *ngIf="currentLoc.wambrella">
        <app-details-weather [wambrella]="currentLoc.wambrella"></app-details-weather>
      </div>
      <div class="details-container" *ngIf="currentLoc.airports && currentLoc.airports.length != 0 ">
        <app-details-airports [currentLoc]="currentLoc"></app-details-airports>
      </div>
      <app-home-plan-trip class="w-100"></app-home-plan-trip>
    </div>
  </div>
</div>
