import { ProfanityFilterPipe } from './shared/pipes/profanity-filter.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navigations/navbar/navbar.component';
import { FooterComponent } from './navigations/footer/footer.component';
import { TermsAndConditionsComponent } from './terms_and_conditions/terms_and_conditions.component';

import { environment } from '../environments/environment';

import { AuthState } from './shared/states/auth/auth.state';
import { PreferenceState } from './shared/states/preference/preference.state';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedEventService } from './shared/services/shared-event-service';
import { FirestorageService, ModalService } from './shared/services';
import { SharedModule } from './shared/modules/shared.module';
import { AccountModule } from './account/account.module';
import { NameParser } from './shared/states/auth/helpers/nameParser';
import { AboutComponent } from './about/about.component';
import { PlanComponent } from './plan/plan.component';
import { HomeModule } from './home/home.module';
import { SearchModule } from './search/search.module';
import { AmbassadorpageComponent } from './ambassadorpage/ambassadorpage.component';
import { HomeAmbassadorsComponent } from './home/home_ambassadors/home_ambassadors.component';
import { HomeAmbassadorsItemComponent } from './home/home_ambassadors/home_ambassadors_item/home_ambassadors_item.component';

import { RatingState } from './shared/states/ratings/rating.state';
import { RateModule } from './rate/rate.module';
import { LocationState } from './shared/states/location/location.state';
import { LocationFilter } from './shared/states/location/helper/location.filter';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AmbassadorsState } from './shared/states/ambassadors/ambassadors.state';
import { FacebookModule } from 'ngx-facebook';

import { HammerModule } from '@angular/platform-browser';

// Admin and Partner page
import { StatisticsFilter } from './admin/admin-shared/states/summary/helpers/statistics';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';

import { SummaryState } from './admin/admin-shared/states/summary/summary.state';
import { AdminAuthState } from './admin/admin-shared/states/admin-auth/admin-auth.state';
import { AdminState } from './admin/admin-shared/states/admin-page/admin.state';
// end

import * as firebase from 'firebase';
firebase.initializeApp(environment.firebaseConfig);
firebase.analytics();

import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { NotfoundComponent } from './not_found/not_found.component';
import { SizeDetectorComponent } from './shared/ui/size_detector/size_detector.component';

// For animations
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomePlanTripComponent } from './home/home_plan_trip/home_plan_trip.component';

import { AdminSignUpComponent } from './admin/admin-sign-up/admin-sign-up.component';
import { RedirectPageComponent } from './redirect_page/redirect_page.component';
import { UrlNamePipe } from './shared/pipes';

import { AdminStatisticsState } from './admin/admin-shared/states/admin-statistics/admin-statistics.state';
import { DevConsoleState } from './admin/admin-shared/states/admin-dev-console/dev-console.state';
import { PromptComponent } from './navigations/prompt/prompt.component';
import { RateLandingPageComponent } from './redirect_page/rate_landing_page/rate_landing_page.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain,
  },
  position: 'bottom-left',
  theme: 'classic',
  palette: {
    popup: {
      background: '#212529',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#34a0a8',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'opt-out',
  content: {
    message:
      'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Refuse cookies',
    link: 'Learn more',
    href: '/terms',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    TermsAndConditionsComponent,
    AboutComponent,
    AmbassadorpageComponent,
    NotfoundComponent,
    PlanComponent,
    SizeDetectorComponent,
    AdminLoginComponent,
    AdminSignUpComponent,
    RedirectPageComponent,
    PromptComponent,
    RateLandingPageComponent,
  ],
  imports: [
    BrowserModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    FormsModule,
    SharedModule,
    SearchModule,
    AccountModule,
    RateModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    AngularFirestoreModule,
    ReactiveFormsModule,
    AngularFireAuthModule,
    NgxsModule.forRoot(
      [
        AuthState,
        PreferenceState,
        RatingState,
        LocationState,
        AmbassadorsState,
        SummaryState,
        AdminAuthState,
        AdminState,
        AdminStatisticsState,
        DevConsoleState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsStoragePluginModule.forRoot({
      key: ['auth', 'location', 'pref', 'AdminAuth'],
    }),
    NgbModule,
    FontAwesomeModule,
    FacebookModule.forRoot(),
    HomeModule,
    AppRoutingModule, // App routing needs to last for 404 detection
    BrowserAnimationsModule, // For animating
    HammerModule, // For mobile gestures
    HttpClientModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (http:HttpClient) => { return new TranslateHttpLoader(http, './assets/locales/','.json');},
          deps: [HttpClient]
        }
      }
    )
  ],
  providers: [
    SharedEventService,
    FirestorageService,
    ModalService,
    NameParser,
    LocationFilter,
    ScreenTrackingService,
    UserTrackingService,
    StatisticsFilter,
    UrlNamePipe,
    ProfanityFilterPipe,
  ],
  exports: [
    HomePlanTripComponent,
    HomeAmbassadorsComponent,
    HomeAmbassadorsItemComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
