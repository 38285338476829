import { Country } from '../../../shared/states/location/entities/countries';
import { UserSearchPreference } from '../../../shared/states/auth/entities/preference_entities/user_search_preference';
import { LocationState } from '../../../shared/states/location/location.state';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';

// For animating countries enter/exit
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-select-country',
  templateUrl: './select_country.component.html',
  styleUrls: ['./select_country.component.scss'],
  animations: [
    trigger('countriesEnterExit', [
      transition(':enter', [
        style({
          transform: 'translateX(100%)',
          height: 0,
          opacity: 0,
        }),
        animate('0.4s ease'),
      ]),
      transition(':leave', [
        animate(
          '0.4s ease',
          style({
            transform: 'translateX(100%)',
            height: 0,
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class SelectCountryComponent implements OnInit, OnDestroy {
  // Receives the userPreferences object so that it can edit it directly
  @Input() preferences: UserSearchPreference;
  @Output() valueEmitter = new EventEmitter();
  @Output() loadingEmitter = new EventEmitter();

  // An array to contain all the different regions
  regions: string[] = [];
  // Used to record which region is currently chosen so that relevant countries can be displayed
  selectedRegion = '';
  // An array contain a list of regions that the users has been through previously (so that a tick can be added)
  previouslySelected: string[] = [];
  // A list of all the countries with resorts
  allCountries: Country[];
  // An array containing countries in the chosen region
  regionCountries: string[] = [];

  subscription: Subscription;

  @Select(LocationState.countryList) listOfCountries: Observable<Country[]>;

  constructor(private store: Store) {}

  // When a region is chosen, we display all countries relating the the specified region
  regionChosen(region: string): any {

    // If user clicking on region: string already selected, to deselect it
    if (region === this.selectedRegion) {
      this.selectedRegion = '';
    } else {
      // Specify the selectedRegion
      this.selectedRegion = region;
      // Get all countries in the selected region
      this.regionCountries = this.countriesInRegion(region);
      // Add the region to 'previouslySelected' (if not already aded) so we can check the user has been through it already
      if (this.previouslySelected.indexOf(region) === -1) {
        this.previouslySelected.push(region);
      }
    }
  }

  countriesInRegion(region: string): any {
    return (
      // Get the list of all countries with resorts...
      this.allCountries
        // ...filter out the ones that are in the specified region...
        .filter((country) => country.region.includes(region))
        // ...and get the country names only.
        .map((country) => country.name)
    );
  }

  clearRegion(region: string): any {
    // Filter the list of countries already selected
    this.preferences.countries = this.preferences.countries.filter(
      (country) => {
        // Get the list of all countries in the region to be cleared
        const regionCountries = this.countriesInRegion(region);
        // And filter them out
        return !regionCountries.includes(country);
      }
    );
    // Update the resorts
    this.updateResorts();
  }

  fillRegion(region: string): any {
    // Get all countries in the region
    const regionCountries = this.countriesInRegion(region);

    // Perform a union of the countries in the region and the ones already selected in prefrences
    this.preferences.countries = [
      ...new Set([...this.preferences.countries, ...regionCountries]),
    ];
    // Update the resorts
    this.updateResorts();
  }

  // Returns true if the region is empty
  regionEmpty(region: string): any {
    // get all countries in the region
    const regionCountries = this.countriesInRegion(region);
    // Return true if no countries in the region are selected (i.e. the intersection of the arrays is empty)
    return (
      regionCountries.filter((country) =>
        this.preferences.countries.includes(country)
      ).length === 0
    );
  }

  // If already selected (in preferences.countries array) we remove it, else we add it
  countryClicked(country: string): any {
    if (this.preferences.countries.includes(country)) {
      // remove it
      this.preferences.countries = this.preferences.countries.filter(
        (c) => c !== country
      );
    } else {
      // add it
      this.preferences.countries.push(country);
    }
    // Update the resorts that match
    this.updateResorts();
  }

  // Send the countries list back to parent (new-search component)
  updateResorts(): any {
    this.valueEmitter.emit();
  }

  // Loops through all countries, saving regions in an array
  // The resulting regions array is only 7 long, it may be quicker to simply hard code the regions???
  populateRegionsArray(): any {
    // hard coded version of the regions, much quicker than looping through all the countries...
    this.regions = [
      'Europe',
      'North America',
      'South America',
      'Asia and Middle East',
      'Oceania',
      'Africa',
    ];

    this.subscription = this.listOfCountries.subscribe((countryList) => {
      // Get a list of all countries with regions
      this.allCountries = countryList.filter(
        (country) => country.region !== undefined
      );
      // // Dynamic version of getting regions, slower but means it can load dynamically from the countries document in the database.
      // // SPOKEN TO EG AND SARAH, THEY WOULD RATHER REGIONS HARD CODED SO CAN PUT IN A SPECIFIC ORDER
      // // Loop through each country
      // this.allCountries.forEach((country) => {
      //   // loop through each region the country belongs to...
      //   country.region.forEach((region) => {
      //     if (!this.regions.includes(region)) {
      //       // ...and add it to the regions array if it isn't already included
      //       this.regions.push(region);
      //     }
      //   });
      // });
      // If the data has been successfully retrieved from the state/server then we can end loading screen
      if (this.allCountries.length !== 0) {
        // Since we can expect there to always be countries loaded, we only end loading when we have them
        this.loadingEmitter.emit(false);
      }
    });
  }

  ngOnInit(): void {
    this.loadingEmitter.emit(true);
    this.previouslySelected = [];
    // get the array of regions
    this.populateRegionsArray();
    // Add all regions with countries already checked to our 'previouslySelected' array so that ticks appear on these regions
    this.regions.forEach((region) => {
      if (!this.regionEmpty(region)) {
        this.previouslySelected.push(region);
      }
    });
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }
}
