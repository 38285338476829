import { first } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {
  LoginWithFaceBook,
  LoginWithGoogle,
} from '../../../../states/auth/auth.action';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-login-signup-start',
  templateUrl: './login_signup_start.component.html',
  styleUrls: ['./login_signup_start.component.scss'],
})
export class LoginSignupStartComponent implements OnInit {
  errorMessage: string;
  @Output() closeModal = new EventEmitter();
  @Output() continueWithEmail = new EventEmitter();

  constructor(private store: Store) {}

  ngOnInit(): void {}

  loginFb(): any {
    this.store
      .dispatch(new LoginWithFaceBook())
      .pipe(first())
      .subscribe(
        (data) => {
          this.closeModal.emit();
          // this.router.navigate(['user-profile']);
        },
        (error) => {
          this.errorMessage = error;
        }
      );
  }

  loginGoogle(): any {
    this.store
      .dispatch(new LoginWithGoogle())
      .pipe(first())
      .subscribe(
        (data) => {
          this.closeModal.emit();
          // this.router.navigate(['user-profile']);
        },
        (error) => {
          this.errorMessage = error;
        }
      );
  }
}
