<div class="d-flex w-100 flex-column align-items-center bebas-neue">
  <img class="title-img mb-4" src="assets/images/info/rating_icon.svg" alt="Rating" />
  <div class="title">Thank you for rating!</div>
  <div class="w-75">
    <p class="title-text">
      Share your rating with your friends on Facebook, letting them know what you think about {{ currentLocation.cityName }} in {{ currentLocation.countryName }}.
    </p>
  </div>
  <span><i (click)="closeModal('review-popUp', true)" class="close fas fa-times"></i></span>
  <div class="review-rate-holder">
    <img class="ratoong-logo" src="assets/images/ratoong_share_logo.jpg" alt="Share your ratings with your friends!" />
    <div class="content-holder">
      <span class="big-text">{{ currentLocation.cityName }}, {{ currentLocation.countryName }}</span>
      <div class="flake-container d-flex align-items-center">
        <ngb-rating [(rate)]="overall" [(ngModel)]="overall" [starTemplate]="snowFlakes" [max]="5" [readonly]="true">
        </ngb-rating>
        <div class="overall-value">
          <span *ngIf="overall > 0">
            {{ overall }} <!---->
          </span>
          <span *ngIf="!overall"></span>
        </div>
      </div>
      <span class="rated-text"> Rated {{ currentDate }}</span> <!---currentDate?-->
    </div>
  </div>
  <div (click)="facebookShare()" class="
      btn
      text-white
      rate-button
      bg-ratoong
      rounded-0
      mb-3
      position-relative
    ">
    Share your rating
    <div class="share-icon pointer">
      <img src="assets/images/icons/share_facebook.svg" alt="Facebook" />
    </div>
  </div>
  <a [routerLink]="['/rate-ski-resort']" (click)="closeModal('review-popUp', true)">
    <div class="
        btn
        no-hover
        color-ratoong
        rate-button
        border-ratoong
        rounded-0
        mb-4
      ">
      Rate another resort
    </div>
  </a>
</div>

<ng-template #snowFlakes let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
      <img class="rate-flakes big-flakes" src="assets/images/src/sf_100.png" alt="Ski resort rating" />
    </span>
    <img class="rate-flakes big-flakes" src="assets/images/src/sf_0.png" alt="Ski resort rating" />
  </span>
</ng-template>
