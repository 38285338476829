<div class="row">
  <div class="col-12 d-flex justify-content-center">
    <div class="w-100 ratoong-container">
      <div class="details-container">
        <app-language-switch></app-language-switch>
        <div *ngIf="questionNumber <= numberOfQuestions">
          <!-- ********** Title section ********** -->
          <div class="d-flex px-2 justify-content-start mb-4">
            <div class="d-flex flex-column w-100">
              <h1 class="title">
                <!-- Translated text with example city & location: RATE KÜHTAI, HOCHOETZ -->
              {{ 'rate.title' | translate: {cityName: currentLocation.cityName, locationName: currentLocation.name} }}
              </h1>
              <app-rate-question-tracker
                [questionNumber]="questionNumber"
                [numberOfQuestions]="numberOfQuestions"
                (changeQuestionNumber)="changeQuestionCount($event)"
              >
              </app-rate-question-tracker>
            </div>
          </div>
          <!-- ********** First group of questions are ski-groups question and rider type ********** -->
          <app-rate-estimate-group
            *ngIf="questionNumber == 1"
            [currentU]="currentU"
            [questionNumber]="questionNumber"
            [reviewsContainer]="reviewsContainer"
            (valueEmitter)="saveGroup($event)"
            (valueEmitter)="addGroup($event)"
            (onChanged)="onChanged($event)"
            (setRiderType)="setRiderType($event)"
            (changeQuestionNumber)="changeQuestionCount($event)"
          ></app-rate-estimate-group>
          <!-- ********** Second question is ski resort recommendation aka NPS ********** -->
          <app-rate-recommendation
            *ngIf="questionNumber == 2"
            [currentU]="currentU"
            [listOfQuestions]="listOfQuestions"
            [questionNumber]="questionNumber"
            [currentLocation]="currentLocation"
            [reviewsContainer]="reviewsContainer"
            (changeQuestionNumber)="changeQuestionCount($event)"
            (valueEmitter)="addExperience($event)"
          >
          </app-rate-recommendation>
          <!-- ********** Third bunches of questions according to a group of questions ********** -->
          <app-rate-groups
            *ngIf="questionNumber > 2 && questionNumber < 9"
            [listOfQuestions]="listOfQuestions"
            [questionNumber]="questionNumber"
            [reviewsContainer]="reviewsContainer"
            [currentU]="currentU"
            [riderType]="riderType"
            [imgLink]="imgLink"
            (changeQuestionNumber)="changeQuestionCount($event)"
            (valueEmitter)="addExperience($event)"
          ></app-rate-groups>
          <!-- ********** Overview USER'S scores component ********** -->
          <app-rate-overview
            *ngIf="questionNumber == 9"
            [currentU]="currentU"
            [questionNumber]="questionNumber"
            [locationNumber]="this.currentLocation.id"
            [reviewsContainer]="reviewsContainer"
            [(reviewText)]="reviewText"
            [reviewImage]="reviewImage"
            (valueEmitter)="addExperience($event)"
            (saveRatingsTest)="saveRate($event)"
            (saveGroup)="saveGroup($event)"
            [currentLocation]="currentLocation"
            (changeQuestionNumber)="changeQuestionCount($event)"
          ></app-rate-overview>
        </div>
      </div>
    </div>
  </div>
</div>
