
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Change password</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal('Reset-Pass-Modal')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="recoveryForm" (ngSubmit)="updatePassword()" class="password-form">
      <div class="form-group">
        <input type="password" class="form-control" placeholder="Current Password" formControlName="Cpassword" [ngClass]="{ 'is-invalid': submitted && f.Cpassword.errors }">
        <div *ngIf="submitted && f.Cpassword.errors" class="invalid-feedback">
          <div *ngIf="f.Cpassword.errors.required">Current Password is required</div>
        </div>
      </div>
      <div class="form-group">
        <input type="password" class="form-control" [(ngModel)]="password" placeholder="New Password" formControlName="newPass" [ngClass]="{ 'is-invalid': submitted && f.newPass.errors }">
        <div *ngIf="submitted && f.newPass.errors" class="invalid-feedback">
          <div *ngIf="f.newPass.errors.required">New Password is required</div>
          <div *ngIf="f.newPass.errors.minlength"> Must contain at least 6 characters.</div>
        </div>
      </div>
      <div class="form-group">
        <input type="password" class="form-control" placeholder="Repeat password" formControlName="newPass2" [ngClass]="{ 'is-invalid': submitted && f.newPass2.errors }">
        <div (click)="logError(f.newPass2.errors)" *ngIf="submitted && f.newPass2.errors" class="invalid-feedback">
          <div *ngIf="f.newPass2.errors.required">Please confirm new password</div>
          <div *ngIf="f.newPass2.errors.passwordsMatch"> Passwords don't match.</div>
        </div>
      </div>
      <div class="form-group">
        <button [disabled]="loading" type="submit" class="btn btn-info text-white change-button">Change</button>
      </div>
      <p *ngIf="passChange">
        <ngb-alert type="success" class="messages" [dismissible]="false">
           Password changed successfully!
        </ngb-alert>
      </p>
      <p *ngIf="isError">
        <ngb-alert type="danger" class="messages" [dismissible]="false">
          <strong>Warning!</strong> Current password is incorrect!
        </ngb-alert>
      </p>
    </form>
  </div>
