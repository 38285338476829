import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { FirestorageService } from '../../shared/services';
import { LocationFilter } from '../../shared/states/location/helper/location.filter';
import { Observable, Subscription } from 'rxjs';
import { LocationState } from '../../shared/states/location/location.state';
import { Select } from '@ngxs/store';
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from '@angular/animations';
import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Component({
  selector: 'app-home-rate-share',
  templateUrl: './home_rate_share.component.html',
  styleUrls: [
    '../home_plan_trip/home_plan_trip.component.scss',
    './home_rate_share.component.scss',
  ], // use CSS stylesheet from home-plan-trip as they are nearly identical
  animations: [
    trigger('carousel-fast', [
      transition(
        ':increment',
        group([
          style({
            transform: 'translateX(100%)',
          }),
          animate('0.5s ease'),
        ])
      ),
      transition(
        ':decrement',
        group([
          style({
            transform: 'translateX(-100%)',
          }),
          animate('0.5s ease'),
        ])
      ),
    ]),
  ],
})
export class HomeRateShareComponent implements OnInit, OnDestroy, AfterViewInit {
  // Parent component detects if mobile and passes information to this component
  @Input() mobile: boolean;
  // Parent component passes user data
  @Input() currentU: AuUser;

  // Loading checker
  loading = true;

  index = 100;
  // Holds the 2 current resorts that are in the view
  resort1: any;
  resort2: any;
  resort3: any;
  resort4: any;
  // Holds the index in the array for the 2 resorts
  index1: number;
  index2: number;
  index3: number;
  index4: number;

  animationInProgress = false;

  // For now, Eg wants to display 10 specific resorts that we have photos for. These are the IDs of the resorts.
  resortIds = [1, 9, 34, 50, 56, 77, 96, 118, 140, 255];
  // Will hold the array of resorts to display
  // resorts = resortsArray;
  resorts: any[];

  // An observable that gets the full list of locations
  @Select(LocationState.locationList) listOfLocations: Observable<any[]>;
  locationSubscription: Subscription;

  // For the auto-carousel feature
  intervalId: any;
  intervalRunning = true;

  restartAnimation: any;

  constructor(
    private locationFilter: LocationFilter,
    private storage: FirestorageService,
    private analytics: AngularFireAnalytics,
  ) {
    // Temporary function that gets a list of specific locations (from provided IDs).
    // eventually (when we have more photos/ratings) this won't be needed as we will not need to get specific locations
    this.locationSubscription = this.listOfLocations.subscribe(
      (list) => {
        // If we do not yet have any data, to continue loading. When data is returned we can set loader to false.
        if (list && list.length !== 0) {
          // Call a function that returns a filtered list using the specified resort IDs.
          // Make a copy of the returned array (so that we can mutate it).
          this.resorts = JSON.parse(
            JSON.stringify(locationFilter.filterMultipleIds(this.resortIds, list))
          );
          this.getResortImages();
          this.renderResorts(this.index);
          this.loading = false;
        }
      },
      (error) => console.log(error)
    );
  }

  ngOnInit(): void {
    // this.index1 = 0;
    // this.index2 = 1;
    // this.index3 = 2;
    // this.index4 = 3;
  }

  ngAfterViewInit(): void{
    this.startAnimation()
  }

  startAnimation(): void {
    this.intervalId = setInterval(() => {
      // Move forwards every 4 seconds
      this.changeIndex(1);
    }, 4000);
  }

  moveCarousel(direction: number) {
    // Prevent the automatic animation from restarting when the user interacts with it.
    if(this.restartAnimation){
      clearTimeout(this.restartAnimation)
    } 

    // If the automatic animation is running, stop it.
    if (this.intervalRunning) {
      clearInterval(this.intervalId);
      this.intervalRunning = false;
    }

    // Move the carousel in the direction specified by the user
    this.changeIndex(direction);

    // Set a timer to restart the animation after 5 seconds of inactivity
    this.restartAnimation = setTimeout(() => {
      this.startAnimation();
      this.intervalRunning = true;
    }, 5000)
  }

  // Direction is either +1 or -1
  changeIndex(direction: number): any {
    this.index = this.index + direction;
    this.renderResorts(this.index);
  }

  renderResorts(index: number): any {
    this.index1 = index % this.resorts.length;
    this.index2 = (index + 1) % this.resorts.length;
    this.index3 = (index + 2) % this.resorts.length;
    this.index4 = (index + 3) % this.resorts.length;
    this.resort1 = this.resorts[this.index1];
    this.resort2 = this.resorts[this.index2];
    this.resort3 = this.resorts[this.index3];
    this.resort4 = this.resorts[this.index4];
  }

  getResortImages(): any {
    for (let i = 0; i < this.resorts.length; i++) {
      this.storage
        .getImageUrl('/resorts/', this.resorts[i].id + '.jpg')
        .then((url: string) => (this.resorts[i].imageUrl = url));
      // Put in temporarily, to account for resorts that don't yet have a rating
      if (isNaN(this.resorts[i].scoreToDisplay)) {
        this.resorts[i].scoreToDisplay = 'Rate';
      }
    }
  }

  ngOnDestroy(): void{
    this.locationSubscription.unsubscribe();
  }

  recordEvent(type, arg?): any{
    this.analytics.logEvent('RAT_user_home_page_button_click', {button: type });
    if (type === 'individual resort'){
      this.analytics.logEvent('RAT_user_clicked_single_resort', { town: arg } );
    }
  }
}
