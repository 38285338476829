import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../../services';
import {AngularFireAnalytics} from "@angular/fire/analytics";
import {Router} from "@angular/router";
import {UrlNamePipe} from "../../../pipes";

@Component({
  selector: 'app-login-popup',
  templateUrl: './login_popup.component.html',
  styleUrls: ['./login_popup.component.scss'],
})
export class LoginPopupComponent implements OnInit {
  // Component trackers - used to load different content onto the popup depending on what the user selects
  loginWithEmail: boolean;
  hasAnAccount: boolean;
  doesntHaveAccount: boolean;
  userEmail: string;

  @Input() xyi;

  // Tracks if we need to redirect the user to profile page after signup
  @Input() dontRedirect = true;
  // The subtitle varies depening on where the user is prompted to login. It is therefore passed to this component as a variable.
  @Input() subtitleOne = "Log in to rate resorts and save your searches.";
  @Input() subtitleTwo = "Rate more and share the ❤️";

  constructor(private modalService: ModalService,
              private analytics: AngularFireAnalytics,
              private router: Router,
              private pipe: UrlNamePipe) {}

  ngOnInit(): any {
    // intialise compnent trackers to false (start with default window).
    this.setStartScreen();
  }

  closeModal(id: string, change: boolean): any {
    this.modalService.close(id);
    this.modalService.onChange(change);
  }

  // **** THE FOLLOWING FUNCTIONS DISPLAY THE CORRECT COMPONENT DEPENDING ON WHERE THE USER IS IN THE FLOW ****

  // Returns the user to the start login/signup screen
  setStartScreen(): any {
    // intialise compnent trackers to false (start with default window).
    this.loginWithEmail = false;
    this.hasAnAccount = false;
    this.doesntHaveAccount = false;
    this.userEmail = null;
  }

  setEnterEmailScreen(): any {
    // intialise compnent trackers to false (start with default window).
    this.loginWithEmail = true;
    this.hasAnAccount = false;
    this.doesntHaveAccount = false;
  }

  // Displays the login component
  setLoginScreen(email): any {
    this.analytics.logEvent('RAT_login', { method: 'Email' , stage: 'start' , pageName: this.pipe.transform(this.router.url) });
    // User is logging in with email and already has an account
    this.loginWithEmail = true;
    this.hasAnAccount = true;
    this.doesntHaveAccount = false;
    // Set the user email to a variable to be read by child component
    this.userEmail = email;
  }

  // Displays the signup component
  setSignupScreen(email): any {
    this.analytics.logEvent('RAT_sign_up', { method: 'Email' , stage: 'start' , pageName: this.pipe.transform(this.router.url) });

    // User is signing up with email (and doesn't have an account)
    this.loginWithEmail = true;
    this.hasAnAccount = false;
    this.doesntHaveAccount = true;
    // Set the user email to a variable to be read by child component
    this.userEmail = email;
  }
}
