import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  CreatePartner,
  GenerateLink,
  GetEmailList, GetLinks, GetPartnerRedirects, GetPartners, GetRedirects,
  GetResorts, RedirectLinkGeneration
} from './admin.action';
import {Resort} from './entities/resort';
import {AdminService} from './admin.service';
import {Link} from './entities/link';
import {Redirect} from './entities/redirect';
import {Partner} from "./entities/partner";

export class AdminStateModel {
  resortList: Resort[];
  emailList: string[];
  linkList: Link[];
  redirectList: Redirect[];
  partnerList: Partner[];
  redirectPartnerList: Redirect[];
}


@State<AdminStateModel>({
  name: 'admin',
  defaults: {
    resortList: [],
    emailList: [],
    linkList: [],
    redirectList: [],
    partnerList: [],
    redirectPartnerList: []
  }
})

@Injectable()
export class AdminState {

  constructor(private adminService: AdminService) {
  }

  @Selector()
  static getEmailList(state: AdminStateModel): any {
    return state.emailList;
  }

  @Selector()
  static getPartners(state: AdminStateModel): any {
    return state.partnerList;
  }

  @Selector()
  static getResortList(state: AdminStateModel): any {
    return state.resortList;
  }

  @Selector()
  static getLinkList(state: AdminStateModel): any {
    return state.linkList;
  }

  @Selector()
  static getRedirectList(state: AdminStateModel): any {
    return state.redirectList;
  }

  @Selector()
  static getPartnerRedirectList(state: AdminStateModel): any {
    return state.redirectPartnerList;
  }
  // Gets all admin-page from DB
  @Action(GetResorts)
  getResorts(ctx: StateContext<AdminStateModel>): any {
    return this.adminService.getAllLocations().then((result) => {
      ctx.patchState({
        resortList: result
      });
    });
  }

  // Gets all admin-page from DB
  @Action(GetEmailList)
  getEmailList(ctx: StateContext<AdminStateModel>): any {
    return this.adminService.getEmail().then((result) => {
      ctx.patchState({
        emailList: result
      });
    });
  }

  // Gets all admin-page from DB
  @Action(GetPartners)
  getPartners(ctx: StateContext<AdminStateModel>): any {
    return this.adminService.getPartners().then((result) => {
      ctx.patchState({
        partnerList: result
      });
    });
  }

  @Action(GetRedirects)
  getRedirects(ctx: StateContext<AdminStateModel>): any {
    return this.adminService.getRedirects().then((result) => {
      ctx.patchState({
        redirectList: result
      });
    });
  }

  // Gets all admin-page from DB
  @Action(GetLinks)
  getLinks(ctx: StateContext<AdminStateModel>): any {
    return this.adminService.getLinks().then((result) => {
      ctx.patchState({
        linkList: result
      });
    });
  }

  // Gets all admin-page from DB
  @Action(GenerateLink)
  generateLink({getState, patchState, setState}: StateContext<AdminStateModel>, {
    partnerID,
    resortID,
    selectedTownIds,
    selectedTownNames,
    resortName,
    roleID,
    roleName
  }: GenerateLink): any {
    return this.adminService.GenerateLink(
      partnerID,
      resortID,
      resortName,
      selectedTownIds,
      selectedTownNames,
      roleID,
      roleName
    ).then((result) => {
      const state = getState();
      const link = result.data as Link;
      patchState({
        linkList: [...state.linkList, link]
      });
    });
  }

  // Gets all admin-page from DB
  @Action(RedirectLinkGeneration)
  redirectLinkGeneration({getState, patchState, setState}: StateContext<AdminStateModel>, {
    customURL,
    value,
    purpose,
    title,
    partnerID
  }: RedirectLinkGeneration): any {
    return this.adminService.RedirectLinkGeneration(
      customURL,
      value,
      purpose,
      title,
      partnerID
    ).then((result) => {
      const state = getState();
      const link = result.data as Redirect;
      patchState({
        redirectList: [...state.redirectList, link]
      });
    });
  }

  @Action(CreatePartner)
  createPartner({getState, patchState, setState}: StateContext<AdminStateModel>, {
  resort, title, managerID, towns
  }: CreatePartner): any {
    return this.adminService.CreatePartner(
      managerID, title, resort, towns
    ).then((result) => {
      const state = getState();
      console.log(result);
    });
  }

  @Action(GetPartnerRedirects)
  getPartnerRedirects({getState, patchState, setState}: StateContext<AdminStateModel>, {
    partnerID
  }: GetPartnerRedirects): any {
    return this.adminService.GetPartnerRedirects(
      partnerID
    ).then((result) => {
      const state = getState();
      patchState({
        redirectPartnerList:  result
      });
    });
  }
}
