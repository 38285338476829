<div class="details-container w-100">
  <div class="row d-flex justify-content-between">
    <!-- LEFT COLUMN -->
    <div class="col-md-7 col-xl-6">
      <div class="d-flex pr-2 justify-content-start title-section">
        <div class="icon">
          <img src="assets/images/icons/plan_trip.svg" alt="Plan trip" />
        </div>
        <div class="d-flex flex-column">
          <!-- TITLE -->
          <h1 class="title">Ready for your next ski trip?</h1>
          <h4 class="subtitle">
            Through our partners we help you to plan and book your next ski
            trip. All from booking, accommodation, ski school, ski insurance, to
            flights, and more!
          </h4>
          <!-- BUTTON -->
          <a
            (click)="recordEvent()"
            title="Go to plan-a-trip page"
            routerLink="/plan"
            class="border-ratoong text-center button-secondary pointer"
          >
            <h5 class="color-ratoong button-text">Plan a trip</h5>
          </a>
        </div>
      </div>
    </div>
    <!-- RIGHT COLUMN -->
    <div class="col-md-5">
      <!-- IMAGE OF CHALET -->
      <img
        class="chalet-image"
        src="assets/images/home/plan-trip.jpg"
        alt="Chalet image"
      />
    </div>
  </div>
</div>
