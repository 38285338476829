import { UserSearchPreference } from './entities/preference_entities/user_search_preference';
export class LoginWithGoogle {
  static readonly type = '[Auth] LoginWithGoogle';
}
export class LoginWithFaceBook {
  static readonly type = '[Auth] LoginWithFaceBook';
}
export class LoginWithEmail {
  static readonly type = '[Auth] LoginWithEmail';
  constructor(public username: string, public password: string) {}
}

export class CreateEmail {
  static readonly type = '[Auth] CreateEmail';

  constructor(public email: string, public password: string) {}
}
export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SendResetPassword {
  static readonly type = '[Auth] SendResetPassword';
  constructor(public recoveryEmail: string) {}
}
export class ChangePassword {
  static readonly type = '[Auth] ResetPassword';
  constructor(public currentPassword: string, public newPassword: string) {}
}

export class UpdateAccount {
  static readonly type = '[Auth] UpdateAccount';
  constructor(
    public email: string,
    public firstname: string,
    public surname: string,
    public country: string,
    public gender: string,
    public instructor: string,
    public snowboarder: string,
    public skier: string,
    public birth: string,
    public tripCount: string,
    public skillLevel: string,
    public holidayType: string,
    public source: string
  ) {}
}

// 'name' = name of the search preference, 'preference' = the users search preference, 'uid' = user ID, 'existingPreferenceId' = if not undefined this will be an ID of the preference that needs to be overwritten
export class SaveCurrentPreferences {
  static readonly type = '[Auth] SaveCurrentPreferences';
  constructor(public name: string, public preferences: UserSearchPreference, public uid: any, public existingPreferenceId) {}
}

// For favouriting and bucketlist (depending on what string is passed as 'type')

export class AddFavouriteBucketlist {
  static readonly type = '[Auth] AddFavouriteBucketlist';
  constructor(
    public locationId: number,
    public uid: string,
    public type: string
  ) {}
}

export class RemoveFavouriteBucketlist {
  static readonly type = '[Auth] RemoveFavouriteBucketlist';
  constructor(
    public locationId: number,
    public uid: string,
    public type: string
  ) {}
}

export class SetUserState {
  static readonly type = '[Auth] SetUserState';
  constructor(public userObject: any) {}
}

export class SetCurrentPreferences {
  static readonly type = 'SetCurrentPreferences';
  constructor(public preferences: UserSearchPreference) {}
}



export class ResetSearchPreferencesStore {
  static readonly type = 'ResetSearchPreferencesStore';
}