import { AuUser } from '../../../shared/states/auth/entities/user_interface';
import { Observable, Subscription } from 'rxjs';
import { AuthState } from '../../../shared/states/auth/auth.state';
import { Select } from '@ngxs/store';
import { DeepLocation } from '../../../shared/states/location/entities/deepLocations';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-search-resort-title',
  templateUrl: './search_resort_title.component.html',
  styleUrls: ['./search_resort_title.component.scss'],
})
export class SearchResortTitleComponent implements OnInit, OnDestroy {
  @Input() currentLoc: DeepLocation;

  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;

  subscription: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.subscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
