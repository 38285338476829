import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop_down.component.html',
  styleUrls: ['./drop_down.component.scss'],
  animations: [
    trigger('expandList', [
      transition(':enter', [
        style({
          height: 0,
          visibility: 'hidden',
        }),
        animate('0.3s ease'),
      ]),
      transition(':leave', [
        animate(
          '0.3s ease',
          style({
            height: 0,
            visibility: 'hidden',
          })
        ),
      ]),
    ]),
  ],
})
export class DropDownComponent implements OnInit {
  // Optional title. If no title then the dropdown will display the default option or the first in the list.
  @Input() dropdownTitle?: string;
  // Default option - will be shown at the top if no title
  @Input() defaultOption?: any;
  @Input() dropdownOptions: any[]; // Each option is an object with a 'name' attribute (to display) and 'value' attribute (underlying value)

  @Output() selectedEmitter = new EventEmitter();
  dropdownExpanded: boolean;
  selectedOption: any;

  constructor() {}

  ngOnInit(): void {
    if(!this.dropdownTitle){
      if(this.defaultOption){
        this.selectedOption = this.defaultOption;
      } else {
        this.selectedOption = this.dropdownOptions[0]
      }
      this.selectedEmitter.emit(this.selectedOption); // and emit the value
    }
    this.dropdownExpanded = false;
  }

  userSelectedOption(option: any): void {
    this.selectedOption = option;
    this.dropdownExpanded = !this.dropdownExpanded; // close the dropdown
    this.selectedEmitter.emit(this.selectedOption); // and emit the value
  }
}
