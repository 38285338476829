import { UserSearchPreference } from './entities/preference_entities/user_search_preference';
import { AuUser } from './entities/user_interface';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { AuthService } from './auth.service';
import {
  AddFavouriteBucketlist,
  ChangePassword,
  CreateEmail,
  LoginWithEmail,
  LoginWithFaceBook,
  LoginWithGoogle,
  Logout,
  RemoveFavouriteBucketlist,
  SendResetPassword,
  UpdateAccount,
  SaveCurrentPreferences,
  SetUserState,
  SetCurrentPreferences,
  ResetSearchPreferencesStore
} from './auth.action';
import { Injectable } from '@angular/core';

export class AuthStateModel {
  loggedInUser: AuUser;
  currentPreferences: UserSearchPreference;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    loggedInUser: undefined,
    currentPreferences: undefined,
  },
})
@Injectable()
export class AuthState {
  constructor(private authService: AuthService) {}

  @Selector()
  static loggedInUser(state: AuthStateModel): any {
    return state.loggedInUser;
  }

  @Selector()
  static getCurrentPreferences(state: AuthStateModel): any {
    return state.currentPreferences;
  }

  @Action(LoginWithGoogle)
  loginWithGoogle({ getState, setState }: StateContext<AuthStateModel>): any {
    return this.authService
      .loginGoogle()
      .then((result) => {
        const state = getState();
        setState({
          ...state,
          loggedInUser: result,
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(LoginWithEmail)
  loginWithEmail(
    { getState, setState }: StateContext<AuthStateModel>,
    { username, password }: LoginWithEmail
  ): any {
    return this.authService
      .loginWithEmail(username, password)
      .then((result) => {
        const state = getState();
        setState({
          ...state,
          loggedInUser: result,
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(LoginWithFaceBook)
  loginWithFacebook({ getState, setState }: StateContext<AuthStateModel>): any {
    return this.authService
      .loginWithFacebook()
      .then((result) => {
        const state = getState();
        setState({
          ...state,
          loggedInUser: result,
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(Logout)
  logout({ getState, setState }: StateContext<AuthStateModel>): any {
    return this.authService
      .logout()
      .then((result) => {
        const state = getState();
        setState({
          ...state,
          loggedInUser: undefined,
          currentPreferences: undefined, // When user logs out, to set preferences to undefined
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(CreateEmail)
  CreateEmail(
    { getState, setState }: StateContext<AuthStateModel>,
    { email, password }: CreateEmail
  ): any {
    return this.authService
      .createNewUser(email, password)
      .then((result) => {
        const state = getState();
        setState({
          ...state,
          loggedInUser: result,
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(SendResetPassword)
  SendResetPassword(
    { getState, setState }: StateContext<AuthStateModel>,
    { recoveryEmail }: SendResetPassword
  ): any {
    return this.authService.sendResetPassword(recoveryEmail).catch((error) => {
      throw new Error(error.message);
    });
  }

  @Action(ChangePassword)
  ResetPassword(
    { getState, setState }: StateContext<AuthStateModel>,
    { currentPassword, newPassword }: ChangePassword
  ): any {
    return this.authService
      .changePassword(currentPassword, newPassword)
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(UpdateAccount)
  UpdateAccount(
    { getState, setState }: StateContext<AuthStateModel>,
    {
      email,
      firstname,
      surname,
      country,
      gender,
      instructor,
      snowboarder,
      skier,
      birth,
      tripCount,
      skillLevel,
      holidayType,
      source,
    }: UpdateAccount
  ): any {
    return this.authService
      .updateAccount(
        email,
        firstname,
        surname,
        country,
        gender,
        instructor,
        snowboarder,
        skier,
        birth,
        tripCount,
        skillLevel,
        holidayType,
        source
      )
      .then((result) => {
        const state = getState();
        setState({
          ...state,
          loggedInUser: result,
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  // // Action to update the users search preferences
  @Action(SaveCurrentPreferences)
  SaveCurrentPreferences(
    { getState, setState }: StateContext<AuthStateModel>,
    { name, preferences, uid, existingPreferenceId }: SaveCurrentPreferences
  ): any {
    return this.authService
      .updateSearchPreferences(name, preferences, uid, existingPreferenceId)
      .then((result) => {
        const state = getState();
        // Only save state if user is logged in
        if (state.loggedInUser) {
          const newState = { ...state.loggedInUser };
          newState.searchPreferences = result;
          setState({
            ...state,
            loggedInUser: newState,
          });
        }
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  // For favouriting and bucketlist (depending on what string is passed as 'type')

  @Action(AddFavouriteBucketlist)
  AddFavouriteBucketlist(
    { getState, setState }: StateContext<AuthStateModel>,
    { locationId, uid, type }: AddFavouriteBucketlist
  ): any {
    return this.authService
      .addFavouriteBucketlist(locationId, uid, type)
      .then(() => {
        const state = getState();
        // Only save state if user is logged in
        if (state.loggedInUser) {
          let newState = { ...state.loggedInUser };
          if (newState[type]) {
            const oldFavourites = [...newState[type]];
            oldFavourites.push(locationId);
            newState[type] = oldFavourites;
          } else {
            newState[type] = [locationId];
          }
          setState({
            ...state,
            loggedInUser: newState,
          });
        }
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(RemoveFavouriteBucketlist)
  RemoveFavouriteBucketlist(
    { getState, setState }: StateContext<AuthStateModel>,
    { locationId, uid, type }: RemoveFavouriteBucketlist
  ): any {
    return this.authService
      .removeFavouriteBucketlist(locationId, uid, type)
      .then(() => {
        const state = getState();
        // Only save state if user is logged in
        if (state.loggedInUser) {
          let newState = { ...state.loggedInUser };
          newState[type] = newState[type].filter((id) => {
            return id != locationId;
          });
          setState({
            ...state,
            loggedInUser: newState,
          });
        }
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  @Action(SetUserState)
  SetUserState(
    { getState, setState }: StateContext<AuthStateModel>,
    { userObject }: SetUserState
  ): any {
    const state = getState();
    return setState({
      ...state,
      loggedInUser: userObject,
    });
  }

  // For storing the user search preferences at the end of a search
  // Needs to be separate from the 'loggedInUser' state, since a user who isn't logged in can also search
  @Action(SetCurrentPreferences)
  SetCurrentPreferences(
    { getState, setState }: StateContext<AuthStateModel>,
    { preferences }: SetCurrentPreferences
  ): any {
    const state = getState();
    return setState({
      ...state,
      currentPreferences: preferences,
    });
  }
  
  // For backwards compatability - sets user search preference state to null if they are still using the old search preferences structure
  @Action(ResetSearchPreferencesStore)
  ResetSearchPreferencesStore(
    { getState, setState }: StateContext<AuthStateModel>,
    {}: ResetSearchPreferencesStore
  ): any {
    const state = getState();
    if (state.loggedInUser) {
      // Make copy of user object (as it is not directly editable)
      let user = { ...state.loggedInUser };
      // Set search preferences to null
      user.searchPreferences = null;
      // Update state
      return setState({
        ...state,
        loggedInUser: user,
      });
    }
  }
}
