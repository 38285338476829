import { environment } from '../../../../environments/environment';
import { defaultPreferences } from './entities/preference_entities/default_preferences';
import { UserSearchPreference } from './entities/preference_entities/user_search_preference';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
import { NameParser } from './helpers/nameParser';
import { AuUser } from './entities/user_interface';
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import { HttpClient } from '@angular/common/http';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  database = firebase.database();
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private namePar: NameParser,
    private http: HttpClient,
    private analytics: AngularFireAnalytics,
    private router: Router
  ) {}

  /**
   * Login with google and login with facebook uses the same type of idea.
   * Firstly we log user in using a popup and then we take the data and check if user is in database
   * If he is - we generate a user and return. If he is not - we register user, then generate user and return
   */
  async loginGoogle(): Promise<any> {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
    provider.addScope('https://www.googleapis.com/auth/user.addresses.read');
    provider.addScope('https://www.googleapis.com/auth/user.gender.read');
    if(this.isFacebookApp()){
      // Set localStorage so that we know there has been a sign in with redirect when returning to the site
      localStorage.setItem('signInWithRedirect', 'true');
      // Need to log the analytics event before redirect happens
      this.analytics.logEvent('RAT_login', { method: 'Google' , pageName: this.router.url});
      return this.afAuth.signInWithRedirect(provider);
    } else {
      const credential = await this.afAuth
        .signInWithPopup(provider)
        .catch((error) => {
          throw new Error(error.message);
        });
      this.analytics.logEvent('RAT_login', { method: 'Google' , pageName: this.router.url});
      return await this.checkIfUserExists(credential).catch((error) => {
        throw new Error(error.message);
      });
    }
  }

  async loginWithFacebook(): Promise<any> {
    const provider = new FacebookAuthProvider();
    provider.addScope('email');
    // provider.addScope('user_birthday');
    // provider.addScope('user_gender');
    if (this.isFacebookApp()){
      // Set localStorage so that we know there has been a sign in with redirect when returning to the site
      localStorage.setItem('signInWithRedirect', 'true');
      // Need to log the analytics event before redirect happens
      this.analytics.logEvent('RAT_login', { method: 'Facebook' , pageName: this.router.url});
      return this.afAuth.signInWithRedirect(provider);
    } else {
      const credential = await this.afAuth
        .signInWithPopup(provider)
        .catch((error) => {
          throw new Error(error.message);
        });
      this.analytics.logEvent('RAT_login', { method: 'Facebook' , pageName: this.router.url});
      return await this.checkIfUserExists(credential).catch((error) => {
        throw new Error(error.message);
      });
    }

  }

  // Returns true if user is using facebook in app browser or instagram
  isFacebookApp(): any {
    const ua = navigator.userAgent || navigator.vendor;
    return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1) || (ua.indexOf('Instagram') > -1);
  }

  public async checkIfUserExists(credential): Promise<AuUser> {
    const user = await this.afAuth.currentUser;
    const snapshot = await this.database
      .ref('/NewUsers/' + user.uid)
      .once('value');
    // User doesn't yet exist
    if (snapshot.val() == null) {
      if (credential.additionalUserInfo.providerId === 'google.com') {
        // firebase.auth().currentUser.sendEmailVerification();
        this.analytics.logEvent('RAT_sign_up', { method: 'Google' , pageName: this.router.url});
        return await this.googleUpdate(credential);
      }
      if (credential.additionalUserInfo.providerId === 'facebook.com') {
        // firebase.auth().currentUser.sendEmailVerification();
        this.analytics.logEvent('RAT_sign_up', { method: 'Facebook' , pageName: this.router.url});
        return await this.facebookUpdate(credential);
      }
    } else if (snapshot.val().hasOwnProperty('preferences')) {
      // ----------- added for new search preferences --------------
      let searchPreferences = null;
      if (snapshot.val().hasOwnProperty('searchPreferences')) {
        searchPreferences = snapshot.val().searchPreferences;
      }
      // -----------------------------------------------------------
      return await this.generateUser(
        user.uid,
        user.displayName,
        snapshot.val().email,
        snapshot.val().birth,
        snapshot.val().country,
        snapshot.val().gender, 
        snapshot.val().instructor,
        snapshot.val().preferences.snowboarder,
        snapshot.val().preferences.skier,
        snapshot.val().firstname,
        snapshot.val().surname,
        snapshot.val().preferences.tripCount,
        snapshot.val().preferences.skillLevel,
        snapshot.val().preferences.holidayType,
        snapshot.val().source,
        searchPreferences,
        snapshot.val().favourites,
        snapshot.val().bucketlist,
      );
    } else {
      // ----------- added for new search preferences --------------
      let searchPreferences = null;
      if (snapshot.val().hasOwnProperty('searchPreferences')) {
        searchPreferences = snapshot.val().searchPreferences;
      }
      // -----------------------------------------------------------
      return await this.generateUser(
        user.uid,
        user.displayName,
        snapshot.val().email,
        snapshot.val().birth,
        snapshot.val().country,
        snapshot.val().gender,
        snapshot.val().instructor,
        null,
        null,
        snapshot.val().firstname,
        snapshot.val().surname,
        null,
        null,
        null,
        snapshot.val().source,
        searchPreferences,
        snapshot.val().favourites,
        snapshot.val().bucketlist
      );
    }
  }

  private async googleUpdate(credential): Promise<any> {
    let birth = null;
    let gender = null;
    let country = null;
    let instructor = null; 
    const promise = await new Promise((resolve, reject) => {
      const apiURL =
        'https://people.googleapis.com/v1/people/' +
        credential.additionalUserInfo.profile.id +
        '?personFields=birthdays,genders,addresses&' +
        '&key=' +
        environment.firebaseConfig.apiKey +
        '&access_token=' +
        credential.credential.accessToken;
      this.http
        .get(apiURL)
        .toPromise()
        .then(
          (data: any) => {
            if (data.hasOwnProperty('birthdays')) {
              // @ts-ignore
              birth = data.birthdays[0].date.year +
                '-' +
                data.birthdays[0].date.month +
                '-' +
                data.birthdays[0].date.day;
            }
            if (data.hasOwnProperty('genders')) {
              // @ts-ignore
              gender = data.genders[0].value;
            }
            if (data.hasOwnProperty('addresses')) {
              // @ts-ignore
              country = data.addresses.country;
            }
            resolve();
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return await this.updateUserData(
      credential.additionalUserInfo.profile.given_name,
      credential.additionalUserInfo.profile.family_name,
      country,
      gender,
      instructor,
      null,
      null,
      birth,
      credential.additionalUserInfo.profile.email,
      null,
      null,
      null,
      'google'
    );
  }

  private async facebookUpdate(credential): Promise<AuUser> {
    const email = credential.additionalUserInfo.profile.email;

    return await this.updateUserData(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      email,
      null,
      null,
      null,
      'facebook'
    );
  }

  async loginWithEmail(email: string, password: string): Promise<AuUser> {
    const credential = await this.afAuth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        throw new Error(error.message);
      });
    this.analytics.logEvent('RAT_login', { stage: 'end'});
    return await this.checkIfUserExists(credential);
  }

  async createNewUser(email: string, password: string): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(email, password).then(
      (user) => {
        firebase.auth().currentUser.sendEmailVerification();
        this.analytics.logEvent('RAT_sign_up', { stage: 'end'});
        return this.updateUserData(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          email,
          null,
          null,
          null,
          'Email'
        );
      },
      (error) => {
        throw new Error(error.message);
      }
    );
  }

  async logout(): Promise<any> {
    await this.afAuth.signOut();
  }

  private async updateUserData(
    firstname?: string,
    surname?: string,
    country?: string,
    gender?: string,
    instructor?: string,
    snowboarder?: string,
    skier?: string,
    birth?: string,
    email?: string,
    tripCount?: string,
    skillLevel?: string,
    holidayType?: string,
    source?: string,
    searchPreferences?: UserSearchPreference,
    favourites?: number[],
    bucketlist?: number[]
  ): Promise<AuUser> {
    if (email == null) {
      email = 'Email';
    }
    const userId = firebase.auth().currentUser.uid;

    country = country == null ? '' : country;
    gender = gender == null ? '' : gender;
    instructor = instructor == null ? '' : instructor;
    snowboarder = snowboarder == null ? '' : snowboarder;
    skier = skier == null ? '' : skier;
    birth = birth == null ? '' : birth;
    tripCount = tripCount == null ? '' : tripCount;
    skillLevel = skillLevel == null ? '' : skillLevel;
    holidayType = holidayType == null ? '' : holidayType;
    source = source == null ? 'web' : source;
    // Create defaultPreferences for user if it doesn't yet exist, else use previous search preferences
    await firebase
      .database()
      .ref('/NewUsers/' + userId)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val() == null) {
          searchPreferences = defaultPreferences;
          favourites = [];
          bucketlist = [];
        } else {
          searchPreferences =
            snapshot.val().searchPreferences == null
              ? defaultPreferences
              : snapshot.val().searchPreferences;
          favourites = snapshot.val().favourites;
          bucketlist = snapshot.val().bucketlist;
        }
      });
    // If source is email and name has not been specified, to set as empty string
    if (source === 'Email') {
      firstname = firstname == null ? '' : firstname;
      surname = surname == null ? '' : surname;
    } else {
      // Else if source is facebook/google and name has not been saved yet, to call parseName function on their displayName
      firstname =
        firstname == null
          ? this.namePar.parseName(firebase.auth().currentUser.displayName)
              .firstName
          : firstname;
      surname =
        surname == null
          ? this.namePar.parseName(firebase.auth().currentUser.displayName)
              .lastName
          : surname;
    }
    // Firebase does not allow saving values as 'undefined'. Therefore change to null.
    favourites = favourites == undefined ? null : favourites;
    bucketlist = bucketlist == undefined ? null : bucketlist;

    const data = {
      email,
      firstname,
      surname,
      country,
      gender,
      instructor,
      birth,
      lastChanges: Date.now(),
      source,
      lastLogin: new Date(),
      uid: userId,
      searchPreferences,
      favourites,
      bucketlist,
    };
    const preferences = {
      snowboarder,
      skier,
      tripCount,
      skillLevel,
      holidayType,
    };
    // Write the new post's data simultaneously in the posts list and the user's post list.
    const user = await this.afAuth.currentUser;
    await firebase
      .database()
      .ref('/NewUsers/' + user.uid)
      .update(data)
      .catch((error) => {
        throw new Error(error.message);
      });

    this.updatePreferences(preferences, user.uid);
    return await this.generateUser(
      user.uid,
      user.displayName,
      email,
      birth,
      country,
      gender,
      instructor,
      snowboarder,
      skier,
      firstname,
      surname,
      tripCount,
      skillLevel,
      holidayType,
      source,
      searchPreferences,
      favourites,
      bucketlist
    );
  }

  /**
   * This method takes in data and generates user.
   * Used in any type of register or login.
   */
  private async generateUser(
    uid,
    name,
    email,
    birth,
    country, 
    gender,
    instructor,
    snowboarder,
    skier,
    firstname,
    surname,
    tripCount,
    skillLevel,
    holidayType,
    source,
    searchPreferences,
    favourites,
    bucketlist
  ): Promise<AuUser> {
    if (name == null) {
      name = firstname + ' ' + surname;
    }
    let favouritesArray = [];
    if (typeof favourites === 'object' && favourites !== null) {
      for (let resort in favourites) {
        favouritesArray.push(Number(resort));
      }
    }
    let bucketlistArray = [];
    if (typeof bucketlist === 'object' && bucketlist !== null) {
      for (let resort in bucketlist) {
        bucketlistArray.push(Number(resort));
      }
    }
    return {
      uid,
      name,
      email,
      birth,
      country,
      gender, 
      instructor,
      firstname,
      surname,
      source,
      preference: { snowboarder, skier, tripCount, skillLevel, holidayType },
      searchPreferences,
      favourites: favouritesArray,
      bucketlist: bucketlistArray,
    } as AuUser;
  }

  private updatePreferences(
    preferences: {
      tripCount: string;
      holidayType: string;
      skier: string;
      snowboarder: string;
      skillLevel: string;
    },
    uid: any
  ): any {
    const updates = {};
    updates['/NewUsers/' + uid + '/preferences'] = preferences;
    return firebase
      .database()
      .ref()
      .update(updates)
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  async sendResetPassword(email: string): Promise<any> {
    return await this.afAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.analytics.logEvent('RAT_user_resets_password');
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  public async changePassword(currentPassword, newPasswrd): Promise<any> {
    const user = firebase.auth().currentUser;
    const credential = await firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      currentPassword
    );

    await user.reauthenticateWithCredential(credential).catch((error) => {
      throw new Error(error.message);
    });

    await firebase
      .auth()
      .currentUser.updatePassword(newPasswrd)
      .then(() => {
        this.analytics.logEvent('RAT_user_changed_password');
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  async updateAccount(
    email: string,
    firstname: string,
    surname: string,
    country: string,
    gender: string,
    instructor: string,
    snowboarder: string,
    skier: string,
    birth: string,
    tripCount: string,
    skillLevel: string,
    holidayType: string,
    source: string
  ): Promise<AuUser> {
    this.analytics.logEvent('RAT_user_updated_profile');

    return await this.updateUserData(
      firstname,
      surname,
      country,
      gender,
      instructor,
      snowboarder,
      skier,
      birth,
      email,
      tripCount,
      skillLevel,
      holidayType,
      source
    ).catch((error) => {
      throw new Error(error.message);
    });
  }

  async updateSearchPreferences(
    name: string,
    preferences: UserSearchPreference,
    uid: any,
    existingPreferenceId: string
  ): Promise<any> {
    const date = new Date();
    const searchPreferenceObject = {};
    searchPreferenceObject['name'] = name;
    searchPreferenceObject['dateCreated'] = date.getTime();
    searchPreferenceObject['preferences'] = preferences;

    const updates = {};
    // If the ID is undefined then we create a new entry in the database. Else we overwrite the existing one.
    if(!existingPreferenceId){
      const postListRef = firebase.database().ref('/NewUsers/' + uid + '/searchPreferences').push();
      updates['/NewUsers/' + uid + '/searchPreferences/' + postListRef.key] = searchPreferenceObject;
    } else {
      updates['/NewUsers/' + uid + '/searchPreferences/' + existingPreferenceId] = searchPreferenceObject;
    }

    await firebase
      .database()
      .ref()
      .update(updates)
      .catch((error) => {
        throw new Error(error.message);
      });
      
    return await firebase
    .database()
    .ref('/NewUsers/' + uid + '/searchPreferences')
    .once('value')
    .then(snapshot => {
      return snapshot.val()
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  }


  // Set the users search preference to null - temporary function used during conversion to new search structure (with names)
  // Can be deleted after deployment
  async setSearchPreferencesToNull(uid: string): Promise<void> {
    firebase.database().ref('/NewUsers/' + uid + '/searchPreferences').set(null);
  } 

  // Checks if the provided email is already related to an account
  async checkEmailExists(email: string): Promise<any> {
    return await firebase.auth().fetchSignInMethodsForEmail(email);
  }

  // Add location to user's favourites or to bucket list (depending on 'type')
  async addFavouriteBucketlist(
    locationId: number,
    uid: string,
    type: string
  ): Promise<any> {
    const date = new Date();
    const newFavourite = {
      date: date.getTime(),
      locationId: locationId,
    };
    const updates = {};
    updates['/NewUsers/' + uid + '/' + type + '/' + locationId] = newFavourite;
    return await firebase
      .database()
      .ref()
      .update(updates)
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  // Add location to user's favourites or to bucket list (depending on 'type')
  async removeFavouriteBucketlist(
    locationId: number,
    uid: string,
    type: string
  ): Promise<any> {
    return await firebase
      .database()
      .ref('/NewUsers/' + uid + '/' + type + '/' + locationId)
      .remove()
      .catch((error) => {
        throw new Error(error.message);
      });
  }
}
