import { UserSearchPreference } from './../../../shared/states/auth/entities/preference_entities/user_search_preference';
import { LocationState } from '../../../shared/states/location/location.state';
import { Router } from '@angular/router';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-resort-list',
  templateUrl: './resort_list.component.html',
  styleUrls: ['./resort_list.component.scss'],
})
export class ResortListComponent implements OnInit, OnDestroy {
  @Select(LocationState.getSearchResults) locations: Observable<any>;
  totalLocations: any[];
  currentLocations: any[];
  sortedList: any[];
  currentPage = 1;
  itemsPerPage = 30;
  subscription: Subscription;
  // The column being sorted on
  firstPriority = '';
  // The column being sorted on as second priority (if the first prio column is equal)
  secondPriority = '';

  // The users selected parameters
  @Input() currentPreferences: UserSearchPreference;
  // Keeps track of which item's prompt is open, so we only have 1 prompt open at a time (contains id of the location)
  currentPrompt: number;

  // For the drop down box
  dropdownOptions = [
    {
      name: 'Town A-Z',
      value: 'cityName',
    },
    {
      name: 'Resort Name A-Z',
      value: 'name',
    },
    {
      name: 'Country A-Z',
      value: 'countryName',
    },
    {
      name: 'Rating High - Low',
      value: 'scoreToDisplay',
    },
    {
      name: 'Match % High - Low',
      value: 'percentageMatch',
    },
  ];

  defaultOption = this.dropdownOptions.find((option) => {
    return option.value == 'percentageMatch';
  });

  constructor(
    private analytics: AngularFireAnalytics,
    private router: Router
  ) {}

  ngOnInit(): any {
    this.subscription = this.locations.subscribe((data) => {
      if (!data || data.length === 0) {
        // If the user tries to access this page without having been through the search page, to be redirected
        this.router.navigate(['search-ski-resorts']);
      }
      this.currentPage = 1;
      const currentData = data.filter((el) => {
        return (
          el.cityName != null &&
          el.cityName !== '' &&
          el.countryName != null &&
          el.countryName !== 'undefined'
        );
      });

      this.totalLocations = currentData;
      const columnToSort = localStorage.getItem('sortColumn');
      if (!columnToSort) {
        // At the start, we sort the list using the percentage match in descending order
        this.sortList('percentageMatch');
      } else {
        this.defaultOption = this.dropdownOptions.find((option) => {
          return option.value == columnToSort;
        });
        this.sortList(columnToSort);
      }
      this.currentPage++;
    });
  }

  sortList(column): any {
    // If user has already sorted this column, to return. This can be deleted if we ever implement sorting in multiple directions again
    if (this.firstPriority === column) {
      return;
    }
    // Store the column being sorted in local storage so that the user can return to this page and the order is saved
    localStorage.setItem('sortColumn', column);
    this.firstPriority = column;
    this.sortedList = [...this.totalLocations].sort((a, b) => {
      // Second priority will be the column to sort on second if the first column items are the same.
      // Will always sort on 'percentageMatch as second priority, unless 'percentageMatch' is first prio.
      this.secondPriority =
        column === 'percentageMatch' ? 'scoreToDisplay' : 'percentageMatch';

      const res = this.compare(
        a[column],
        b[column],
        a[this.secondPriority],
        b[this.secondPriority]
      );
      return res;
    });

    this.currentLocations = this.sortedList.slice(0, this.itemsPerPage);
  }

  // Sorts by 1 or 2 columns (if first column values are equal)
  // a1 and b1 are the values in first priority column. a2 and b2 are values in second prio
  compare(a1: any, b1: any, a2: any, b2: any): number {
    // If the values in the sorted column are equal, we sort by the second priority column
    if (a1 === b1) {
      // If we sort by score or percentage match, we want to sort in descending order (i.e. highest score first)
      if (
        this.secondPriority === 'scoreToDisplay' ||
        this.secondPriority === 'percentageMatch'
      ) {
        if (isNaN(a2)) return 1;
        if (isNaN(b2)) return -1;
        if (a2 < b2) return 1;
        if (a2 > b2) return -1;
      } else {
        // Else, we want to sort in ascending order (i.e. alphabetical)
        if (a2 < b2) return -1;
        if (a2 > b2) return 1;
      }
      return 0;
    } else {
      if (
        this.firstPriority === 'scoreToDisplay' ||
        this.firstPriority === 'percentageMatch'
      ) {
        if (isNaN(a1)) return 1;
        if (isNaN(b1)) return -1;
        if (a1 < b1) return 1;
        if (a1 > b1) return -1;
      } else {
        if (a1 < b1) return -1;
        if (a1 > b1) return 1;
      }
    }
  }

  getNextLocations(): void {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = this.currentPage * this.itemsPerPage;
    this.currentLocations.push(...this.sortedList.slice(start, end));
    this.currentPage++;
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  userSelectedSort(sortBy): void {
    this.analytics.logEvent('RAT_user_ordered_by', { col: sortBy.value });
    this.sortList(sortBy.value);
  }

  setCurrentPrompt(id): void {
    this.currentPrompt = id;
  }
}
