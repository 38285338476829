import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import {PreviousRating} from './entities/previousRating';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {Review} from './entities/review';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  today = new Date();
  downloadableURL = '';
  task: AngularFireUploadTask;

  constructor(private analytics: AngularFireAnalytics,
              private fireStorage: AngularFireStorage) {
    this.database = firebase.database();
    this.today.setFullYear(this.today.getFullYear() - 1);
  }

  database: any = null;

  async saveRating(locationId: number, questionId: number, ratingNumber: number, userId: string,
                   questionName: string, questionCategory: string): Promise<any> {
    const isValidUser = await this.checkUserValidity(userId);
    if (userId !== '' && isValidUser) {
      // User exists
      const reviewId = await this.createReviewIfNotExist(locationId, userId);
      const saveRating = await firebase.functions().httpsCallable('makeRating');

      this.analytics.logEvent('RAT_rated_rating', {questionName, questionCategory});
      return await saveRating({locationId, questionId, ratingNumber, reviewId});
    }
  }

  async saveRatingTest(locationId: number, userId: string, dataContainer: any): Promise<any> {
    const isValidUser = await this.checkUserValidity(userId);
    if (userId !== '' && isValidUser) {
      // User exists
      const reviewId = await this.createReviewIfNotExist(locationId, userId);
      const saveRatingTest = await firebase.functions().httpsCallable('makeRatingTest');
      
      if(dataContainer) {
        for (let item of dataContainer) {
          let questionCategory = item.categoryId;
          let questionName = item.questionName;
          this.analytics.logEvent('RAT_rated_rating', {questionName, questionCategory});
         }
      }
      return await saveRatingTest({locationId, dataContainer, reviewId});
      }
    }

  async saveSkiGroup(locationId: number, skiGroupId: number, userId: string, skiGroupTitle: string, riderType: string): Promise<any> { 
    const isValidUser = await this.checkUserValidity(userId);
    if (userId !== '' && isValidUser) {
      // User exists
      const reviewId = await this.createReviewIfNotExist(locationId, userId);
      const saveSkiGroup = await firebase.functions().httpsCallable('saveSkiGroup');
      this.analytics.logEvent('RAT_ski_group_rating', {skiGroupTitle});
      this.analytics.logEvent('RAT_rider_type', {riderType}); 
      await saveSkiGroup({skiGroupId, reviewId ,riderType});
      return this.getUserRatingByLocation(locationId, userId);
    }
  }


  private async createReviewIfNotExist(locationId: number, userId: string): Promise<any> {
    const date = new Date();
    const val = await this.getReview(locationId, userId);
    if (val !== null) {
      // Review already exists, so we get the existing one
      return val;
    } else {
      // Review doesn't exist, so we create one
      const newPostKey = this.database.ref().child('Reviews').push().key;
      const locationRating = {reviewId: newPostKey, date: date.getTime()};

      const newUpdates = {};

      newUpdates['/Reviews/' + newPostKey] = {
        userId,
        date: date.getTime(),
        locationId,
        reviewHeadline: '',
        reviewbody: '',
        skiGroup: '',
        questionRatings: [],
        riderType: ''
      };
      newUpdates['/NewUsers/' + userId + '/reviews/' + locationId + '/' + newPostKey] = locationRating;
      newUpdates['/NewLocations/' + locationId + '/LocationReviews/' + newPostKey] = locationRating;
      await this.database
        .ref()
        .update(newUpdates)
        .catch((error) => {
          throw new Error(error.message);
        });
      return newPostKey;
    }
  }

  /**
   * Check if user exists in the database. if the does not - return false
   * @param userId
   * @private
   */
  private async checkUserValidity(userId: string): Promise<boolean> {
    const snapshot = await this.database
      .ref('/NewUsers/' + userId)
      .once('value');
    return snapshot.val() !== null;
  }

  /**
   *  Locate existing review. Return nothing if review doesnt exist
   * @param location
   * @param user
   * @private
   */
  private async getReview(location: number, user: string): Promise<any> {
    let largestValue = -9999;
    let key = null;
    const snapshot = await this.database.ref('/Reviews/').orderByChild('userId').equalTo(user).once('value');
    if (snapshot.val()) {
      Object.keys(snapshot.val()).map((personNamedIndex) => {
        const dataObject = snapshot.val()[personNamedIndex];
        if (dataObject.locationId === location) { // If review is from location
          if (dataObject.date > largestValue) { // If time flows forward = unix time gets bigger. Most recent review is largest.
            if (this.checkDate(dataObject.date)) { // If review is done < 1 year ago. We want to modify that review.
              key = personNamedIndex;
            }
            largestValue = dataObject.date; // Keep checking for more recent review.
          }
        }
      });
    }
    return key;
  }

  private checkDate(reviewDate: any): boolean {
    const date = new Date(Number(reviewDate));
    return date.getTime() > this.today.getTime();
  }

  async saveWrittenReview(locationId: number, review: string, userId: string, image: File, filePath: string): Promise<any> {
    this.downloadableURL = "" // Reset the imageUrl to empty string
    const isValidUser = await this.checkUserValidity(userId);
    let selectedPhoto = filePath ? filePath : "";
    if (userId !== '' && isValidUser) {
      const reviewId = await this.createReviewIfNotExist(locationId, userId);

      if (image) {
        if(selectedPhoto !== ""){ // PREVIOUS PHOTO EXISTS
          // TO DO - IF WE DECIDE TO DELETE OLD PHOTOS, IMPLEMENT CALL FOR IT HERE
        }
        selectedPhoto = 'service-images/src/reviews/' + reviewId + '/' + image.name;

        this.task =  this.fireStorage.upload(selectedPhoto, image);

        (await this.task).ref.getDownloadURL().then(url => {
          this.downloadableURL = url;
          const sav = firebase.functions().httpsCallable('saveReview');
          sav({reviewId, review, selectedPhoto: this.downloadableURL});
        });
        this.analytics.logEvent('RAT_user_wrote_review', { photoIncluded: 'true' });
      }else{
        const saveReview = await firebase.functions().httpsCallable('saveReview');
        await saveReview({reviewId, review, selectedPhoto});
        this.analytics.logEvent('RAT_user_wrote_review', { photoIncluded: 'false' });
      }
      return await this.getUserRatingByLocation(locationId, userId);
    } else {
      throw new Error('No');
    }
  }

  async getReviewsByLocation(locationId: number): Promise<any> {
    const child = [];
    await this.database
      .ref('/Reviews/')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((obj) => {
          if (obj.val().locationId === locationId && obj.val().reviewbody) {
            child.push({
                date: obj.val().date,
                imageURL: obj.val().reviewHeadline,
                reviewText: obj.val().reviewbody,
                previousRatings: obj.val().questionRatings,
                skiGroup: obj.val().skiGroup,
                userId: obj.val().userId,
                riderType: obj.val().riderType,
                id: obj.key
              } as Review
            );
          }
        });
      });
    return child;
  }

  getAverage(questionRatings): any {
    let sum = 0;
    let total = 0;
    if (!Array.isArray(questionRatings)) {
      questionRatings = Object.values(questionRatings);
    }
    for (const questionRating of questionRatings) {
      if (questionRating) {
        total++;
        sum += questionRating.rating;
      }
    }
    return sum / total;
  }

  async getAllReviewsFromLocation(locationId): Promise<any> {
    const reviews = await this.getReviewsByLocation(locationId);

    for (let i = 0; i < reviews.length; i++) {
      if (reviews[i].previousRatings) {
        reviews[i].generalAverage = this.getAverage(reviews[i].previousRatings);
      }
      if (reviews[i].userId) {
        await this.database
          .ref('/NewUsers/' + reviews[i].userId)
          .once('value')
          .then((snapshot) => {
            reviews[i].user = {firstname: snapshot.val().firstname, surname: snapshot.val().surname, };
          });
      }
      reviews[i].visible = false;
    }

    return reviews;
  }


  private async LatestUserReview(location: any, user: any): Promise<any> {
    let largestValue = -9999;
    let key = null;
    const snapshot = await this.database.ref('/NewUsers/' + user + '/reviews/' + location).orderByChild('date').once('value');
    if (snapshot.val()) {
      Object.keys(snapshot.val()).map((personNamedIndex) => {
        const dataObject = snapshot.val()[personNamedIndex];
        if (dataObject.date > largestValue) { // If time flows forward = unix time gets bigger. Most recent review is largest.
          if (this.checkDate(dataObject.date)) { // If review is done < 1 year ago. We want to modify that review.
            key = personNamedIndex;
          }
          largestValue = dataObject.date; // Keep checking for more recent review.
        }
      });
    }
    return key;
  }


  async getUserRatingByLocation(locationId: number, userId: string): Promise<any> {
    // ----- Initialise variables to hold information on the previous ratings and the previously selected ski group
    const getMostRecent = await this.LatestUserReview(locationId, userId);
    let skiGroup;
    let riderType;
    let list = [];
    if (getMostRecent != null) {
      // User has inserted a review date
      const reviewSnapshot = await this.database
        .ref('/Reviews/' + getMostRecent)
        .once('value');

      if (reviewSnapshot.val().skiGroup != null) {
        skiGroup = reviewSnapshot.val().skiGroup;
        riderType = reviewSnapshot.val().riderType;
      }
      if (reviewSnapshot.val().questionRatings != null) {
          Object.keys(reviewSnapshot.val().questionRatings).map((personNamedIndex) => {
          const dataObject = reviewSnapshot.val().questionRatings[personNamedIndex];
          list.push({
            questionId:  dataObject.questionId,
            rating: dataObject.rating,
          } as PreviousRating);
        });
        // -----------------
      }
      // If no reviews, set the list back to undefined (so it can be used in the application as before)
      list = list.length === 0 ? undefined : list;

      const downloadUrl = this.downloadableURL ? this.downloadableURL : reviewSnapshot.val().reviewHeadline;
      
      return {
        id: String(getMostRecent), skiGroup, previousRatings: list, reviewText: reviewSnapshot.val().reviewbody, riderType,
        imageURL: downloadUrl, date: reviewSnapshot.val().date
      } as Review;
    } else {
      this.downloadableURL = "" // if review doesn't exist, reset the image URL to an empty string
    }
  }
}
