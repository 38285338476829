import { takeUntil } from 'rxjs/operators';
import { GetAmbassadors } from '../../shared/states/ambassadors/ambassadors.action';
import { Observable, Subscription, Subject } from 'rxjs';
import {
  Select,
  Store,
  Actions,
  ofActionSuccessful,
  ofActionErrored,
} from '@ngxs/store';
import { AmbassadorsState } from '../../shared/states/ambassadors/ambassadors.state';
import { Ambassador } from '../../shared/states/ambassadors/entities/ambassador';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Component({
  selector: 'app-home-ambassadors',
  templateUrl: './home_ambassadors.component.html',
  styleUrls: [
    '../home_plan_trip/home_plan_trip.component.scss',
  ],
})
export class HomeAmbassadorsComponent implements OnInit, OnDestroy {
  // Parent component detects if mobile and passes information to this component
  @Input() mobile: boolean;

  // So we can unsubscribe when component is destroyed
  ambassadorSubscription: Subscription;

  // Holds a list of all the ambassadors (so the user can 'expand' by clicking 'MEET MORE')
  // Eventually this will not be needed as ambassadors will instead by on the about page
  ambassadorFullList: Ambassador[];
  // Told hold a filtered list of the ambassadors to display
  ambassadorShortList: Ambassador[];
  // Holds the list to render on the page (will either be the full list or the short list)
  ambassadorList: Ambassador[];

  // Tracker to check if the list of ambassadors is expanded (ambassadorFullList) or not (ambassadorShortList)
  expanded: boolean;

  private ngUnsubscribe = new Subject();

  @Select(AmbassadorsState.ambassadorList) $ambassadorList: Observable<
    Ambassador[]
  >;

  constructor(private store: Store,
              private actions$: Actions,
              private analytics: AngularFireAnalytics) {
    this.actions$
      .pipe(ofActionSuccessful(GetAmbassadors), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.ambassadorSubscription = this.$ambassadorList.subscribe((data) => {
          // Hold data of the full list of ambassadors
          this.ambassadorFullList = data;
          // A filtered list, containing just 2 ambassadors
          this.ambassadorShortList = data.filter((ambassador) => {
            return (
              ambassador.name === 'Anne-Flore Marxer' ||
              ambassador.name === 'Aymar Navarro'
            );
          });
          // By default, the list to display will be the short list
          this.ambassadorList = this.ambassadorShortList;
          // Fall-back array (if data in database changes and therefore the names do not match the above)
          if (this.ambassadorList.length !== 2) {
            this.ambassadorList = data.slice(0, 2);
          }
        });
      });
    this.actions$
      .pipe(ofActionErrored(GetAmbassadors), takeUntil(this.ngUnsubscribe))
      .subscribe((error) => {
        console.log(error);
      });
  }

  ngOnInit(): any {
    this.expanded = false;
    this.store.dispatch(new GetAmbassadors());
  }

  // Temporary function. Eventually the ambassadors will be on the 'ABOUT' page and the 'MEET MORE' button will take them there.
  updateAmbassadorList(list: Ambassador[]): void {
    this.expanded = !this.expanded;
    this.ambassadorList = list;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.ambassadorSubscription !== undefined){
      this.ambassadorSubscription.unsubscribe();
    }
  }

  recordEvent(): any{
    this.analytics.logEvent('RAT_user_home_page_button_click', {button: 'Meet more' });
  }
}
