<div class="col-12 d-flex justify-content-center xs-minimum-padding">
  <div class="w-100 ratoong-container">
    <div class="details-container w-100">
      <div class="row">
        <div class="col-lg-7 col-md-10">
          <app-title-section
            title="Ratoong ambassadors"
            subtitleOne="We are proud to introduce all our ambassadors coming from many
              different ski countries and ski resorts !"
            imagePath="assets/images/src/ambassadors/ambassadors-icon-01.svg"
          >
            <div class="amb-smile-tag">#smileinthesnow</div>
            <div class="ml-auto mr-auto ml-md-0 mr-md-0 amb-button">
              <a
                href="mailto:lovesnow@ratoong.com?subject=I want to become an ambassador of Ratoong!"
                class="btn btn-info text-white confirm-button text-uppercase"
                *ngIf="!expanded"
              >
                Become an Ambassador
              </a>
            </div>
          </app-title-section>
        </div>
      </div>
      <div class="amb-ambassadors-section-wrapper">
        <div class="row amb-ambassadors-section align-items-start">
          <div
            *ngFor="let ambassador of ambassadorFullList"
            class="col-12 col-md-6 col-lg-4 col-xl-4"
          >
            <app-home-ambassadors-item
              [mobile]="mobile"
              [ambassador]="ambassador"
            ></app-home-ambassadors-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
