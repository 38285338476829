<div class="row">
  <div class="col-12 d-flex justify-content-center xs-minimum-padding">
    <div class="w-100 ratoong-container">
      <div class="details-container">
        <div class="mb-4">
          <app-title-section title="Plan your next trip" subtitleOne="Let’s plan your trip and get you out on the slopes! Here you can plan your entire trip from rental
          equipment to hotels with our partners."
            subtitleTwo="Get special discounts with some of our partners when you book through Ratoong!"
            imagePath="/assets/images/plan/suitcase.svg">
          </app-title-section>
        </div>
        <div class="row">
          <div class="col-md-10 offset-md-1 tiles-container">
            <div class="text-uppercase">Start booking your trip with our partners!</div>
            <div class=row>
              <!--Desktop tiles-->
              <div (click)="recordEvent('checkyeti')"
                class="col-md-4 affiliate-tiles px-0 pb-3 pr-sm-2 ski-school-tile">
                <img class="w-100 h-100 pointer" src='assets/images/plan/ski_school_web.svg' alt="Ski School" />
                <div class="ski-school-overlay px-0 pb-3 pr-sm-2">
                  <a href="https://www.checkyeti.com/de/v/1/winter?utm_source=partner&utm_medium=integration&utm_campaign=ratoong&utm_content=website&utm_term=ratoong&mediumId=integration&campaignId=ratoong&contentId=website&referralId=ratoong"
                    target="_blank">
                    <img class="w-100 h-100 pointer" src='assets/images/plan/checkyeti.svg' alt="Check Yeti" />
                  </a>
                </div>
              </div>
              <div (click)="recordEvent('columbusdirect')"
                class="col-md-4 affiliate-tiles px-0 pb-3 px-sm-1 insurance-tile">
                <img class="w-100 h-100 pointer" src='assets/images/plan/buy_insurance_web.svg' alt="Insurance" />
                <div class="insurance-overlay px-0 pb-3 px-sm-1">
                  <a href="https://www.columbusdirect.com" target="_blank">
                    <img class="w-100 h-100 pointer" src='assets/images/plan/columbusdirect.svg' alt="Ski Cover" />
                  </a>
                </div>
              </div>
              <div (click)="recordEvent('bergfreunde')" class="col-md-4 affiliate-tiles px-0 pb-3 pl-sm-2 gear-tile">
                <img class="w-100 h-100 pointer" src='assets/images/plan/buy_gear_web.svg' alt="Buy Gear" />
                <div class="gear-overlay px-0 pb-3 pl-sm-2">
                  <a href="https://www.bergfreunde.eu/#Q0C16297" target="_blank">
                    <img class="w-100 h-100 pointer" src='assets/images/plan/bergfreunde.svg' alt="Berg Freunde" />
                  </a>
                </div>
              </div>
              <!--mobile tiles-->
              <div (click)="recordEvent('checkyeti')"
                class="col-sm-12 affiliate-tiles-mobile px-0 pb-3 ski-school-tile">
                <a href="https://www.checkyeti.com/de/v/1/winter?utm_source=partner&utm_medium=integration&utm_campaign=ratoong&utm_content=website&utm_term=ratoong&mediumId=integration&campaignId=ratoong&contentId=website&referralId=ratoong"
                  target="_blank">
                  <img class="w-100 pointer" src='assets/images/plan/ski_school_mobile.svg' alt="Ski School" />
                </a>
              </div>
              <div (click)="recordEvent('columbusdirect')"
                class="col-sm-12 affiliate-tiles-mobile px-0 pb-3 insurance-tile">
                <a href="https://www.columbusdirect.com" target="_blank">
                  <img class="w-100 pointer" src='assets/images/plan/buy_insurance_mobile.svg' alt="Insurance" />
                </a>
              </div>
              <div (click)="recordEvent('bergfreunde')" class="col-sm-12 affiliate-tiles-mobile px-0 pb-3 gear-tile">
                <a href="https://www.bergfreunde.eu/#Q0C16297" target="_blank">
                  <img class="w-100 pointer" src='assets/images/plan/buy_gear_mobile.svg' alt="Buy Gear" />
                </a>
              </div>
            </div>
            <br>
            <div>
              <div class="row">
                <div class="accordion-group col-12 px-1 px-sm-3" (click)="recordEvent('SkyScanner')">
                  <div class="box-opener row plan-trip-drop-down" (click)="openSkyScanner()">
                    <div class=col-md-3>
                      <img class="accordion-icon" src="assets/images/plan/hotel_flights_web.svg" alt="Hotel flights">
                      <img class="accordion-icon-mobile" src="assets/images/plan/hotel_flights_mobile.svg" alt="Hotel flights">
                    </div>
                    <div class="col-md-6 plan-trip-drop-down-text">
                      Book a hotel, flight or rent a car
                    </div>
                    <div class=col-md-3>
                      <img class="accordion-icon" src="assets/images/plan/skyscannericon.svg" alt="SkyScanner" />
                    </div>
                  </div>
                  <div class="px-0 sky-scanner-box" [class.opened]="showSkyScanner">
                    <div data-skyscanner-widget="MultiVerticalWidget" data-locale="en-GB" data-market="GB"
                      data-currency="GBP" data-origin-geo-lookup="true"></div>
                  </div>
                  <img class="sky-scanner-accordion-down-arrow" [class.opened]="showSkyScanner"
                    src="assets/images/plan/downarrow.svg" alt="SkyScanner" (click)="showSkyScanner = !showSkyScanner" />
                </div>
                <div class="accordion-group col-12 px-0 px-sm-3" (click)="recordEvent('SkiSet')">
                  <div class="box-opener row plan-trip-drop-down" (click)="showSkiSet = !showSkiSet">
                    <div class=col-md-3>
                      <img class="accordion-icon" src="assets/images/plan/rent_gear_web.svg" alt="Rent gear" />
                      <img class="accordion-icon-mobile" src="assets/images/plan/rent_gear_mobile.svg"
                        alt="Rent gear" />
                    </div>
                    <div class="col-md-6 plan-trip-drop-down-text">
                      Rent ski gear
                    </div>
                    <div class=col-md-3>
                      <img class="accordion-icon" src="assets/images/plan/skiseticon.svg" alt="Skiset" />
                    </div>
                  </div>
                  <div class="ski-set-box" [class.opened]="showSkiSet">
                    <div id="skBox"></div>
                  </div>
                  <img class="ski-set-accordion-down-arrow" [class.opened]="showSkiSet"
                    src="assets/images/plan/downarrow.svg" alt="Ski set" (click)="showSkiSet = !showSkiSet" />
                </div>
                <div class="accordion-group col-12 px-3" (click)="recordEvent('SkiLifts')">
                  <div class="box-opener row plan-trip-drop-down" (click)="showSkiLifts = !showSkiLifts">
                    <div class=col-md-3>
                      <img class="accordion-icon" src="assets/images/plan/transfer_web.svg" alt="Transfer" />
                      <img class="accordion-icon-mobile" src="assets/images/plan/transfer.svg" alt="Transfer" />
                    </div>
                    <div class="col-md-6 plan-trip-drop-down-text">
                      Coming soon - Book airport transfer
                    </div>
                    <div class=col-md-3>
                      <img class="accordion-icon" src="assets/images/plan/skilifticon.svg" alt="Skilift" />
                    </div>
                  </div>
                  <div class="box" [class.opened]="showSkiLifts">
                    <div id="skiLiftElement"></div>

                    <div>
                      <h2 class="text-uppercase">
                        Please check back later to book an airport transfer.
                      </h2>
                    </div>

                  </div>
                  <img class="ski-lifts-accordion-down-arrow" [class.opened]="showSkiLifts"
                    src="assets/images/plan/downarrow.svg" alt="Ski lifts" (click)="showSkiLifts = !showSkiLifts" />
                </div>
              </div>
            </div>
            <br>
            <div class='row'>
              <h1 class="col-12 headers text-uppercase display-4 footer-header">
                Thank you for using our Partners!
              </h1>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
