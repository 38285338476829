<div class="details-container w-100">
  <div class="row d-flex justify-content-between">
    <!-- LEFT COLUMN -->
    <div class="col-lg-5">
      <div class="d-flex pr-2 justify-content-start title-section">
        <div class="icon">
          <img src="/assets/images/icons/rate_icon.svg" alt="Rate a ski resort" />
        </div>
        <div class="d-flex flex-column">
          <!-- TITLE -->
          <h1 class="title">Share your experience</h1>
          <h4 class="subtitle mb-2">
            Rate a ski resort to help others find the best place to ski or
            snowboard for their upcoming ski holiday. 
          </h4>

          <h4 class="subtitle">
            The more you rate, the more
            <span>
              <img class="heart" src="assets/images/src/heart.svg" alt="Love" />
            </span>
            you share.
          </h4>
          <!-- BUTTON -->
          <a
            (click)="recordEvent('Go Rate')"
            title="Go to ratings page"
            [routerLink]="['/rate-ski-resort']"
            class="border-ratoong text-center mt-4 button-secondary pointer"
          >
            <h5 *ngIf="!currentU" class="color-ratoong button-text">
              Log in and rate
            </h5>
            <h5 *ngIf="currentU" class="color-ratoong button-text">Go rate</h5>
          </a>
        </div>
      </div>
    </div>
    <!-- RIGHT COLUMN -->
    <!-- Loader for right column -->
    <div *ngIf="loading" class="col-lg-6 d-flex align-items-center">
      <div class="load-container">
        <div class="loader"></div>
      </div>
    </div>
    <!-- Content for right column -->
    <div *ngIf="!mobile && !loading" class="col-lg-6 test">
      <!-- IMAGE CAROUSEL -- WEB-->
      <div class="carousel-container">
        <div class="home-carousel">
          <!-- The .start and .done can be on any of the divs - placed on the first one for simplicity -->
          <div
            [@carousel-fast]="index"
            (@carousel-fast.start)="animationInProgress = true"
            (@carousel-fast.done)="animationInProgress = false"
            class="carousel-col"
          >
            <div class="rating-container">
              <div
                class="
                  neg-margin
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-3
                "
              >
                <ngb-rating
                  [rate]="resort1.scoreToDisplay"
                  [starTemplate]="snowFlakes"
                  [max]="5"
                  [readonly]="true"
                ></ngb-rating>
                <div class="color-ratoong ml-2 rate-number">
                  {{ resort1.scoreToDisplay }}
                </div>
              </div>

              <h4 class="color-ratoong rate-text mb-4">
                {{ resort1.cityName }}, {{ resort1.countryName }}
              </h4>

              <div class="image-container">
                <img
                  class="img-fluid"
                  [src]="resort1.imageUrl"
                  alt="{{resort1.cityName}}"
                />
              </div>
            </div>
          </div>
          <!-- If we have gone out of bounds, display if index2==0. Else display if i+1 == index2 -->
          <div [@carousel-fast]="index" class="carousel-col">
            <div class="rating-container">
              <div
                class="
                  neg-margin
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-3
                "
              >
                <ngb-rating
                  [rate]="resort2.scoreToDisplay"
                  [starTemplate]="snowFlakes"
                  [max]="5"
                  [readonly]="true"
                ></ngb-rating>
                <div class="color-ratoong ml-2 rate-number">
                  {{ resort2.scoreToDisplay }}
                </div>
              </div>
              <a
                (click)="recordEvent('individual resort', resort2.cityName)"
                [routerLink]="[
                  '/search-ski-resorts/' + resort2.cityName + '/' + resort2.id
                ]"
              >
                <h4 class="color-ratoong rate-text mb-4">
                  {{ resort2.cityName }}, {{ resort2.countryName }}
                </h4>
              </a>
              <div class="image-container">
                <img
                  class="img-fluid"
                  [src]="resort2.imageUrl"
                  alt="{{resort2.cityName}}"
                />
              </div>
            </div>
          </div>
          <div [@carousel-fast]="index" class="carousel-col">
            <div class="rating-container">
              <div
                class="
                  neg-margin
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-3
                "
              >
                <ngb-rating
                  [rate]="resort3.scoreToDisplay"
                  [starTemplate]="snowFlakes"
                  [max]="5"
                  [readonly]="true"
                ></ngb-rating>
                <div class="color-ratoong ml-2 rate-number">
                  {{ resort3.scoreToDisplay }}
                </div>
              </div>
              <a
                (click)="recordEvent('individual resort', resort3.cityName)"
                [routerLink]="[
                  '/search-ski-resorts/' + resort3.cityName + '/' + resort3.id
                ]"
              >
                <h4 class="color-ratoong rate-text mb-4">
                  {{ resort3.cityName }}, {{ resort3.countryName }}
                </h4>
              </a>
              <div class="image-container">
                <img
                  class="img-fluid"
                  [src]="resort3.imageUrl"
                  alt="{{resort3.cityName}}"
                />
              </div>
            </div>
          </div>
          <div [@carousel-fast]="index" class="carousel-col">
            <div class="rating-container">
              <div
                class="
                  neg-margin
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-3
                "
              >
                <ngb-rating
                  [rate]="resort4.scoreToDisplay"
                  [starTemplate]="snowFlakes"
                  [max]="5"
                  [readonly]="true"
                ></ngb-rating>
                <div class="color-ratoong ml-2 rate-number">
                  {{ resort4.scoreToDisplay }}
                </div>
              </div>
              <h4 class="color-ratoong rate-text mb-4">
                {{ resort4.cityName }}, {{ resort4.countryName }}
              </h4>
              <div class="image-container">
                <img
                  class="img-fluid"
                  [src]="resort4.imageUrl"
                  alt="{{resort4.cityName}}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="back arrow pointer"
        (click)="moveCarousel(-1)"
        [disabled]="animationInProgress"
      >
        <img src="assets/images/src/arrow_bold.svg" alt="Back arrow pointer" />
      </button>
      <button
        class="forwards arrow pointer"
        (click)="moveCarousel(1)"
        [disabled]="animationInProgress"
      >
        <img src="assets/images/src/arrow_bold.svg" alt="Forward arrow pointer" />
      </button>
      <!-- ------------------------->
    </div>
    <!-- IMAGE CAROUSEL -- MOBILE-->
    <div *ngIf="mobile && !loading" class="col-lg-6">
      <div class="home-carousel carousel-container">
        <div *ngFor="let resort of resorts" class="carousel-col">
          <div class="rating-container">
            <div
              class="
                neg-margin
                d-flex
                align-items-center
                justify-content-start
                mb-3
              "
            >
              <ngb-rating
                [rate]="resort.scoreToDisplay"
                [starTemplate]="snowFlakes"
                [max]="5"
                [readonly]="true"
              ></ngb-rating>
              <div class="color-ratoong ml-2 rate-number">
                {{ resort.scoreToDisplay }}
              </div>
            </div>
            <a
              (click)="recordEvent('individual resort', resort.cityName)"
              [routerLink]="[
                '/search-ski-resorts/' + resort.cityName + '/' + resort.id
              ]"
            >
              <h4 class="color-ratoong rate-text mb-4">
                {{ resort.cityName }}, {{ resort.countryName }}
              </h4>
            </a>
            <div class="image-container">
              <img
                class="img-fluid"
                [src]="resort.imageUrl"
                alt="{{resort.cityName}}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #snowFlakes let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
      <img class="rate-flakes big-flakes" src="assets/images/src/sf_100.png" alt="Ski resort rating"/>
    </span>
    <img class="rate-flakes big-flakes" src="assets/images/src/sf_0.png" alt="Ski resort rating"/>
  </span>
</ng-template>
