import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rate-question-tracker',
  templateUrl: './rate_question_tracker.component.html',
  styleUrls: ['./rate_question_tracker.component.scss'],
})
export class RateQuestionTrackerComponent implements OnInit {
  @Input() questionNumber: number;
  @Input() numberOfQuestions: number;

  // An array. This is needed so that we can loop through the number of questions using ngFor
  questionsArray: number[];

  
  constructor() {}

  ngOnInit(): void {
    // Create an array which has length equal to the number of questions. Map the index (+1) to the value of each one
    // This means that the first question will correspond to [1] in the array etc.
    // basically allows us to create an *ngFor loop of length == numberOfQuestions
    this.questionsArray = Array(this.numberOfQuestions)
      .fill(0)
      .map((x, i) => i + 1);
    }
  }
