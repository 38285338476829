import { Router } from '@angular/router';
import { CreateEmail } from '../../../../states/auth/auth.action';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '../../../../services';
import { PasswordsMatch } from '../../../../states/auth/helpers/confirmed.validator';

import {
  Actions,
  Store,
  ofActionErrored,
  ofActionSuccessful,
} from '@ngxs/store';
import { empty, Subject } from 'rxjs';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import {Component, Input, OnInit, EventEmitter, Output, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-signup-email',
  templateUrl: './signup_email.component.html',
  styleUrls: ['./signup_email.component.scss'],
})
export class SignupEmailComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;
  @Input() dontRedirect: boolean;

  @Output() closeModal = new EventEmitter();

  fieldTextType = 'password';
  signupForm: FormGroup;
  errorMessage: boolean;
  isLoading = false;
  passwordStrength = '';

  private ngUnsubscribe = new Subject();
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private actions$: Actions,
    private store: Store,
    private modalService: ModalService,
    private router: Router
  ) {
    this.actions$
      .pipe(ofActionSuccessful(CreateEmail), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.isLoading = false;
        this.closeModal.emit();
        if (!this.dontRedirect) {
          this.router.navigate(['/profile']);
        }
      });
    this.actions$
      .pipe(ofActionErrored(CreateEmail), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.isLoading = false;
        this.errorMessage = true;
      });
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngOnInit(): void {
    this.signupForm = this.formBuilder.group(
      {
        password: new FormControl(
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(100),
          ])
        ),
        password2: new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
      },
      { validator: PasswordsMatch('password', 'password2') }
    );
    this.onPasswordInput();
  }

  async onSubmit(): Promise<any> {
    this.errorMessage = false;
    this.isLoading = true;
    this.submitted = true;
    // If invalid email or not entered, to display an error message
    if (this.signupForm.invalid) {
      this.isLoading = false;
      return;
    }

    this.store.dispatch(
      new CreateEmail(this.userEmail, this.signupForm.value.password)
    );
  }
  // convenience getter for easy access to form fields
  get f(): any {
    return this.signupForm.controls;
  }

  changeInputType(value: string): void {
    this.fieldTextType = value;
  }

  open(id, content): any {
    this.modalService.open(id, content);
  }

  // Validity checkers - done this way so that only one error message is ever displayed. Priority is required > pattern > match
  // Checking password is provided is highest priority
  checkRequired(): boolean {
    return this.f.password.errors && this.f.password.errors.required;
  }

  // If password has been provided, we then check the pattern
  checkMinLength(): boolean {
    return (
      !this.checkRequired() &&
      this.f.password.errors &&
      this.f.password.errors.minlength
    );
  }

  // If password has been provided and the pattern is good, we then check they match
  checkMatch(): boolean {
    return (
      !this.checkRequired() &&
      !this.checkMinLength() &&
      this.f.password2.errors &&
      (this.f.password2.errors.passwordsMatch ||
        this.f.password2.errors.required)
    );
  }

  onPasswordInput(): any {
    this.signupForm.valueChanges.subscribe((val) => {
      this.checkPasswordStrength(val.password);
    });
  }

  checkPasswordStrength(password): void {
    // Reset passwordStrength to empty string
    this.passwordStrength = '';
    // If empty password, return
    if (!password) {
      return;
    }
    // As soon as the user starts typing set the passwordStrength to 'Weak'
    this.passwordStrength = 'Weak';

    // get a score based on the variation in the password
    const score = this.scorePassword(password);
    if (score > 60) {
      this.passwordStrength = 'Average';
    }
    if (score > 80) {
      this.passwordStrength = 'Strong';
    }
  }

  // Maximum score is 3. Lowest score is 0
  // Calculates based on character variation. based on - https://stackoverflow.com/questions/948172/password-strength-meter
  scorePassword(password: string): number {
    let score = 0;

    // award every unique letter until 5 repetitions
    const letters = new Object();
    for (let i = 0; i < password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }

    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
    };

    let variationCount = 0;
    for (const check in variations) {
      variationCount += variations[check] === true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return score;
  }
}
