<div class="d-flex justify-content-end">
  <span><i (click)="closeModal()" class="close fas fa-times"></i></span>
</div>
<div class="row flex-column align-items-center py-4 px-4">
  <div class="col-sm-11 flex flex-column align-items-center">
    <h5 class="title text-center">New search parameters</h5>
    <div class="d-flex justify-content-center my-4">
      <div class="symbol d-flex justify-content-center align-items-center">
        <div class="N mt-1 text-white">N</div>
      </div>
    </div>
    <div class="d-flex">
      <img class="snow-flake mr-3" src="assets/images/search/sf_small.png" alt="New rating and search parameters announcer marker" />
      <p class="text">
        We have added new rating and search parameters, marked with an
        <span class="small-N">N</span>.
      </p>
    </div>
    <div class="d-flex">
      <img class="snow-flake mr-3" src="assets/images/search/sf_small.png" alt="Note for the ski resort searchers" />
      <p class="text">
        Until we have ratings on these they will not influence the resorts we
        list based on your search parameters.
      </p>
    </div>
    <div class="d-flex">
      <img class="snow-flake mr-3" src="assets/images/search/sf_small.png" alt="Note for the ski resort searchers" />
      <p class="text">
        As we get ratings on the new parameters they will be included in your
        search results.
      </p>
    </div>
  </div>
</div>
