declare var require: any;
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profanityFilter'
})
export class ProfanityFilterPipe implements PipeTransform {
  public readonly Filter = require('bad-words');

  transform(value: unknown, ...args: unknown[]): unknown {
    if(value){
      const filter = new this.Filter();
      return filter.clean(value);
    } else{
      return value;
    }
  }

}
