<!-- <label for="RatoongRate"></label> -->
<div class="lang-container">
  <button type="button" (mouseenter)="languageButton()" (mouseleave)="languageOffButton()" >
    <span style="height:20px; align-self: center; width:100%; ">
      <img src="{{supportedLanguages[currentLanguage].flag}}" alt="{{supportedLanguages[currentLanguage].alt}}" class="current-lang"/>
      {{supportedLanguages[currentLanguage].name | uppercase}}
    </span>
    <img src="../../../assets/images/icons/expand_more_white.svg" alt="expand-more-icon" class="languages"/>
  </button>
  <ul [ngClass] = "{ 'active' : toggleLanguage }" >
    <li *ngFor="let selectedLanguage of supportedLanguageList">
      <a [value]="selectedLanguage" (click)="useLang($event.target.value)" (mouseenter)="languageButton()" (mouseleave)="languageOffButton()" >
        <img src="{{supportedLanguages[selectedLanguage].flag}}" alt="{{supportedLanguages[selectedLanguage].alt}}" class="languages"/>
        {{supportedLanguages[selectedLanguage].name | uppercase}}</a>
    </li>
  </ul>
</div>
