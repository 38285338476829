<meta name="viewport" content="width=device-width, initial-scale=1.0" />

<div class="mb-4">
  <app-title-section title="{{ currentLoc.cityName }} RATINGS" subtitleOne="See how other users have rated their ski experiences at {{
      currentLoc.cityName
    }}, {{ currentLoc.name }}. " subtitleTwo="Is this the best resort for you?"
    imagePath="/assets/images/info/rating_icon.svg">
  </app-title-section>
</div>

<section class="d-flex justify-content-center">
  <div class="rate-section w-100">
    <!-- Overall score -->
    <div class="row">
      <div class="col-7 no-padding d-flex align-items-end">
        <div class="big-text d-flex align-items-center">
          <img src="/assets/images/info/ratings/dark_sf.svg" alt="Overall rating" class="img-fluid" />
          OVERALL RATING
        </div>
        <div class="overall-rating">{{ totalRatings }} RATINGS</div>
      </div>
      <div class="col-5 d-flex align-items-center">
        <div class="neg-margin-top">
          <ngb-rating
            [(rate)]="overall"
            [(ngModel)]="overall"
            [starTemplate]="z"
            [max]="5"
            [readonly]="true">
          </ngb-rating>
        </div>
        <div *ngIf="overall" class="overall-score">
          {{ overall }}
        </div>
      </div>
    </div>
    <!-- The Area -->
    <div class="row mt-4 mb-2">
      <div class="col-7 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img src="/assets/images/info/ratings/the_area.svg" alt="The area" class="img-fluid" />
          <div class="big-text">THE AREA</div>
        </div>
        <div class="drop-down" (click)="changeState('6')">
          <img src="/assets/images/info/ratings/button.svg" alt="The area ratings"
            [ngClass]="theArea ? 'drop-btn img-hor-vert' : 'drop-btn'" />
        </div>
      </div>
      <div class="col-5 d-flex align-items-center justify-end">
        <div class="main-rating big-text">
          {{ calculateAverage(theAreaGroup) | number: "1.2-2" }} OUT OF 5
        </div>
      </div>
    </div>
    <div [@expandSection] class="w-100" *ngIf="theArea">
      <!-- --------THE AREA INDIVIDUAL-------- -->
      <div *ngFor="let rating of theAreaGroup" class="row subcategory mt-1">
        <div class="col-7 d-flex subcategory">
          <div class="specific-rating d-flex">
            {{ rating.title | uppercase }}
            <div *ngIf="rating.isNew" class="small-N white bg-ratoong">N</div>
            <span class="asterisk ml-0 pb-2 d-inline-block" *ngIf="rating.isAsterisk">&#10033;</span>
          </div>
        </div>
        <div class="col-5 d-flex align-items-center justify-end">
          <div class="mb-3">
            <ngb-rating
              class="subcategory"
              [ngClass]="rating.filter"
              ngbTooltip="{{ rating.score }}"
              tooltipClass="really-custom"
              [(rate)]="rating.score"
              [(ngModel)]="rating.score"
              [starTemplate]="t"
              [max]="5"
              [readonly]="true"></ngb-rating>
          </div>
        </div>
      </div>
    </div>
    <!-- Ski piste -->
    <div class="row mt-4 mb-2">
      <div class="col-7 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img src="/assets/images/info/ratings/ski_piste.svg" alt="Ski piste" class="img-fluid" />
          <div class="big-text">SKI PISTE</div>
        </div>
        <div class="drop-down" (click)="changeState('1')">
          <img src="/assets/images/info/ratings/button.svg" alt="Ski piste ratings"
            [ngClass]="thePiste ? 'drop-btn img-hor-vert' : 'drop-btn'" />
        </div>
      </div>
      <div class="col-5 d-flex align-items-center justify-end">
        <div class="main-rating big-text">
          {{ calculateAverage(thePisteGroup) | number: "1.2-2" }} OUT OF 5
        </div>
      </div>
    </div>
    <div [@expandSection] class="w-100" *ngIf="thePiste">
      <!-- --------THE PISTE INDIVIDUAL-------- -->
      <div *ngFor="let rating of thePisteGroup" class="row subcategory mt-1">
        <div class="col-7 d-flex subcategory">
          <div class="specific-rating d-flex">
            {{ rating.title | uppercase }}
            <div *ngIf="rating.isNew" class="small-N white bg-ratoong">N</div>
            <span class="asterisk ml-0 pb-2 d-inline-block" *ngIf="rating.isAsterisk">&#10033;</span>
          </div>
        </div>
        <div class="col-5 d-flex align-items-center justify-end">
          <div class="mb-3">
            <ngb-rating
              class="subcategory"
              [ngClass]="rating.filter"
              ngbTooltip="{{ rating.score }}"
              tooltipClass="really-custom"
              [(rate)]="rating.score"
              [(ngModel)]="rating.score"
              [starTemplate]="t"
              [max]="5"
              [readonly]="true"></ngb-rating>
          </div>
        </div>
      </div>
    </div>

    <!-- Snow park -->
    <div class="row mt-4 mb-2">
      <div class="col-7 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img src="/assets/images/info/ratings/snowpark.svg" alt="Snowpark" class="img-fluid" />
          <div class="big-text">SNOW PARK</div>
        </div>
        <div class="drop-down" (click)="changeState('2')">
          <img
            src="/assets/images/info/ratings/button.svg"
            alt="Snow park ratings"
            [ngClass]="thePark ? 'drop-btn img-hor-vert' : 'drop-btn'" />
        </div>
      </div>
      <div class="col-5 d-flex align-items-center justify-end">
        <div class="main-rating big-text">
          {{ calculateAverage(theParkGroup) | number: "1.2-2" }} OUT OF 5
        </div>
      </div>
    </div>
    <div [@expandSection] class="w-100" *ngIf="thePark">
      <!-- --------SNOW PARK INDIVIDUAL-------- -->
      <div *ngFor="let rating of theParkGroup" class="row subcategory mt-1">
        <div class="col-7 d-flex subcategory">
          <div class="specific-rating d-flex">
            {{ rating.title | uppercase }}
            <div *ngIf="rating.isNew" class="small-N white bg-ratoong">N</div>
            <span class="asterisk ml-0 pb-2 d-inline-block" *ngIf="rating.isAsterisk">&#10033;</span>
          </div>
        </div>
        <div class="col-5 d-flex align-items-center justify-end">
          <div class="mb-3">
            <ngb-rating
              class="subcategory"
              [ngClass]="rating.filter"
              ngbTooltip="{{ rating.score }}"
              tooltipClass="really-custom"
              [(rate)]="rating.score"
              [(ngModel)]="rating.score"
              [starTemplate]="t"
              [max]="5"
              [readonly]="true"></ngb-rating>
          </div>
        </div>
      </div>
    </div>

    <!-- The Lifts -->
    <div class="row mt-4 mb-2">
      <div class="col-7 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img src="/assets/images/info/ratings/lifts.svg" alt="Lifts" class="img-fluid" />
          <div class="big-text">LIFTS</div>
        </div>
        <div class="drop-down" (click)="changeState('3')">
          <img src="/assets/images/info/ratings/button.svg" alt="Lifts ratings"
            [ngClass]="theLift ? 'drop-btn img-hor-vert' : 'drop-btn'" />
        </div>
      </div>
      <div class="col-5 d-flex align-items-center justify-end">
        <div class="main-rating big-text">
          {{ calculateAverage(theLiftsGroup) | number: "1.2-2" }} OUT OF 5
        </div>
      </div>
    </div>
    <div [@expandSection] class="w-100" *ngIf="theLift">
      <!-- --------THE LIFTS INDIVIDUAL-------- -->
      <div *ngFor="let rating of theLiftsGroup" class="row subcategory mt-1">
        <div class="col-7 d-flex subcategory">
          <div class="specific-rating d-flex">
            {{ rating.title | uppercase }}
            <div *ngIf="rating.isNew" class="small-N white bg-ratoong">N</div>
            <span class="asterisk ml-0 pb-2 d-inline-block" *ngIf="rating.isAsterisk">&#10033;</span>
          </div>
        </div>
        <div class="col-5 d-flex align-items-center justify-end">
          <div class="mb-3">
            <ngb-rating
              class="subcategory"
              [ngClass]="rating.filter"
               ngbTooltip="{{ rating.score }}"
              tooltipClass="really-custom"
              [(rate)]="rating.score"
              [(ngModel)]="rating.score"
              [starTemplate]="t"
              [max]="5"
              [readonly]="true"></ngb-rating>
          </div>
        </div>
      </div>
    </div>

    <!-- The Town -->
    <div class="row mt-4 mb-2">
      <div class="col-7 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img src="/assets/images/info/ratings/town.svg" alt="Town" class="img-fluid" />
          <div class="big-text">TOWN</div>
        </div>
        <div class="drop-down" (click)="changeState('4')">
          <img src="/assets/images/info/ratings/button.svg" alt="Town ratings"
            [ngClass]="theTown ? 'drop-btn img-hor-vert' : 'drop-btn'" />
        </div>
      </div>
      <div class="col-5 d-flex align-items-center justify-end">
        <div class="main-rating big-text">
          {{ calculateAverage(theTownGroup) | number: "1.2-2" }} OUT OF 5
        </div>
      </div>
    </div>
    <div [@expandSection] class="w-100" *ngIf="theTown">
      <!-- --------THE TOWN INDIVIDUAL-------- -->
      <div *ngFor="let rating of theTownGroup" class="row subcategory mt-1">
        <div class="col-7 d-flex subcategory">
          <div class="specific-rating d-flex">
            {{ rating.title | uppercase }}
            <div *ngIf="rating.isNew" class="small-N white bg-ratoong">N</div>
            <span class="asterisk ml-0 pb-2 d-inline-block" *ngIf="rating.isAsterisk">&#10033;</span>
          </div>
        </div>
        <div class="col-5 d-flex align-items-center justify-end">
          <div class="mb-3">
            <ngb-rating
              class="subcategory"
              [ngClass]="rating.filter"
              ngbTooltip="{{ rating.score }}"
              tooltipClass="really-custom"
              [(rate)]="rating.score"
              [(ngModel)]="rating.score"
              [starTemplate]="t"
              [max]="5"
              [readonly]="true"></ngb-rating>
          </div>
        </div>
      </div>
    </div>

    <!-- Sustainability -->
    <div class="row mt-4 mb-2">
      <div class="col-7 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img src="/assets/images/info/ratings/sustainability.svg" alt="Sustainability" class="img-fluid" />
          <div class="big-text">SUSTAINABILITY</div>
        </div>
        <div class="drop-down" (click)="changeState('5')">
          <img src="/assets/images/info/ratings/button.svg" alt="Sustainability ratings"
            [ngClass]="theSustainability ? 'drop-btn img-hor-vert' : 'drop-btn'" />
        </div>
      </div>
      <div class="col-5 d-flex align-items-center justify-end">
        <div class="main-rating big-text">
          {{ calculateAverage(theSustainabilityGroup) | number: "1.2-2" }} OUT
          OF 5
        </div>
      </div>
    </div>
    <div [@expandSection] class="w-100" *ngIf="theSustainability">
      <!-- --------SUSTAINABILITY INDIVIDUAL-------- -->
      <div *ngFor="let rating of theSustainabilityGroup" class="row subcategory mt-1">
        <div class="col-7 d-flex subcategory">
          <div class="specific-rating d-flex">
            {{ rating.title | uppercase }}
            <div *ngIf="rating.isNew" class="small-N white bg-ratoong">N</div>
            <span class="asterisk ml-0 pb-2 d-inline-block" *ngIf="rating.isAsterisk">&#10033;</span>
          </div>
        </div>
        <div class="col-5 d-flex align-items-center justify-end">
          <div class="mb-3">
            <ngb-rating
              class="subcategory"
              [ngClass]="rating.filter"
              ngbTooltip="{{ rating.score }}"
              tooltipClass="really-custom"
              [(rate)]="rating.score"
              [(ngModel)]="rating.score"
              [starTemplate]="t"
              [max]="5"
              [readonly]="true"></ngb-rating>
          </div>
        </div>
      </div>
    </div>

    <!-- Check Yeti -->
    <div class="row mt-4 mb-2">
      <div class="col-7 d-flex align-items-center">
        <img src="/assets/images/info/ratings/checkyeti.svg" alt="Ski schools" class="img-fluid" />
        <div class="big-text">
          SKI SCHOOLS
          <span
            [ngbTooltip]="infoToolTip"
            placement="top"
            triggers="click"
            [autoClose]="'outside'"
            tooltipClass="lifts-tooltip">
            <img class="info pointer" src="assets/images/info/info_icon_01.svg" alt="Ski schools ratings" />
          </span>
        </div>
      </div>
      <div class="col-5 d-flex align-items-center justify-end">
        <a (click)='logEvent()'
          href="https://www.checkyeti.com/de/v/1/winter?utm_source=partner&utm_medium=integration&utm_campaign=ratoong&utm_content=website&utm_term=ratoong&mediumId=integration&campaignId=ratoong&contentId=website&referralId=ratoong"
          target="_blank">
          <img src="/assets/images/info/ratings/checkyeti-logo.png" alt="Checkyeti image" class="sponsor-img" />
        </a>
      </div>
    </div>

    <div class="d-flex mt-5">
      <div class="parameter-N">N</div>
      <p class="ml-5 small-text">
        New parameters that may not have as much data
      </p>
    </div>
    <div class="d-flex position-relative">
      <div class="parameter-X">
        <div>&#10033;</div>
      </div>
      <p class="ml-5 small-text">
        Parameters that are ratings but not included in the Search
      </p>
    </div>

    <div class="go-rate mt-4">
      <div class="small-text mr-3 small-center">
        Have you been to {{ currentLoc.cityName }}, {{ currentLoc.name }} but
        haven't rated yet?
      </div>
      <div (click)="goRate(openLogin)" title="Go and rate this resort"
        class="border-ratoong text-center button-secondary pointer">
        <h5 class="color-ratoong button-text">GO RATE</h5>
      </div>
    </div>
  </div>
</section>

<ng-template #t let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
      <img class="rate-flakes" src="assets/images/src/sf_100.png" alt="Ski resort rating" />
    </span>
    <img class="rate-flakes" src="assets/images/src/sf_0.png" alt="Ski resort rating" />
  </span>
</ng-template>

<ng-template #z let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
      <img class="big-flakes" src="assets/images/src/sf_100.png" alt="Ski resort rating" />
    </span>
    <img class="big-flakes" src="assets/images/src/sf_0.png" alt="Ski resort rating" />
  </span>
</ng-template>

<ng-template #openLogin>
  <app-login-popup subtitleOne="Log in to rate resorts and save your searches." subtitleTwo="Rate more and share the ❤️"
    [dontRedirect]="true">
  </app-login-popup>
</ng-template>

<ng-template #infoToolTip>
  <div class="d-flex align-items-center p-3">
    <img src="/assets/images/info/ratings/checkyeti.svg" alt="Check yeti" class="tooltip-image mr-3" />
    <div class="tooltip-text">
      For ratings on ski schools go to our partner <a (click)='logEvent()' class="tooltip-text"
        href="https://www.checkyeti.com/de/v/1/winter?utm_source=partner&utm_medium=integration&utm_campaign=ratoong&utm_content=website&utm_term=ratoong&mediumId=integration&campaignId=ratoong&contentId=website&referralId=ratoong"
        target="_blank" title="Go to the Check Yeti website"><u>Check Yeti</u></a>
    </div>
  </div>
</ng-template>
