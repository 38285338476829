import {Injectable} from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})

export class AdminStatisticsService {
  database: any = null;
  today = new Date();

  constructor() {
    this.database = firebase.database();
  }

  async getAllStatistics(): Promise<any>  {
    let totalUsers = 0;
    let totalRegisteredUsers = 0;
    let totalUsersThatFilledOutTheirProfile = 0;

    let finalObject: object;
    const snapshot = await firebase.database().ref('/NewUsers/').once('value');
    if (snapshot.val() !== null) { // If there is a result
      await snapshot.forEach((child: any) => {
        totalUsers = totalUsers + 1;
        const email = child.val().email;
        if (email && email.includes('@')){
          totalRegisteredUsers = totalRegisteredUsers + 1;
        }

        if (child.val().hasOwnProperty('preferences')) {
          totalUsersThatFilledOutTheirProfile = totalUsersThatFilledOutTheirProfile + 1;
        }
      });
      finalObject = {
        users: totalUsers ,
        registeredUsers: totalRegisteredUsers,
        userProfiles: totalUsersThatFilledOutTheirProfile,
        country: await this.mapData(snapshot, 'Country'),
        gender: await this.mapData(snapshot, 'Gender'),
        type: await this.mapData(snapshot, 'Holiday Type'),
        skier: await this.mapData(snapshot, 'Skier'),
        skill: await this.mapData(snapshot, 'Skill Level'),
        snowboarder: await this.mapData(snapshot, 'Snowboarder'),
        travelCount: await this.mapData(snapshot, 'Trip Count'),
        age: await this.mapData(snapshot, 'Age')
      };
    }else {
      return Promise.resolve('Unknown error has Occurred');
    }
    return Promise.resolve(finalObject);
  }

  mapData(arr, type): any{
    const map = new Map();
    arr.forEach((obj: any) => {
      let currentVariable;
      switch (type) {
        case 'Country': {
          if (obj.val().hasOwnProperty('country')) {
            if (obj.val().country !== '') {
              currentVariable = obj.val().country;
            }
          }
          break;
        }
        case 'Gender': {
          if (obj.val().hasOwnProperty('gender')) {
            if (obj.val().gender !== '') {
              currentVariable = obj.val().gender;
            }
          }
          break;
        }
        case 'Holiday Type': {
          if (obj.val().hasOwnProperty('preferences')) {
            if (obj.val().preferences.holidayType !== '') {
              currentVariable = obj.val().preferences.holidayType;
            }
          }
          break;
        }
        case 'Skier': {
          if (obj.val().hasOwnProperty('preferences')) {
            if (obj.val().preferences.skier !== '') {
              currentVariable = obj.val().preferences.skier;
            }
          }
          break;
        }
        case 'Skill Level': {
          if (obj.val().hasOwnProperty('preferences')) {
            if (obj.val().preferences.skillLevel !== '') {
              currentVariable = obj.val().preferences.skillLevel;
            }
          }
          break;
        }
        case 'Snowboarder': {
          if (obj.val().hasOwnProperty('preferences')) {
            if (obj.val().preferences.snowboarder !== '') {
              currentVariable = obj.val().preferences.snowboarder;
            }
          }
          break;
        }
        case 'Trip Count': {
          if (obj.val().hasOwnProperty('preferences')) {
            if (obj.val().preferences.tripCount !== '') {
              currentVariable = obj.val().preferences.tripCount;
            }
          }
          break;
        }
        case 'Age': {
          if (obj.val().hasOwnProperty('preferences')) {
            if (obj.val().preferences.tripCount !== '') {
              currentVariable = 'None';
            }
          }
          if (obj.val().birth !== '') {
            const date = new Date(obj.val().birth);
            currentVariable = this.today.getFullYear() - date.getFullYear();
            if (isNaN(currentVariable) || currentVariable === 0){
              currentVariable = 'None';
            }
          }
          break;
        }
      }

      if (map.has(currentVariable)){
        const currentCount = map.get(currentVariable);
        map.set(currentVariable, currentCount + 1);
      }else if ((currentVariable === undefined || currentVariable === 'none' || currentVariable === '' || currentVariable === 'default' || currentVariable === 'Default')){
        if (map.has('None')){
          const currentCount = map.get('None');
          map.set('None', currentCount + 1);
        }else{
          map.set('None', 1);
        }
      }else{
        map.set(currentVariable, 1);
      }
    });
    return Array.from(map.entries());
  }

}
