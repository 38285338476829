<div *ngIf="reviews && reviews.length > 0" class="details-container">
  <div class="row">
    <!-- TITLE -->
    <div class="col-md-8">
      <div class="mb-3">
        <app-title-section title="Reviews of {{ currentLoc.cityName }}, {{ currentLoc.name }}"
          subtitleOne="Read about other snowlovers experience and see their ratings."
          imagePath="/assets/images/src/review_icon.svg">
          <h4 class="subtitle">
            Remember by rating and reviewing the ski resorts you have been to, you share
            <span>
              <img class="heart" src="assets/images/src/heart.svg" alt="Heart of snow lovers" />
            </span>
            with the ski community.
          </h4>
        </app-title-section>
      </div>
    </div>
  </div>
  <!-- LOADER -->
  <div *ngIf="loading" class="col-lg-12 d-flex align-items-center">
    <div class="load-container">
      <div class="loader"></div>
    </div>
  </div>
  <div *ngIf="!loading" class="col-lg-12 mt-3 test">
    <!-- IMAGE CAROUSEL -->
    <div class="carousel-container">
      <div class="home-carousel">
        <div *ngIf="reviews" [@carousel-fast]="index" (@carousel-fast.start)="animationInProgress = true"
          (@carousel-fast.done)="animationInProgress = false" class="carousel-col px-0">
          <div class="rating-container" *ngFor="let review of currentReviews">
            <app-single-review *ngIf="!mobile" [review]="review" [currentLoc]="currentLoc"
              (imageModalEmitter)="openImageModal($event)"></app-single-review>
            <app-single-review *ngIf="mobile" (panleft)="moveCarousel(1)" (panright)="moveCarousel(-1)"
              [review]="review" [currentLoc]="currentLoc" (imageModalEmitter)="openImageModal($event)">
            </app-single-review>
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="index > 1" class="back arrow pointer" (click)="moveCarousel(-1)" [disabled]="animationInProgress">
      <img src="assets/images/src/arrow_bold.svg" alt="Back arrow pointer" />
    </button>
    <button *ngIf="index * this.itemsPerPage < this.length" class="forwards arrow pointer" (click)="moveCarousel(1)"
      [disabled]="animationInProgress">
      <img src="assets/images/src/arrow_bold.svg" alt="Forward arrow pointer" />
    </button>
  </div>
</div>

<!-- Image modal -->
<div class="modal-background" [ngClass]="{ 'display-modal': displayModal }">
  <span><i (click)="closeImageModal()" class="close-modal fas fa-times"></i></span>
  <img src="{{ modalImage }}" class="modal-content" alt="Modal content" />
  <div class="modal-caption">{{ modalText | profanityFilter }}</div>
</div>

<ng-template #openLogin>
  <app-login-popup [dontRedirect]="true"></app-login-popup>
</ng-template>
