import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, ofActionErrored, ofActionSuccessful, Select, Store} from '@ngxs/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegisterAdmin} from '../admin-shared/states/admin-auth/admin-auth.action';
import {first, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {AdminAuthState} from '../admin-shared/states/admin-auth/admin-auth.state';
import {AdminsUsers} from '../admin-shared/states/admin-auth/entities/AdminUser';

@Component({
  selector: 'app-admin-sign-up',
  templateUrl: './admin-sign-up.component.html',
  styleUrls: ['./admin-sign-up.component.scss']
})
export class AdminSignUpComponent implements OnInit, OnDestroy {

  public referralID: string;

  isLoading = false;
  private ngUnsubscribe = new Subject();

  @Select(AdminAuthState.getAdminAuth) currentUser: Observable<AdminsUsers>;
  currentU: AdminsUsers;

  errorMsg = false;
  alreadyExists = false;
  loginDetail: FormGroup;
  showPwd = false;

  fieldTextType: boolean;
  submitted = false;

  constructor(    private store: Store,
                  private router: Router,
                  private route: ActivatedRoute,
                  private actions$: Actions,
                  private fb: FormBuilder) {
    this.route.params.subscribe((params) => {
    this.referralID = String(params.referralID);

    if (!this.referralID) {
      this.router.navigate(['/404']);
    }
  });

    this.currentUser.subscribe(
      (data) => {
        if (data) {
          this.router.navigate(['/partners/overview/summary']);
        }
      });

    this.actions$.pipe(ofActionSuccessful(RegisterAdmin),
      takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.router.navigate(['/partners']);
      this.isLoading = false;
    });
  }

  ngOnInit(): any {
    this.loginDetail = this.fb.group({
      email : ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(200)])],
      pwd : ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(20)])],
    });
  }

  ngOnDestroy(): any{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get f(): any { return this.loginDetail.controls; }

  signIn(): any{
    this.submitted = true;
    this.alreadyExists = false;
    if (this.loginDetail.invalid) {
      return;
    }
    this.isLoading = true;
    this.store.dispatch(new RegisterAdmin(this.referralID, this.loginDetail.value.email, this.loginDetail.value.pwd))
      .pipe(first())
      .subscribe(
        data => {

        },
        error => {
          console.log(error);
          if ('already exists') {
            this.alreadyExists = true;
          }else{
            this.errorMsg = true;
          }
          this.isLoading = false;
        });
  }

  toggleFieldTextType(): any {
    this.fieldTextType = !this.fieldTextType;
  }
}
