<div class="space-maintainer">
  <div class="dropdown-container">
    <div
      (click)="dropdownExpanded = !dropdownExpanded"
      class="bg-white d-flex justify-content-between align-items-center"
    >
      <p *ngIf="dropdownTitle && !selectedOption" class="dropdown-title mr-5">
        {{ dropdownTitle }}
      </p>
      <p *ngIf="!dropdownTitle || selectedOption" class="dropdown-title mr-5">
        {{ selectedOption.name }}
      </p>
      <i class="fas fa-chevron-down icon-size"></i>
    </div>
    <div class="underneath" [@expandList] *ngIf="dropdownExpanded">
      <!-- Loop through each of the options. Only display the options that aren't selected -->
      <ng-container *ngFor="let option of dropdownOptions">
        <p
          (click)="userSelectedOption(option)"
          class="dropdown-item"
          *ngIf="!selectedOption || selectedOption.value != option.value"
        >
          {{ option.name }}
        </p>
      </ng-container>
    </div>
  </div>
</div>
