import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { AuthState } from '../../shared/states/auth/auth.state';
import { SubCategory } from '../../shared/states/preference/entities/subCategory';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { PreferenceState } from '../../shared/states/preference/preference.state';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rate-groups',
  templateUrl: './rate_groups.component.html',
  styleUrls: ['./rate_groups.component.scss'],
})
export class RateGroups implements OnInit {
  @Select(PreferenceState.listOfSubRatings) questions: Observable<
    SubCategory[]
  >;
  listOfQuestions: SubCategory[];

  //Get current user details
  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;

  // Current question tracker
  @Input() questionNumber: number;
  @Output() changeQuestionNumber = new EventEmitter();

  // Emits the users chosen questions reviews data to the parent (rate-estimate-container)
  @Output() valueEmitter = new EventEmitter();

  // Current type of rider (if user choose this parameter it can be true/false. By default this value is undefined)
  @Input() riderType: string;

  //Reviews container
  @Input() reviewsContainer;

  //Loading tracker
  isLoading: boolean;

  //Create a core object, use it for grouping questions, it is a kind of hash table
  questionGroups = {
    1: {
      title: 'thePisteGroup',
      groupName: 'SKI PISTE',
      questionsList: [],
      imgLink: 'ski_piste.svg',
      order: 2,
    },
    2: {
      title: 'theParkGroup',
      groupName: 'SNOW PARK',
      questionsList: [],
      imgLink: 'snowpark.svg',
      order: 3,
    },
    4: {
      title: 'theLiftsGroup',
      groupName: 'LIFTS',
      questionsList: [],
      imgLink: 'lifts.svg',
      order: 4,
    },
    5: {
      title: 'theTownGroup',
      groupName: 'TOWN',
      questionsList: [],
      imgLink: 'town.svg',
      order: 5,
    },
    6: {
      title: 'theSustainabilityGroup',
      groupName: 'SUSTAINABILITY',
      questionsList: [],
      imgLink: 'sustainability.svg',
      order: 6,
    },
    7: {
      title: 'theAreaGroup',
      groupName: 'THE AREA',
      questionsList: [],
      imgLink: 'the_area.svg',
      order: 1,
    },
  };

  //Img group title adress holder
  imgQuestionsGroupUrl = '/assets/images/info/ratings/';
  questionImgLink;

  // If rider type is skier we will hide questions related with snowboarding
  // defined as array in case other questions should be hidden in future
  snowboardQuestions = [23];
  kidsQuestions = [22];

  questionGroupsOrderedList = {};

  currentRate: number = 0;

  asteriskQuestions = [6, 32];
  modalSubscription: Subscription;

  constructor(
    private analytics: AngularFireAnalytics,
    private translateService: TranslateService
    ) {}

  ngOnInit(): any {
    this.questions.subscribe((data) => {
      this.listOfQuestions = data;
    });
    if (this.reviewsContainer.riderType == 'skier') {
      this.listOfQuestions = this.listOfQuestions.filter(
        (question) => !this.snowboardQuestions.includes(question.id)
      );
    }
    if (
      this.reviewsContainer.skiGroup == 1 ||
      this.reviewsContainer.skiGroup == 2
    ) {
      this.listOfQuestions = this.listOfQuestions.filter(
        (question) => !this.kidsQuestions.includes(question.id)
      );
    }
    if (this.listOfQuestions) {
      this.ratingsGroupConstructor();
      this.questionGroupsConstructor();
      this.questionImgLink =
        this.questionGroupsOrderedList[this.currentQuestionNumber()].imgLink;
      this.imgQuestionsGroupUrl =
        this.imgQuestionsGroupUrl + this.questionImgLink;
    }
  }

  // Get review values and push inside reviewsContainer
  triggerSaveRate(id, rate, groupID, question, categoryId): any {
    this.updateValue(id, rate, groupID, question, categoryId);
  }

  updateValue(id, rate, groupID, question, categoryId): any {
    let previous;
    let current = this.reviewsContainer.reviews.find(
      (question) => question.questionId == id
    );
    if (this.reviewsContainer.previousReviews) {
      previous = this.reviewsContainer.previousReviews.find(
        (question) => question.questionId == id
      );
    }
    let updatedRate;
    if (previous) {
      previous.rating == rate ? (updatedRate = 0) : (updatedRate = rate);
    }
    if (current) {
      current.tmpValue == rate ? (updatedRate = 0) : (updatedRate = rate);
    }

    this.valueEmitter.emit({
      questionId: id,
      rating: this.getUpdatedRate(updatedRate, rate),
      questionName: question,
      categoryId: categoryId,
      tmpValue: this.getUpdatedRate(updatedRate, rate),
    });
    this.questionGroupsOrderedList[groupID].questionsList.find(
      (element) => element.id == id
    ).rate = this.getUpdatedRate(updatedRate, rate);
  }

  questionGroupsConstructor(): any {
    Object.keys(this.questionGroups).forEach((key) => {
      this.questionGroupsOrderedList[this.questionGroups[key].order] = {};
      this.questionGroupsOrderedList[this.questionGroups[key].order].groupName =
        this.questionGroups[key].groupName;
      this.questionGroupsOrderedList[
        this.questionGroups[key].order
      ].questionsList = this.questionGroups[key].questionsList;
      this.questionGroupsOrderedList[this.questionGroups[key].order].imgLink =
        this.questionGroups[key].imgLink;
    });

    //Remove off-piste questions and add it the end of array of quesiton groupId = 2
    let offPistQuestions = this.questionGroupsOrderedList[
      '2'
    ].questionsList.slice(0, 2);
    this.questionGroupsOrderedList['2'].questionsList.splice(0, 2);
    this.questionGroupsOrderedList['2'].questionsList =
      this.questionGroupsOrderedList['2'].questionsList.concat(
        offPistQuestions
      );
  }

  ratingsGroupConstructor(): void {
    this.listOfQuestions.forEach((item) => {
      if (item.categoryId !== 0 && item.categoryId !== 9) {
        let previousScore, currentScore, previousRating, currentRating;
        if (this.reviewsContainer.previousReviews) {
          previousScore = this.reviewsContainer.previousReviews.find(
            (element) => element.questionId === item.id
          );
          previousRating = previousScore ? previousScore.rating : 0;
        }

        if (this.reviewsContainer.reviews) {
          currentScore = this.reviewsContainer.reviews.find(
            (element) => element.questionId === item.id
          );
          currentRating = currentScore ? currentScore.rating : false;
        }

        this.questionGroups[item.categoryId].questionsList.push({
          question: item.questionText,
          min: item.min || '',
          max: item.max || '',
          id: item.id,
          rate: this.getRate(currentRating, previousRating),
          categoryId: item.categoryId,
          imagePath: item.imagePath,
        });
      }
    });
  }

  getUpdatedRate(updatedRate, rate): any {
    if (updatedRate === 0) {
      return 0;
    } else {
      return rate;
    }
  }

  getRate(current, previous) {
    let rate;
    if (current !== 0) {
      rate = current || previous || 0;
    } else {
      rate = current;
    }
    return rate;
  }

  currentQuestionNumber(): any {
    if (this.questionNumber > 2) {
      return this.questionNumber - 2;
    }
  }

  currentSlide(direction, hasSkipped): any {
    direction ? this.questionNumber++ : this.questionNumber--;
    // Update question number to container component
    this.changeQuestionNumber.emit(this.questionNumber);
    //keep new page on top
    window.scrollTo(0, 0);
  }

  skipToEnd(): void {
    this.questionNumber = 9;
    this.changeQuestionNumber.emit(this.questionNumber);
    //keep new page on top
    window.scrollTo(0, 0);
  }
}
