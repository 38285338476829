import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
import { UserSearchPreference } from './../../../shared/states/auth/entities/preference_entities/user_search_preference';
import { SaveCurrentPreferences, ResetSearchPreferencesStore } from './../../../shared/states/auth/auth.action';
import { takeUntil, first } from 'rxjs/operators';
import { ModalService } from './../../../shared/services/modal-service.service';
import { AuUser } from './../../../shared/states/auth/entities/user_interface';
import { Observable, Subscription, Subject } from 'rxjs';
import { AuthState } from './../../../shared/states/auth/auth.state';
import { Select, Store } from '@ngxs/store';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-save-results',
  templateUrl: './save_results.component.html',
  styleUrls: ['./save_results.component.scss'],
  animations: [
    trigger('adjustHeight', [
      state(
        'default',
        style({
          height: '215px',
        })
      ),
      state(
        'alreadyExists',
        style({
          height: '150px',
        })
      ),
      state(
        'tooMany',
        style({
          height: '330px',
        })
      ),
      transition('* => *', [animate('0.4s ease')]),
    ]),
  ],
})
export class SaveResultsComponent implements OnInit, OnDestroy {
  @Input() currentPreferences: UserSearchPreference;
  @Output() searchSavedEmitter = new EventEmitter();

  saveSearch: FormGroup;
  isLoading = false;
  subscription: Subscription;
  currentU: AuUser;
  modalUnsubscribe$: Subject<any> = new Subject<any>();
  // Boolean, true if the user has chosen a name that already exists
  alreadyExists = false;
  // Holds the ID of the preference that already exists with that name
  existingPreferenceId: string;
  // True if the user already has 6 or more preferences saved
  tooManySaved = false;

  // Access modal to check if user wants to login and save search preferences
  @ViewChild('LoginPopup') loginPopup: ElementRef;
  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private store: Store
  ) {
    this.subscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
      // A backwards compatability check. Basically to check if the user still has the 'old' system of saving searchPreferences in their local storage.
      if(this.currentU && this.currentU.searchPreferences && this.currentU.searchPreferences.hasOwnProperty('airportDistance')){
        // User still has old preference object stored in their local storage and we need to reset it.
        this.store.dispatch(new ResetSearchPreferencesStore)
      }
    });
  }

  ngOnInit(): void {
    this.saveSearch = this.formBuilder.group({
      name: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(40),
        ])
      ),
    });
  }

  async onSubmit(): Promise<any> {
    this.isLoading = true;
    if (!this.currentU) {
      this.modalService.open('Login-Popup', this.loginPopup, 'lg');
      this.modalService.onChange(false);
      this.modalService.onChange$
        .pipe(takeUntil(this.modalUnsubscribe$))
        .subscribe(() => {
          // i.e. When the login modal is closed we can then navigate away from the page
          if (!this.modalService.get('Login-Popup')) {
            if (this.currentU) {
              this.savePreferences();
            }
          }
        });
      // Else, the user is logged in and we don't need to open a modal
    } else {
      this.savePreferences();
    }
  }

  savePreferences(): void {
    // If invalid name, to display an error message
    if (this.saveSearch.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.currentU.searchPreferences) {
      // Loop through the existing preferences saved by the user. Identify if there is an existing preference with the same name.
      for (const [preferenceId, preference] of Object.entries(
        this.currentU.searchPreferences
      )) {
        if (preference.name == this.saveSearch.value.name) {
          this.existingPreferenceId = preferenceId;
        }
      }
    }

    if (
      !this.alreadyExists &&
      !this.tooManySaved &&
      this.existingPreferenceId
    ) {
      // Check if the user really wants to overwrite their existing search preference that has the same name
      this.alreadyExists = true;
      return;
    }

    if (
      !this.alreadyExists &&
      !this.tooManySaved &&
      this.currentU.searchPreferences &&
      Object.values(this.currentU.searchPreferences).length >= 6
    ) {
      // Show the saved preferences, allowing user to select one to overwrite.
      this.tooManySaved = true;
      return;
    }

    // Save current preferences. If existingPreferenceId is not 'undefined' then it contains the ID of the preference to overwrite
    this.store
      .dispatch(
        new SaveCurrentPreferences(
          this.saveSearch.value.name,
          this.currentPreferences,
          this.currentU.uid,
          this.existingPreferenceId
        )
      )
      .pipe(first())
      .subscribe(
        () => {
          this.searchSavedEmitter.emit();
          // We reset all the 'errors' back to false after the user has saved
          this.alreadyExists = false;
          this.tooManySaved = false;
          this.existingPreferenceId = undefined;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  ngOnDestroy(): void {
    this.modalUnsubscribe$.next();
    this.modalUnsubscribe$.complete();
    this.subscription.unsubscribe();
  }
}
