import { searchParameters } from './search_parameters';
import { UserSearchPreference } from './user_search_preference';

export const defaultPreferences: UserSearchPreference = {
  multipleChoice: [],
  rangeQuestions: [],
  countries: [],
  airportDistance: searchParameters.airportDistance.max,
  homeDistance: searchParameters.homeDistance.max,
  minAltitude: searchParameters.minAltitude.min,
};
