<div class="mb-2">
  <!-- Maybe we need to also apply the profanity filter also to names? But this will filter out the name "dick" and others that could be legitimate names etc -->
  <div class="name text-muted" *ngIf="review.user && review.user.firstname">
    {{ review.user.firstname | titlecase }}
    {{ review.user.surname | titlecase }}
  </div>
  <div class="name text-muted" *ngIf="!review.user || !review.user.firstname">
    Ratoonger
  </div>
  <div class="d-flex align-items-center">
    <ngb-rating *ngIf="review.generalAverage" [rate]="review.generalAverage" [starTemplate]="snowFlakes" [max]="5"
      [readonly]="true"></ngb-rating>
    <div class="color-ratoong rate-number pl-2">{{review.generalAverage | number: "1.2-2"}}</div>
  </div>
  <!-- .filler maintains the height on mobile so it is consistent with reviews that have a score-->
  <div class="color-ratoong filler" *ngIf="!review.generalAverage">NO SCORE</div>
</div>
<h4 class="color-ratoong rate-text mb-2">
  {{ currentLoc.cityName }}, {{ currentLoc.countryName }}
</h4>
<div class="text-and-image d-flex flex-column justify-content-between">
  <!-- If the description is less than/equal to 100 characters we can just display it all -->
  <div *ngIf="review.reviewText">
    <div *ngIf="review.reviewText.length <= (numberOfCharacters) || !review.imageURL" class="review-text">
      "{{ review.reviewText | profanityFilter }}"
    </div>
    <div *ngIf="review.imageURL && review.reviewText.length > (numberOfCharacters) && !displayAllReview"
      class="review-text">
      "{{ (review.reviewText | profanityFilter | slice: 0:numberOfCharacters) + "..." }}"
      <span (click)="displayAllReview = true" class="color-ratoong pointer text-nowrap">Read more</span>
    </div>
    <div *ngIf="review.imageURL && (review.reviewText.length > (numberOfCharacters) && displayAllReview )"
      class="review-text">
      "{{ review.reviewText | profanityFilter }}"
      <span (click)="displayAllReview = false" class="color-ratoong pointer text-nowrap">Show less</span>
    </div>
  </div>
  <div [@image-in-out] *ngIf="!displayAllReview" #ImageContainer class="review-image">
    <img *ngIf="review.imageURL" class="img-fluid" [src]="review.imageURL" alt="Review photo of {{currentLoc.cityName}}"
      (click)="imageModalEmitter.emit(review);" />
  </div>
</div>


<ng-template #snowFlakes let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
      <img class="rate-flakes big-flakes" src="assets/images/src/sf_100.png" alt="Ski resort rating" />
    </span>
    <img class="rate-flakes big-flakes" src="assets/images/src/sf_0.png" alt="Ski resort rating" />
  </span>
</ng-template>
