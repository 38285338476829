// A file that contains IDs on the new paramaters so that they are not factored into the search. This can be deleted when we have more data on new parameters
export const newSearchParameters: number[] = [
  16,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];
