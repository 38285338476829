import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import {Resort} from '../admin-page/entities/resort';
import {AdminService} from '../admin-page/admin.service';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  database: any = null;

  constructor(private resortsService: AdminService) {
    this.database = firebase.database();
  }

  async getFilteredResortData(arrayOfLocations: [], country: string, age: string, gender: string, fromDate: string,
                              toDate: string): Promise<any>{
    const getFilteredData = await firebase.functions().httpsCallable('getFilteredData');
    return getFilteredData({ arrayOfLocations, country, age, gender, fromDate, toDate});
  }

  async getAllLocations(id: number, listOfTowns: string): Promise<Resort>  {
    const allresortList = await this.resortsService.getAllLocations();
    const resortList = [];
    if (listOfTowns) {
      const townsThatAreAllowed = listOfTowns.split(',');
      let resort;
      for (let i = 0; i < allresortList.length; i++) {
        if (String(allresortList[i].commonId) === String(id)) {
          resort = allresortList[i];
          for (let y = 0; y < allresortList[i].townList.length; y++) {
            for (let x = 0; x < townsThatAreAllowed.length; x++) {
              if (allresortList[i].townList[y].id === townsThatAreAllowed[x]) {
                resortList.push(allresortList[i].townList[y]);
              }
            }
          }
        }
      }
      resort.townList = resortList;
      return resort;
    }
    else{
      return undefined;
    }
  }

}
