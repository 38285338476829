// Update this file if you want to change the questions in the search page
export const searchParameters = {
  multipleChoice: [
    {
      group: 'The area',
      image: 'the_area.svg',
      options: [
        {
          questionID: 30,
          name: 'Ski in / Ski out',
          image: 'in_out.svg',
        },
        {
          questionID: 31,
          name: 'Tree skiing',
          image: 'tree_skiing.svg',
        },
        {
          questionID: 21,
          name: 'The scenery',
          image: 'the_scenery.svg',
        },
        {
          questionID: 20,
          name: 'Gastronomy',
          image: 'gastronomic_level.svg',
        },
      ],
    },
    {
      group: 'The town',
      image: 'the_town.svg',
      options: [
        {
          questionID: 15,
          name: 'Shopping possibilities',
          image: 'shopping_possibilities.svg',
        },
        {
          questionID: 14,
          name: 'Town cosiness',
          image: 'cosiness.svg',
        },
        {
          questionID: 19,
          name: 'Afterski',
          image: 'afterski.svg',
        },
      ],
    },
    {
      group: 'The piste',
      image: 'the_piste.svg',
      options: [
        {
          questionID: 7,
          name: 'Piste preparation',
          image: 'piste_preparation.svg',
        },
        {
          questionID: 23,
          name: 'Snowboard friendly',
          image: 'snowboard_friendly.svg',
        },
        {
          questionID: 22,
          name: "Children's area",
          image: 'childrens_area.svg',
        },
        {
          questionID: 1,
          name: 'Off piste possibilities',
          image: 'offpiste_possibilities.svg',
        },
        {
          questionID: 2,
          name: 'Off piste access',
          image: 'offpiste_access.svg',
        },
      ],
    },
    {
      group: 'Ski lifts',
      image: 'the_lifts.svg',
      options: [
        {
          questionID: 12,
          name: 'Lift standards',
          image: 'lift_standards.svg',
        },
        {
          questionID: 11,
          name: 'Low wait times',
          image: 'lift_wait.svg',
        },
        {
          questionID: 10,
          name: 'Well connected',
          image: 'lift_connections.svg',
        },
      ],
    },
    {
      group: 'The snowpark',
      image: 'the_snowpark.svg',
      options: [
        { questionID: 25, name: 'rails', image: 'rails.svg' },
        {
          questionID: 26,
          name: 'halfpipe',
          image: 'halfpipe.svg',
        },
        { questionID: 24, name: 'jumps', image: 'jumps.svg' },
      ],
    },
    {
      group: 'Sustainability',
      image: 'sustainability.svg',
      options: [
        {
          questionID: 27,
          name: 'Public transport',
          image: 'public_transport.svg',
        },
        {
          questionID: 28,
          name: 'Renewable energy',
          image: 'renewable_energy.svg',
        },
        {
          questionID: 29,
          name: 'Use of recycling',
          image: 'use_of_recycling.svg',
        },
      ],
    },
  ],
  rangeQuestions: [
    {
      questionID: 17,
      name: 'Price level',
      image: 'price_level.svg',
      range: { highest: 'expensive', lowest: 'inexpensive' },
    },
    {
      questionID: 3,
      name: 'Difficulty level',
      image: 'difficulty_level.svg',
      range: { highest: 'difficult', lowest: 'easy' },
    },
  ],
  countries: [],
  airportDistance: {
    title: 'Airport distance',
    min: '0',
    max: '500',
  },
  homeDistance: {
    title: 'Home distance',
    min: '0',
    max: '3000',
  },
  minAltitude: {
    title: 'Minimum altitude',
    min: '0',
    max: '2400',
  },
};
