import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ModalService } from '../../../shared/services/modal-service.service';
import { Component, Input, OnInit } from '@angular/core';
import { DeepLocation } from '../../../shared/states/location/entities/deepLocations';
import { SCREEN_SIZE } from '../../../shared/services/enums/screen-size.enum';
import { DeviceDetectorService } from '../../../shared/services/device-detector.service';

@Component({
  selector: 'app-details-ski-piste',
  templateUrl: './details_ski_piste.component.html',
  styleUrls: ['./details_ski_piste.component.scss'],
})
export class DetailsSkiPisteComponent implements OnInit {
  @Input() currentLoc: DeepLocation;
  @Input() pisteMap: string;
  image: string;
  total = 0;
  convertedData = [];
  skiRoutes: any;

  mobile = false;
  size: SCREEN_SIZE;

  constructor(private resizeSvc: DeviceDetectorService, private modalService: ModalService, private analytics: AngularFireAnalytics) {
    this.size = resizeSvc.getCurrentSize();
    this.mobile = this.size < 3;

    resizeSvc.onResize$.subscribe((x) => {
      this.size = x;
      this.mobile = this.size < 3;
    });
  }
  ngOnInit(): void {
    this.coords();
  }

  coords(): any {
    const data = [];

    this.currentLoc.pistes.forEach((el) => (this.total += el.km));

    this.currentLoc.pistes.forEach((el, i) => {
      let color;
      let id;
      color =
        el.title === 'easy'
          ? 'blue'
          : el.title === 'intermediate'
          ? 'red'
          : el.title === 'difficult'
          ? 'black'
          : 'yellow';
      id =
        el.title === 'easy'
          ? 4
          : el.title === 'intermediate'
          ? 2
          : el.title === 'difficult'
          ? 3
          : 1;
      if (el.title == 'Ski routes') {
        this.skiRoutes = { id, name: el.title, count: el.km, color };
      }
      data.push({ id, name: el.title, count: el.km, color });
    });
    const newData = data
      .slice()
      .sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
    // Biggest - 2. Second - 1. Third - 3. Smallest - 4.
    this.convertedData[0] = newData[2];
    this.convertedData[1] = newData[0];
    this.convertedData[2] = newData[1];
    this.convertedData[3] = newData[3];

    let link = '';
    this.convertedData.forEach((obj) => {
      link = link + obj.id;
    });
    this.image = link + '.png';
  }



  open(id, content): any {
    this.modalService.open(id, content);
  }

  logEvent() {
    this.analytics.logEvent('RAT_user_clicked_partner_link', {partnerName: 'fatmap' });
  }
}
