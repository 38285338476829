<div class="container mb-4">
  <app-section-header title="My preferences" subtitle="Choose what is important to you"></app-section-header>
  <div class="row">
    <!-- ----LOOP THROUGH MULTIPLE CHOICE QUESTIONS---- -->
    <div *ngFor="let question of parameters.multipleChoice" class="col-xl-4 col-lg-6 col-4 px-2 pb-3">
      <!-- Multi-choice image -->
      <div class="position-relative">
        <img
          [ngClass]="{ selected: userHasSelectedOne(question) }"
          class="w-100 border-ratoong pointer"
          src="{{ 'assets/images/search/' + question.image }}"
          alt="{{ question.group }}"
          (click)="openSelectModal('Preference-Popup', PreferencePopup, question.group)"
          (load)="imageLoaded()" />
        <!--  Renders tick when an option is selected -->
        <img
          *ngIf="userHasSelectedOne(question)"
           class="tick"
           src="assets/images/search/selected_checkmark.svg"
           alt="{{ question.group }} selected" />
      </div>
    </div>
    <!-- ----LOOP THROUGH SLIDER QUESTIONS---- -->
    <div *ngFor="let question of parameters.rangeQuestions" class="col-xl-4 col-lg-6 col-4 px-2 pb-3">
      <div class="position-relative">
        <img
          class="w-100 border-ratoong pointer"
          src="{{ 'assets/images/search/' + question.image }}"
          alt="{{ question.name }}"
          (click)=" openSliderModal( 'Preference-Popup' , SliderPreferencePopup, question.name ) "
          (load)="imageLoaded()" />
        <img
          *ngIf="userSpecifiedRange(question)"
          class="tick"
          src="assets/images/search/selected_checkmark.svg"
          alt="{{ question.name }} selected" />
      </div>
    </div>
  </div>
</div>
<!-- Modal to appear when user is on a multiple choice question -->
<ng-template #PreferencePopup>
  <app-preference-select-popup [title]="questionTitle" [options]="options"
    [userPreferences]="preferences.multipleChoice" (valueEmitter)="optionSelected()"></app-preference-select-popup>
</ng-template>
<!-- Modal to appear when user is on a range/slider question -->
<ng-template #SliderPreferencePopup>
  <app-preference-slider-popup [question]="rangeQuestion" [userPreferences]="preferences.rangeQuestions"
    (valueEmitter)="optionSelected()"></app-preference-slider-popup>
</ng-template>
