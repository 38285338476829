import { UserSearchPreference } from '../../../shared/states/auth/entities/preference_entities/user_search_preference';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ɵallowPreviousPlayerStylesMerge } from '@angular/animations/browser';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-travelling-preferences',
  templateUrl: './travelling_preferences.component.html',
  styleUrls: ['./travelling_preferences.component.scss'],
})
export class TravellingPreferencesComponent implements OnInit {
  @Input() preferences: UserSearchPreference;
  // The questions to display
  @Input() parameters: any;

  @Output() valueEmitter = new EventEmitter();
  @Output() loadingEmitter = new EventEmitter<boolean>();
  @Output() latLongEmitter = new EventEmitter<any>();

  longitude: number;
  latitude: number;

  // Permission granted for location
  locationPermission: boolean = null;

  // Hard coded at the moment, perhaps change this in future
  travelOption = 'airportDistance';

  constructor() {}

  selectTravel(type: string): any {
    // If user starts editing home distance, the airport distance resets (and visa versa)
    if (type === 'homeDistance') {
      this.preferences.airportDistance = this.parameters.airportDistance.max;
    } else {
      this.preferences.homeDistance = this.parameters.homeDistance.max;
    }
    // Reset the filter when user selects a new travel option
    this.valueEmitter.emit();
    this.travelOption = type;
  }

  setValue(value: string, type: string): any {
    // If user starts editing home distance, the airport distance resets (and visa versa)
    if (type === 'homeDistance') {
      this.preferences.homeDistance = value;
      this.preferences.airportDistance = this.parameters.airportDistance.max;
    }
    if (type === 'airportDistance') {
      this.preferences.airportDistance = value;
      this.preferences.homeDistance = this.parameters.homeDistance.max;
    }
    this.valueEmitter.emit();
  }

  // Get users current location (if they grant permission)
  getLocation(): void {
    if (navigator.geolocation) {
      this.locationPermission = true;
      this.loadingEmitter.emit(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.selectTravel('homeDistance');
          this.longitude = position.coords.longitude;
          this.latitude = position.coords.latitude;
          this.loadingEmitter.emit(false);
          // Emit the values to the parent component (new-search)
          this.latLongEmitter.emit({
            latitude: this.latitude,
            longitude: this.longitude,
          });
        },
        (error) => {
          if (error) {
            console.log(error);
            this.locationPermission = false;
            this.loadingEmitter.emit(false);
          }
        },
        {
          timeout: 6000,
        }
      );
    } else {
      console.log('Permission not granted');
      // TODO: DISABLE THE DISTANCE FROM HOME
    }
  }

  ngOnInit(): void {
    // If the user has specified a home distance previously, then we want to display this by default. Else, the default is airportDistance
    if (this.preferences.homeDistance !== this.parameters.homeDistance.max) {
      this.travelOption = 'homeDistance';
    }
  }
}
