<form
  [formGroup]="emailDetails"
  (ngSubmit)="onSubmit()"
  action=""
  class="w-100"
>
  <div class="form-group w-100 mb-4">
    <div class="error-container">
      <div class="error-box p-1 pl-2" *ngIf="submitted && f.email.errors">
        <div>Please enter a valid email address.</div>
      </div>
    </div>
    <label for="email" class="form-label mb-0">Email</label>
    <input
      autofocus
      type="email"
      id="email"
      class="form-input form-control rounded-0"
      placeholder="user@email.com"
      formControlName="email"
      [ngClass]="{ 'error-input': submitted && f.email.errors }"
    />
  </div>
  <div class="form-group mb-2">
    <input
      type="submit"
      class="w-100 text-white rounded-0 next-button"
      value="NEXT"
    />
  </div>
</form>
<div class="position-relative w-100 mb-3">
  <div
    *ngIf="isLoading"
    [ngClass]="{
      visible: isLoading
    }"
    class="load-container"
  >
    <div class="loader"></div>
  </div>
</div>
