import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';

import { UpdateAccount } from '../../shared/states/auth/auth.action';
import {
  Actions,
  ofActionErrored,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';
import { AuthState } from '../../shared/states/auth/auth.state';
import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { Country } from '../../shared/states/location/entities/countries';
import { LocationState } from '../../shared/states/location/location.state';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../shared/services';
import { takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  private userData: FormGroup;
  private loaded: boolean;
  editedSuccess = false;
  private ngUnsubscribe = new Subject();
  instructorSelect = 'default';
  genderSelect = 'default';
  countrySelect = 'default';
  ridingSelect = 'rare';
  skillSelect = 'none';
  holidaySelect = 'default';
  CurrentDate = new Date();
  isError = false;
  profileChange = false;
  dateBad = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store,
    private modalService: ModalService,
    private actions$: Actions,
    private titleService: Title
  ) {
    this.actions$
      .pipe(ofActionSuccessful(UpdateAccount), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.loaded = true;
        this.profileChange = true;
      });
    this.actions$
      .pipe(ofActionErrored(UpdateAccount), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.loaded = true;
        this.isError = true;
      });
  }
  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  @Select(LocationState.countryList) listOfCountries: Observable<Country[]>;

  @ViewChild('dp', { static: true }) datepicker: NgbDatepicker;
  currentU: AuUser;
  model: any;
  subscription: Subscription;

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): any {
    this.loaded = false;
    this.createUser();
    this.subscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
      if (data.gender && data.gender !== '') {
        this.genderSelect = data.gender;
      }
      if (data.country && data.country !== '') {
        this.countrySelect = data.country;
      }
      if (data.preference.tripCount && data.preference.tripCount !== '') {
        this.ridingSelect = data.preference.tripCount;
      }
      if (data.preference.skillLevel && data.preference.skillLevel !== '') {
        this.skillSelect = data.preference.skillLevel;
      }
      if (data.preference.holidayType && data.preference.holidayType !== '') {
        this.holidaySelect = data.preference.holidayType;
      }
      if (data.birth && data.birth !== '') {
        const res = data.birth.split('-');
        const year = Number(res[0]);
        const month = Number(res[1]);
        const day = Number(res[2]);
        const birth = this.checkDate(year, month, day);
        if (birth === true) {
          this.model = {
            month: Number(res[1]),
            year: Number(res[0]),
            day: Number(res[2]),
          };
        }
      }
      if (data.instructor && data.instructor !== '') {
        this.instructorSelect = data.instructor;
       }
      

      this.userData.patchValue({
        firstname: data.firstname,
        surname: data.surname,
        email: data.email,
        snowboarder: data.preference.snowboarder,
        skier: data.preference.skier,
      });
    });
    this.loaded = true;

    this.setTitle('Profile page')

  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscription.unsubscribe();
  }

  private createUser(): any {
    this.userData = this.fb.group({
      firstname: null,
      surname: null,
      gender: 'default',
      country: 'default',
      email: null,
      snowboarder: null,
      skier: null,
      RidingDurations: 'rare',
      ConsiderAsRider: 'none',
      SkiHolidays: 'default',
      instructor: 'default'
    });
  }


  // convenience getter for easy access to form fields
  get f(): any {
    return this.userData.controls;
  }

  private updateUser(): any {
    let birthString = '';
    this.dateBad = false;
    this.profileChange = false;
    this.isError = false;
    //console.log(this.model); //changed log object with user data when user data updated
    if (this.model) {
      const year = this.model.year;
      const month = this.model.month;
      const day = this.model.day;
      const birth = this.checkDate(year, month, day);
      if (birth === true) {
        birthString = year + '-' + month + '-' + day;
      } else {
        this.dateBad = true;
        throw new Error('Bad Date');
      }
    }
    this.loaded = false;
    this.store.dispatch(
      new UpdateAccount(
        this.currentU.email,
        this.userData.value.firstname,
        this.userData.value.surname,
        this.userData.value.country,
        this.userData.value.gender,
        this.userData.value.instructor,
        this.userData.value.snowboarder,
        this.userData.value.skier,
        birthString,
        this.userData.value.RidingDurations,
        this.userData.value.ConsiderAsRider,
        this.userData.value.SkiHolidays,
        this.currentU.source
      )
    );
  }
  

  checkDate(year, month, day): any {
    const dateFrom = new Date('1910-01-01');
    const dateTo = new Date(
      this.CurrentDate.getFullYear() +
        '-' +
        (this.CurrentDate.getMonth() + 1) +
        '-' +
        this.CurrentDate.getDate()
    );
    const date = new Date(year + '-' + month + '-' + day);
    return dateFrom <= date && date <= dateTo;
  }

  getActivityImage(image, type): any {
    if (image === 'skier') {
      return type
        ? 'assets/images/src/skier_checked.svg'
        : 'assets/images/src/skier.svg';
    } else if (image === 'snowboarder') {
      return type
        ? 'assets/images/src/snowboarder_checked.svg'
        : 'assets/images/src/snowboarder.svg';
    }
  }

  open(id, content): any {
    this.modalService.open(id, content);
  }
}
