<div class="details-container w-100">
  <div class="row d-flex justify-content-between">
    <div class="col-md-7 col-xl-6">
      <div class="d-flex pr-2 justify-content-start title-section">
        <div class="icon">
          <img
            src="assets/images/src/ambassadors/ambassadors-icon-01.svg"
            alt="Ambassadors"
          />
        </div>
        <div class="d-flex flex-column">
          <h1 class="title">Ratoong ambassadors</h1>
          <h4 class="subtitle">
            We are proud to introduce all our ambassadors coming from many
            different ski countries and ski resorts! Check them out and learn
            how you can become one.
          </h4>
          <h4 class="subtitle">#smileinthesnow</h4>
          <a
            (click)="recordEvent()"
            title="Go to ambassadors page"
            [routerLink]="['/ambassador']"
            class="border-ratoong text-center mt-4 button-secondary pointer"
          >
            <h5 class="color-ratoong button-text">Meet more</h5>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="px-0 px-lg-5 mx-5">
    <div class="row ambassadors-section">
      <div
        *ngFor="let ambassador of ambassadorList"
        class="col-12 col-md-6 px-md-5 ng-star-inserted"
      >
        <app-home-ambassadors-item
          [mobile]="mobile"
          [ambassador]="ambassador"
        ></app-home-ambassadors-item>
      </div>
    </div>
  </div>
</div>
