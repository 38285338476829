<div *ngIf="!isLoading" class="details-container">
  <div class="d-flex px-2 justify-content-start title-section">
    <div class="mountain-icon mr-4">
      <img src="assets/images/search/mountain_icon.svg" alt="Search a resort by name on Ratoong" />
    </div>
    <div class="d-flex flex-column">
      <h1 class="title">Search a resort by name</h1>
      <h4 class="subtitle">Already know where you want to go?</h4>
      <h4 class="subtitle">
        See details on a resort and how other Ratoongers have rated it.
      </h4>
      <span class="pointer p-1 pr-2 my-3 border-ratoong d-flex text-uppercase anchor-tag align-self-start"
        (click)="scrollToTop()">
        <img class="mr-2 align-self-center" src="assets/images/search/anchor_up.svg" alt="Search resorts by preference" />
        Search resorts by preference
      </span>
      <div class="search-bar mt-3">
        <app-search-bar class="big-width"></app-search-bar>
      </div>
    </div>
  </div>
</div>
