import { ResetRatingState } from '../../../shared/states/ratings/rating.action';
import { AuthState } from '../../../shared/states/auth/auth.state';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ModalService } from '../../../shared/services/modal-service.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  trigger,
  transition,
  style,
  animate,
  group,
} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { AuUser } from '../../../shared/states/auth/entities/user_interface';
import { AngularFireStorage } from '@angular/fire/storage';
import { DeepLocation } from '../../../shared/states/location/entities/deepLocations';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { RatingState } from '../../../shared/states/ratings/rating.state';
import { Observable, Subject, Subscription } from 'rxjs';
import { Review } from '../../../shared/states/ratings/entities/review';
import { takeUntil } from 'rxjs/operators';
import { GetAllReviewsFromLocation } from '../../../shared/states/ratings/rating.action';
import { DeviceDetectorService } from '../../../shared/services/device-detector.service';
import { SCREEN_SIZE } from '../../../shared/services/enums/screen-size.enum';



@Component({
  selector: 'app-details-reviews',
  templateUrl: './details_reviews.component.html',
  styleUrls: ['./details_reviews.component.scss'],
  animations: [
    trigger('carousel-fast', [
      transition(
        ':increment',
        group([
          style({
            transform: 'translateX(100%)',
          }),
          animate('0.5s ease'),
        ])
      ),
      transition(
        ':decrement',
        group([
          style({
            transform: 'translateX(-100%)',
          }),
          animate('0.5s ease'),
        ])
      ),
    ]),
  ],
})
export class DetailsReviewsComponent implements OnInit, OnDestroy {
  // Parent component passes user data
  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;
  @Input() currentLoc: DeepLocation;

  @Select(RatingState.getAllReviews) previousRatings: Observable<Review[]>;
  // Loading checker
  loading = true;

  private ngUnsubscribe = new Subject();
  index = 1;
  itemsPerPage = 2;

  length = 0;

  mobile = false;
  animationInProgress = false;
  intervalId: any;
  size: SCREEN_SIZE;

  // reviews: Review[];
  reviews: any[] = [];
  currentReviews: Review[] = [];

  modalImage = "";
  modalText = "";
  displayModal = false;

  isClosed = false;
  subscription: Subscription;


  constructor(
    private storage: AngularFireStorage,
    private route: ActivatedRoute,
    private actions$: Actions,
    private store: Store,
    private resizeSvc: DeviceDetectorService,
    private modalService: ModalService,
    private analytics: AngularFireAnalytics,
    private router: Router,
  ) {
    this.size = resizeSvc.getCurrentSize();
    this.calculateItemsPerPage();


    resizeSvc.onResize$.subscribe((x) => {
      this.size = x;
      this.calculateItemsPerPage();
      // A check when resizing mobile to desktop to ensure we don't end up with an extreme index and no reviews being displayed
      if(Math.ceil(this.length/this.itemsPerPage) < this.index){
        this.index = 1;
      }
      this.renderReviews(this.index);
    });
    this.actions$.pipe(ofActionSuccessful(GetAllReviewsFromLocation),
      takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loading = false;
    });
  }

  calculateItemsPerPage(): void{
    this.mobile = this.size < 2;
    if (this.size === 2){
      this.itemsPerPage = 2;
    }else if (this.size <= 1) {
      this.itemsPerPage = 1;
    } else {
      this.itemsPerPage = 3;
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new ResetRatingState());
    this.route.params.subscribe(
       async (params) => {
         this.store.dispatch(new GetAllReviewsFromLocation(Number(params.id)));
      },
      error => {
        console.error(error);
      });

      this.subscription = this.currentUser.subscribe((data) => {
        this.currentU = data;
      });

      this.previousRatings.subscribe(
        (data) => {
          // By default the state is null. If not null it means that a call to the database has been completed.
          if (data !== null && data.length > 0) {
            this.reviews = data;
            this.length = data.length;
            this.renderReviews(this.index);
          }
        }
      );
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  sortByDate(unsortedArray: any[]): any[] {
    return unsortedArray.sort((obj1, obj2) => {
      if (obj1.date > obj2.date) {
        return -1;
      }

      if (obj1.date < obj2.date) {
        return 1;
      }

      return 0;
    });
  }

  moveCarousel(direction: number): any {
    let oldIndex = this.index;
    this.index = this.index + direction;
    // If we have reached the end or the start, to return
    if(Math.ceil(this.length/this.itemsPerPage) < this.index || this.index <= 0){
      this.index = oldIndex;
      return;
    }
    this.renderReviews(this.index);
  }


  renderReviews(index: number): any {
    const start = (index - 1) * this.itemsPerPage;
    const end = index * this.itemsPerPage;
    if(this.reviews){
      this.currentReviews = this.reviews.slice(start, end);
    }
  }



  openImageModal(review: Review): void {
    this.modalImage = review.imageURL;
    this.modalText = review.reviewText;
    this.displayModal = true;
  }

  closeImageModal(): void {
    this.displayModal = false;
  }
}
