<div>
  <div
    class="overview-icon"
    xmlns="http://www.w3.org/1999/html"
    xmlns="http://www.w3.org/1999/html"
  >
    <img
      src="/assets/images/categories/rate_overview.svg"
      class="img-fluid-title"
      alt="Rate overview"
    />
    <span class="custom-title">
      <!-- Translated text: YOUR RATING OVERVIEW-->
      {{ "rate.overview-title" | translate | uppercase }}
    </span>
    <p class="custom-text text-center">
      <!-- Translated text: See the ratings you have just made - adjust them if needed. You can also write a review and add a photo before submitting. -->
      {{"rate.overview-text" | translate }}
    </p>
  </div>
  <div class="reviews-groups-wrapper">
    <div class="prev" (click)="currentSlide(false, false)">
      <img class="carousel-arrow-prev" src="assets/images/src/arrow_bold.png" alt="Previous ski resort rating parameters" />
    </div>
    <div class="content-holder">
      <div class="d-flex justify-content-between align-items-center">
        <!--
        <div class="d-flex justify-content-between align-items-center">
          <img
            src="/assets/images/info/ratings/dark_sf.svg"
            class="img-fluid custom-img-fluid"
            alt="Snowflake icon"
          />
          <span class="big-text"> OVERALL RATING </span>
        </div>
        -->
        <span class="big-text">
          <!-- Translated text: OVERALL RATING-->
          {{ "rate.overall" | translate | uppercase }}
        </span>
        <div class="d-flex align-items-center neg-margin-top">
          <ngb-rating [(rate)]="overall" [(ngModel)]="overall" [starTemplate]="z" [max]="5" [readonly]="true">
          </ngb-rating>
          <div *ngIf="overall" class="big-text ml-3">{{ overall }}</div>
        </div>
      </div>
      <div *ngFor="let in of counter(); let item = index" class="">
        <div class="d-flex justify-content-between my-3">
          <div class="review-group-title">
            <div class="d-flex mr-2">
              <div class="text-center image-wrapper">
                <img class="image-custom"
                  src="/assets/images/info/ratings/{{ questionGroupsOrderedList[item + 1].imgLink }}"
                  alt="{{ questionGroupsOrderedList[item + 1].groupName }}" />
              </div>
              <span class="big-text">
                <!-- Translated text with an example question group: THE AREA -->
                {{ "rate." + questionGroupsOrderedList[item + 1].groupName | translate | uppercase}}
              </span>
            </div>
            <div (click)="changeState(item)">
              <img class="drop-btn" id="dropdownHead{{ item }}" src="/assets/images/info/ratings/button.svg" alt="Ski resort rating parameters" />
            </div>
          </div>
          <span *ngIf="questionGroupsOrderedList[item + 1].averageScore" class="big-text custom-overall">
            {{ questionGroupsOrderedList[item + 1].averageScore }} / 5
          </span>
          <span *ngIf="!questionGroupsOrderedList[item + 1].averageScore" class="big-text custom-overall">
            - / 5
          </span>
        </div>

        <ul class="d-none dropdown-holder" id="dropdownBody{{ item }}">
          <li *ngFor="
              let question of questionGroupsOrderedList[item + 1].questionsList
            " class="question-item">
            <span class="question-title">
              <!-- Translated text with an example question: OFF-PISTE POSSIBILITIES -->
              {{ 'rate.question' + question.questionId | translate | uppercase }}
            </span>
            <div class="question-item-flakes">
              <div class="inline-block">
                <div class="">
                  <ngb-rating class="subcategory" [(rate)]="question.score" [(ngModel)]="question.score"
                    [starTemplate]="t" [max]="5" (click)="
                      triggerSaveRate(
                        question.questionId,
                        question.score,
                        item + 1,
                        question.question,
                        question.categoryId
                      )
                    "></ngb-rating>
                </div>
              </div>
              <div class="star-width d-flex justify-content-between px-2">
                <small class="low-high low">
                  <!-- Translated text with above example question min value: Few -->
                  {{ 'rate.question' + question.questionId + "-min"  | translate }}
                </small>
                <small class="low-high high">
                  <!-- Translated text with above example question min value: Many -->
                  {{ 'rate.question' + question.questionId + "-max"   | translate }}
                </small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <form [formGroup]="reviewForm" (ngSubmit)="onSubmit()">
    <div class="review-photo-section mt-4">
      <label for="review" class="
            text-box
            custom-title-text-review
            text-uppercase
            align-self-start
          "
          >
          {{ "rate.additionals-text" | translate | uppercase }}
        </label>
        <textarea
          class="form-control rounded-0"
          id="review"
          name="review"
          formControlName="review"
          cols="30"
          maxlength="1000"
          placeholder="{{ 'rate.share-experience' | translate }}"
          rows="5"
        >
        </textarea>
      <div class="mr-md-2 pt-2 review-photo-wrapper">
        <label for="file-input">
          <input id="file-input" type="file" formControlName="fileInput" accept=".jpg,.jpeg,.png"
            (change)="onFileChange($event)" hidden />
          <div class="pointer" *ngIf="!reviewImage">
            <img
              *ngIf="!selectedFile"
              src="assets/images/src/upload.svg"
              alt="Upload image"
              class="add-img-custom"
              type="file" />
            <img
              *ngIf="selectedFile"
              [src]="fileUrl"
              alt="Add file"
              class="add-img-custom"
              type="file" />
          </div>
          <div class="pointer" *ngIf="reviewImage">
            <div *ngIf="imageLoading" class="position-relative">
              <div class="load-container">
                <div class="loader"></div>
              </div>
            </div>
            <img
              src="{{ reviewImage }}"
              alt="Review images"
              width="60"
              height="60"
              [ngClass]="{ invisible: imageLoading }"
              (load)="imageLoading = false" />
          </div>
        </label>
        <div *ngIf="!reviewImage && !selectedFile" class="text-photo text-uppercase">
          Add photo
        </div>
        <div *ngIf="reviewImage || selectedFile" class="text-photo text-uppercase">
          Click photo to change
        </div>
      </div>
    </div>
    <div class="form-control2">
      <button class="
          d-flex
          justify-content-center
          btn
          text-white
          review-button
          rounded-0
          text-uppercase
        "
        type="submit"
        (click)="openPopUp('Login-Popup', openLogin, openOverviewPopUp)"
      >
        {{ "rate.save-button" | translate | uppercase }}
      </button>
    </div>
    <div class="position-relative w-100">
      <div [ngClass]="{
          visible: isLoading
        }" class="load-container">
        <div class="loader"></div>
      </div>
    </div>
  </form>

  <div class="pointer text-center color-grey" (click)="goBackwards()">
    <img class="anchor-tag-back grey-filter align-self-center"
      src="assets/images/search/anchor_down_gray.svg"
      alt="Back to rating"
    />
    <span class="pointer skip text-uppercase">
      <!-- Translated text: BACK TO RATE -->
      {{ "rate.back-to-rate-button" | translate | uppercase }}
    </span>
  </div>
</div>

<!-- templates -->
<ng-template #t let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
      <img class="rate-flakes" src="assets/images/src/sf_100.png" alt="Ski resort rating" />
    </span>
    <img class="rate-flakes" src="assets/images/src/sf_0.png" alt="Ski resort rating" />
  </span>
</ng-template>

<ng-template #z let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
      <img class="big-flakes" src="assets/images/src/sf_100.png" alt="Ski resort rating" />
    </span>
    <img class="big-flakes" src="assets/images/src/sf_0.png" alt="Ski resort rating" />
  </span>
</ng-template>

<ng-template #openLogin>
  <app-login-popup
    subtitleOne="Share what you think about the resorts you have visited to help others know where to go!"
    subtitleTwo="Log in / Sign up to rate a resort!" [dontRedirect]="true">
  </app-login-popup>
</ng-template>
<ng-template #openOverviewPopUp>
  <app-rate-estimate-end [currentLocation]="currentLocation" [overall]="overall">
  </app-rate-estimate-end>
</ng-template>
