import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LocationService } from './location.service';
import { Location } from './entities/location';
import { Country } from './entities/countries';
import {
  FilterLocationList,
  GetAllCountries,
  GetAllLocations,
  FilterCountriesByRegion,
  GetSpecificLocation,
  AddNewLocation,
  SetSearchResults,
  NewFilterLocationList,
} from './location.action';
import { LocationFilter } from './helper/location.filter';
import { DeepLocation } from './entities/deepLocations';
import { PreferenceService } from '../preference/preference.service';

export class LocationStateModel {
  locationCount: number;
  countryList: Country[];
  filteredCountries: Country[];
  locationList: Location[];
  filteredLocations: Location[];
  currentLocation: DeepLocation;
  searchResults: any[];
}

@State<LocationStateModel>({
  name: 'location',
  defaults: {
    locationCount: 0,
    countryList: [],
    filteredCountries: [],
    locationList: [],
    filteredLocations: [],
    currentLocation: null,
    searchResults: [],
  },
})
@Injectable()
export class LocationState {
  constructor(
    private locationService: LocationService,
    private locationFilter: LocationFilter,
    private prefService: PreferenceService
  ) {}
  @Selector()
  static countryList(state: LocationStateModel): any {
    return state.countryList;
  }
  @Selector()
  static locationList(state: LocationStateModel): any {
    return state.locationList;
  }
  @Selector()
  static filteredLocations(state: LocationStateModel): any {
    return state.filteredLocations;
  }
  @Selector()
  static filteredCountries(state: LocationStateModel): any {
    return state.filteredCountries;
  }
  @Selector()
  static locationCount(state: LocationStateModel): any {
    return state.locationCount;
  }

  @Selector()
  static currentLocation(state: LocationStateModel): any {
    return state.currentLocation;
  }

  @Selector()
  static getSearchResults(state: LocationStateModel): any {
    return state.searchResults;
  }

  @Action(GetAllCountries)
  getAllCountries({
    getState,
    setState,
  }: StateContext<LocationStateModel>): any {
    return this.locationService.getAllCountries().then((result) => {
      const state = getState();
      setState({
        ...state,
        countryList: result,
      });
    });
  }

  @Action(FilterCountriesByRegion)
  getListOfCountriesFromRegion(
    { getState, patchState, setState }: StateContext<LocationStateModel>,
    { region }: FilterCountriesByRegion
  ): any {
    const state = getState();
    const filteredList = this.locationFilter.filterCountriesByRegion(
      region,
      state.countryList
    );
    setState({
      ...state,
      filteredCountries: filteredList,
    });
  }

  @Action(GetAllLocations)
  getAllLocations({
    getState,
    setState,
  }: StateContext<LocationStateModel>): any {
    return this.prefService.getSubRatings().then((questionList) => {
      this.locationService.getAllLocations(questionList).then((result) => {
        const state = getState();
        setState({
          ...state,
          locationList: result,
          locationCount: result.length,
        });
      });
    });
  }

  @Action(GetSpecificLocation)
  getSpecificLocation(
    { getState, setState }: StateContext<LocationStateModel>,
    { uid }: GetSpecificLocation
  ): any {
    const state = getState();
    setState({
      ...state,
      currentLocation: null,
    });
    return this.prefService.getSubRatings().then((questionList) => {
      return this.locationService
        .getLocation(uid, questionList)
        .then((result) => {
          setState({
            ...state,
            currentLocation: result,
          });
        });
    });
  }

  @Action(FilterLocationList)
  filterLocations(
    { getState, setState }: StateContext<LocationStateModel>,
    { params }: FilterLocationList
  ): any {
    const state = getState();
    const filteredList = this.locationFilter.filterLocations(
      params,
      state.locationList
    );
    setState({
      ...state,
      filteredLocations: filteredList,
      locationCount: filteredList.length,
    });
  }

  @Action(AddNewLocation)
  addNewLocation(
    { getState, setState }: StateContext<LocationStateModel>,
    { userUid, date, guid, city, website, location }: AddNewLocation
  ): any {
    return this.locationService.addNewLocation(
      userUid,
      date,
      guid,
      city,
      website,
      location
    );
  }

  @Action(NewFilterLocationList)
  newFilterLocations(
    { getState, setState }: StateContext<LocationStateModel>,
    { searchPreferences, latitude, longitude }: NewFilterLocationList
  ): any {
    const state = getState();
    const locations = this.locationFilter.newFilterLocations(
      searchPreferences,
      latitude,
      longitude,
      state.locationList
    );
    setState({
      ...state,
      searchResults: locations,
    });
  }

  // // Action to set the search results to the list of locations that match the users preferences
  // @Action(SetSearchResults)
  // setSearchResults(
  //   { getState, setState }: StateContext<LocationStateModel>,
  //   { locations }: SetSearchResults
  // ): any {
  //   const state = getState();
  //   setState({
  //     ...state,
  //     searchResults: locations,
  //   });
  // }
}
