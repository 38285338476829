import { Router } from '@angular/router';
import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
} from '@angular/animations';
import {
  RemoveFavouriteBucketlist,
  AddFavouriteBucketlist,
} from '../../../../shared/states/auth/auth.action';
import { takeUntil } from 'rxjs/operators';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ModalService } from '../../../../shared/services/modal-service.service';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { DeepLocation } from '../../../../shared/states/location/entities/deepLocations';
import { AuUser } from '../../../../shared/states/auth/entities/user_interface';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-favourite-bucketlist',
  templateUrl: './favourite_bucketlist.component.html',
  styleUrls: ['./favourite_bucketlist.component.scss'],
  animations: [
    trigger('favouriteBucket', [
      transition(':enter', [
        style({ transform: 'scale(0.7);' }),
        animate(
          '500ms cubic-bezier(0.35, 0, 0.25, 1)',
          keyframes([
            style({ transform: 'scale(0)' }),
            style({ transform: 'scale(1.5)' }),
            style({ transform: 'scale(1)' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class FavouriteBucketlistComponent implements OnInit {
  @Input() currentU: AuUser;
  @Input() currentLoc: DeepLocation;

  subtitleOne = '';
  subtitleTwo = '';

  @ViewChild('LoginPopupFavourites') loginPopup: ElementRef;
  modalUnsubscribe$: Subject<any> = new Subject<any>();

  // Holds information on whether this component is on the search results page (or the info page)
  // This is because the CSS is different depending on which page this component is on
  resultsPage: boolean;

  constructor(
    private router: Router,
    private store: Store,
    private modalService: ModalService,
    private analytics: AngularFireAnalytics
  ) {}

  ngOnInit(): void {
    this.resultsPage = this.router.url === '/search-ski-resorts/results';
  }

  onFavourites(): boolean {
    if (this.currentU && this.currentU.favourites) {
      return this.currentU.favourites.includes(this.currentLoc.id);
    } else {
      return false;
    }
  }

  onBucketlist(): boolean {
    if (this.currentU && this.currentU.bucketlist) {
      return this.currentU.bucketlist.includes(this.currentLoc.id);
    } else {
      return false;
    }
  }

  onVisited(): boolean {
    if (this.currentU && this.currentU.visited) {
      return this.currentU.visited.includes(this.currentLoc.id);
    } else {
      return false;
    }
  }

  clickedFavouriteBucketlist(list: string): void {
    if (list === 'bucketlist') {
      this.subtitleOne =
        'Have a long list of places you want to visit? Save them all in one place!';
      this.subtitleTwo =
        'Log in / Sign up to add the resorts you want to visit to the bucket list on your profile!';
    } else if (list === 'favourites') {
      this.subtitleOne = 'Do you have resorts that you love to return to?';
      this.subtitleTwo =
        'Log in / Sign up to add your favourite destinations to your profile!';
    } else {
      this.subtitleOne = 'Want to save the places you have been before?';
      this.subtitleTwo =
        'Log in / Sign up to add your previous destinations to your profile!';
    }
    // If user isn't logged in, we first want to open a login modal
    if (!this.currentU) {
      this.modalService.open('Login-Popup', this.loginPopup, 'lg');
      this.modalService.onChange(false);
      this.modalService.onChange$
        .pipe(takeUntil(this.modalUnsubscribe$))
        .subscribe(() => {
          // i.e. When the popup is closed
          if (!this.modalService.get('Login-Popup')) {
            //  we want to check if the user is now logged in.
            if (this.currentU) {
              // And can add it to their favourites/bucketlist
              this.addOrRemove(list);
            }
          }
        });
      // Else, the user is already logged in and we don't need to open a modal
    } else {
      this.addOrRemove(list);
    }
  }

  // Adds or removes the location from either their favourites list ot their bucketlist
  // onFavouriteList and onBucketList are updated before dispatching the action to improve responsiveness of UI.
  // If the action fails then it will be caught anyway by the currentuser.susbcribe and converted back.
  addOrRemove(list): void {
    if (list == 'favourites') {
      if (this.onFavourites()) {
        this.analytics.logEvent('RAT_user_removed_from_list', {
          list: 'favourites',
          town: this.currentLoc.cityName,
        });
        this.store.dispatch(
          new RemoveFavouriteBucketlist(
            this.currentLoc.id,
            this.currentU.uid,
            'favourites'
          )
        );
      } else {
        this.analytics.logEvent('RAT_user_added_to_list', {
          list: 'favourites',
          town: this.currentLoc.cityName,
        });
        this.store.dispatch(
          new AddFavouriteBucketlist(
            this.currentLoc.id,
            this.currentU.uid,
            'favourites'
          )
        );
      }
    } else if (list == 'bucketlist') {
      if (this.onBucketlist()) {
        this.analytics.logEvent('RAT_user_removed_from_list', {
          list: 'bucketlist',
          town: this.currentLoc.cityName,
        });
        this.store.dispatch(
          new RemoveFavouriteBucketlist(
            this.currentLoc.id,
            this.currentU.uid,
            'bucketlist'
          )
        );
      } else {
        this.analytics.logEvent('RAT_user_added_to_list', {
          list: 'bucketlist',
          town: this.currentLoc.cityName,
        });
        this.store.dispatch(
          new AddFavouriteBucketlist(
            this.currentLoc.id,
            this.currentU.uid,
            'bucketlist'
          )
        );
      }
    } else if (list == 'visited') {
      if (this.onVisited()) {
        this.analytics.logEvent('RAT_user_removed_from_list', {
          list: 'visited',
          town: this.currentLoc.cityName,
        });
        this.store.dispatch(
          new RemoveFavouriteBucketlist(
            this.currentLoc.id,
            this.currentU.uid,
            'visited'
          )
        );
      } else {
        this.analytics.logEvent('RAT_user_added_to_list', {
          list: 'visited',
          town: this.currentLoc.cityName,
        });
        this.store.dispatch(
          new AddFavouriteBucketlist(
            this.currentLoc.id,
            this.currentU.uid,
            'visited'
          )
        );
      }
    }
  }
}
