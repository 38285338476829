import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/modules/shared.module';
import { RateRoutes } from './rate.routes';
import { RateMainComponent } from './rate_main/rate_main.component';
import { RateEstimateEndComponent } from './rate_estimate_end/rate_estimate_end.component';
import { AccountModule } from '../account/account.module';
import { RatingModule } from 'ngx-bootstrap/rating';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchBarComponent } from '../shared/ui/search_bar/search_bar.component';
import { RateEstimateGroupComponent } from './rate_estimate_group/rate_estimate_group.component';
import { RateQuestionTrackerComponent } from './rate_question_tracker/rate_question_tracker.component';
import { RateEstimateContainerComponent } from './rate_estimate_container/rate_estimate_container.component';
import { RateReviewComponent } from './rate_review/rate_review.component';
import { RateGroups } from './rate_groups/rate_groups.component';
import { RateRecommendation } from './rate_recommendation/rate_recommendation.component';
import {RateOverviewComponent} from './rate_overview/rate_overview.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [RateRoutes, AccountModule, SharedModule, RatingModule, NgbModule,
    HttpClientModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (http:HttpClient) => { return new TranslateHttpLoader(http, './assets/locales/','.json');},
          deps: [HttpClient]
        }
      }
    )
],
  declarations: [
    RateMainComponent,
    RateEstimateEndComponent,
    RateEstimateGroupComponent,
    RateQuestionTrackerComponent,
    RateEstimateContainerComponent,
    RateReviewComponent,
    RateGroups,
    RateRecommendation,
    RateOverviewComponent,
  ],
  providers: [SearchBarComponent],
})
export class RateModule {}
