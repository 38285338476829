<!-- Title section -->
<div class="mb-3 row">
  <div class="col-md-8">
    <app-title-section title="Get insights into the ski area"
      subtitleOne="See the number of kilometers of ski piste for each difficulty level and ski routes, the altitude of the ski resort, and the total altitude difference."
      imagePath="/assets/images/info/piste_icon.svg">
      <h4 class="subtitle">
        You can also access the {{currentLoc.cityName}} piste map and get freeriding information at our partner <a
          (click)="logEvent()" class="fatmap-link" href="https://fatmap.com" target="_blank"
          title="Go to the FATMAP website">FATMAP</a>.
      </h4>
    </app-title-section>
  </div>
</div>

<div class="d-flex justify-content-center">
  <div class="center">


    <div class="position-relative">
      <div class="elevation-text" *ngIf="currentLoc.ElevationInfo">
        <div>
          <h4 class="elevation-info">ALTITUDE:</h4>
          <h4 class="elevation-info">
            {{ currentLoc.ElevationInfo.min }}m -
            {{ currentLoc.ElevationInfo.max }}m
          </h4>
          <h4 class="elevation-info">
            {{ currentLoc.ElevationInfo.max - currentLoc.ElevationInfo.min }}m
            difference
          </h4>
        </div>
      </div>
      <div class="d-flex mb-4">
        <div>
          <img src="/assets/images/piste/{{ image }}" alt="Different pistes at {{ currentLoc.cityName }}" />
        </div>
        <div class="py-2 h-100">
          <img src="/assets/images/icons/arrow.png" alt="Ski pistes" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-6">
        <h3 class="routes-title my-2">Piste Levels</h3>
        <!-- Ensure the difficulties are displayed in the correct order -->
        <div *ngFor="let difficulty of ['easy', 'intermediate', 'difficult']">
          <div *ngFor="let item of convertedData">
            <div class="elevation-info d-flex align-items-center no-wrap" *ngIf="item.name == difficulty">
              <div class="circle {{ item.color }} mr-2"></div>
              {{ item.name }}
              <div class="km-text" *ngIf="item.count != 0">{{ item.count }}</div>
              <div class="km-text" *ngIf="item.count == 0 || item.count == undefined">
                UNKNOWN
              </div>
              KM
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="skiRoutes && skiRoutes.count" class="col-md-4 col-6">
        <div *ngIf="skiRoutes">
          <h3 class="routes-title my-2">Ski Routes</h3>
          <div class="elevation-info d-flex align-items-center no-wrap">
            <div class="square yellow mr-2"></div>
            Ski routes {{ skiRoutes.count }} KM *
          </div>
          <p class="small-text">*secured but unprepared routes</p>
        </div>
      </div>
      <div class="col-md-4 col-6">
        <div class="elevation-info bold text-right">
          TOTAL PISTES : {{ total }} KM
        </div>
      </div>
    </div>

    <section class="row map-section">
      <div class="col-md-5 d-flex flex-column align-items-center justify-content-center" *ngIf="pisteMap">
        <img
          class="pointer map-icon"
          (click)="open('Piste-Popup',PisteModal)"
          src="/assets/images/info/find_on_map.svg"
          alt="Ski Piste map" />
        <a class="pointer map-text" (click)="open('Piste-Popup',PisteModal)">Piste Map</a>
      </div>
      <div class="col-md-2" *ngIf="pisteMap"></div>
      <div class="col-md-5">
        <p class="small-text center-small">
          Use our partner FATMAP's 3D maps for freeriding at
          {{ currentLoc.name | uppercase }}, {{ currentLoc.cityName | uppercase}}
        </p>
        <a (click)="logEvent()" class="d-flex justify-content-center" href="https://fatmap.com" target="_blank">
          <img class="fat-map" src="/assets/images/sponsors/fat_map.png" alt="3D ski piste map" />
        </a>
      </div>
    </section>
  </div>
</div>

<ng-template #PisteModal>
  <app-piste-modal [pisteMap]="pisteMap"></app-piste-modal>
</ng-template>
