<!-- If the data is not yet loaded from the state or the images haven't yet loaded -->

<div
  *ngIf="previousAnswerLoading || skiGroupsLoading || imagesLoading"
  class="load-container"
>
  <div class="loader"></div>
</div>

<!-- Keep this div invisible whilst the content is loading  -->
<div
  [ngClass]="{
    invisible: previousAnswerLoading || skiGroupsLoading || imagesLoading
  }"
  class="d-flex flex-column align-items-center"
>
  <div>
    <h2 *ngIf="currentU" class="title">
      <!-- Translated text with an example user: Hi Merve, did you ski or snowboard? -->
      {{ 'rate.estimate-group.question1-without-user' | translate: { firstname: currentU.firstname } }}
    </h2>
    <h2 *ngIf="!currentU" class="title">
      <!-- Translated text: Hi, did you ski or snowboard? -->
      {{'rate.estimate-group.question1-without-user' | translate }}
    </h2>
  </div>
  <div class="d-flex justify-content-center w-75 mb-5">
    <div class="rider-button-wrapper">
      <button
      class="
          border-ratoong
          text-center
          rider-type-button
          skier-icon
          pt-2
          pb-1
          button-frame
        "
        [ngClass]="{ selected: riderType === 'skier' || riderType === 'both'}"
        (click)="setRider('skier')"
        >
        <!-- Translated text: SKIER -->
        {{"rate.estimate-group.question1-button1" | translate}}
      </button>
      <img
        *ngIf="riderType === 'skier' || riderType === 'both'"
        class="tick-rider"
        src="assets/images/search/selected_checkmark.svg"
        alt="Skier selected" />
    </div>
    <div class="rider-button-wrapper">
      <button
        class="
          border-ratoong
          text-center
          rider-type-button
          snowboarder-icon
          ml-3
          pt-2
          pb-1
          button-frame
        "
        [ngClass]="{ selected: riderType === 'snowboarder' || riderType === 'both'}"
        (click)="setRider('snowboarder')"
        >
        <!-- Translated text: SNOWBOARDER -->
        {{"rate.estimate-group.question1-button2" | translate}}
      </button>
      <img
        *ngIf="riderType === 'snowboarder' || riderType === 'both'"
        class="tick-rider"
        src="assets/images/search/selected_checkmark.svg"
        alt="Snowboarder selected" />
    </div>
  </div>
  <h2 class="title">
    <!-- Translated text: WHO DID YOU GO WITH -->
    {{"rate.estimate-group.question2" | translate}}
  </h2>
  <!-- Ski group images -->
  <div class="row image-container justify-content-center">
    <div *ngFor="let group of skiGroups" class="col-sm-4 col-6 mb-3">
      <div class="position-relative">
        <div>
        <img
          [ngClass]="{ selected: skiGroup == group.groupId }"
          class="w-100 border-ratoong pointer image"
          src="{{ 'assets/images/ski_groups/' + currentLanguage + '/' + group.imageName }}"
          alt="{{ group.title }}"
          (click)="selectOption(group.groupId, group.title)"
          (load)="imageLoaded()"
          />
      </div>
      <!--  Renders tick when an option is selected -->
      <img
        *ngIf="skiGroup == group.groupId"
        class="tick"
        src="assets/images/search/selected_checkmark.svg"
        alt="{{ group.title }} selected" />
    </div>
    </div>
  </div>
  <div class="d-flex justify-content-center w-100">
    <!-- Skip param -->
    <!--
    <button
      class="border-ratoong text-center text-white next-button pt-1 w-25"
      (click)="currentSlide(true, true)"
    >
      NEXT
    </button>
    -->
  </div>
</div>
