<div class="w-100 item-container">
  <div class="row">
    <div
      class="col-2 d-flex flex-column align-items-center justify-content-center"
    >
      <div
        *ngIf="notANumber(currentLocation.percentageMatch)"
        (click)="showHelperPrompt = false"
        class="
          grey
          match-text
          d-flex
          justify-content-center
          text-center
          align-items-center
          text-white
        "
      >
        N/A
      </div>
      <div
        *ngIf="!notANumber(currentLocation.percentageMatch)"
        [ngClass]="{
          red: currentLocation.percentageMatch < 50,
          yellow:
            currentLocation.percentageMatch >= 50 &&
            currentLocation.percentageMatch < 80,
          green: currentLocation.percentageMatch >= 80
        }"
        (click)="showMatchingParameters()"
        class="
          match-text
          d-flex
          justify-content-center
          text-center
          align-items-center
          text-white
          shadow
          pointer
        "
      >
        {{ currentLocation.percentageMatch | number: "1.0-0" }}
      </div>
      <p class="text text-justify mt-2 mb-0 text-nowrap">Match %</p>
      <!-- Helper prompt. Only show if the first item in the list, if the user hasn't closed it and if the user hasn't already opened a prompt -->
      <div
        *ngIf="isFirst && showHelperPrompt && !currentPrompt"
        [ngClass]="{'match-na': notANumber(currentLocation.percentageMatch)}"
        class="helper-prompt shadow wiggle"
      >
        <div class="left-arrow">
          <div class="inner-triangle"></div>
        </div>
        <div
          *ngIf="notANumber(currentLocation.percentageMatch)"
          class="bg-white border-ratoong prompt-container">
          Match % requires selection of parameters
        </div>
        <div
          (click)="showHelperPrompt = false"
          *ngIf="!notANumber(currentLocation.percentageMatch)"
          class="bg-white pointer border-ratoong prompt-container">
          Click to see what is included in your match %
        </div>
      </div>
      <!-- End of helper prompt -->
      <!-- Match prompt. Only show if the user has clicked to open it. Close it if another prompt is opened for a different location -->
      <div
        *ngIf="currentPrompt == currentLocation.id"
        (click)="promptOpened.emit(0)"
        class="match-prompt pointer shadow"
        [ngClass]="{
          'bigger-prompt':
            matchingParameters.length + nonMatchingParameters.length > 8
        }"
      >
        <div class="left-arrow top-50">
          <div class="inner-triangle"></div>
        </div>
        <div class="bg-white border-ratoong prompt-container">
          <div class="text" *ngIf="matchingParameters.length > 0">
            Preferences included in match percentage
            <div class="row mt-1">
              <div
                class="col-auto mb-2"
                *ngFor="let parameter of matchingParameters"
              >
                <div class="green text-capitalize text text-white px-1">
                  {{ parameter.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="text" *ngIf="nonMatchingParameters.length > 0">
            Preferences you selected that are NOT included
            <div class="row mt-1">
              <div
                class="col-auto mb-2"
                *ngFor="let parameter of nonMatchingParameters"
              >
                <div class="red text text-capitalize text-white px-1">
                  {{ parameter.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of match prompt -->
    </div>
    <div class="col-6 d-flex flex-column justify-content-between">
      <div class="row">
        <div class="col-sm-4 text bold-text ellipses">
          {{ currentLocation.cityName }}
        </div>
        <div class="col-sm-5 text bold-text ellipses">
          {{ currentLocation.name }}
        </div>
        <div class="col-sm-3 text bold-text ellipses">
          {{ currentLocation.countryName }}
        </div>
      </div>
      <div class="pl-0 d-flex align-items-center mb-1">
        <ngb-rating
          [(rate)]="currentLocation.scoreToDisplay"
          [(ngModel)]="currentLocation.scoreToDisplay"
          [starTemplate]="z"
          [max]="5"
          [readonly]="true"
        ></ngb-rating>
        <div
          *ngIf="
            isNumber(currentLocation.scoreToDisplay) &&
            currentLocation.scoreToDisplay != '0'
          "
          class="overall-score"
        >
          {{ currentLocation.scoreToDisplay }}
        </div>
        <div
          *ngIf="
            !isNumber(currentLocation.scoreToDisplay) &&
            currentLocation.scoreToDisplay != '0'
          "
          class="overall-score"
        >
          No Ratings
        </div>
      </div>
    </div>
    <div
      class="
        col-4
        padding
        d-flex
        flex-column
        justify-content-between
        align-items-center
      "
    >
      <div class="favourite-container">
        <app-favourite-bucketlist
          class="w-100"
          [currentLoc]="currentLocation"
          [currentU]="currentU"
        ></app-favourite-bucketlist>
      </div>
      <button
        class="
          bg-ratoong
          text-white
          view-resort
          text-uppercase
          w-100
          text-nowrap
        "
        (click)="navigateToResortPage()"
      >
        View resort
      </button>
    </div>
  </div>
</div>

<ng-template #z let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
      <img class="flakes" src="assets/images/src/sf_100.png" alt="Ski resort rating" />
    </span>
    <img class="flakes" src="assets/images/src/sf_0.png" alt="Ski resort rating" />
  </span>
</ng-template>
