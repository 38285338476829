<div class="d-flex justify-content-center">
  <div class="center w-100">
    <div class="mb-3">
      <p>Sort by:</p>
      <app-drop-down
        (selectedEmitter)="userSelectedSort($event)"
        [defaultOption]="defaultOption"
        [dropdownOptions]="dropdownOptions"
      ></app-drop-down>
    </div>
    <!-- Add some non-breaking space to create padding (as in design) -->
    <p>
      Search Results:&nbsp;&nbsp;&nbsp; {{ totalLocations.length }} Ski Resorts
    </p>
    <div
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="300"
      (scrolled)="getNextLocations()"
    >
    <div 
      class="mb-4" 
      data-aos="fade-up"
      data-aos-duration="700" 
      data-aos-once="true"
      data-aos-disable="mobile"
      *ngFor="let location of currentLocations; first as isFirst">
      <app-resort-list-item
        [isFirst]="isFirst"
        [currentLocation]="location"
        [currentPreferences]="currentPreferences"
        [currentPrompt]="currentPrompt"
        (promptOpened)="setCurrentPrompt($event)"
      ></app-resort-list-item>
    </div>
      
    </div>
  </div>
</div>
