import { Injectable } from '@angular/core';

import { FirebaseApp } from '@angular/fire';
import 'firebase/storage';

@Injectable()
export class FirestorageService {

  constructor(private firebaseApp: FirebaseApp) { }

  public getImageUrl(folder, image?): Promise<any>{
      const storageRef = image ? this.firebaseApp.storage().ref().child(`${folder}/${image}`) : this.firebaseApp.storage().ref().child(`${folder}`);
      return storageRef.getDownloadURL().then(url => url).catch(err => false);
  }

}
