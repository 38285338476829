import { AuthService } from './../../shared/states/auth/auth.service';
import { SetCurrentPreferences } from './../../shared/states/auth/auth.action';
import { searchParameters } from '../../shared/states/auth/entities/preference_entities/search_parameters';
import { NewParametersComponent } from '../../shared/ui/modals/preferences/new_parameters/new_parameters.component';
import { ModalService } from '../../shared/services';
import { first } from 'rxjs/operators';
import { AuUser } from '../../shared/states/auth/entities/user_interface';
import { AuthState } from '../../shared/states/auth/auth.state';
import { Router } from '@angular/router';
import { NewFilterLocationList } from '../../shared/states/location/location.action';
import { Country } from '../../shared/states/location/entities/countries';
import { defaultPreferences } from '../../shared/states/auth/entities/preference_entities/default_preferences';
import { UserSearchPreference } from '../../shared/states/auth/entities/preference_entities/user_search_preference';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { LocationState } from '../../shared/states/location/location.state';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';

import { ViewportScroller } from '@angular/common';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-search-page',
  templateUrl: './search_page.component.html',
  styleUrls: ['./search_page.component.scss'],
})
export class SearchPageComponent implements OnInit, OnDestroy {
  // Access modal to confirm resetting preferences
  @ViewChild('ResetAll') resetAllModal: ElementRef;
  // Access modal to check if user wants to login and save search preferences
  @ViewChild('LoginPopup') loginPopup: ElementRef;
  // To alert error if no matches
  infoBad: boolean;
  // To show reset button
  showReset = true;
  // Loading screen checkers for different components that make service calls
  isLoading: boolean;
  countryLoading: boolean;
  travelLoading: boolean;
  preferenceLoading: boolean;

  // -------- SUBSCRIPTION AND STATE SELECTORS --------
  // An observable that gets the full list of locations
  @Select(LocationState.locationList) listOfLocations: Observable<any[]>;
  // An observable that gets the search results after applying a filter
  @Select(LocationState.getSearchResults) searchResults: Observable<any[]>;
  // User observable
  @Select(AuthState.loggedInUser) user: Observable<AuUser>;
  // Get the full list of countries from the state
  @Select(LocationState.countryList) listOfCountries: Observable<Country[]>;
  locationSubscription: Subscription;
  resultSubscription: Subscription;
  userSubscription: Subscription;
  preferenceSubscription: Subscription;
  modalUnsubscribe$: Subject<any> = new Subject<any>();

  currentUser: AuUser = null;

  // Default is to set preferences to the default object. This will be initialised to the user preferences (if they have selected in the past)
  // At the moment there are 2 types of objects for search preferences (old type and new). For now I have set this object to type any. In the future can change to UserSearchPreference
  // preferences: UserSearchPreference = JSON.parse(
  preferences: any = JSON.parse(JSON.stringify(defaultPreferences));
  // An array containing all locations
  allLocations: any[] = [];
  // An array containing all of the locations that match the search preferences
  includedLocations: any[] = [];
  // Variables to store the users current latitude and longitude (if they have provided them)
  latitude: number = null;
  longitude: number = null;
  // An array to hold all countries, so user can easily click to include all or exclude all
  allCountries: string[];
  // When true, will load the users existing preferences. We want this by default.
  // However we do not want to do this when the user is logging in to save new preferences (since it will override their new preferences).
  loadOldPreferences = true;

  parameters = searchParameters;

  // Previous search preferences
  @Select(AuthState.getCurrentPreferences)
  currentPreferencesState: Observable<UserSearchPreference>;

  constructor(
    private store: Store,
    private viewportScroller: ViewportScroller,
    private router: Router,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private analytics: AngularFireAnalytics,
    private titleService: Title,
    private authService: AuthService
  ) {
    this.locationSubscription = this.listOfLocations.subscribe(
      (list) => {
        // get the full list of locations. Copy and store inside this.allLocations
        this.allLocations = JSON.parse(JSON.stringify(list));
        this.store.dispatch(
          new NewFilterLocationList(
            this.preferences,
            this.latitude,
            this.longitude
          )
        );
      },
      (error) => console.log(error)
    );

    this.userSubscription = this.user.subscribe((data) => {
      this.currentUser = data;
      // ---------------------------------
      // Temporary function to help in migration from old search to new 'named' search
      // Can be deleted after deployment to production
      // DONT FORGET TO ALSO DELET THE AUTH SERVICE IMPORT (AS THIS IS BIG AND UNECESSARY)
      if (this.currentUser) {
        this.backwardsCompatability();
      }
      // ---------------------------------

    });

    this.preferenceSubscription = this.currentPreferencesState.subscribe(
      (data) => {
        if (data) {
          // Load old preferences (if the user has selected any)
          this.preferences = JSON.parse(JSON.stringify(data));
          // If user has previously specified distance from home, we need to get the location (Getting location also runs the filter)
          if (
            this.preferences.homeDistance !== this.parameters.homeDistance.max
          ) {
            this.getLocation();
          } else {
            this.runFilter();
          }
        }
      }
    );

    // Results of search after filters applied.
    this.resultSubscription = this.searchResults.subscribe(
      (list) => {
        // get the search results
        this.includedLocations = list;
        // If result isn't empty then remove the error  (if present)
        if (this.includedLocations.length !== 0) {
          this.infoBad = false;
        }
      },
      (error) => console.log(error)
    );
  }

  // Temporary function to help in migration from old search to new 'named' search
  // Can be deleted after deployment to production
  private backwardsCompatability(): void {
    if (
      this.currentUser.searchPreferences &&
      this.currentUser.searchPreferences.hasOwnProperty('airportDistance')
    ) {
      this.authService.setSearchPreferencesToNull(this.currentUser.uid)
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  // A list of all countries with resorts. Can be used allowing user to 'select-all' or 'deselect-all'
  getCountriesWithRegions(): any {
    this.listOfCountries.pipe(first()).subscribe((list) => {
      this.allCountries = list
        .filter((country) => country.region !== undefined)
        .map((country) => country.name);
    });
  }

  // Applies all filters from the user preferences to the list of all locations
  runFilter(): any {
    // Display loading screen when running filter
    this.isLoading = true;
    // Set reset to false
    this.showReset = false;
    // Call filter function inside shared/states/location/location.state
    this.store.dispatch(
      new NewFilterLocationList(this.preferences, this.latitude, this.longitude)
    );
    // If there are differences with the default object, show the reset button
    if (
      JSON.stringify(defaultPreferences) !== JSON.stringify(this.preferences)
    ) {
      this.showReset = true;
    }
    this.isLoading = false;
  }

  // Event triggered by 'travelling-preferences' component when user confirms their consent to location
  setLatitudeLongitude(location): any {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  // Applies the loading screen when child components are loading
  setCountryLoading(value: boolean): any {
    this.countryLoading = value;
    this.cdr.detectChanges();
  }
  setTravelLoading(value: boolean): any {
    this.travelLoading = value;
    this.cdr.detectChanges();
  }
  setPreferenceLoading(value: boolean): any {
    this.preferenceLoading = value;
    this.cdr.detectChanges();
  }

  scrollTo(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  saveAndProceed(): any {
    if (this.includedLocations.length === 0) {
      this.infoBad = true;
      return;
    }
    // If the user has previously sorted columns in the result page, we want to delete and start afresh
    localStorage.removeItem('sortColumn');

    //  -----------LOG EVENTS AND PREFERENCES ON GOOGLE ANALYTICS------------
    this.preferences.countries.forEach((value) => {
      this.analytics.logEvent('RAT_user_preference_countries ', {
        country: value,
      });
    });

    this.analytics.logEvent('RAT_user_searched', {
      searchMethod: 'parameters',
    });

    this.preferences.multipleChoice.forEach((question) => {
      this.analytics.logEvent('RAT_user_preference_parameters ', {
        param: question.name,
      });
    });

    this.preferences.rangeQuestions.forEach((question) => {
      this.analytics.logEvent('RAT_user_preference_parameters ', {
        param: question.name,
      });
    });

    if (
      this.preferences.airportDistance !== this.parameters.airportDistance.max
    ) {
      this.analytics.logEvent('RAT_user_preference_airport_distance ', {
        distance: this.preferences.airportDistance,
      });
    }

    if (this.preferences.homeDistance !== this.parameters.homeDistance.max) {
      this.analytics.logEvent('RAT_user_preference_home_distance ', {
        distance: this.preferences.homeDistance,
      });
    }

    if (this.preferences.minAltitude !== '0') {
      this.analytics.logEvent('RAT_user_preference_min_altitude ', {
        altitude: this.preferences.minAltitude,
      });
    }

    // Save the current preferences in the state so they can be accessed on the results screen
    this.store.dispatch(new SetCurrentPreferences(this.preferences));
    this.router.navigate(['search-ski-resorts/results']);
  }

  ngOnInit(): void {
    this.getCountriesWithRegions();
    // Display popup if not already displayed previously
    if (!localStorage.getItem('seenPopup')) {
      localStorage.setItem('seenPopup', 'true');
      this.modalService.open('new-params-popup', NewParametersComponent);
    }
    this.runFilter();
    this.setTitle('Search ski resort');
  }

  // Get users current location.
  // NOTE: This function is a repeat of that in the child component. However, since getting location can take time, was not able to call it from child
  getLocation(): any {
    if (navigator.geolocation) {
      this.isLoading = true;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.longitude = position.coords.longitude;
          this.latitude = position.coords.latitude;
          this.runFilter();
          this.isLoading = false;
        },
        (error) => {
          if (error) {
            console.log(error);
            this.isLoading = false;
          }
        },
        {
          timeout: 6000,
        }
      );
    } else {
      this.preferences.homeDistance = this.parameters.homeDistance.max;
      console.log('Permission not granted');
      this.isLoading = false;
    }
  }

  circleWidth(value: number): any {
    // The percentage range width of the circle will be between 60% and 100%
    const lowerRange = 60;
    // The percentage value of a single unit
    const incrementValue = (100 - lowerRange) / this.allLocations.length;
    // The percentage value of the currently matching number of resorts
    const percentageValue = incrementValue * value;
    // Added to 50%
    return lowerRange + percentageValue;
  }

  // Confirm if user really wants to reset all parameters
  resetAllPopup(): void {
    this.modalService.open('reset-all-popup', this.resetAllModal, 'sm');
  }

  // Reset all parameters to default value
  resetAll(): void {
    this.showReset = false;
    this.preferences.multipleChoice = [];
    this.preferences.rangeQuestions = [];
    this.preferences.airportDistance = this.parameters.airportDistance.max;
    this.preferences.homeDistance = this.parameters.homeDistance.max;
    this.preferences.minAltitude = this.parameters.minAltitude.min;
    this.preferences.countries = [];

    // rerun the filter
    this.runFilter();
  }

  ngOnDestroy(): any {
    this.modalUnsubscribe$.next();
    this.modalUnsubscribe$.complete();
    this.locationSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.resultSubscription.unsubscribe();
  }
}
