import { RateLandingPageComponent } from './redirect_page/rate_landing_page/rate_landing_page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TermsAndConditionsComponent } from './terms_and_conditions/terms_and_conditions.component';
import { GuardService } from './shared/states/auth/_guard/guard.service';
import { AboutComponent } from './about/about.component';
import { PlanComponent } from './plan/plan.component';
import { HomeComponent } from './home/home.component';
import { AmbassadorpageComponent } from './ambassadorpage/ambassadorpage.component';
import { RateMainComponent } from './rate/rate_main/rate_main.component';
import { ProfileComponent } from './account/profile/profile.component';

import { NotfoundComponent } from './not_found/not_found.component';

import { AdminGuardService } from './admin/admin-shared/states/admin-auth/_guard/_adminGuard';

import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminSignUpComponent } from './admin/admin-sign-up/admin-sign-up.component';
import { RedirectPageComponent } from './redirect_page/redirect_page.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'redirect/:referralID',
    component: RedirectPageComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'ratingTest',
    component: RateMainComponent,
  },
  {
    path: 'plan',
    component: PlanComponent,
  },
  {
    path: 'ambassador',
    component: AmbassadorpageComponent,
  },
  {
    path: 'profile',
    canActivate: [GuardService],
    loadChildren: () =>
      import('../app/account/account.module').then((m) => m.AccountModule),
    component: ProfileComponent,
  },
  {
    path: 'rate-ski-resort',
    loadChildren: () =>
      import('../app/rate/rate.module').then((m) => m.RateModule),
    component: RateMainComponent,
  },
  {
    path: 'terms',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'partners',
    component: AdminLoginComponent,
  },
  {
    path: 'partners/sign-up/:referralID',
    component: AdminSignUpComponent,
  },
  {
    path: 'partners/admin',
    canActivateChild: [AdminGuardService],
    loadChildren: () =>
      import('./admin/admin/admin.module').then((m) => m.AdminModule),
  },
  /*{
    path: 'user-profile',
    canActivate: [GuardService],
    loadChildren: () => import('../app/account/account.module').then(m => m.AccountModule),
    component: ProfileComponent
  },*/
  {
    path: 'partners/overview',
    canActivateChild: [AdminGuardService],
    loadChildren: () =>
      import('./admin/overview/overview.module').then((m) => m.RestModule),
  },
  {
    path: '404',
    component: NotfoundComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
