<div class="container">
  <app-section-header title="How are you travelling?" subtitle="Choose ONE option"></app-section-header>
  <div class="d-flex mb-4">
    <span class="travel rounded-0 text-uppercase py-0 px-3 mr-2" (click)="selectTravel('airportDistance')">
      Flying?
      <!-- Tick to appear top right of region when user selects airportDistance -->
      <img
        *ngIf="travelOption == 'airportDistance'"
        class="tick"
        src="../assets/images/search/selected_checkmark.svg"
        alt="Travel by flying!" />
    </span>
    <span
      class="travel rounded-0 text-uppercase py-0 px-3"
      ngbTooltip="To calculate the driving distance we need access to your current location."
      tooltipClass="search-tooltip"
      (click)="getLocation()"
      [ngClass]="{ disabled: locationPermission == false }">
      Driving?
      <!-- Tick to appear top right of region when user selects homeDistance -->
      <img
        *ngIf="travelOption == 'homeDistance'"
        class="tick"
        src="assets/images/search/selected_checkmark.svg"
        alt="Travel by driving!" />
    </span>
  </div>
  <div *ngIf="travelOption == 'airportDistance'" class="mb-5">
    <h5 class="text-uppercase slider-text mb-0">
      Travel distance from airport
    </h5>
    <h6 class="slider-subtext mb-4">
      How far from a resort would you like to land?
    </h6>
    <div *ngIf="preferences.airportDistance == parameters.airportDistance.max" class="slider-value">
      {{ parameters.airportDistance.max }}+ km
    </div>
    <div *ngIf="preferences.airportDistance != parameters.airportDistance.max" class="slider-value">
      {{ preferences.airportDistance }} km
    </div>
    <input #airport (input)="setValue(airport.value, 'airportDistance')" class="rainbow-slider" type="range" min="0"
      max="{{ parameters.airportDistance.max }}" step="50" value="{{ preferences.airportDistance }}" />
    <div class="d-flex justify-content-between">
      <span class="text-uppercase slider-sub-text" [ngClass]="{
          'not-eco': airport.value > parameters.airportDistance.max / 2
        }">Not eco friendly</span>
      <span class="text-uppercase slider-sub-text" [ngClass]="{
          eco: airport.value < parameters.airportDistance.max / 2
        }">Eco friendly</span>
    </div>
  </div>
  <div *ngIf="travelOption == 'homeDistance'" class="mb-5">
    <h5 class="text-uppercase slider-text mb-0">
      Travel distance from current location
    </h5>
    <h6 class="slider-subtext mb-4">
      How far would you like to drive to arrive at a resort?
    </h6>
    <div *ngIf="preferences.homeDistance == parameters.homeDistance.max" class="slider-value">
      {{ parameters.homeDistance.max }}+ km
    </div>
    <div *ngIf="preferences.homeDistance != parameters.homeDistance.max" class="slider-value">
      {{ preferences.homeDistance }} km
    </div>
    <input #home (input)="setValue(home.value, 'homeDistance')" class="rainbow-slider" type="range" min="0"
      max="{{ parameters.homeDistance.max }}" step="50" value="{{ preferences.homeDistance }}" />
    <div class="d-flex justify-content-between">
      <span class="text-uppercase slider-sub-text not-eco"
        [ngClass]="{ 'not-eco': home.value > parameters.homeDistance.max / 2 }">Not eco friendly</span>
      <span class="text-uppercase slider-sub-text eco"
        [ngClass]="{ eco: home.value < parameters.homeDistance.max / 2 }">Eco friendly</span>
    </div>
  </div>
</div>
