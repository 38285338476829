<div *ngIf="isLoading" class="load-screen">
  <div class="loader"></div>
</div>
<div class="row">
  <div class="col-12 d-flex justify-content-center">
    <div class="w-100 ratoong-container">
      <div class="details-container">
        <!-- Title section -->
        <div class="row position-relative">
          <div class="col-lg-7 col-sm-11 pl-0">
            <app-title-section title="Your search results"
              subtitleOne="Here are the results of your search. See the ski resorts and ski towns that match your preferences the best."
              imagePath="assets/images/search/mountain_icon.svg">
            </app-title-section>
          </div>
          <div class="col-5">
            <!-- DESKTOP -->
            <div *ngIf="!searchSaved" class="save-results border-ratoong">
              <app-save-results (searchSavedEmitter)="searchSaved = true" [currentPreferences]="currentPreferences">
              </app-save-results>
            </div>
            <div [@searchSaved] *ngIf="searchSaved && !mobile"
              class="search-saved d-flex flex-column align-items-center">
              <img src="/assets/images/icons/save_icon.svg" alt="Save Search" />
              <p>Search Saved</p>
            </div>
          </div>
          <!-- MOBILE -->
          <div *ngIf="!searchSaved" (click)="openModal('Save-Search-Results', saveResultsMobile)"
            class="save-results-icon d-flex flex-column align-items-center">
            <img src="/assets/images/icons/bookmark.svg" alt="Save Search" />
            <p>Save Search</p>
          </div>
          <div *ngIf="searchSaved && mobile" class="search-saved-mobile d-flex flex-column align-items-center">
            <img src="/assets/images/icons/save_icon.svg" alt="Save Search" />
            <p>Search Saved</p>
          </div>
        </div>
        <div class="neg-margin-top">
          <app-resort-list [currentPreferences]="currentPreferences"></app-resort-list>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #saveResultsMobile>
  <span><i (click)="closeModal('Save-Search-Results', true)" class="close fas fa-times"></i></span>
  <div class="pt-2">
    <app-save-results (searchSavedEmitter)="
        searchSaved = true; closeModal('Save-Search-Results', true)
      " [currentPreferences]="currentPreferences"></app-save-results>
  </div>
</ng-template>
