<!-- Title section -->
<div class="row">
  <div class="col-md-8 mb-4">
    <app-title-section
    title="Distance to resort"
    subtitleOne="Allow us to calculate your distance and travel time by sharing your location with us, no matter if you travel by air or by ground!"
    imagePath="assets/images/info/distance.svg"
  >
  <h4 class="subtitle">
    Did you know that 60% of the carbon footprint in your trip comes from travel to and from the ski town, according to our partner <a (click)='logEvent()' class="pow-link" href="https://protectourwinters.eu/copy-forging-a-new-path/" target="_blank" title="Go to the POW website">POW</a>.
  </h4>
    </app-title-section>
  </div>
</div>

<agm-map *ngIf="!currentLocation || noDirections" [latitude]="currentLoc.latitude" [longitude]="currentLoc.longitude">
  <agm-marker [latitude]="currentLoc.latitude" [longitude]="currentLoc.longitude"></agm-marker>
</agm-map>
<agm-map *ngIf="currentLocation && !noDirections" [latitude]="currentLoc.latitude" [longitude]="currentLoc.longitude">
    <agm-direction 
        [origin]="currentLocation" 
        [destination]="destinationLocation"
        (onResponse)="onResponse($event)"
    >
    </agm-direction>
</agm-map>

<div class="d-flex justify-content-center mt-3">
  <div class="row w-100 max-w-900">
    <div class="col-lg-4 col-sm-5 order-sm-3">
      <h3 class="highlight-text my-2">From my location</h3>
      <!-- <h5 class="text-left highlight-text">From my location</h5> -->
      <div [@fadein] *ngIf="locationPermission" class="main-text">
        <div class="row main-text">
          <div >Direct route:</div>
          <div class="right">{{ distanceFromLocation | number: "1.0-0" }} km</div>
        </div>
        <div [@fadein] *ngIf="drivingDistance" class="row main-text">
          <div >Road distance:</div>
          <div class="right">{{ drivingDistance }}</div>
        </div>
        <div [@fadein] *ngIf="drivingDuration" class="row main-text">
          <div >Road duration:</div>
          <div class="right">{{ drivingDuration }}</div>
        </div>
        <div [@fadein]  *ngIf="noDirections">Not able to calculate direction</div>
      </div>
      <div *ngIf="!locationPermission" class="row main-text">
        <button
          title="Calculate distance from your current location"
          class="red-button text-nowrap"
          [ngClass]="{ grey: locationPermission === false }"
          (click)="calculateDistance()"
        >
          {{ buttonText }}{{ dots }}
        </button>
        <div class="position-relative w-100 pt-5">
          <div
            *ngIf="isLoading"
            [ngClass]="{
              visible: isLoading
            }"
            class="load-container"
          >
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 order-sm-1">
      <h3 class="highlight-text my-2">From the nearest airport</h3>
      <div *ngFor="let airport of currentLoc.airports">
        <div class="row main-text">
          <div>{{ airport?.name }}, {{ airport?.city }}</div>
          <div class="right">{{ airport?.distance }} km</div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-sm-1 order-sm-2"></div>

  </div>
</div>
