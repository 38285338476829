import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { FilterLocationList } from '../../states/location/location.action';
import { Select, Store } from '@ngxs/store';
import { LocationState } from '../../states/location/location.state';
import { Location } from '../../states/location/entities/location';
import { Router } from '@angular/router';
import { AuthState } from '../../states/auth/auth.state';
import { AuUser } from '../../states/auth/entities/user_interface';
import { ModalService } from '../../services';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search_bar.component.html',
  styleUrls: ['./search_bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  isClosed = false;
  currentItem = null;
  subscription: Subscription;
  modalSubscription: Subscription;
  searchPage: boolean;

  constructor(
    private router: Router,
    private store: Store,
    private modalService: ModalService,
    private analytics: AngularFireAnalytics
  ) {
    this.modalSubscription = modalService.onChange$.subscribe((x) => {
      this.isClosed = x;
      if (this.isClosed === true) {
        localStorage.removeItem('ratoongRedirect');
        this.checkChanges();
      }
    });
  }

  @Select(LocationState.filteredLocations) filteredList: Observable<Location[]>;
  model: any;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @Select(AuthState.loggedInUser) currentUser: Observable<AuUser>;
  currentU: AuUser;

  ngOnInit(): any {
    this.subscription = this.currentUser.subscribe((data) => {
      this.currentU = data;
    });
    this.searchPage = this.router.url === '/search-ski-resorts';
  }

  formatter = (x: { name: string }) => x.name;
  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) => {
        if (term.length >= 2) {
          const newArray = [];
          this.store.dispatch(new FilterLocationList({ query: term }));
          this.filteredList.subscribe((data) => {
            data.forEach((obj) => {
              newArray.push({
                id: obj.id,
                name: obj.cityName + ' , ' + obj.name + ' , ' + obj.countryName,
                town: obj.cityName,
              });
            });
          });
          return newArray.splice(0, 20);
        } else {
          return [];
        }
      })
    );
  }

  selectedItem(item, content): any {
    if (
      this.router.url === '/search-ski-resorts'
    ) {
      this.analytics.logEvent('RAT_user_searched', {searchMethod: 'name'});
      this.analytics.logEvent('RAT_user_clicked_single_resort', { town: item.name } );

      this.router.navigate([
        '/search-ski-resorts/' + item.item.town + '/' + item.item.id,
      ]);
    } 
    else {
      this.currentItem = item;
      //this.checkChanges(content);
      this.router.navigate([
        '/rate-ski-resort/' + item.item.town + '/' + item.item.id,
      ]);
    }
  }
  
  

  checkChanges(content?: any): any {
    //if (this.currentU && this.currentItem) {
    if (this.currentItem) {
      // Commented out for now, as this is on the rating page only
      // this.analytics.logEvent('RAT_user_searched', {searchMethod: 'name'});
      // this.analytics.logEvent('RAT_user_clicked_single_resort', { town: this.currentItem.item.town } );
      
      this.router.navigate([
        '/rate-ski-resort/' +
          this.currentItem.item.town +
          '/' +
          this.currentItem.item.id,
      ]);
    } 
    else if (content && this.currentItem) {
      console.log('with user', this.currentU)
      // Set the redirect link so we can redirect user after signin with redirect (messenger app only)
      localStorage.setItem('ratoongRedirect', '/rate-ski-resort/' + this.currentItem.item.town + '/' + this.currentItem.item.id);
      this.modalService.open('Login-Popup', content, 'lg');
      //this.modalService.onChange(false);
    }
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
    this.modalSubscription.unsubscribe();
  }
}
