import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent implements OnInit {
  @Output() userClosedPrompt = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  closePrompt(): void {
    this.userClosedPrompt.emit();
  }
}
