import { RangeQuestion } from '../../../shared/states/auth/entities/preference_entities/range_question';
import { ModalService } from '../../../shared/services';
import { UserSearchPreference } from '../../../shared/states/auth/entities/preference_entities/user_search_preference';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-my-preferences',
  templateUrl: './my_preferences.component.html',
  styleUrls: ['./my_preferences.component.scss'],
})
export class MyPreferencesComponent implements OnInit {
  // Input is the search preferences object
  @Input() preferences: UserSearchPreference;
  // The questions to display
  @Input() parameters: any;
  // Output event that triggers filter
  @Output() valueEmitter = new EventEmitter();
  @Output() loadEmitter = new EventEmitter();

  // Title of an individual question
  questionTitle: string;
  // Answers to an individual select question
  options: object[];
  // The currently selected range question (used to keep track of which modal is open)
  rangeQuestion: RangeQuestion;

  // To store number of images loaded. Initialisaed to 0
  count: number;
  // To hold the total number of images to load
  imagesToLoad: number;

  // Modal for the select options (multiple choice)
  openSelectModal(id: string, content, selectedQuestion: string): any {
    this.parameters.multipleChoice.forEach((question) => {
      if (question.group === selectedQuestion) {
        this.options = question.options;
        this.questionTitle = question.group;
        this.modalService.open(id, content, 'lg');
      }
    });
  }

  // Modal for the slider/range question (e.g. price level)
  openSliderModal(id: string, content, selectedQuestion: string): any {
    this.parameters.rangeQuestions.forEach((question) => {
      if (question.name === selectedQuestion) {
        this.rangeQuestion = question;
        this.modalService.open(id, content, 'lg');
      }
    });
  }

  // Causes the parent component (new-search) to run the filter
  optionSelected(): any {
    this.valueEmitter.emit();
  }

  // Called whenever an image loaded. Loading completed when all images loaded
  imageLoaded(): any {
    this.count++;
    if (this.count === this.imagesToLoad) {
      // When all images loaded, to emit to new-search.component
      this.loadEmitter.emit(false);
    }
  }

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    // Set loading to true until all images have been loaded
    this.loadEmitter.emit(true);
    this.count = 0;
    this.imagesToLoad =
      this.parameters.multipleChoice.length +
      this.parameters.rangeQuestions.length;
  }

  // Returns true if any of the quesitons within the selected category are selected.
  userHasSelectedOne(question: any): boolean {
    if (!this.preferences.multipleChoice) return false;
    let selectedFlag = false;
    for (let i = 0; i < this.preferences.multipleChoice.length; i++) {
      if (
        question.options.some((option) => {
          return (
            option.questionID === this.preferences.multipleChoice[i].questionID
          );
        })
      ) {
        selectedFlag = true;
      }
    }
    return selectedFlag;
  }

  // Returns true if the slider question has been specified by the user.
  userSpecifiedRange(question: any): boolean {
    if (!this.preferences.rangeQuestions) return false;
    let selectedFlag = false;
    for (let i = 0; i < this.preferences.rangeQuestions.length; i++) {
      if (
        this.preferences.rangeQuestions[i].questionID === question.questionID
      ) {
        selectedFlag = true;
      }
    }
    return selectedFlag;
  }
}
