
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class DevConsoleService {
  database: any = null;

  constructor() {
    this.database = firebase.database();
  }

  async deleteUnusedUsers(uid: string): Promise<any>  {
    const deleteUnusedUsers = await firebase.functions().httpsCallable('deleteUnusedUsers');
    return deleteUnusedUsers({uid});
  }
}
