import { ModalService } from '../../../../services';
import { first, takeUntil } from 'rxjs/operators';
import { LoginWithEmail } from '../../../../states/auth/auth.action';
import { Actions, Store, ofActionErrored } from '@ngxs/store';
import { Subject } from 'rxjs';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-login-email',
  templateUrl: './login_email.component.html',
  styleUrls: ['./login_email.component.scss'],
})
export class LoginEmailComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;
  @Output() closeModal = new EventEmitter();

  fieldTextType = 'password';
  loginForm: FormGroup;
  errorMessage: boolean;
  isLoading = false;

  private ngUnsubscribe = new Subject();
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private actions$: Actions,
    private store: Store,
    private modalService: ModalService
  ) {
    this.actions$
      .pipe(ofActionErrored(LoginWithEmail), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.errorMessage = true;
      });
  }
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100),
        ])
      ),
    });
    // When the user begins to type their password again, we remove the errorMessage
    this.onInputChange();
  }

  async onSubmit(): Promise<any> {
    this.errorMessage = false;
    this.isLoading = true;
    this.submitted = true;
    // If invalid email or not entered, to display an error message
    if (this.loginForm.invalid) {
      this.isLoading = false;
      return;
    }

    this.store
      .dispatch(
        new LoginWithEmail(this.userEmail, this.loginForm.value.password)
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.closeModal.emit();
        },
        (error) => {
          this.isLoading = false;
          this.errorMessage = true;
        }
      );
  }
  // convenience getter for easy access to form fields
  get f(): any {
    return this.loginForm.controls;
  }

  changeInputType(value: string): void {
    this.fieldTextType = value;
  }

  onInputChange(): any {
    this.loginForm.valueChanges.subscribe((val) => {
      this.errorMessage = false;
    });
  }

  open(id, content): any {
    this.modalService.open(id, content);
  }
}
