import { searchParameters } from '../../auth/entities/preference_entities/search_parameters';
import { newSearchParameters } from '../../auth/entities/preference_entities/new_params';
import { RangeQuestion } from '../../auth/entities/preference_entities/range_question';
import { UserSearchPreference } from '../../auth/entities/preference_entities/user_search_preference';
import { Country } from '../entities/countries';
import { Location } from '../entities/location';

export class LocationFilter {
  // Id of the new parameters. Can remove this when we have more data on them.
  newParams: number[] = newSearchParameters;
  diacritics = {
    a: 'àáâãäåāą',
    c: 'çćč',
    d: 'đď',
    e: 'èéêëěēę',
    i: 'ìíîïī',
    l: 'ł',
    n: 'ñňń',
    o: 'òóôõöøō',
    r: 'ř',
    s: 'šśß',
    t: 'ť',
    u: 'ùúûüůū',
    y: 'ÿý',
    z: 'žżź',
  };
  filterCountriesByRegion(region: string, ListOfCountries: Country[]): any {
    return ListOfCountries.filter((country) => {
      return country.region.includes(region);
    });
  }

  // The passmark for multiChoice params
  passmark = 4;

  parameters = searchParameters;

  /**
   * God method that would split to specialised methods.
   * Example: if params has "query" (filtering by string). Then call filterByString
   * if params has "rating" (filtering by specific rating) then call filterByRating
   */
  filterLocations(params: object, locationList: Location[]): any {
    if (params.hasOwnProperty('query')) {
      // @ts-ignore
      return this.filterByString(params.query, locationList);
    }
    if (params.hasOwnProperty('Rating')) {
      // @ts-ignore
      return this.filterByRating(params.Rating, locationList);
    }
  }

  private filterByString(query: string, locationList: Location[]): any {
    let filteredList: any[];
    if (query !== '' && locationList && locationList.length !== 0) {
      filteredList = locationList.filter((el) => {
        if (el.cityName && el.countryName && el.name) {
          return (
            this.replaceDiacritics(
              `${el.name} - ${el.countryName} - ${el.cityName}`
                .toLowerCase()
                .replace('.', '')
            ).indexOf(query.toLowerCase().replace('.', '')) > -1 ||
            `${el.name} - ${el.countryName} - ${el.cityName} `
              .toLowerCase()
              .replace('.', '')
              .indexOf(query.toLowerCase().replace('.', '')) > -1
          );
        } else {
          return false;
        }
      });
    } else {
      filteredList = [];
    }
    return filteredList;
  }

  replaceDiacritics(text: string): any {
    for (const toLetter in this.diacritics) {
      if (this.diacritics.hasOwnProperty(toLetter)) {
        for (
          let i = 0,
            ii = this.diacritics[toLetter].length,
            fromLetter,
            toCaseLetter;
          i < ii;
          i++
        ) {
          fromLetter = this.diacritics[toLetter][i];
          if (text.indexOf(fromLetter) < 0) {
            continue;
          }
          toCaseLetter =
            fromLetter === fromLetter.toUpperCase()
              ? toLetter.toUpperCase()
              : toLetter;
          text = text.replace(new RegExp(fromLetter, 'g'), toCaseLetter);
        }
      }
    }
    return text;
  }

  private filterByRating(questionID: number, locationList: Location[]): any {
    if (questionID === 0) {
      return locationList
        .slice()
        .sort(
          (a, b) => parseFloat(b.generalScore) - parseFloat(a.generalScore)
        );
    } else {
      const newestArray = locationList.map((data) => {
        let scoreToDisplay = '0';
        const value = data.locationScores.find(
          (i) => Number(i.questionID) === questionID
        );
        if (value != null) {
          scoreToDisplay = value.overallScore;
        }
        return {
          id: data.id,
          scoreToDisplay,
          name: data.name,
          countryName: data.countryName,
          cityName: data.cityName,
          generalScore: data.generalScore,
          locationScores: data.locationScores,
        } as Location;
      });
      return newestArray
        .slice()
        .sort(
          (a, b) => parseFloat(b.scoreToDisplay) - parseFloat(a.scoreToDisplay)
        );
    }
  }

  // Applies all filters from the user preferences to the list of all locations
  newFilterLocations(
    preferences: any, // changed to any for now for backwards compatability
    latitude: number,
    longitude: number,
    locations: Location[]
  ) {
    // If only new parameters are selected, they will effectively not be excluded (so as to not produce 0 results)
    let onlyNewParams = this.onlyNewParamsSelected(preferences);
    // Make a copy of the locations list so that we can edit it (by adding the percentage match)
    let allLocations = JSON.parse(JSON.stringify(locations));
    // Loop through each location
    allLocations.forEach((location) => {
      // Start by resetting the % match score to 0
      this.resetScores(location);
      // Then run all the filters again
      if (preferences.multipleChoice)
        this.filterMultiChoice(location, preferences.multipleChoice);
      if (preferences.rangeQuestions)
        this.filterRangeQuestions(location, preferences.rangeQuestions);
      // Calculate the percentage match
      location.percentageMatch =
        (location.matchingScores * 100) / location.numberOfQuestions;
    });
    return allLocations.filter((location) => {
      // We now filter the location based on the countries, airport, home and altitude distances
      // These are HARD filters, meaning that if they don't meet the criteria specified by the user they are excluded from the results
      let filterThisLocation =
        this.filterCountries(location, preferences.countries) &&
        this.filterAirportDistance(location, preferences.airportDistance) &&
        this.filterHomeDistance(
          location,
          preferences.homeDistance,
          latitude,
          longitude
        ) &&
        this.filterAltitude(location, preferences.minAltitude);
      // Finally, we remove all 0% matches UNLESS the user has specified ONLY parameters OR if they have not specified any parameters other than country/distance
      if (location.numberOfQuestions != 0 && !onlyNewParams) {
        return filterThisLocation && location.percentageMatch >= 1;
      } else {
        return filterThisLocation;
      }
    });
  }

  // At the start of each filter, reset both scores back to 0
  // The number of questions stores the total number of options/questions specified by the user
  // The score stores the total number of options/questions that have passed the parameters specified by the user
  // They are used for calculating how closely locations match their specified options/questions
  resetScores(location: any) {
    location['numberOfQuestions'] = 0;
    location['matchingScores'] = 0;
    location['percentageMatch'] = 0;
  }

  // ******** -------- FILTER FUNCTIONS -------- ********

  // This function loops through all of the multiple choice questions and each specific rating criteria
  // Returns false if the provided location does not pass all of the filters
  filterMultiChoice(location: any, multipleChoice: any[]) {
    // Loop through the multiple choice questions
    multipleChoice.forEach((question) => {
      // If the option is selected, we add 1 to the number of matching questions
      location['numberOfQuestions']++;
      // *** TODO: add questionIDs to 'defaultPreferences.ts' for all of the new parameters that don't yet have them (after checking them with Eg) ***
      // *** can then delete '&& questionID != undefined' ***
      if (question.questionID != undefined) {
        // Now we have the specific criteria, we can loop through the scores in the
        // current location, checking if this location has a score for this particular criteria.
        location.locationScores.forEach((score) => {
          // If the location has a rating for the specific criteria, and the rating is more than the this.passmark...
          if (
            score.questionID == question.questionID &&
            parseFloat(score.overallScore) > this.passmark
          ) {
            location['matchingScores']++;
          }
        });
      }
    });
  }

  // Newer method that handles each range question differently.
  // If it is PRICE then it includes resorts with price LESS than that specified. If it is DIFFICULTY then it includes resorts that have scores CLOSE to the specified score
  // MESSY FUNCTION I KNOW, BUT HAD LITTLE TIME FOR IMPLEMTING IT DIFFERENTLY
  filterRangeQuestions(location: any, rangeQuestions: RangeQuestion[]) {
    // Loop through the range choice questions
    rangeQuestions.forEach((question) => {
      // If this question is one of the user specified parameters we add one to the number of questions
      location['numberOfQuestions']++;
      location.locationScores.forEach((score) => {
        // If the price is less than that specified, we want to increase the location score
        if (
          question.name == 'Price level' &&
          score.questionID == question.questionID &&
          parseFloat(score.overallScore) <= parseFloat(question.value)
        ) {
          location['matchingScores']++;
        }
        // If the difficulty is +/- 1 of that specified by the user, we want to increase the location score
        else if (
          question.name == 'Difficulty level' &&
          score.questionID == question.questionID &&
          this.plusOrMinusOne(
            parseFloat(score.overallScore),
            parseFloat(question.value)
          )
        ) {
          location['matchingScores']++;
        }
      });
    });
  }

  // Returns true if the location score is close to (+/- 1) the level specified by the user
  plusOrMinusOne(locationScore: number, specifiedLevel: number): boolean {
    return (
      locationScore < specifiedLevel + 1 && locationScore > specifiedLevel - 1
    );
  }

  filterCountries(location: any, countries: string[]) {
    let includedCountries: string[];
    // If the selected countries is 0 (user hasn't picked any) then they are shown all resorts
    // As soon as they start to select countries, they are filtered based on their chosen countries
    if (countries.length == 0) {
      // if no selected countries then present all of them
      return true;
    } else {
      // Else, present just their selected ones
      includedCountries = countries;
    }
    // Filter based on included countries
    return includedCountries.includes(location.countryName);
  }

  filterAirportDistance(location: any, airportDistance: any) {
    let airportDistanceKm = parseInt(airportDistance);
    // If airport distance is 0, the question is disabled and we don't want to do anything
    if (airportDistanceKm == parseInt(this.parameters.airportDistance.max)) {
      return true;
    } else {
      return location.minAirportDistance < airportDistanceKm;
    }
  }

  filterHomeDistance(
    location: any,
    homeDistance: any,
    latitude: number,
    longitude: number
  ) {
    let homeDistanceKm = parseInt(homeDistance);
    // If home distance is 0 or if we don't have latitude/longitude the question is disabled and we don't want to do anything
    if (
      homeDistanceKm == parseInt(this.parameters.homeDistance.max) ||
      latitude == null ||
      longitude == null
    ) {
      return true;
    } else {
      let distance = this.distanceInKm(
        location.latitude,
        location.longitude,
        latitude,
        longitude
      );
      return distance < homeDistanceKm;
    }
  }

  filterAltitude(location: any, minAltitude: any) {
    let minAltitudeMeters = parseInt(minAltitude);
    // If the question is disabled, (i.e. altitude is '0') we don't want to filter out any results
    if (minAltitudeMeters == parseInt(this.parameters.minAltitude.min)) {
      return true;
    } else {
      return location.minAltitude > minAltitudeMeters;
    }
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  distanceInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
    var R = 6371; // radius of earth in km
    var dLat = this.toRadians(lat2 - lat1); // Convert degrees to radians
    var dLon = this.toRadians(lon2 - lon1);
    var lat1 = this.toRadians(lat1);
    var lat2 = this.toRadians(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distance = R * c; //convert distance in km
    return distance;
  }

  // Converts numeric degrees to radians
  toRadians(Value: number) {
    return (Value * Math.PI) / 180;
  }

  // Returns true if the user has only selected the new parameters
  // Eventually we can take this out (when we have more data on the new parameters)
  onlyNewParamsSelected(preferences: UserSearchPreference): boolean {
    // Initialise a flag. This will keep track of whether any old questions are in the users select
    let flag = true;
    if (preferences.multipleChoice) {
      preferences.multipleChoice.forEach((question) => {
        if (!this.newParams.includes(question.questionID)) {
          flag = false;
        }
      });
    }
    if (preferences.rangeQuestions) {
      preferences.rangeQuestions.forEach((question) => {
        if (!this.newParams.includes(question.questionID)) {
          flag = false;
        }
      });
    }

    return flag;
  }

  // A temporary function to be used on the homepage for displaying a list of specific resorts
  // Eventually when we have more data and photos of other resorts then we can display any resorts and this won't be needed
  filterMultipleIds(ids: number[], locations: Location[]): Location[] {
    let locationArray = locations.filter((location) => {
      return ids.includes(location.id);
    });
    return locationArray;
  }

  // A function that returns a list of matching parameters and nonMatchingParameters, given a location and the users preferences
  // Used by the results page (list page)
  // I am sure there is a cleaner way of doing this. However it will be changing soon with the new search algorithm so I don't want to spend too much time
  getMatchingParameters(locationScores, multipleChoice, rangeQuestions) {
    let matchingParameters = [];
    let nonMatchingParameters = [];
    // Check if the user has selected multipleChoice parameters
    if (multipleChoice) {
      // Loop through each parameter in the users multiChoice preferences
      multipleChoice.forEach((parameter) => {
        // Find the corresponding question in the location
        let currentQuestion = locationScores.find((question) => {
          return question.questionID == parameter.questionID;
        });
        if (
          currentQuestion &&
          parseFloat(currentQuestion.overallScore) > this.passmark
        ) {
          matchingParameters.push(parameter);
        } else {
          nonMatchingParameters.push(parameter);
        }
      });
    }
    // Check if the user has selected range parameters
    if (rangeQuestions) {
      // Loop through each parameter in the users rangeQuestion preferences
      rangeQuestions.forEach((parameter) => {
        // Find the corresponding question in the location
        let currentQuestion = locationScores.find((question) => {
          return question.questionID == parameter.questionID;
        });
        // If the price is less than that specified then it matches
        if (currentQuestion && parameter.name == 'Price level') {
          if (
            parseFloat(currentQuestion.overallScore) <=
            parseFloat(parameter.value)
          ) {
            matchingParameters.push(parameter);
          } else {
            nonMatchingParameters.push(parameter);
          }
        }
        // If the difficulty is +/- 1 of that specified by the user then it matches
        else if (currentQuestion && parameter.name == 'Difficulty level') {
          if (
            this.plusOrMinusOne(
              parseFloat(parameter.value),
              parseFloat(currentQuestion.overallScore)
            )
          ) {
            matchingParameters.push(parameter);
          } else {
            nonMatchingParameters.push(parameter);
          }
        } else {
          nonMatchingParameters.push(parameter);
        }
      });
    }

    return { matchingParameters, nonMatchingParameters };
  }
}
