import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SharedEventService } from '../shared/services/shared-event-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent implements OnInit, AfterViewInit {

  private routerSubs: Subscription;
  private fragment: string;

  constructor(private sharedEventService: SharedEventService, router: Router, private route: ActivatedRoute) {
    // this.routerSubs = router.events.subscribe(s => {
    //   if (s instanceof NavigationEnd) {
    //     const tree = router.parseUrl(router.url);
    //     this.fragment = tree.fragment
    //   }
    // });
  }


  public members: Array<object> = [
    {
      name: 'MAŠA ',
      text: `Maša is our CPO and design guru. She is leading the development of the platform and all our design when she is
      not out skiing or in learning mode. Maša comes from a very beautiful country that for some reason isn’t that know for
      skiing, despite having a lot of mountains and great ski resorts - Slovenia. Today Maša is living in Seattle close
      to a lot of good skiing in the USA and Canada.`,
      img: 'https://i.imgur.com/9tqk4OO.png',
      ldin: 'https://www.linkedin.com/in/masa-wu/'
    },
    {
      name: 'SAM',
      text: `Sam is our Head of Backend Development. He is leading our backend development team and juggling with our database.
      He has only ridden skis twice in his life. First time as a kid but after joining Ratoong he has been skiing again and
      had great fun. Sam is originally from Australia - did you know there are several ski resorts on the East Coast of Australia?
      Sam holds a degree from University of Technology Sydney, and met Eg while studying at the IT University, Copenhagen.`,
      img: 'https://firebasestorage.googleapis.com/v0/b/ratoong-dev.appspot.com/o/service-images%2Fabout-avatars%2FSam.png?alt=media&token=b96d72dd-b27d-4819-8bad-fa3cf40cb7c8',
      ldin: 'https://www.linkedin.com/in/sam-parkinson-03126738/'
    },
    {
      name: 'HENNING',
      text: `Henning our CIO, has several years of development experience. He is the CEO of Source Angel, a development
      company in Ukraine that has more than 100 developers. But next to that he leads our team of outsourced developers
      in Source Angel. Henning has a master’s degree from Aalborg University in International Technology Management.
      Henning loves his yearly ski holiday with the boys!`,
      img: 'https://i.imgur.com/Py81jb0.png',
      ldin: 'https://www.linkedin.com/in/henninggh/'
    },
    {
      name: 'LISE',
      text: `Lise is our CSO. She is also our one and only Pro-skier. She leads our business development team but
      she is also our legal expert. She is responsible for building our network within the ski community around the globe.
      Lise has a Master Of Laws from Århus University and afterward she has worked with legal matters in many aspects,
      but she prefers to ski. If you wanna catch Lise on skis you need to be very fast and skilled.`,
      img: 'https://image.ibb.co/jmcCbz/my_Avatar_Lise.png',
      ldin: 'https://www.linkedin.com/in/liseroulund/'
    },
    {
      name: 'EMILY',
      text: `Emily is the CMO of Ratoong. She is leading our marketing and social media department and working to grow
      Ratoong’s users. She began skiing when she was 4, but became a snowboarder at age 10 and has since worked
      snow seasons out in Lake Louise, Alberta. She has ridden over a dozen different resorts across North America and
      is excited to ski more of Europe! She is a proud Canadian, but huge traveler and she is excited to bring Ratoong
      all around the world.`,
      img: 'https://firebasestorage.googleapis.com/v0/b/ratoong-dev.appspot.com/o/service-images%2Fabout-avatars%2FEmily.svg?alt=media&token=c323b04a-1478-4b44-844d-d2e8e8949b7c',
      ldin: 'https://www.linkedin.com/in/emily-bassett-4917b6171/'
    },
    {
      name: 'ANDREEA',
      text: `Andreea is our Front-end developer and design specialist. She is the one seeing that our users get the best
      experience when using Ratoong. Besides working in Ratoong, Andreea studies Web Development (BA) at the Copenhagen
      School of Design and Technology - KEA. Andreea is originally from Romania - where surprisingly, you can ski!`,
      img: 'https://firebasestorage.googleapis.com/v0/b/ratoong-dev.appspot.com/o/service-images%2Fabout-avatars%2FAndreea.svg?alt=media&token=f6a198ef-6f47-455f-b154-b545aba2a5f0',
      ldin: 'https://www.linkedin.com/in/andreea-steriu-68b123173/'
    },
    {
      name: 'EG',
      text: `Eg is our CEO and the guy who started Ratoong. He is an “Old” guy that got tired of being an Executive
      Leader in the corporate world and decided to join the start-up community some years ago. He is a pretty fanatic skier
      and would anytime prefer to ski than go to the beach - it’s a bit silly when he is living next to the sea, and in a
      country with no snow or mountains. Eg holds an MBA In Technology; Market & Organisation.`,
      img: 'https://firebasestorage.googleapis.com/v0/b/ratoong-dev.appspot.com/o/service-images%2Fabout-avatars%2FEg.png?alt=media&token=e1cfe40e-6829-423c-88c2-6b9fdab371f2',
      ldin: 'https://www.linkedin.com/in/egnicolajsen/'
    }
  ];

  ngOnInit(): any {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngAfterViewInit(){
    // if(this.fragment){
    //   const element = window.document.querySelector('#' + this.fragment);
    //   if (element) {
    //     element.scrollIntoView(true);
    //     window.scrollBy(0, -60);
    //   }
    // }
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
      window.scrollBy(0, -60);
    } catch (e) { }
  }

  showFbMessenger(): void {
    this.sharedEventService.showFbMessengerEvent.emit(true);
  }
}
